import { Component, ElementRef, Input } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-line-chart-consumption',
  template: `
    <figure id="line-chart-consumption"></figure>
  `,
  styleUrls: []
})
export class LineChartConsumptionComponent {
  @Input() data: any;
  @Input() public start_date: any;
  @Input() public end_date: any;

  private svg: any;
  width = 250;
  height = 270;
  margin = 40;
  radius: any;
  duration = 250;

  public yScale: any;
  public xScale: any;
  public xAxis: any;
  public yAxis: any;
  public lineGroup: any;

  lineOpacity = "1";
  lineOpacityHover = "0.85";
  otherLinesOpacityHover = "0.1";
  lineStroke = "1.5px";
  lineStrokeHover = "2.5px";

  circleOpacity = '1';
  circleOpacityOnLineHover = "0.25"
  circleRadius = 12;
  circleRadiusHover = 12;

  constructor(
    public chartElem: ElementRef,
  ) { }

  ngOnInit(): void {
    this.createSvg();
    this.drawChart(this.data);
  }

  private createSvg(): void {
    const myElement = document.getElementById('cardConsumptions');
    if (myElement) {
      this.width = myElement.getBoundingClientRect().width - 60;
    }

    this.svg = d3.select("figure#line-chart-consumption")
        .append("svg")
        .attr("width", this.width + this.margin)
        .attr("height", this.height + this.margin)
        .append("g")
        .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
  }

  simpleGroup(array: any[], key: string) {
    const groupedData = array.reduce((acc, obj) => {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(obj);
      return acc;
    }, {});
  
    return { keys: Object.keys(groupedData), data: groupedData };
  }

  groupBy(array: any[], key: string) {
    const groupedData = array.reduce((acc, obj) => {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(obj);
      return acc;
    }, {});
  
    return { keys: Object.keys(groupedData), data: groupedData };
  }

  private drawChart(data: any[]): void {    

    // console.log("Line Chart Consumptions data ====> ", data);
    
    if (data != undefined && data.length === 0) {
      return;
    }

    this.svg.selectAll("*").remove();

    // let startDate = new Date(`${this.start_date.year}-${this.start_date.month}-${this.start_date.day}`);
    // let endDate = new Date(`${this.end_date.year}-${this.end_date.month}-${this.end_date.day}`);

    /*const groupedData = this.groupBy(data, 'tanque_id');
    const groupdWeeks = this.simpleGroup(data, 'week');

    // console.log('GROUPED DATA ===> ', groupedData);
    // console.log('GROUPED WEEKS ===> ', groupdWeeks);

    let mainData: any = [];
    groupdWeeks.keys.forEach((key: any) => {
      // console.log('KEY ===> ', key);
      // console.log('DATA ===> ', groupdWeeks.data[key]);
      let mean = d3.mean(groupdWeeks.data[key], (d: any) => d.liters);
      // console.log('MEAN ===> ', typeof mean, mean);
      mainData.push({
        week: parseInt(key),
        liters: mean
      })
    });*/

    // console.log('MAIN DATA ===> ', mainData.sort((a: any, b: any) => a.week - b.week));

    this.xScale = d3.scaleLinear()      
      .domain([d3.min(data, (d: any) => d.week) - 1, d3.max(data, (d: any) => d.week) + 1])
      .range([0, this.width - this.margin]);
      

    this.yScale = d3.scaleLinear()
      .domain([0, d3.max(data, (d: any) => d.liters)])
      .range([this.height-this.margin, 0]);

    let controller = this;
    let color = d3.scaleOrdinal(d3.schemeCategory10);

    const lines = this.svg
      .append('g')
      .attr('class', 'lines');

    const bars = this.svg
      .append('g')
      .attr('class', 'bars');

    // const subgroups = groupedData.keys;

    // var xSubgroup = d3.scaleBand()
      // .domain(subgroups)
      // .range([0, this.xScale.bandwidth()])
      // .padding(0.05)

    lines
      .datum(data)
      .append('path')
      .attr("d", d3.line()
        .x((d: any) => this.xScale(d.week))
        .y((d: any) => this.yScale(d.liters))
      )
      .style('fill', 'none')
      .attr("stroke", "orange")
      .attr("stroke-width", 4)
      .style('opacity', this.lineOpacity);
    
    // groupedData.keys.forEach((key: any, i: any) => {
      // console.log('KEY ===> ', key);
      // console.log('GROUPED DATA ===> ', groupedData.data[key]);
      // lines
        // .datum(groupedData.data[key])
        // .append('path')
        // .attr("d", d3.line()
          // .x(function(d: any) { return controller.xScale(d.week) })
          // .y(function(d: any) { return controller.yScale(d.liters) })
        // )
        // .style('fill', 'none')
        // .attr("stroke", color(i))
        // .attr("stroke-width", 3)
        // .style('opacity', this.lineOpacity);

      // bars
        // .selectAll('bars')
        // .data(groupedData.data[key])
        // .enter()
        // .selectAll('g')
        // .data(function(d: any) {
          // return subgroups.map((key) => {
            // // // console.log("KEY => ", key);
            // // // console.log("d => ", d);
            // // // console.log("D KEY => ", d[key]);
            // return {
              // key: key, value: d.tanque_id, liters: d.liters, week: d.week
            // };
          // });
        // })
        // .enter().append("rect")
        // .attr("x", (d: any) => {
          // // // console.log("RECT XD ===> ", d);
          // return xSubgroup(d.key)
        // })
        // .attr("width", xSubgroup.bandwidth()/4)
        // .attr("height", (d: any) => this.height - controller.yScale(d.liters))
        // .attr("fill", function(d: any) { return color(i); });
    // })


    // .attr("x", (d: any) => controller.xScale(d.week))
    // .attr("y", (d: any) => controller.yScale(d.liters))
    // .attr("width", controller.xScale.bandwidth()/2)
    // .attr("height", (d: any) => this.height - controller.yScale(d.liters))
    // .attr("fill", color(i));

    // console.log("BEFORE MAIN DATA =====> ", mainData);

    

    // var tooltip = d3.select("figure#line-chart-consumption")
      // .append("div")
      // .style("position", "absolute")
      // .style("z-index", "10000")
      // .style("visibility", "hidden")
      // .style("background-color", "white")
      // .style("border", "solid")
      // .style("border-width", "1px")
      // .style("border-radius", "5px")
      // .style("padding", "10px")
      // .text("I'm a circle!");

    // var mouseover = (d: any) => {
      // // console.log("MOUSE OVER => ", d);
      // tooltip
        // .style("opacity", 1)
      // d3.select(d)
        // .style("stroke", "black")
        // .style("opacity", 1)
    // }

    var tooltip = d3.select("figure#line-chart-consumption")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10000")
      .style("visibility", "hidden")
      .style("background-color", "white")
      .style("border-radius", "15px")
      .style("padding", "10px")
      .style("box-shadow", "0 0 5px rgba(0,0,0,0.4)")
    
    const cricles = this.svg
      .append('g')
      .attr('class', 'circles');

    cricles
      .selectAll(".circle")
      .data(data)
      .enter()
      .append('circle')      
      .attr("class", "circle")
      .attr("cx", (d: any) => controller.xScale(d.week))
      .attr("cy", (d: any) => controller.yScale(d.liters))
      .attr("r", controller.circleRadius)
      .style('opacity', controller.circleOpacity)
      .attr('fill', 'orange')
      .on("mouseover", (d: any, i: any) => {
        tooltip
          .html(`
            <h5>Resumen</h5>
            <p class="mb-0">${i.week} Semana</p>
            <p class="mb-0">${i.liters.toFixed(2)} Lts.</p>
          `)
          .style("visibility", "visible")
          .style('top', (d.offsetY - 10) + 'px')
          .style('left', (d.offsetX + 10) + 'px');
      })
      .on("mouseout", function(d: any) {
        tooltip.style("visibility", "hidden");
      });
    
    var xAxis = d3.axisBottom(this.xScale).ticks(3);
    var yAxis = d3.axisLeft(this.yScale).ticks(5);

    this.svg.append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${this.height-this.margin})`)
      .call(xAxis)
      .attr("stroke","#545454")
      .attr("stroke-width","3")
      .attr("opacity",".6")
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end")
      .attr("stroke-width","0")
      .attr("opacity","1")
      .style("font-size", "10px");

    this.svg.append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .attr("stroke","#545454")
      .attr("stroke-width","3")
      .attr("opacity",".6")
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("stroke-width","0")
      .attr("opacity","1")
      .append('text')
      .attr("y", 0)
      .attr("transform", "rotate(-90)")
      .attr("stroke-width","0")
      .attr("opacity","1")
      .text("Lts. totales");

  }

}
