import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-gas-meter-maintenance-remove',
  template: `
    <div #modalGasMeterMaintenanceRemove id="modalGasMeterMaintenanceRemove" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalGasMeterMaintenanceRemove" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Concluir mantenimiento de medidor de gas</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="icons.faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body p-4">
            <form [formGroup]="meterForm" (ngSubmit)="onSubmit()">
              <div class="form-group mt-2">
                <label for="consumption">Lectura posterior al mantenimiento</label>
                <input type="number" formControlName="last_read" class="form-control form-control-lg" id="last_read" placeholder="">
              </div>
              <div class="form-group mt-4 text-end">
                <button type="submit" class="btn btn-warning btn-lg rounded-5"><fa-icon [icon]="icons.faHammer" class="me-2"></fa-icon> Terminar mantenimiento</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalGasMeterMaintenanceRemoveComponent {
  @Input() meter: any;

  meterForm: FormGroup = new FormGroup({});

  constructor(
    public icons: IconsService,
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    // console.log('METER ==========> ', this.meter);
    this.initForm();
  }

  initForm() {
    this.meterForm = this.fb.group({
      id: [],
      last_read: [''],
      maintenance: [false],
    });

    this.meterForm.patchValue({ id: this.meter.id });
  }

  onSubmit() {
    this.store.updateGasMeter(this.meterForm.value.id, this.meterForm.value).subscribe({
      next: (res: any) => {
        this.toastr.success('Mantenimiento actualizado');
        this.store.getSettlement(this.meter.settlement_id);
      },
      error: (err: any) => {
        this.toastr.error('Error al actualizar mantenimiento');
      }
    });
  }

}
