import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import * as bs from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-bill',
  template: `
    <div class="d-none d-sm-block">
      <div class="row">
        <div class="col-12 col-sm-2 pt-0"> 
          <h3 class="mb-1 text-start float-start" style="line-height: 0.7;">
            <span class="mb-0"># {{bill.id}} </span><br>
            <i class="text-sm text-muted">{{bill.reference}}</i>
          </h3><br>
          <div class="badge rounded-pill bg-danger py-2 px-2" *ngIf="bill.date_status==='expirado'" placement="bottom" ngbTooltip="Este recibo expiró, ya no se puede pagar.">
            <fa-icon [icon]="icons.faCircleExclamation" style="font-size: 12px;" ></fa-icon>
            Expirado
          </div>
          <div class="badge rounded-pill bg-warning py-2 px-2" *ngIf="bill.date_status==='vencido'" placement="bottom" ngbTooltip="Este recibo no ha sido pagado aún, puede generar intereses por falta de pago.">
            <fa-icon [icon]="icons.faTriangleExclamation" style="font-size: 12px;"></fa-icon>
            Vencido
          </div>
          <div class="badge rounded-pill bg-info py-2 px-2" *ngIf="bill.date_status==='verify'" placement="bottom" ngbTooltip="Este recibo no ha sido pagado aún, puede generar intereses por falta de pago.">
            <fa-icon [icon]="icons.faSpinner" style="font-size: 12px;"></fa-icon>
            En validación
          </div>
        </div>
        <div class="col-2 mt-0" *ngIf="permissions?.modules?.admin.billing.pagos.write">
          <p class="mb-0" style="font-size: 17px;">{{bill?.unit_name}}</p>
          <p class="mb-0 text-sm" style="font-size: 13px;">{{bill?.settlement_name}}, {{bill?.building_name}}</p>
        </div>
        <div class="col-12 col-sm-2 text-center mt-0 mt-sm-2">
          <p class="mb-0">
            <span class="text-danger" *ngIf="bill.consumo_lt < 0"><fa-icon [icon]="icons.faCircleExclamation"></fa-icon></span>
            {{bill.consumo_lt | number: '1.2-2'}} Lts.
          </p>
        </div>
        <div class="col-12 col-sm-2 text-center mt-2">
          <p class="mb-0">{{bill.total | currency: 'USD'}}</p>
        </div>
        <div class="col-md-2 text-center mt-2">
          <p class="mb-1 ms-3">{{bill.created_at | date: 'dd/MM/yy'}}</p>
        </div>

        <div class="col-4 col-sm-1 text-center pt-2">
          <p class="mb-1 ms-3">{{bill.due_date | date: 'dd/MM/yy'}}</p>
        </div>
        <div class="col-12 col-sm-3 mt-2 mt-sm-0 text-end" *ngIf="permissions?.modules?.tenants.billing.active">
          <button class="btn btn-default" [disabled]="!bill.pdf" *ngIf="!bill.invoiced" (click)="downloadReceipt()">
            <!-- href="{{receiptUrl}}/public/{{bill.pdf}}" -->
            <fa-icon [icon]="icons.faDownload"></fa-icon>
            Descargar
          </button>
          <button class="btn btn-primary rounded-4 ms-2" (click)="gotoPayment(bill)"> <!-- *ngIf="this.bill.date_status!='expirado'" -->
            <!-- routerLink="/tenant/bill/{{bill.id}}" -->
            <fa-icon [icon]="icons.faCashRegister" class="m-1"></fa-icon>
            Pagar
          </button>
        </div>

        <div class="col-12 col-sm-1 mt-2 mt-sm-0 text-end" *ngIf="permissions?.modules?.admin.billing.pagos.write">
          <div ngbDropdown class="d-inline-block ms-2">
            <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1" id="dropdownBasic1" ngbDropdownToggle>
              <fa-icon [icon]="icons.faEllipsisVertical" class="m-1"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem routerLink="/pagos/recibos/{{bill.id}}" *ngIf="!this.can_pay">
                <fa-icon [icon]="icons.faEye"></fa-icon>
                Consultar recibo
              </button>
              <a ngbDropdownItem href="{{receiptUrl}}/public/{{bill.pdf}}" target="_blank" [disabled]="!bill.pdf" *ngIf="!bill.invoiced">
                <fa-icon [icon]="icons.faDownload"></fa-icon>
                Descargar recibo
              </a>
              
              <a ngbDropdownItem target="_blank" [disabled]="!bill.pdf" *ngIf="!bill.invoiced&&bill.state!=3" (click)="verificationBill()">
                <fa-icon [icon]="icons.faSpinner"></fa-icon>
                Marcar en validación
              </a>
              <a ngbDropdownItem target="_blank" (click)="unpublishBill()">
                <fa-icon [icon]="icons.faCircleExclamation"></fa-icon>
                Despublicar recibo
              </a>
              <!-- (click)="dispatchPayment()"  *ngIf="this.bill.date_status!='expirado'" -->
              <button ngbDropdownItem (click)="dispatchPayment()">
                <fa-icon [icon]="icons.faCashRegister" class="m-1"></fa-icon>
                Pagar recibo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block d-sm-none">
      <div class="row py-3 px-2">
        <div class="col-6">
          <h2 class="mb-1 text-start" style="line-height: 0.7;">
            <b class="mb-0"># {{bill.id}} </b><br>
            <i class="text-sm text-muted">{{bill.reference}}</i>
          </h2>
        </div>
        <div class="col-6">
          <div class="badge rounded-pill bg-danger py-2 px-2" *ngIf="bill.date_status==='expirado'" placement="bottom" ngbTooltip="Este recibo expiró, ya no se puede pagar.">
            <fa-icon [icon]="icons.faCircleExclamation" style="font-size: 12px;" ></fa-icon>
            Expirado
          </div>
          <div class="badge rounded-pill bg-warning py-2 px-2" *ngIf="bill.date_status==='vencido'" placement="bottom" ngbTooltip="Este recibo no ha sido pagado aún, puede generar intereses por falta de pago.">
            <fa-icon [icon]="icons.faTriangleExclamation" style="font-size: 12px;"></fa-icon>
            Vencido
          </div>
          <div class="badge rounded-pill bg-info py-2 px-2" *ngIf="bill.date_status==='verify'" placement="bottom" ngbTooltip="Este recibo no ha sido pagado aún, puede generar intereses por falta de pago.">
            <fa-icon [icon]="icons.faSpinner" style="font-size: 12px;"></fa-icon>
            En validación
          </div>
        </div>
        <div class="col-12 mt-2 mb-2">
          <table class="table table-sm">
            <tbody>
              <tr>
                <td class="text-start" colspan="2">Consumo</td>
                <td class="text-end" colspan="2">
                  <fa-icon [icon]="icons.faCircleExclamation" class="text-danger" *ngIf="bill.consumo_lt < 0"></fa-icon>
                  {{bill.consumo_lt | number: '1.2-2'}} Lts.
                </td>
              </tr>
              <tr>
                <td class="text-start" colspan="2">Emisión</td>
                <td class="text-end" colspan="2">{{bill.created_at | date: 'dd/MM/yy'}}</td>
              </tr>
              <tr>
                <td class="text-start" colspan="2">Vencimiento</td>
                <td class="text-end" colspan="2">{{bill.due_date | date: 'dd/MM/yy'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-5 text-start">
          <h4 class="mb-0 mt-2"><b>{{bill.total | currency: 'USD'}}</b></h4>
        </div>
        <div class="col-7 text-end" *ngIf="permissions?.modules?.tenants.billing.active">
          <button class="btn btn-primary rounded-4 w-100 ms-2" (click)="gotoPayment(bill)">
            <fa-icon [icon]="icons.faCashRegister" class="m-1"></fa-icon>
            Pagar
          </button>
        </div>
      </div>
    </div>
    <!-- app-modal-options-pay [bill]="bill" (billData)="payBill($event)"></!-->
    <app-modal-bill-verify [bill]="bill" (billData)="updateBill($event)"></app-modal-bill-verify>
  `,
  styleUrls: []
})
export class ItemBillComponent implements OnInit {
  @Input() bill: any;
  @Input() profile: any;
  @Input() permissions: any;
  @Output() selectBill: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("btnOptionsPay", { read: ElementRef, static: false }) btnOptionsPay !: ElementRef;

  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;

  lsUser: any = localStorage.getItem('user');
  user: any = this.lsUser != null ? JSON.parse(this.lsUser) : null;

  settlement: any = null;
  can_pay: boolean = false;

  constructor(
    private route: Router,
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) { }

  checkPermissions() {
    switch (this.user.role_id) {
      case 1:
        this.can_pay = false;
        break;
      case 2:
        this.can_pay = false;
        break;
      case 4:
        this.can_pay = true;
        break;
      default:
        this.can_pay = false;
        break;
    }
  }

  ngOnInit(): void {
    this.checkPermissions();

    const due_date = new Date(this.bill.due_date);
    const today = new Date();

    let expiredDate = new Date(due_date.getFullYear(), due_date.getMonth()+1, 0);
    expiredDate.setDate(expiredDate.getDate() - 3);

    if (this.bill.state == 3) {
      this.bill.date_status = 'verify';
    } else {
      if (due_date >= today) {
        // this.bill.date_status = 'vencido';
      } else if (due_date < today) {
        this.bill.date_status = today >= expiredDate ? 'expirado' : 'vencido';
      } else if (today >= expiredDate) {
        this.bill.date_status = 'expirado';
      }
    }
  }

  verificationBill() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalBillVerify')).show();
  }

  

  updateBill(bill: any) {
    this.store.uploadReceipt(bill.id, bill).subscribe({
      next: (data: any) => {
        this.toastr.success('Recibo actualizado correctamente.');
      },
      error: (error: any) => {
        this.toastr.error('Error al actualizar el recibo.');
      }
    });
  }

  dispatchPayment() {
    this.selectBill.emit(this.bill);
  }

  payBill(bill: any) {

    
    return;
    this.store.createTransaction(bill).subscribe({
      next: (data: any) => {
        this.toastr.success('Recibo creado correctamente.', 'Éxito');
        // this.loading = false;
        console.log('DATA', data);
        // this.route.navigate(['/pagos/pendientes']);
        // window.location.reload();

      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al crear el recibo', 'Error');
        // this.loading = false;
      }
    });
  }

  gotoPayment(bill: any) {
    this.route.navigate(['/tenant/bill', bill.id]);
  }

  unpublishBill() {
    this.store.unpublishBill(this.bill.id).subscribe({
      next: (data: any) => {
        this.toastr.success('Recibo despublicado correctamente.', 'Éxito');
        this.route.navigate([`/pagos/recibos/${this.bill.id}`]);
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al despublicar el recibo', 'Error');
      }
    });
  }

  downloadReceipt() {
    const link = document.createElement('a');
    link.href = `${this.receiptUrl}/public/${this.bill.pdf}`;
    link.download = this.bill.pdf;
    // link.click();
    window.open(`${this.receiptUrl}/public/${this.bill.pdf}`, '_blank')!.focus();
    // href="{{receiptUrl}}/public/{{bill.pdf}}" 
  }

  
}