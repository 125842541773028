import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-public-record',
  template: `
    <form [formGroup]="formPublicRecord" (ngSubmit)="savePublicRecord()">
      <!-- div class="form-group required mt-3">
        <label for="full_name">Nombre completo</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="full_name" formControlName="full_name">
        <div *ngIf="ufc['full_name'].touched && ufc['full_name'].invalid">
          <div *ngIf="ufc['full_name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </!-->
      <div class="form-group required mt-3">
        <label for="ledger">Folio</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="ledger" formControlName="ledger">
        <div *ngIf="ufc['ledger'].touched && ufc['ledger'].invalid">
          <div *ngIf="ufc['ledger'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="notary_full_name">Nombre completo del notario</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="notary_full_name" formControlName="notary_full_name">
        <div *ngIf="ufc['notary_full_name'].touched && ufc['notary_full_name'].invalid">
          <div *ngIf="ufc['notary_full_name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="notary_number">Notario No.</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="notary_number" formControlName="notary_number">
        <div *ngIf="ufc['notary_number'].touched && ufc['notary_number'].invalid">
          <div *ngIf="ufc['notary_number'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="notary_full_address">Dirección completa Notario</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="notary_full_address" formControlName="notary_full_address">
        <div *ngIf="ufc['notary_full_address'].touched && ufc['notary_full_address'].invalid">
          <div *ngIf="ufc['notary_full_address'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="notary_state">Estado de la República Notario</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="notary_state" formControlName="notary_state">
        <div *ngIf="ufc['notary_state'].touched && ufc['notary_state'].invalid">
          <div *ngIf="ufc['notary_state'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="notary_city">Ciudad de la República Notario</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="notary_city" formControlName="notary_city">
      </div>
      <div class="form-group required mt-3">
        <label for="property_number">Registro público de la propiedad</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="property_number" formControlName="property_number">
        <div *ngIf="ufc['property_number'].touched && ufc['property_number'].invalid">
          <div *ngIf="ufc['property_number'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="property_full_address">Dirección Registro público de la propiedad</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="property_full_address" formControlName="property_full_address">
        <div *ngIf="ufc['property_full_address'].touched && ufc['property_full_address'].invalid">
          <div *ngIf="ufc['property_full_address'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="property_state">Estado de la República Registro público de la propiedad</label>
        <select class="form-select form-select-lg" id="property_state" formControlName="property_state">
          <option value="Estado de Aguascalientes">Aguascalientes</option>
          <option value="Estado de Baja California">Baja California</option>
          <option value="Estado de Baja California Sur">Baja California Sur</option>
          <option value="Estado de Chiapas">Chiapas</option>
          <option value="Estado de Chihuahua">Chihuahua</option>
          <option value="Ciudad de México">Ciudad de México</option>
          <option value="Estado de Coahuila">Coahuila</option>
          <option value="Estado de Colima">Colima</option>
          <option value="Estado de Durango">Durango</option>
          <option value="Estado de México">Estado de México</option>
          <option value="Estado de Guanajuato">Guanajuato</option>
          <option value="Estado de Guerrero">Guerrero</option>
          <option value="Estado de Hidalgo">Hidalgo</option>
          <option value="Estado de Jalisco">Jalisco</option>
          <option value="Estado de Michoacán">Michoacán</option>
          <option value="Estado de Morelos">Morelos</option>
          <option value="Estado de Nayarit">Nayarit</option>
          <option value="Estado de Nuevo León">Nuevo León</option>
          <option value="Estado de Oaxaca">Oaxaca</option>
          <option value="Estado de Puebla">Puebla</option>
          <option value="Estado de Querétaro">Querétaro</option>
          <option value="Estado de San Luis Potosí">San Luis Potosí</option>
          <option value="Estado de Sin Localidad">Sin Localidad</option>
          <option value="Estado de Sinaloa">Sinaloa</option>
          <option value="Estado de Sonora">Sonora</option>
          <option value="Estado de Tabasco">Tabasco</option>
          <option value="Estado de Tamaulipas">Tamaulipas</option>
          <option value="Estado de Tlaxcala">Tlaxcala</option>
          <option value="Estado de Veracruz">Veracruz</option>
          <option value="Estado de Yucatán">Yucatán</option>
          <option value="Estado de Zacatecas">Zacatecas</option>
        </select>
      </div>
      <div class="form-group required mt-3">
        <label for="property_city">Ciudad de la República Registro público de la propiedad</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="property_city" formControlName="property_city">
      </div>
      <div class="form-group required mt-3">
        <label for="property_entry">Entrada Registro público de la propiedad</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="property_entry" formControlName="property_entry">
      </div>
      <div class="form-group required mt-3">
        <label for="property_section">Sección Registro público de la propiedad</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="property_section" formControlName="property_section">
      </div>
      
      <div class="form-group required mt-3">
        <label for="property_date">Fecha registro público de la propiedad</label>
        <input type="date" class="form-control form-control-lg rounded-3" id="property_date" formControlName="property_date">
        <div *ngIf="ufc['property_date'].touched && ufc['property_date'].invalid">
          <div *ngIf="ufc['property_date'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="legal_name">Nombre representante legal</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="legal_name" formControlName="legal_name">
        <div *ngIf="ufc['legal_name'].touched && ufc['legal_name'].invalid">
          <div *ngIf="ufc['legal_name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="legal_last_name_p">Primer apellido representante legal</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="legal_last_name_p" formControlName="legal_last_name_p">
        <div *ngIf="ufc['legal_last_name_p'].touched && ufc['legal_last_name_p'].invalid">
          <div *ngIf="ufc['legal_last_name_p'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="legal_last_name_m">Segundo apellido representante legal</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="legal_last_name_m" formControlName="legal_last_name_m">
        <div *ngIf="ufc['legal_last_name_m'].touched && ufc['legal_last_name_m'].invalid">
          <div *ngIf="ufc['legal_last_name_m'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="legal_rfc">RFC representante legal</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="legal_rfc" formControlName="legal_rfc">
        <div *ngIf="ufc['legal_rfc'].touched && ufc['legal_rfc'].invalid">
          <div *ngIf="ufc['legal_rfc'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="legal_email">Correo electrónico representante legal</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="legal_email" formControlName="legal_email">
        <div *ngIf="ufc['legal_email'].touched && ufc['legal_email'].invalid">
          <div *ngIf="ufc['legal_email'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="id_type">Tipo de identificación</label>
        <select class="form-select form-select-lg" id="id_type" formControlName="id_type">
          <option value="INE">INE</option>
          <option value="Pasaporte">Pasaporte</option>
        </select>
      </div>
      <div class="form-group required mt-3">
        <label for="id_number">Número de identificación</label>
        <input type="text" class="form-control form-control-lg rounded-3" id="id_number" formControlName="id_number">
        <div *ngIf="ufc['id_number'].touched && ufc['id_number'].invalid">
          <div *ngIf="ufc['id_number'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mt-3">
        <label for="registration_date">Fecha constitución empresa</label>
        <input type="date" class="form-control form-control-lg rounded-3" id="registration_date" formControlName="registration_date">
        <div *ngIf="ufc['registration_date'].touched && ufc['registration_date'].invalid">
          <div *ngIf="ufc['registration_date'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3 text-end">
        <div class="alert alert-info text-start">
          <p class="mb-0">La información ingresada en este formulario se utiliza para la generación de contratos con los condominios, por favor verifica que la información sea la correcta.</p>
        </div>
        <button type="submit" class="btn btn-outline-primary rounded-4">
          Guardar
        </button>
      </div>
      
    </form>
  `,
  styles: [
  ]
})
export class FormPublicRecordComponent implements OnInit {
  @Input() publicRecord: any;
  @Input() isLegal: any;
  @Input() related: any;
  @Output() publicRecordData = new EventEmitter<any>();

  formPublicRecord: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.formPublicRecord = this.fb.group({
      id: [],
      ledger: [''],
      notary_full_name: [''],
      notary_number: [''],
      notary_full_address: [''],
      notary_state: [''],
      notary_city: [''],
      property_number: [''],
      property_full_address: [''],
      property_state: [''],
      property_city: [''],
      property_entry: [''],
      property_section: [''],
      legal_name: [''],
      legal_last_name_p: [''],
      legal_last_name_m: [''],
      legal_email: [''],
      legal_rfc: [''],
      public_record_id: [''],
      property_date: [''],
      registration_date: [''],
      id_type: [''],
      id_number: [''],
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['publicRecord'] && changes['publicRecord'].currentValue) {
      this.formPublicRecord.patchValue(changes['publicRecord'].currentValue);
      this.formPublicRecord.patchValue({
        registration_date: new Date(changes['publicRecord'].currentValue.registration_date).toISOString().split('T')[0],
        property_date: new Date(changes['publicRecord'].currentValue.property_date).toISOString().split('T')[0],
      });
    }
  }

  get ufc() {
    return this.formPublicRecord.controls;
  } 

  savePublicRecord() {
    console.log('savePublicRecord', this.formPublicRecord.value);
    if (this.related) {
      this.formPublicRecord.patchValue({ public_record_id: this.related });
    }

    if (this.formPublicRecord.invalid) {
      this.toastr.error('Favor de llenar todos los campos');
      return;
    }

    this.publicRecordData.emit(this.formPublicRecord.value);
  }

}
