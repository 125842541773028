import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SummaryService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  getTanqueSummary(id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/summary_consumptions/tanque/${id}`);
  }

  getTanqueSummaryBySettlement(id: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/summary_consumptions/settlement/${id}`);
  }

  getConsumeSummaryByProfileMonth(data: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/summary_consumptions/profile/month?${this.parseQueryParams(data)}`);
  }

  getConsumeSummaryBySettlementMonth(data: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/summary_consumptions/settlement/month?${this.parseQueryParams(data)}`);
  }

  getConsumeSummaryByTanqueWeek(data: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/summary_consumptions/tanque/week?${this.parseQueryParams(data)}`);
  }

  getTrackingByProfile(data: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/overdue_tracking/profile?${this.parseQueryParams(data)}`);
  }

  getTrackingBySettlement(data: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/overdue_tracking/settlement?${this.parseQueryParams(data)}`);
  }

  getTrackingByBuilding(data: any): Observable<any> {
    return this.httpClient.get<any>(`${this.apiURL}/overdue_tracking/building?${this.parseQueryParams(data)}`);
  }


}
