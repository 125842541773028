import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-add-building',
  template: `
  <div #modalBuilding id="modalBuilding" class="modal fade" tabeindex="-1" aria-labelledby="modalBuilding" aria-hidden="true">
    <div class="modal-dialog modal-dialog-top">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalBuilding">Agregar edificio</h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <form [formGroup]="addBuildingForm" (ngSubmit)="createBuilding()">
              <div class="row px-3">
                <div class="col-md-12">
                  <div class="form-group required mb-4 mt-3">
                    <label for="name" class="form-label ms-2">Nombre del edificio</label>
                    <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
                    <div *ngIf="abfc['name'].touched && abfc['name'].invalid">
                      <div *ngIf="abfc['name'].errors?.['required']" class="text-danger text-sm">El nombre del edificio es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4 mt-3">
                    <label for="clave" class="form-label ms-2">Clave del edificio</label>
                    <input type="text" class="form-control form-control-lg rounded-3" id="clave" placeholder="" formControlName="clave">
                    <div *ngIf="abfc['clave'].touched && abfc['clave'].invalid">
                      <div *ngIf="abfc['clave'].errors?.['required']" class="text-danger text-sm">La clave del edificio es requerida.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-2">
                <div class="form-group text-end mt-1 clearfix">
                  <button type="submit" class="btn btn-lg btn-outline-success rounded-5 float-end">Agregar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class ModalAddBuildingComponent implements OnInit {
  @ViewChild('modalBuilding', { read: ElementRef, static: false }) modalBuilding !: ElementRef;
  @Output() onBuildingCreated = new EventEmitter<any>();

  addBuildingForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.buildBuildingForm();
  }

  buildBuildingForm() {
    this.addBuildingForm = this.fb.group({
      name: ['', Validators.required],
      clave: ['', Validators.required],
    });
  }

  get abfc () {
    return this.addBuildingForm.controls;
  }

  createBuilding() {
    if (this.addBuildingForm.invalid) {
      this.addBuildingForm.markAllAsTouched();
      return;
    }

    this.onBuildingCreated.emit(this.addBuildingForm.value);
  }
}
