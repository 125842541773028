import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-vehicles-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-9 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Vehículo</h1>
            <p>Configura los vehículos que están disponibles para los técnicos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Agregar vehículo</h4>
                    <p>Configura la vehículo con los siguientes campos.</p>
                    <app-form-vehicle (vehicleData)="createVehicle($event)" [profile]="profile" [loading]="loading"></app-form-vehicle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class VehiclesCreateComponent implements OnInit {
  loading: boolean = false;
  profile: any = {};
  permissions: any = {};

  constructor(
    private store: SettlementsService,
    private tasks: TasksService,
    private toastr: ToastrService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
  }

  ngOnInit(): void {
    this.breadcrumbService.set('@vehicleCreate', 'Crear vehículo');
  }

  createVehicle(event: any) {
    this.tasks.createVehicle(event).subscribe({
      next: (data: any) => {
        console.log(data);
        this.toastr.success('Vehículo creado correctamente.');
        this.router.navigate(['/inventory/vehicles']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al crear el vehículo.');
      }
    });
  }
}
