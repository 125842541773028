import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-holiday',
  template: `
    <div #modalHolidays id="modalHolidays" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalHolidays" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Agregar Día no laboral</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body pt-3 pb-3">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="formHoliday">
                  <div class="form-group required mb-4 mt-3">
                    <label for="date">Fecha</label>
                    <input type="date" class="form-control form-control-lg rounded-3" formControlName="fecha">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveDate()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalHolidayComponent implements OnInit {
  @Output() holidayData = new EventEmitter<any>();

  faTimes = faTimes;
  dateSelected: string = '';

  formHoliday: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  get ufc() {
    return this.formHoliday.controls;
  }

  initForm() {
    this.formHoliday = this.fb.group({
      fecha: ['', Validators.required],
    });
  }

  saveDate() {
    this.holidayData.emit(this.formHoliday.value);
    
  }
}
