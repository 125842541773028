import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-tenant',
  template: `
    <form [formGroup]="formTenant" (ngSubmit)="createInquilino()">
      <div class="row">
        <!-- div class="col-md-4">
          <div class="form-group">
            <img [src]="avatarURL" width="100%">
            <input type="file" (change)="loadAvatar($event)" >
          </div>
        </!-->
        <div class="avatar-container text-center">
          <div #imageContainer class="image-container"></div> 
          <label for="avatar-input-file">
            <div class="icon-wrapper">
              <input type="file" id="avatar-input-file" accept="image/*" (change)="loadAvatar($event)"/>
              <fa-icon [icon]="faEdit" class="text-white"></fa-icon>
            </div>
          </label>
        </div>
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre del inquilino</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="last_name" class="form-label ms-2">Primer Apellido del inquilino</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="last_name" placeholder="" formControlName="last_name">
            <div *ngIf="ufc['last_name'].touched && ufc['last_name'].invalid">
              <div *ngIf="ufc['last_name'].errors?.['required']" class="text-danger text-sm">El apellido es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="last_name_m" class="form-label ms-2">Segundo Apellido del inquilino</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="last_name_m" placeholder="" formControlName="last_name_m">
            <div *ngIf="ufc['last_name_m'].touched && ufc['last_name_m'].invalid">
              <div *ngIf="ufc['last_name_m'].errors?.['required']" class="text-danger text-sm">El apellido es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="rfc" class="form-label ms-2">RFC</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="rfc" placeholder="" formControlName="rfc">
            <div *ngIf="ufc['rfc'].touched && ufc['rfc'].invalid">
              <div *ngIf="ufc['rfc'].errors?.['required']" class="text-danger text-sm">El apellido es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="email" class="form-label ms-2">Correo electrónico</label>
            <input type="email" class="form-control form-control-lg rounded-3" id="email" placeholder="" formControlName="email">
            <div *ngIf="ufc['email'].touched && ufc['email'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El correo es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="phone" class="form-label ms-2">Teléfono</label>
            <input type="tel" class="form-control form-control-lg rounded-3" id="phone" placeholder="" formControlName="phone">
            <div *ngIf="ufc['phone'].touched && ufc['phone'].invalid">
              <div *ngIf="ufc['phone'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="is_primary" formControlName="is_primary">
              <label class="form-check" for="is_primary">Es el inquilino principal</label>
            </div>
          </div>
          <div class="form-group required mb-4 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" (click)="cancel()">Cancelar</button>
            <button type="submit" class="btn btn-success rounded-5">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTenantComponent implements OnInit {

  @Input() addingTenant: any;
  @Output() saveTenant = new EventEmitter<any>();
  @Output() cancelForm = new EventEmitter<any>();

  @ViewChild('imageContainer') imageContainer: ElementRef | undefined;

  avatarURL: any = '';

  formTenant: FormGroup = new FormGroup({});

  imageError: string = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";
  previewImagePath: string = "";

  faEdit = faEdit;

  constructor(
    private fb: FormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.initForm();
  }

  get ufc() {
    return this.formTenant.controls;
  }

  initForm() {
    this.formTenant = this.fb.group({
      avatar: [''],
      name: ['', Validators.required],
      last_name: [''],
      last_name_m: [''],
      rfc: [''],
      email: [''],
      phone: [''],
      dob: [''],
      unit_id: ['', Validators.required],
      is_primary: [false],
      // USER part
    });
  }

  cancel() {
    this.addingTenant = false;
    this.cancelForm.emit();
  }

  createInquilino() {

    // if (this.formTenant.invalid) {
      // this.toastr.
      // return;
    // }
    console.log('tenant => ', this.formTenant.value);
    this.saveTenant.emit(this.formTenant.value);
  }

  loadAvatar(event: any): void {

    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        // return false;
      }

      var output = this.imageContainer!.nativeElement.style;
      output.backgroundImage = `url(${URL.createObjectURL(event.target.files[0])})`;
      

      // if (!_.includes(allowed_types, event.target.files[0].type)) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        // return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          // console.log(img_height, img_width);
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            // return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            this.formTenant.patchValue({
              avatar: imgBase64Path
            });
            // console.log('imgBase64Path => ', imgBase64Path);
            // console.log('previewImagePath => ', this.previewImagePath);
            
            // let payload = {
              // id: this.userId,
              // data: this.previewImagePath
            // }
            // // console.log('event => ', event.target.files[0]);
            // this.settlementsService.updateAvatar(this.userId, payload).subscribe((data: any) => {
              // console.log('data => ', data);
              // this.loadUser(this.userId);
            // });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }    
  }
}
