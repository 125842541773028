import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faEdit, faBuilding, faPhoneAlt, faMailBulk, faLink, faPlus, faTrashAlt, faChevronUp, faChevronDown, faEllipsisVertical, faEye } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-item-commercial',
  template: `
    <div class="row">
      <div class="col-2 col-md-2 col-lg-1 text-center">
        <img src="assets/images/building-avatar.png" width="40%">
      </div>
      <div class="col-6 col-md-5 col-lg-6">
        <h4 class="mb-0">{{settlement.name}}</h4>
        <p class="mb-0" style="font-size: 13px;">{{settlement.address}} {{settlement.city}}</p>
        <!-- p class="mb-0 text-sm text-muted" style="font-size: 11px;">Actualizado: {{settlement.updated_at | date: 'dd/MM/yyyy HH:mm'}}</!-->
      </div>
      <!-- div class="col-md-5">
     
      </!-->
      <div class="col-4 col-md-5 col-lg-5 clearfix text-end">
        <button class="btn btn-link text-secondary rounded-5 me-2" style="font-size: 13px;" (click)="toggleData()">
          <span *ngIf="!showData">
            Mostrar detalles
            <fa-icon [icon]="faChevronDown" class="m-1"></fa-icon>
          </span>
          <span *ngIf="showData">
            Ocultar detalles
            <fa-icon [icon]="faChevronUp" class="m-1"></fa-icon>
          </span>
        </button>
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1 px-2" id="dropdownBasic1" ngbDropdownToggle >
            <fa-icon [icon]="faEllipsisVertical" class="m-1"></fa-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem routerLink="edit/{{settlement.id}}">
              <fa-icon [icon]="faEdit"></fa-icon>
              Editar condominio
            </button>
            <button ngbDropdownItem routerLink="{{settlement.id}}">
              <fa-icon [icon]="faEye"></fa-icon>
              Configuración
            </button>
            <button ngbDropdownItem routerLink="{{settlement.id}}/dashboard">
              <fa-icon [icon]="faPlus" class="m-1"></fa-icon>
              Dashboard
            </button>
            <button ngbDropdownItem routerLink="comercials/{{settlement.id}}">
              <fa-icon [icon]="faBuilding" class="m-1"></fa-icon>
              Pagos
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showData">
      <div class="col-md-12 mt-2">
        <div class="list-group-item rounded-3">
          <div class="text-center" *ngIf="loading">
            <fa-icon [icon]="faCircleNotch" size="1x" [spin]="true" class="text-secondary"></fa-icon>
            <p class="text-muted" style="font-size: 13px;">Cargando...</p>
          </div>
          <div class="row" *ngIf="!loading">
            <div class="col-md-6">
              <ul style="font-size: 13px;">
                <li *ngFor="let building of buildings">
                  {{building.name}}
                  <ul>
                    <li>{{building.countTanques}} Tanques</li>
                    
                    <ul>
                      <li *ngFor="let tanque of building.tanques">
                        {{tanque.name}}
                      </li>
                    </ul>
                    <li>{{building.countUnits}} Departamentos
                      <ul>
                        <li *ngFor="let unit of building.units">
                          Depto. {{unit.name}}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <p class="mb-0">
                <fa-icon [icon]="faPhoneAlt" style="font-size: 12px;"></fa-icon>
                <span class="ms-2" style="font-size: 12px;">{{settlement.phone ? settlement.phone : '--'}}</span>
              </p>
              <p class="mb-0">
                <fa-icon [icon]="faMailBulk" style="font-size: 12px;"></fa-icon>
                <span class="ms-2" style="font-size: 12px;">{{settlement.email ? settlement.email : '--'}}</span>
              </p>
              <p class="mb-0">
                <fa-icon [icon]="faLink" style="font-size: 12px;"></fa-icon>
                <span class="ms-2" style="font-size: 12px;">{{settlement.website ? settlement.website : '--'}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemCommercialComponent {
  @Input() settlement: any;

  loading: boolean = false;

  buildings: any = [];
  countUnits: number = 0;
  countTanques: number = 0;

  showData: boolean = false;

  faEllipsisVertical = faEllipsisVertical;
  faEye = faEye;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faTrashAlt = faTrashAlt;
  faCircleNotch = faCircleNotch;
  faBuilding = faBuilding;
  faEdit = faEdit;
  faPlus = faPlus;
  faPhoneAlt = faPhoneAlt;
  faMailBulk = faMailBulk;
  faLink = faLink;

  constructor(
    private store: SettlementsService,
  ) { }

  ngOnInit(): void {
    // console.log("settlement ===> ", this.settlement);
  }

  toggleData() {
    this.loading = true;
    this.showData = !this.showData;
    if (this.buildings.length==0) {
      this.loadData();
    }
  }

  loadData() {
    // console.log('trigger loadData');
    this.store.getSettlement(this.settlement.id).subscribe((data: any) => {
      // console.log("settlement ===> ", data);
    });

    this.store.getBuildingsBySettlement(this.settlement.id).subscribe((data: any) => {
      let buildings: any = data.sort((a: any, b: any) => a.id - b.id);
      buildings.map((building: any) => {
        let build: any = building;
        console.log("building => ", building);
        let countUnits: number = 0;
        let countTanques: number = 0;
        building.floors.map((floor: any) => {
          countUnits += floor.units.length
          build.units = floor.units;
        });
        build.tanques = building.tanques;
        build.countUnits = countUnits;
        build.countTanques = building.tanques.length;

        this.buildings.push(build);          
      });
      this.loading = false;
    });


  }
}
