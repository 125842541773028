import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService extends CommonService {

  constructor(
    private HttpClient: HttpClient,
  ) { 
    super(HttpClient);
  }

  createCustomer(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/customer`, data);    
  }

  async createOpenpayCustomer(data: any) {
    return this.HttpClient.post(`${this.apiURL}/payments/customer/openpay`, data).toPromise();
  }

  getCustomerById(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/customer/by_id`, data);
  }

  tokenizeCard(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/tokenize`, data);
  }

  tokenizeCardOpenpay(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/tokenize/openpay`, data);
  }

  createPaymentMethod(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/create_payment_method`, data);
  }

  destroyPaymentMethod(id: any): Observable<any> {
    return this.HttpClient.delete(`${this.apiURL}/payment_methods/${id}`);
  }

  tokenizePayment(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/tokenize_payment`, data);
  }

  createPayment(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/payment_sale`, data);
  }

  async createPaymentStoreActnet(data: any) {
    return this.HttpClient.post(`${this.apiURL}/payments/payment_store`, data).toPromise();
  }

  async createPaymentStoreOpenpay(data: any) {
    return this.HttpClient.post(`${this.apiURL}/payments/payment_store_openpay`, data).toPromise();
  }

  createPaymentOpenpay(data: any) {
    return this.HttpClient.post(`${this.apiURL}/payments/openpay_payment`, data);
  }

  async createOpenpayStore(data: any) {
    console.log('data', data);
    return this.HttpClient.post(`${this.apiURL}/payments/openpay_store`, data).toPromise();
  }

  createPaymentRefund(data: any): Observable<any> {
    return this.HttpClient.post(`${this.apiURL}/payments/payment_refund`, data);
  }

  getTransactionStatus(id: any): Observable<any> {
    return this.HttpClient.get(`${this.apiURL}/payments/transaction_status/${id}`);
  }

  getLastCashTransaction(id: any): Observable<any> {
    return this.HttpClient.get(`${this.apiURL}/transactions/bill/${id}`);
  }

}
