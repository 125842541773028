import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-modal-options-pay',
  template: `
    <ng-template #modalOptionsPay let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Opciones de pago</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
          <fa-icon [icon]="icons.faTimes"></fa-icon>
        </button>
      </div>
      <div class="modal-body p-4">
        <table class="table table-sm border-0" style="font-size: 0.8em;">
          <tbody class="text-dark">
            <tr>
              <td colspan="3" scope="col" class="text-start"><b>Gas</b></td>
            </tr>
            <tr>
              <td class="ps-4 text-secondary">Consumo</td>
              <td colspan="2" scope="col" class="text-end text-secondary">{{bill?.consumo_periodo | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td class="ps-4 text-secondary">Descuento(s)</td>
              <td colspan="2" scope="col" class="text-end text-secondary">-{{bill?.discount | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td class="ps-4">Total consumo</td>
              <td colspan="2" scope="col" class="text-end">{{bill?.consumo_total | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <td scope="col" class="text-start">Adeudos</td>
              <td colspan="2" scope="col" class="text-end" *ngIf="bill?.interests">{{bill?.interests | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <td scope="col" class="text-start">Recargos (% Int.)</td>
              <td colspan="2" scope="col" class="text-end" *ngIf="bill?.recargos">{{bill?.recargos | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <td scope="col" class="text-start">Admon.</td>
              <td colspan="2" scope="col" class="text-end" *ngIf="bill?.admin_price">{{bill?.admin_price | currency: 'USD'}}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-sm mt-4 border-0" style="font-size: 0.8em;">
          <thead>
            <tr>
              <td class="text-secondary">Subtotal</td>
              <td colspan="2" scope="col" class="text-end text-secondary">{{bill?.sub_total | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td class="text-secondary">IVA</td>
              <td colspan="2" scope="col" class="text-end text-secondary">{{bill?.taxes | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-0">
              <th class="border-bottom-0">Total</th>
              <th colspan="2" scope="col" class="text-end border-bottom-0">{{bill?.total | currency: 'USD'}}</th>              
            </tr>
          </thead>
        </table>

        <form [formGroup]="payForm">
          <div class="form-group text-start">
            <label for="transaction_id" class="form-label text-sm">Folio</label>
            <input type="text" class="form-control" placeholder="Ingresar folio del pago" formControlName="transaction_id">
            <div *ngIf="ufc['transaction_id']?.touched && ufc['transaction_id']?.invalid">
              <div *ngIf="ufc['transaction_id']?.errors?.['required']" class="text-danger text-sm">El folio es requerido.</div>
            </div>
          </div>
          <div class="form-group text-start mt-3">
            <label for="payment_date" class="form-label text-sm">Fecha de pago</label>
            <input type="date" class="form-control" formControlName="payment_date">
          </div>
          <div class="form-group text-start mt-3">
            <label for="amount" class="form-label text-sm">Monto pagado</label>
            <input type="text" class="form-control" placeholder="Ingresar monto del pago" formControlName="amount">
            <div *ngIf="ufc['amount']?.touched && ufc['amount']?.invalid">
              <div *ngIf="ufc['amount']?.errors?.['required']" class="text-danger text-sm">La cantidad es requerida.</div>
            </div>
          </div>
          
        </form>
        <div *ngIf="bill?.invoiced" class="mt-4">

          <div class="form-check form-switch ps-1">
            <input
              class="form-check-input float-end"
              type="checkbox"
              [formControl]="complement"
              (change)="updateComplement($event)"
            >
            <label class="form-check-label" for="flexCheckDefault">
              Generar complemento de pago
            </label>
          </div>

        </div>
      </div>
      <div class="modal-footer pt-4">
        <div class="d-flex flex-fill">
          <div class="text-start">
            <p class="text-sm text-muted">{{loading_msg}}</p>
          </div>
          <div class="ms-auto text-end ms-2">
            <button type="button" 
              class="btn btn-outline-success" 
              (click)="registerPay()" 
              [disabled]="loading"
            >
              <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="me-2"></fa-icon>
              <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="loading" class="me-2"></fa-icon>
              Registrar pago
            </button>
          </div>
        </div>
      </div>     
    </ng-template>

    <!-- div class="btn-group" role="group" aria-label="Basic radio toggle button group">
              <!- input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked (click)="selectPayMethod('CASH')">
              <label class="btn btn-outline-primary pt-3" for="btnradio1">
                <fa-icon [icon]="icons.faCashRegister"></fa-icon><br>
                <p>Pago en efectivo</p>
              </label ->

              <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked (click)="selectPayMethod('STP')">
              <label class="btn btn-outline-primary pt-3" for="btnradio2">
                <fa-icon [icon]="icons.faCashRegister"></fa-icon><br>
                <p>Pago vía STP</p>
              </label>

              <!- input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" routerLink="/tenant/bill/{{bill.id}}">
              <label class="btn btn-outline-primary pt-3" for="btnradio3">
                <fa-icon [icon]="icons.faCreditCard"></fa-icon><br>
                <p>Tarjeta de crédito</p>
              </label ->
            </div -->
  `,
  styleUrls: []
})
export class ModalOptionsPayComponent implements AfterViewInit {
  @Input() bill: any;
  @Input() profile: any;
  @Output() billData: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("modalOptionsPay") modalOptionsPay !: ElementRef;

  private modalRef: NgbModalRef | undefined;

  payForm: FormGroup = new FormGroup({});
  invoiceComplementForm: FormGroup = new FormGroup({});
  invoiceData: any = {};
  loading: boolean = false;
  loading_msg: string = '';

  complement: FormControl = new FormControl();

  parser: any;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    public icons: IconsService,
    private toastr: ToastrService,
    public modalService: NgbModal
  ) { }

  ngAfterViewInit(): void {
    this.initForm();
  }

  initForm() {
    this.payForm = this.fb.group({
      transaction_id: ['', Validators.required],
      amount: [this.bill?.total, Validators.required],      
      currency: ['MXN', Validators.required],
      status_code: ['3', Validators.required],
      error_code: ['00', Validators.required],
      error_message: ['Approved', Validators.required],
      payment_method_bin: ['0', Validators.required],
      payment_method_type: ['CASH', Validators.required],
      payment_method_brand: ['CASH', Validators.required],
      bill_id: [this.bill?.id, Validators.required],
      payment_date: [new Date(), Validators.required],
    });
  }

  updateComplement(event: any) {
    console.log('updateComplement => ', event.target.checked);
    if (event.target.checked) {
      this.buildInvoiceComplementForm();
    }
  }

  buildInvoiceComplementForm() {
    this.invoiceComplementForm = this.fb.group({
      payment_date: ['', [Validators.required]]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['bill'] && changes['bill'].currentValue) {
      this.payForm.patchValue({ 
        amount: this.bill.total,
        payment_date: new Date(),
        bill_id: this.bill.id,
      });
    }
  }

  get ufc() {
    return this.payForm.controls;
  }

  selectPayMethod(method: string) {
    this.payForm.patchValue({
      amount: '',
      payment_date: new Date(),
      transaction_id: '',
    });
    this.payForm.get('payment_method_type')?.setValue(method);
    this.payForm.get('payment_method_brand')?.setValue(method);
  }

  registerPay() {
    console.log('Form', this.payForm.value);
    console.log('complement', this.complement.value);
    this.loading_msg = 'Registrando pago en ZAZZ...';

    if (this.payForm.invalid) {
      this.toastr.error('Favor de llenar todos los campos', 'Error');
      return;
    }

    this.store.createTransaction(this.payForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Recibo creado correctamente.', 'Éxito');
        this.loading_msg = 'Registrando pago completado...';
        if (this.complement.value) {
          this.invoiceComplementForm.patchValue({ payment_date: this.payForm.value.payment_date });
          this.createComplement();
        } else {
          this.loading = false;
          this.dismiss();          
        }
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al crear el recibo', 'Error');
        this.loading = false;
      }
    });
  }

  createComplement() {
    this.loading_msg = 'Creando complemento de pago...';
    if (this.invoiceComplementForm.invalid) {
      this.invoiceComplementForm.markAllAsTouched();
      this.toastr.error('Por favor, completa el formulario.');
      this.loading = false;
      return;
    }

    console.log('createComplement => ', this.invoiceComplementForm.value);
    
    this.store.getInvoice(this.bill.invoice_id).subscribe({
      next: async (res: any) => {
        let complemento: any = {
          "PagosV20": {
            "Pagos": [
              {
                "FechaPago": `${this.invoiceComplementForm.value.payment_date}T00:00:00`,
                "FormaPago": "03",
                "Moneda": "MXN",
                "DocumentosRelacionados": [
                  {
                      "IdDocumento": res.invoice.uuid,
                      "Moneda": "MXN",
                      "NumeroParcialidad": 1,
                      "ObjetoDeImpuesto": "02",
                      "ImporteSaldoAnterior": this.bill.total.toFixed(2),
                      "ImportePagado": this.bill.total.toFixed(2),
                      "ImporteSaldoInsoluto": "0",
                      "Impuestos": {
                        "Trasladados": [
                            {
                              "Impuesto": "2",
                              "Factor": "1",
                              "Tasa": '0.160000',
                              "Base": this.bill.sub_total.toFixed(2),
                              "Importe": this.bill.taxes.toFixed(2)
                            }
                        ]
                      }
                  }
                ],
                "Impuestos": {
                  "Trasladados": [
                      {
                        "Impuesto": "2",
                        "Factor": "1",
                        "Tasa": '0.160000',
                        "Base": this.bill.sub_total.toFixed(2),
                        "Importe": this.bill.taxes.toFixed(2)
                      }
                  ]
                }
              }
            ],
             "Totales":{
                "TotalTrasladosBaseIVA16": this.bill.sub_total.toFixed(2),
                "TotalTrasladosImpuestoIVA16": this.bill.taxes.toFixed(2),
                "MontoTotalPagos": this.bill.total.toFixed(2),
             }
          },
          "TipoComplemento": 6
        }

        console.log('invoice => ', res);
        const comprobante: any = await this.processXML(res.invoice.cfdixml);
        const receptor: any = comprobante['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
    
        console.log('RECEPTOR => ', receptor);
        const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        const tn = tzoffset + 3600000;
        const localISOTime = (new Date(Date.now() - tn)).toISOString();
    
        let payload: any = {
          Encabezado: {
            "Fecha": localISOTime.slice(0,19),
            "Serie": res.invoice.serie,
            "Folio": res.invoice.folio,
            "MetodoPago":"",
            "FormaPago":"",
            "Moneda":"",
            "LugarExpedicion":"26015",
            "SubTotal": "0",
            "Total": "0",
            "Receptor": {
              "RFC": receptor['Rfc'],
              "NombreRazonSocial": receptor['Nombre'],
              "RegimenFiscal": receptor['RegimenFiscalReceptor'],
              "UsoCFDI": "",
              "Direccion":{
                "Calle":"",
                "NumeroExterior":"",
                "NumeroInterior":"",
                "Colonia":"",
                "Localidad":"",
                "Municipio":"",
                "Estado":"",
                "Pais":"Mexico",
                "CodigoPostal": receptor['DomicilioFiscalReceptor']
             }
            }
          },
          Complemento: complemento,
          complemento: {
            serie: 'C',
            folio: this.bill.id,
            related_invoice: res.invoice.id,
          },
          profile_id: this.profile.id,
        }
    
        console.log('PAYLOAD =====> ', payload);
    
        this.store.createInvoiceComplement(payload).subscribe({
          next: (res: any) => {
            console.log(res);
            this.invoiceData = res;
            this.toastr.success('Complemento generado correctamente.', 'Éxito');  
            this.loading_msg = 'Descargando complemento de pago...';      
            this.download();
          },
          error: (err: any) => {
            console.log(err);
            this.toastr.error('Ocurrió un error al generar el complemento.', 'Error');
            this.loading = false;
          }
        });
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al obtener la factura.', 'Error');
        this.loading = false;
      }
    });

  }

  download() {
    console.log('download ====> ', this.bill);

    let dueDate = new Date(this.bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    this.store.getSubscriptions(this.bill.subscription_id).subscribe((data: any) => {
      console.log("SS ==> ", data);
      this.store.getUnit(data.unit_id).subscribe(async (unitData: any) => {
        console.log('unitData => ', unitData);
        this.store.getBuilding(1).subscribe(async (data: any) => {
          const payload = {
            bill: this.bill,
            dueDate: dueDate,
            unitData: unitData,
            buildingData: data,
            invoiceData: this.invoiceData,
            utilityData: this.profile,
          };
  
          this.store.downloadComplement(payload).subscribe({
            next: (data: any) => {
              const int8Array = new Uint8Array(data.result.data);
              const blob = new Blob([int8Array], { type: 'application/pdf' });
  
              var fileURL = window.URL.createObjectURL(blob);
              var link = document.createElement('a');

              link.href = fileURL;
              link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
              link.click();

              const blob2 = new Blob([this.invoiceData.cfdixml], { type: 'application/xml' });

              var fileURL2 = window.URL.createObjectURL(blob2);
              var link2=document.createElement('a');

              link2.href = fileURL2;
              link2.download = `${this.invoiceData.serie}_${this.invoiceData.folio}.xml`;
              link2.click();

              this.store.updateInvoice(this.invoiceData.id, { pdf: data.filename }).subscribe((data: any) => {
                console.log('updateInvoice => ', data);
                this.loading = false;
                this.dismiss();
                this.payForm.reset();
                this.invoiceComplementForm.reset();
              });
  
              return;
            }, error: (error: any) => {
              console.log('createReceipt ERROR => ', error);
              this.loading = false;
            }
          });
        });
      });
    });
  }

  async processXML(xml: any) {
    this.parser = new xml2js.Parser({
      trim: true,            
      explicitArray: true,            
    });
    return await this.parser.parseStringPromise(xml).then(function (result: any) {
      return result;
    });
  }

  openModal(modalContent: TemplateRef<any>) {
    console.log('openModal', modalContent);
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
