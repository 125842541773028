import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faEdit, faPlus, faTrashAlt, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { DateService } from 'src/app/shared/services/date.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-task-refuel',
  template: `
    <div class="col-sm-10 offset-sm-1 col-md-9 offset-md-2 col-lg-7 offset-lg-4 col-xl-7 offset-xl-4 col-xxl-9 offset-xxl-3 mt-3">
      <div class="row mb-4">
        <div class="col-sm-5 offset-sm-1 col-md-5 offset-md-0">
          <h1>Tareas no asignadas</h1>
        </div>
        <div class="col-sm-6 col-md-7 mt-4 text-end">
        <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.admin.settlements.write" routerLink="/condominios/create/condominio">
        <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
          Agregar
        </!-->
          <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
        </div>
      </div>
      
      <div class="row mt-2 mb-4">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label for="description" class="form-label ms-2 sr-only">Condominio</label>
                <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectSettlement($event)">
                  <option selected [selected]="true" disabled>Condominio</option>
                  <option *ngFor="let settlement of settlements" [value]="settlement.id" >{{settlement.name}}</option>
                </select>
              </div>
            </div>
            <!-- div class="col-md-3">
              <div class="form-group mb-0">
                <label for="description" class="form-label ms-2 sr-only">Actividad</label>
                <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectCategory($event)">
                  <option selected [selected]="true" disabled>Actividad</option>
                  <option *ngFor="let category of taskCategories" [value]="category.id" >{{category.name}}</option>
                </select>
              </div>
            </!-->
            <!-- div class="col-md-3">
              <div class="form-group mb-0">
                <label for="description" class="form-label ms-2 sr-only">Técnico</label>
                <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectTecnico($event)">
                  <option selected [selected]="true" disabled>Técnico</option>
                  <option *ngFor="let technician of technicians" [value]="technician.id" >{{technician.name}}</option>
                </select>
              </div>
            </!-->
            <!-- div class="col-md-3">
              <div class="form-group mb-0">
                <label for="description" class="form-label ms-2 sr-only">Status</label>
                <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectStatus($event)">
                  <option selected [selected]="true" disabled>Estatus</option>
                  <option *ngFor="let stat of catalog.status" [value]="stat.id" >{{stat.name}}</option>
                </select>
              </div>
            </!-->
          </div>
        </div>
        <div class="col-md-4 text-end">
          <button class="btn btn-link text-danger mt-0 pt-0" *ngIf="searchActive" (click)="clearFilter()">
            <fa-icon [icon]="faTimes" size="lg" [spin]="loading" class="me-3"></fa-icon>
            Limpiar filtros
          </button>
          <!-- form [formGroup]="searchBox" (ngSubmit)="searchTasks($event)" *ngIf="!searchActive">
            <input class="form-control form-control-lg" placeholder="Buscar..." formControlName="search">
          </form -->
        </div>
        <div class="col-md-12 mt-5 pb-3">
          <div class="list-group rounded-4 shadow" *ngIf="tasks.length>0">
            <div class="list-group-item p-0 overflow-hidden" *ngFor="let task of tasks | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
              <app-item-task-refuel
                [task]="task"
                [profile]="profile"
                [permissions]="permissions"
                [technicians]="technicians"
                [settlements]="settlements"
                [builders]="builders"
                (taskData)="saveTask($event)"
              ></app-item-task-refuel>
            </div>
          </div>
          <div class="list-group rounded-4 shadow" *ngIf="tasks.length==0">
            <div class="list-group-item text-center py-5" *ngIf="loading">
              <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
              <p class="text-muted">Cargando...</p>
            </div>
            <div class="list-group-item text-center py-5" *ngIf="!loading">
              <img src="/assets/images/empty-state.png" width="240px">
              <h5 class="text-muted">No se encontraron registros</h5>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="tasks.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"                  
            ></pagination-controls>
          </div>
        </div>
      </div>    
    </div>
  `,
  styleUrls: []
})
export class TaskRefuelComponent implements OnInit {
  loading: boolean = false;
  tasks: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  profile: any = {};
  permissions: any = {};

  settlements: any = [];
  buildings: any = [];
  technicians: any = [];
  taskCategories: any[] = [];
  builders: any[] = [];

  searchActive: boolean = false;

  faPlus = faPlus;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faCircleNotch = faCircleNotch;
  faTimes = faTimes;

  payload: any = {
    page: 1,
    per_page: 50,
    status: 0,
    builder_id: 8
  };

  p: number = 1;

  constructor(
    private store : SettlementsService,
    private taskService: TasksService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private dateService: DateService,
    public catalog: CatalogsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    this.loadTasks(this.payload);
    this.initForm();
    this.breadcrumbService.set('@index', 'Servicio técnico');
    this.breadcrumbService.set('@tasks', 'Tareas');

    this.loadTechnicians();
    this.loadSettlements();
    this.loadTaskCategories();
    // this.loadBuilders();
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  loadSettlements() {
    let settlementPayload: any = {
      page: 1,
      per_page: 300,
      utility_id: this.profile.utility_id
    };
    this.store.getSettlements(settlementPayload).subscribe((data: any) => {
      this.settlements = data.data;
    });
  }

  loadTechnicians() {
    this.store.getUsers({ page: 1, per_page: 100, role_id: 3, profile_id: this.profile.id }).subscribe((data: any) => {
      this.technicians = data.data;
    });
  }

  loadBuilders() {
    this.taskService.getBuilders({ page: 1, per_page: 100, profile_id: this.profile.id }).subscribe((data: any) => {
      this.builders = data.data;
    });
  }

  searchTasks(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadTasks(this.payload);
  }

  loadTasks(payload: any) {
    this.loading = true;
    this.taskService.getTasks(payload).subscribe({
      next: (categories: any) => {
        console.log("==============> ", categories.data);
        this.tasks = categories.data;
        this.meta = categories.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
      }
    });
  }

  selectSettlement(event: any) {
    let buildingPayload: any = {
      page: 1,
      per_page: 50,
      settlement_id: event.target.value
    };
    
    // this.store.getBuildings(buildingPayload).subscribe((data: any) => {
      // this.buildings = data.data;      
    // });

    this.searchActive = true;
    this.payload.settlement_id = event.target.value;
    this.loadTasks(this.payload);
  }

  selectBuilding(event: any) {

    this.payload.building_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectCategory(event: any) {
    this.payload.builder_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectStatus(event: any) {
    this.payload.status = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  async loadTaskCategories() {
    this.taskService.getBuilders({ page: 1, per_page: 100}).subscribe((cat: any) => {
      console.log('BUILDERS ======> ', cat);
      this.builders = cat.data;
      this.taskCategories = cat.data;
      this.loadTasks(this.payload);
    });    
  }

  clearFilter() {
    this.payload = {
      page: 1,
      per_page: 10,
      status: 0,
      builder_id: 8
    }
    this.settlements = [];
    this.loadSettlements();
    this.buildings = [];
    this.technicians = [];
    this.loadTechnicians();
    this.taskCategories = [];
    this.loadTaskCategories();
    this.searchActive = false;
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadTasks(this.payload);
  }

  destroyTask(event: any) {
    this.loadTasks(this.payload);
  }

  saveTask(data: any) {
    console.log('!!!! ===> ', data);
    this.taskService.updateTask(data.id, data).subscribe({
      next: (resp: any) => {
        this.toastr.success(resp.message, 'Éxito');
        // this.loadTasks(this.payload);
        window.location.reload();
      },
      error: (err: any) => {
        this.toastr.error(err.error.message, 'Error');
      }
    });
    
  }
}
