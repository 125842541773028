import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-gas-meter',
  template: `
    <div #modalGasMeter id="modalGasMeter" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalGasMeter" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Medidor de gas</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="icons.faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body p-4">
            <app-form-gas-meter [unit_id]="unit.id" [meter]="meter" (updateMeter)="updateGasMeter($event)" (createMeter)="createGasMeter($event)" (cancelMeter)="cancel()"></app-form-gas-meter>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalGasMeterComponent implements OnInit {
  @Input() unit: any = {};
  @Input() meter: any = {};
  @Output() updateGMeter = new EventEmitter<any>();

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService,
  ) { }

  ngOnInit(): void {
    // console.log('UNIT ==========> ', this.unit)
  }

  createGasMeter(meter: any) {
    // console.log('createGasMeter', meter);
    this.updateGMeter.emit(meter);

    this.store.createGasMeter(meter).subscribe({
      next: (data: any) => {
        // this.addingMedidor = false;
        this.toastr.success('Medidor se creó correctamente.', 'Éxito');
        // this.loadUnit(this.unit.id);
        window.location.reload();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(`Medidor no pudo ser creado. \n ${err.error.exception}`, 'Error');
      }
    });
  }

  public updateGasMeter(meter: any) {
    // console.log('updateGasMeter', meter);
    this.updateGMeter.emit(meter);
    
    this.store.updateGasMeter(meter.id, meter).subscribe({
      next: (data: any) => {
        // this.addingMedidor = false;
        this.toastr.success('Medidor se creó correctamente.', 'Éxito');
        // this.loadUnit(this.unit.id);
        window.location.reload();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(`Medidor no pudo ser creado. \n ${err.error.exception}`, 'Error');
      }
    });
  }



  cancel() {}
}
