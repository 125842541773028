import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { PaymentsService } from 'src/app/services/payments.service';
import { environment } from 'src/environments/environment';
import { SupportService } from 'src/app/services/support.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';

import * as bs from 'bootstrap';
import { SlackService } from 'src/app/services/slack.service';

@Component({
  selector: 'app-show-bill',
  template: `
      <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Editar recibo</h1>
          </div>          
        </div>
        <div class="row">
          <div class="col-md-6">
          <div class="card border-0 rounded-4 shadow">
            <div class="card-body p-4">
              <h5 class="mb-0">Condominio</h5>
              <h2>{{settlement?.name}}</h2>
              <h4>{{building?.name}}</h4>
              <p class="mb-0">Unidad: {{unit?.name}}</p>
              <div class="row mt-2">
                <div class="col-md-6" *ngIf="tenant">
                  <div class="rounded-3 border border-1 p-2">
                    <h5>Inquilino</h5>
                    <p class="mb-0">{{tenant?.name}}</p>
                    <p class="mb-0">{{tenant?.last_name}}</p>
                    <p class="mb-0">{{tenant?.email}}</p>
                    <p class="mb-0">Tel. {{tenant?.phone}}</p>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="fiscal">
                  <div class="rounded-3 border border-1 p-2">
                    <h5>Datos fiscales</h5>
                    <p class="mb-0"><strong>RFC:</strong> {{fiscal?.rfc}}</p>
                    <p class="mb-0"><strong>Razón social</strong> {{fiscal?.razon_social}}</p>
                    <p class="mb-0"><strong>C.P.</strong> {{fiscal?.zip_code}}</p>
                    <p class="mb-0" *ngIf="fiscal?.regimen_fiscal">{{regimenFiscal(fiscal?.regimen_fiscal).id}} - {{regimenFiscal(fiscal?.regimen_fiscal).name}}</p>
                    <p *ngIf="fiscal?.uso_cfdi">{{usocfdi(fiscal?.uso_cfdi).id}} - {{usocfdi(fiscal?.uso_cfdi).name}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0 rounded-4 shadow mt-4 mb-5">
            <div class="card-body p-4" *ngIf="billForm">
              <h3>Detalle del recibo</h3>
              <form [formGroup]="billForm" >
                <div class="row">
                  <div class="col-12 col-md-12">
                    <div class="form-group mt-3">
                      <label for="reference" class="form-control-label">Referencia</label>
                      <input type="text" formControlName="reference" class="form-control form-control-lg">
                      <div *ngIf="bfc['reference'].touched && bfc['reference'].invalid">
                        <div *ngIf="bfc['reference'].errors?.['required']" class="text-danger text-sm">La referencia es requerido.</div>
                      </div>
                    </div>                    
                    <div class="card cleafix mt-4 px-2 py-1 rounded-3 bg-white" *ngIf="!bill?.adv || bill?.state===0">
                      <div class="card-body">
                        <div class="clearfix">
                          <h3 class="float-start mt-2">Servicios</h3>
                          <div class="float-end">
                            <button type="button" (click)="addConcept()" class="btn btn-sm btn-primary" *ngIf="bill?.state==0">
                              <fa-icon [icon]="icons.faPlus"></fa-icon>
                            </button>
                          </div>
                        </div>
                        <div class="clearfix mt-2" *ngIf="addingConcept">
                          <app-item-form-concept (conceptData)="saveConcept($event)" [concepts]="concepts"></app-item-form-concept>
                        </div>
                        <div class="list-group mt-3">
                          <div class="list-group-item bg-light clearfix" *ngFor="let concept of additionalConcepts">
                            <div class="row">
                              <div class="col-md-7">
                                <p class="mb-0">{{concept.name}}</p>
                              </div>
                              <div class="col-md-4">
                                <p class="float-end mb-0">{{concept.amount | currency: 'USD' }}</p>
                              </div>
                              <div class="col-md-1 text-center">
                                <button type="button" class="btn btn-link text-danger btn-sm me-2 pt-0" [disabled]="bill?.state==1" (click)="removeConcept(concept)">
                                  <fa-icon [icon]="icons.faTrashAlt"></fa-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card clearfix mt-4 shadow-md rounded-4 bg-light" *ngIf="!bill?.adv">
                      <div class="card-body">
                        <h3>Gas</h3>
                        <div class="list-group shadow-md mt-3">
                          <div class="list-group-item">
                            <div class="row">
                              <div class="col-md-1 text-center mt-2">
                                <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji>
                              </div>
                              <div class="col-md-10 mt-2">
                                <strong>{{tanque?.name}}</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="form-group mt-3" *ngIf="permissions?.modules.admin.billing.modify.write">
                          <div class="form-check">
                            <input class="form-check-input border-dark" type="checkbox" id="cancel_interests" formControlName="cancel_interests"> 
                            <label class="form-check-label" for="cancel_interests">Cancelar intereses</label>
                          </div>
                        </div>
                        <div class="form-group mt-3" *ngIf="permissions?.modules.admin.billing.modify.write">
                          <div class="form-check">
                            <input class="form-check-input border-dark" type="checkbox" id="cancel_recargos" formControlName="cancel_recargos"> 
                            <label class="form-check-label" for="cancel_recargos">Cancelar recargos</label>
                          </div>
                        </div>
                        <div class="form-group mt-3">
                          <label for="read_curr" class="form-control-label">Lectura actual</label>
                          <input type="number" formControlName="read_curr" class="form-control form-control-lg" (focusout)="currReadFocusOut()" [readonly]="bill?.state==1">
                        </div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="description" class="form-control-label">Comentarios</label>
                      <textarea formControlName="description" class="form-control form-control-lg"></textarea>
                    </div>
                    <hr>
                  </div>
                  <div class="col-md-6 clearfix pt-2" *ngIf="bill">
                    <div class="row">
                      <div class="col-md-12 clearfix">
                        <div class="rounded-5 me-2 float-start mt-2" [ngClass]="getState(bill.state)" style="width: 8px !important; height: 8px !important;"></div>
                        <p class="mb-0 mt-0 float-start">{{getStatus(bill.state)}}</p>
                      </div>
                      <div class="col-md-12 clearfix" *ngIf="bill.state==1">
                        <div class="rounded-5 me-2 float-start mt-2" [ngClass]="getBillStatus(bill.status)" style="width: 8px !important; height: 8px !important;"></div>
                        <p class="mb-0 mt-0 float-start">{{billStatus(bill.status)}}</p>
                      </div>
                      <div class="col-md-12">
                      <p class="mb-0 text-muted" style="font-size: 13px;">Fecha de creación: {{ bill.created_at | date: 'dd/MM/yy HH:mm'}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 text-end mt-2 mb-3">
                    <div ngbDropdown class="d-inline-block">
                      <button type="button" class="btn btn-outline-success rounded-5 btn-lg" id="dropdownBasic1" ngbDropdownToggle [disabled]="loading">
                        <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="me-2"></fa-icon>
                        <fa-icon [icon]="icons.faCircleNotch" *ngIf="loading" [spin]="true" class="me-2"></fa-icon>
                        Actualizar recibo
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <div *ngIf="bill?.total==0">
                          <button ngbDropdownItem [disabled]="bill?.state==1" (click)="payBill()">Marcar como pagado</button>
                        </div>
                        <div *ngIf="bill?.total>0">
                          <button ngbDropdownItem (click)="regenerateReceipt()" *ngIf="bfc['state']?.value === 1" [disabled]="transaction?.transaction_id">Reemitir Recibo</button>
                          <button ngbDropdownItem *ngIf="bfc['invoiced']?.value == true" (click)="regenerateInvoice()">Regenerar factura</button>
                          <button ngbDropdownItem [disabled]="bfc['state'].value === 1 || bfc['total'].value < 0" (click)="publishBill()">Emitir Recibo</button>
                          <button ngbDropdownItem [disabled]="bfc['state'].value === 1" (click)="configInvoice()">Emitir Factura</button>
                          <!-- && bfc['invoiced'].value === true -->
                        </div>
                        <button ngbDropdownItem (click)="updateBill()">Guardar borrador</button>
                        <!-- button ngbDropdownItem (click)="resendBill()">Reenviar correo</!-->
                      </div>
                    </div>
                  </div>                  
                  <p class="">{{loadingMsg}}</p>
                </div>
              </form>
            </div>
          </div>
          </div>
          <div class="col-md-5 offset-md-1" *ngIf="bill">
            <div *ngIf="!bill?.adv">
              <app-receipt-reads [bfc]="bfc" [medidor]="medidor" [prev_medidor]="prev_medidor"></app-receipt-reads>
              <app-receipt-breakdown
              [bfc]="bfc"
              [additionalConcepts]="additionalConcepts"
              [interests]="5"
            ></app-receipt-breakdown>
            </div>
            <div *ngIf="bill?.adv">
              <app-receipt-tiny [bfc]="bfc" [additionalConcepts]="additionalConcepts" [interests]="5"></app-receipt-tiny>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-modal-invoice-config [loading]="loading" (saveInvoice)="publishInvoice($event)"></app-modal-invoice-config>
  `,
  styleUrls: []
})
export class ShowBillComponent implements OnInit {
  billId: any;
  transactions: any = []
  bill: any = null;
  // bills: any[] = [];
  transaction: any = {};
  unit: any = {};
  settlement: any = {};
  building: any = {};
  buildings: any[] = [];
  billForm: FormGroup = new FormGroup({});
  loading: boolean = false;
  utilities: any[] = [];
  selectedBuilding: any = {};
  selectedFloor: any = {};
  selectedUnit: any = {};
  selectedUtility: any = {};
  profile: any = {};
  permissions: any = {};
  meterImages: any = null;
  concepts: any[] = [];
  additionalConcepts: any[] = [];

  loadingMsg: string = '';

  addingConcept: boolean = false;

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  token_ca: any;

  utilityData: any = null;

  invoiceForm: FormGroup = new FormGroup({});

  factor: number = 0;
  admon: number = 0;
  price: number = 0;
  adeudos: number = 0;
  recargos: number = 0;
  descuento: number = 0;
  interests: number = 0;
  tanque: any = null;

  tenant: any = null;
  fiscal: any = null;

  medidor: any = null;
  prev_medidor: any = null;

  invoiceData: any = {};
  conceptos: any = [];
  // additionalConcepts: any = [];
  serie: string = '';
  // formapago: string = '';
  complemento: any = {};

  constructor(
    private store: SettlementsService,
    private paymentService: PaymentsService,
    private slackService: SlackService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private support: SupportService,
    private image: ImageService,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService,
    public catalog: CatalogsService,
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!).profile;
    this.permissions = JSON.parse(localStorage.getItem('permissions')!);

    this.activateRoute.params.subscribe((params: any) => {
      this.loading = true;
      if (params['id'] != 'new') {

        this.store.getPaymentConcepts({ page: 1, per_page: 1000 }).subscribe((data: any) => {
          this.concepts = data.data;
        });

        this.store.getBill(params['id']).subscribe(async (data: any) => {
          this.bill = data;
          this.billForm.patchValue(this.bill);
          // 
          if (data.additional_services) {
            let services: any = JSON.parse(data.additional_services);
            this.additionalConcepts = services;
          }

          this.store.getUnitSettlement(data.unit_id).subscribe(async (response: any) => {
            // console.log('GET UNIT SETTLEMENT DATA =====> ', response);
            this.settlement = response.settlement;
            this.building = response.building;
            this.unit = response.unit;
            this.tenant = response.tenants;
            this.fiscal = response.fiscal;
            this.invoiceForm.patchValue(this.fiscal);

            // if (this.settlement.merchant_id == 'actnet') {
              let token: any = `${this.profile.razon_social}_TOKEN_CA`;
              this.token_ca = environment[token as keyof typeof environment];
            // }
            
            this.store.getProfile(this.profile.utility_id).subscribe(async (profile: any) => {              
              this.utilityData = profile;
              // TODO: add profile series
              this.serie = 'MAER'; // profile.series;
              //
              if (this.utilityData.logo) {
                let logo: any = await this.image.getBase64ImageFromUrl(this.image.buildURL(this.utilityData.logo));
                this.utilityData.logo = logo.error ? '/assets/images/empty-image.jpg' : "data:image/jpeg;base64," + logo.split(',')[1];
              }
            });

            this.store.getPreviousBill(data.id).subscribe(async (prevBill: any) => {
              if (prevBill != null && prevBill.medidor) {
                let prev_medidor: any = await this.image.getBase64ImageFromUrl(this.image.buildURL(prevBill.medidor));
                this.prev_medidor = this.image.buildURL(prevBill.medidor);
                this.billForm.patchValue({ medidor_prev: "data:image/jpeg;base64," + prev_medidor.split(',')[1] });
              } else {
                this.prev_medidor = '/assets/images/empty-image.jpg';
              }
            });

            if (data.medidor) {
              let medidor: any = await this.image.getBase64ImageFromUrl(this.image.buildURL(data.medidor));
              this.medidor = this.image.buildURL(data.medidor);
              this.billForm.patchValue({ medidor: "data:image/jpeg;base64," + medidor.split(',')[1] });
            }

            if (this.bill.state > 0) {
              this.store.getLastTransactionByUnit(this.bill.id).subscribe((data: any) => {
                this.transaction = data;
              });
            }
          });

          this.loading = false;
        });
      } else {
        this.store.getBuildings({ page: 1, per_page: 1000 }).subscribe((data: any[]) => {
          this.buildings = data;
          this.loading = false;
        });
      }
    });
  }

  getStatus(status: any) {
    switch (status) {
      case 0:
        return 'Borrador';
      case 1:
        return 'Emitido';
      case 3:
        return 'Cancelado';
      case 4:
        return 'Pendiente';
      default:
        return 'N/A';
    }
  }

  getState(status: any) {
    switch (status) {
      case 0:
        return 'bg-warning';
      case 1:
        return 'bg-success';
      case 3:
        return 'bg-danger';
      case 4:
        return 'bg-info';
      default:
        return 'bg-secondary';
    }
  }

  billStatus (status: any) {
    switch (status) {
      case true:
        return 'Pagado';
      case false:
        return 'No pagado';
      default:
        return 'No pagado';
    }
  }

  getBillStatus (status: any) {
    switch (status) {
      case true:
        return 'bg-success';
      case false:
        return 'bg-danger';
      default:
        return 'bg-danger';
    }
  }

  usocfdi(uso_cfdi: any) {
    let finder = this.catalog.usosCFDI.find((uso: any) => uso.id == uso_cfdi);
    return finder;
  }

  regimenFiscal(regimen: any) {
    let finder = this.catalog.regimenes.find((reg: any) => reg.id == regimen);
    return finder;      
  }

  currReadFocusOut() {
    this.calculateTotal();
  }

  ngOnInit(): void {
    this.buildForm();
    this.initForm();
  }

  addConcept() {
    this.addingConcept = true;
  }

  saveConcept(event: any) {
    let concept = event;
    concept.total = concept.total/1.16;
    //
    this.additionalConcepts.push(event);
    this.addingConcept = false;

    // TODO: CALCULATE TOTAL
    this.calculateTotal();
  }

  removeConcept(index: any) {
    this.additionalConcepts.splice(index, 1);
    this.calculateTotal();
  }

  buildForm() {
    this.billForm = this.fb.group({
      id: [''],
      reference: [''],
      description: [''],
      status: [''],
      consumo_m3: [''],
      consumo_lt: [''],
      consumo_periodo: [''],
      consumo_total: [''],
      taxes: [''],
      sub_total: [''],
      total: [''],
      days: [''],
      read_prev: [''],
      read_curr: [''],
      admin_price: [''],
      discount: [''],
      interests: [''],
      recargos: [''],
      periodo_inicio: [''],
      periodo_fin: [''],
      due_date: [''],
      paid_date: [''],
      subscription_id: [''],
      additional_services: [''],
      medidor: [''],
      medidor_prev: [null],
      unit_id: [''],
      state: [''],
      pdf: [''],
      adv: [''],
      gas_price: [''],
      created_at: [''],
      updated_at: [''],
      cancel_interests: [''],
      cancel_recargos: [''],
      merchant_id: [''],
    });    
  }

  initForm(): void {
    this.invoiceForm = this.fb.group({
      rfc: ['', Validators.required],
      razon_social: ['', Validators.required],
      regimen_fiscal: ['', Validators.required],
      uso_cfdi: ['', Validators.required],
      zip_code: ['', Validators.required],
    });    
  }

  get bfc () {
    return this.billForm.controls;
  }

  protected calculateTotal(): void {
    this.loading = true;
    const read_prev = this.billForm.value.read_prev;
    const read_curr = this.billForm.value.read_curr;

    if (read_curr < read_prev) {
      this.toastr.error('La lectura actual no puede ser menor a la anterior.', 'Error');
      this.loading = false;
      return;
    }

    this.store.getBillCalculation({
      unit_id: this.unit.id,
      settlement_id: this.settlement.id,
      bill_id: this.billForm.value.id,
      additional_services: this.additionalConcepts,
      read_curr: read_curr,
      cancel_interests: this.billForm.value.cancel_interests,
      cancel_recargos: this.billForm.value.cancel_recargos,
    }).subscribe({
      next: (calculation: any) => {
        this.billForm.patchValue({
          consumo_m3: calculation.consumo_m3,
          consumo_lt: calculation.consumo_lt,
          consumo_periodo: calculation.consumo_periodo,
          consumo_total: calculation.consumo_total,
          sub_total: calculation.sub_total,
          admin_price: calculation.admin_price,
          discount: calculation.discount,
          taxes: calculation.taxes,
          total: calculation.total,
          interests: calculation.interests,
          recargos: calculation.recargos,
          additional_services: JSON.stringify(calculation.additional_services),
          gas_price: calculation.gas_price,
          due_date: calculation.due_date,
        });

        this.loading = false;
        this.toastr.success('Cálculo del recibo actualizado correctamente', 'Éxito');
      }, error: (err: any) => {
        console.log('calculateTotal ERROR => ', err);
        this.toastr.error('Ocurrió un error al calcular el recibo', 'Error');
        this.loading = false;
      }
    });
  }

  protected configInvoice(): void {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalInvoiceConfig')).show();
  }

  async publishBill() {
    this.loading = true;
    this.loadingMsg = 'Recopilando información del recibo..';

    if (this.billForm.invalid) {
      this.billForm.markAllAsTouched();
      this.toastr.error('Favor de llenar todos los campos', 'Error');
      this.loadingMsg = '';
      this.loading = false;
      return;
    }

    this.billForm.patchValue({
      status: false,
      adv: false,
    });

    let dueDate = new Date(this.bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    let rtry: string = this.bill.retry > 0 ? `-${this.bill.retry}` : '';

    this.billForm.patchValue({
      merchant_id: this.settlement.merchant_id
    });

    await this.updateBill();

    let paymanetData: any = {}
    let receiptPayload = {
      bill: this.billForm.value,
      dueDate: dueDate,
      unitData: this.unit,
      buildingData: this.settlement,
      paymentData: paymanetData,
      utilityData: this.utilityData,
      tenantData: this.tenant,
    };

    const default_cus: string = `${this.profile.razon_social}_DEFAULT_CUS`;
    const generic_default_cus = environment[default_cus as keyof typeof environment];

    const payload: any = {
      process_token: this.token_ca, 
      unique_payment: true,
      transaction_id: `${this.billForm.value.id}-C${rtry}`, // payData.transaction_id,
      description: this.billForm.value.reference,
      expiration_interval_days: "30",
      currency_code: "MXN",
      amount: this.billForm.value.total?.toFixed(2),
      ip_address: "201.207.239.230",
      customer_username: this.tenant ? this.tenant.usename : 'ZAZZ', 
      first_name: this.tenant ? this.tenant.name : 'Administrador',
      last_name: this.tenant ? this.tenant.last_name : 'ZAZZ',
      email: this.tenant ? this.tenant.email : 'grupo.centurion.tech@gmail.com',
      phone: this.tenant ? this.tenant.phone : '5555555555',
      country: "MX",
      state: "CX",
      city: "CDMX",
      address: this.settlement.address,
      zip_code: this.settlement.zip,
      profile_id: this.profile.id,
      customer_id: this.tenant ? this.tenant.customer_id : generic_default_cus
    };

    let payment: any = null;

    console.log("PAYLOAD ====================> ", payload);
    console.log("SETTLEMENT ====================> ", this.settlement);  
    // this.settlement.merchant_id = 'openpay';

    this.loadingMsg = `Comunicando con el motor de pago ${this.settlement.merchant_id}...`;
    // if (this.bill.merchant_id == 'actnet') {
      // payment = await this.merchantActnet(payload);
      // receiptPayload.paymentData.transaction_id = payment.transaction_id; // .id;
    // } else if (this.bill.merchant_id == 'openpay') {

      if (this.tenant && !this.tenant.customer_id) {
        let customer: any = await this.merchantOpenpayCustomer(this.tenant);
        payload.customer_id = customer.id;
      }

      payment = await this.merchantOpenpay(payload);
      receiptPayload.paymentData.transaction_id = payment.payment_method.reference;
      // 
      let barcode: any = await this.image.getBase64ImageFromUrl(payment.payment_method.barcode_url);
      receiptPayload.paymentData.barcode = "data:image/jpeg;base64," + barcode.split(',')[1];
    // }
    receiptPayload.paymentData.merchant_id = 'openpay'; // this.bill.merchant_id;

    console.log('Payment =======================> ', payment);
    if (!payment) {
      this.toastr.error('Ocurrió un error al procesar el pago.', 'Error');
      this.loadingMsg = '';
      this.loading = false;
      return;
    }

    this.loadingMsg = 'Respuesta exitosa, creando transacción...';
    console.log('receiptPayload ==> ', receiptPayload);

    await this.createCashTransaction({
      bill_id: this.bill.id,
      transaction_id: receiptPayload.paymentData.transaction_id,
      amount: this.billForm.value.total?.toFixed(2),
      external_transaction_id: payload.transaction_id,
      merchant_id: receiptPayload.paymentData.merchant_id,
    });

    await this.createReceipt(receiptPayload);
    this.loadingMsg = 'Creando recibo...';

    this.store.publishBill(this.bill.id, this.billForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Recibo emitido correctamente.', 'Éxito');
        this.loadingMsg = '';
        this.loading = false;
        this.route.navigate(['/pagos/pendientes']);
      },
      error: (err: any) => {
        console.log('publishBill ERROR => ', err);
        this.loadingMsg = '';
        this.toastr.error(`Ocurrió un error al emitir el recibo. ${err}`, 'Error');
        this.loading = false;
        this.slackService.sendMessage({
          color: '#36a64f',
          title: `Bill ${this.bill.id}`,
          description: ` *Error al emitir el recibo* \n\n ${err}`,
          fields: []
        });
      }
    });
  }

  publishInvoice(configs: any) {
    this.loading = true;

    console.log('PUBLISH INVOICE configs =>', configs.metodo);

    // if (this.invoiceForm.invalid) {
      // this.toastr.error('No existe información de facturación del RECEPTOR, debes agregar al responsable/inquilino con su información fiscal del local.', 'Error');
      // this.loading = false;
      // return;
    // }

    let subtotal_service = 0;
    this.additionalConcepts.map((concept: any) => {
      subtotal_service += parseFloat(concept.cantidad);
    });
    
    console.log("bill => ", this.billForm.value.due_date);

    let dueDate = new Date(this.billForm.value.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    // this.billForm.value.medidor = this.meterImages;

    let receiptPayload = {
      bill: this.billForm.value,
      dueDate: dueDate,
      unitData: this.unit,
      utilityData: this.utilityData,
      buildingData: this.settlement
    };

    console.log("receiptPayload => ", receiptPayload);
    console.log('billForm => ', this.billForm.value);
    console.log('invoiceForm => ', this.invoiceForm.value);

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const tn = tzoffset + 3600000;
    const localISOTime = (new Date(Date.now() - tn)).toISOString();

    // let receptor: any = {};

    console.log("localISOTime => ", localISOTime);

    /*if (environment.production === false) {
        LugarExpedicion: '42501', //
        receptor = {
          Receptor: {
          RFC: 'SSF1103037F1',
          NombreRazonSocial: 'SCAFANDRA SOFTWARE FACTORY, ',
          UsoCFDI: 'G03',
          RegimenFiscal: '601',
          Direccion: {
            Calle: 'Reforma',
            NumeroExterior: '6283',
            NumeroInterior: 'A',
            Colonia: 'Centro',
            Localidad: 'CDMX',
            Municipio: 'Benito Juarez',
            Estado: 'Ciudad de Mexico',
            Pais: 'Mexico',
            CodigoPostal: '06470'
          }
        },
      };
    } else {
      receptor = {
        Receptor:{
        RFC: this.invoiceForm.value.rfc,
        NombreRazonSocial: this.invoiceForm.value.razon_social,
        UsoCFDI: this.invoiceForm.value.uso_cfdi,
        RegimenFiscal: this.invoiceForm.value.regimen_fiscal,
        Direccion: {
          Calle: "",
          NumeroExterior: "",
          NumeroInterior: "",
          Colonia: "",
          Localidad: "",
          Municipio: "",
          Estado: "",
          Pais: "",
          CodigoPostal: this.invoiceForm.value.zip_code
        }
      }
      };
    }*/

    let encabezados: any = {
      CFDIsRelacionados: "",
      TipoRelacion: "04",
      Receptor:{
        RFC: this.invoiceForm.value.rfc,
        NombreRazonSocial: this.invoiceForm.value.razon_social,
        UsoCFDI: this.invoiceForm.value.uso_cfdi,
        RegimenFiscal: this.invoiceForm.value.regimen_fiscal,
        Direccion: {
          Calle: "",
          NumeroExterior: "",
          NumeroInterior: "",
          Colonia: "",
          Localidad: "",
          Municipio: "",
          Estado: "",
          Pais: "",
          CodigoPostal: this.invoiceForm.value.zip_code
        }
      },
      Fecha: localISOTime.slice(0,19),
      Serie: this.serie.toString(),
      Folio: this.billForm.value.id.toString(),
      MetodoPago: configs.metodoPago,
      FormaPago: configs.formaPago,
      Moneda: "MXN",
      LugarExpedicion: this.invoiceForm.value.zip_code,
      SubTotal: this.billForm.value.sub_total,
      Total: this.billForm.value.total,
    };

    this.complemento = {
      formaPago: configs.formaPago,
      serie: this.serie,
      folio: this.billForm.value.id,
      usoCFDI: this.invoiceForm.value.uso_cfdi,
      metodoPago: configs.metodoPago,
    }

    let payload: any = {
      bill: this.billForm.value,
      profile_id: this.profile.id,
      Encabezado: encabezados,
      Conceptos: this.billForm.value,
      complemento: this.complemento,
      utilityData: this.utilityData,
    };

    // if (this.invoiceForm.invalid) {
      // this.toastr.error('Completa toda la información requerida del formulario.', 'Error');
      // this.loading = false;
      // this.invoiceForm.markAllAsTouched();
      // return;
    // }

    console.log('PAYLOAD INVOICE ===> ', payload);

    this.store.createInvoice(payload).subscribe({
      next: (data: any) => {
        console.log(data);        
        this.invoiceData = data;
        this.loadingMsg = 'Generando factura...';
        this.download();
        this.toastr.success('Factura creada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(`Ocurrió un error al crear la factura. ${err.error.error}`, 'Error');
        this.loading = false;
        this.slackService.sendMessage({
          color: '#36a64f',
          title: `Bill ${this.bill.id}`,
          description: ` *Error al crear factura* \n\n ${err}`,
          fields: []
        });
      }
    });      
  }

  async regenerateReceipt() {
    let dueDate = new Date(this.bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    this.paymentService.getLastCashTransaction(this.bill.id).subscribe({
      next: (data: any) => {
        let paymanetData: any = {}

        let receiptPayload = {
          bill: this.billForm.value,
          dueDate: dueDate,
          unitData: this.unit,
          buildingData: this.settlement,
          paymentData: paymanetData,
          utilityData: this.utilityData,
          tenantData: this.tenant,
        };
        receiptPayload.paymentData.transaction_id = data.transaction_id; 
        receiptPayload.paymentData.merchant_id = this.settlement.merchant_id;
        console.log('receiptPayload => ', receiptPayload);
        this.createReceipt(receiptPayload);
      }
    });
  }

  async regenerateInvoice() {
    console.log('regenerateInvoice ==> ', this.bill);
    if (this.bill.invoice_id != null) {
      this.store.getInvoice(this.bill.invoice_id).subscribe({
        next: (data: any) => {
          this.invoiceData = data.invoice;
          this.download();
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
  }

  async merchantActnet(payload: any) {

    let payment: any = await this.paymentService.createPaymentStoreActnet(payload);

    console.log('PAYMENT ACTNET => ', payment);

    if (payment != undefined && (payment.status_code == 4 || payment.transaction_id == null)) {
      console.log('paymentFailed => ', payment.error);
      this.toastr.error(`El motor de pago no logró la comunicación, por favor intentar más tarde. <br><br> <strong>${payment.error.error_message}</strong>`, 'Error', {
        enableHtml: true,
      });
      this.loadingMsg = 'Error de motor de pago...';
      this.store.updateFailedPayment(this.bill.id).subscribe({
        next: (data: any) => {
          console.log('updateFailedPayment => ', data);
          this.billForm.patchValue(data);
        },
        error: (err: any) => {
          console.log('updateFailedPayment ERROR => ', err);
          this.slackService.sendMessage({
            color: '#36a64f',
            title: `Bill ${this.bill.id}`,
            description: ` *Error al crear orden de pago en actnet* \n\n ${err}`,
            fields: []
          });
        }
      });
      // this.support.paymentFailed(payment.error);
      this.loadingMsg = '';
      this.loading = false;
      // window.location.reload();
      return null;
    }

    return payment;
  }

  async merchantOpenpay(payload: any) {
    let payment: any = await this.paymentService.createOpenpayStore(payload);

    if (payment != undefined && payment['ErrorCode']) {
      console.log('paymentFailed => ', payment.error);
      this.toastr.error(`El motor de pago no logró la comunicación, por favor intentar más tarde. <br><br> <strong>${payment['Description']}</strong>`, 'Error', {
        enableHtml: true,
      });
      this.loadingMsg = 'Error de motor de pago...';
      this.store.updateFailedPayment(this.bill.id).subscribe({
        next: (data: any) => {
          console.log('updateFailedPayment => ', data);
          this.billForm.patchValue(data);
        },
        error: (err: any) => {
          console.log('updateFailedPayment ERROR => ', err);
          this.slackService.sendMessage({
            color: '#36a64f',
            title: `Bill ${this.bill.id}`,
            description: ` *Error al crear orden de pago en openpay* \n\n ${err}`,
            fields: []
          });
        }
      });

      this.loadingMsg = '';
      this.loading = false;
      // window.location.reload();
      return null;
    }

    console.log('PAYMENT OPENPAY => ', payment);

    return payment;
  }

  protected async merchantOpenpayCustomer(tenant: any) {
    console.log('merchantOpenpayCustomer => ', tenant);
    let payload: any = {};
    payload = tenant;
    payload.profile_id = this.profile.id;
    return await this.paymentService.createOpenpayCustomer(payload);
  }

  async createReceipt(payload: any) {
    this.store.createReceipt(payload).subscribe({
      next: (data: any) => {
        console.log('CREATE RECEIPT !!!!!!!');
        this.store.saveReceipt(this.bill.id, { pdf: data.filename }).subscribe((data: any) => {
          console.log('saveReceipt => ', data);
        });

        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        return;
      },
      error: (err: any) => {
        console.log('createReceipt ERROR => ', err);
        this.toastr.error('Ocurrió un error al crear el recibo', 'Error');
        this.loading = false;
        this.slackService.sendMessage({
          color: '#36a64f',
          title: `Bill ${this.bill.id}`,
          description: ` *Error al crear recibo pdf* \n\n ${err}`,
          fields: []
        });
      }
    });
  }

  download() {
    let dueDate = new Date(this.billForm.value.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);
    //
    this.store.downloadInvoice({
      bill: this.billForm.value,
      dueDate: dueDate,
      unitData: this.unit,
      buildingData: this.building,
      utilityData: this.utilityData,
      invoiceData: this.invoiceData
    }).subscribe({
      next: (data: any) => {
        console.log('downloadInvoice => ', data.result.data);
        this.store.saveInvoicePdf(this.invoiceData.id, { pdf: data.filename });

        this.loading = false;

        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link=document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        const blob2 = new Blob([this.invoiceData.cfdixml], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `${this.invoiceData.serie}_${this.invoiceData.folio}.xml`;
        link2.click();

        window.location.reload();
        
        return;
      }, error: (error: any) => {
        this.toastr.error('Ocurrió un error al descargar la factura', 'Error');
        console.log('createReceipt ERROR => ', error);
        this.loading = false;
      }
    });
  }

  payBill(): void {
    this.loading = true;

    if (this.billForm.invalid) {
      this.billForm.markAllAsTouched();
      this.toastr.error('Favor de llenar todos los campos', 'Error');
      this.loading = false;
      return;
    }

    this.billForm.patchValue({
      status: true
    });

    let dueDate = new Date(this.bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    // this.bill.medidor = this.meterImages;
    let paymanetData: any = {}

    let receiptPayload = {
      bill: this.billForm.value,
      dueDate: dueDate,
      unitData: this.unit,
      buildingData: this.settlement,
      paymentData: paymanetData,
      utilityData: this.utilityData,
    };

    receiptPayload.bill.transaction_id = Date.now();

    this.store.createReceipt(receiptPayload).subscribe({
      next: (data: any) => {
        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        this.billForm.patchValue({
          pdf: data.filename
        });

        this.store.publishBill(this.bill.id, this.billForm.value).subscribe({
          next: (data: any) => {
            let payload: any = {
              transaction_id: receiptPayload.bill.transaction_id,
              amount: '0',
              currency: 'MXN',
              status_code: '3',
              error_code: '00',
              error_message: 'Approved',
              payment_method_bin: '0',
              payment_method_type: 'SALDO',
              payment_method_brand: 'SALDO',
              bill_id: this.bill.id,
              external_transaction_id: this.bill.id
            }

            this.store.createTransaction(payload).subscribe({
              next: (data: any) => {
                this.toastr.success('Recibo creado correctamente.', 'Éxito');
                this.loading = false;
                this.route.navigate(['/pagos/pendientes']);
              },
              error: (err: any) => {
                this.toastr.error('Ocurrió un error al crear el recibo', 'Error');
                this.loading = false;
              }
            });
          },
          error: (err: any) => {
            this.toastr.error('Ocurrió un error al crear el recibo', 'Error');
            this.loading = false;
          }
        });
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al crear el recibo', 'Error');
        this.loading = false;
      }
    });
  }

  protected updateBill(): void {
    this.loading = true;

    if (this.billForm.invalid) {
      this.billForm.markAllAsTouched();
      this.toastr.error('Favor de llenar todos los campos', 'Error');
      this.loading = false;
      return;
    }

    this.store.updateBill(this.bill.id, this.billForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Recibo actualizado correctamente', 'Éxito');
        console.log('updateBill => ', data);
        // window.location.reload();
        this.billForm.patchValue(data);
        this.loading = false;
      }, 
      error: (err: any) => {
        this.toastr.error('Favor de llenar todos los campos', 'Error');
        this.loading = false;
      }
    });
  }

  // resendBill() {}

  async createCashTransaction(data: any) {
    console.log('createCashTransaction => ', data);
    let payload: any = {
      transaction_id: data.transaction_id,
      amount: data.amount,
      currency: 'MXN',
      status_code: '1',
      error_message: 'Pending',
      payment_method_bin: '0',
      payment_method_type: 'CASH',
      payment_method_brand: 'CASH',
      bill_id: data.bill_id,
      external_transaction_id: data.external_transaction_id,
      merchant_id: data.merchant_id,
    }

    console.log('createCashTransaction PAYLOAD => ', payload);

    await this.store.createTransaction(payload).subscribe({
      next: (data: any) => {
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.slackService.sendMessage({
          color: '#36a64f',
          title: `Bill ${this.bill.id}`,
          description: ` *Error al crear transacción de efectivo* \n\n ${err}`,
          fields: []
        });
      }
    });
  }
}
