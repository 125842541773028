import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlackService extends CommonService {

  constructor(
    private httpClient: HttpClient,
  ) { 
    super(httpClient);
  }

  public sendMessage(message: any): Observable<any> {
    return this.httpClient.post(`${this.slackURL}`, {
      body: {
        channel: 'zazz',
        color: message.color,
        title: message.title,
        text: message.description,
        fields: message.fields,
        footer: 'ZAZZ Admin'
      }
    });
  }
}
