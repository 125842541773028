import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-contract-types-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg- offset-lg-3 col-xl-6 offset-xl-3 mt-4">
      <xng-breadcrumb></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Tipo de contrato</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <app-form-contract-types [contractType]="contractType" (contractTypeData)="updateContractType($event)"></app-form-contract-types>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ContractTypesEditComponent {

  contractType: any = {};

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    public icons: IconsService
  ) { 
    this.activateRoute.params.subscribe((params: any) => {
      this.store.getContractType(params['id']).subscribe((data: any) => {
        this.contractType = data;
      });
    });
  }

  updateContractType(data: any) {
    this.store.updateContractType(this.contractType.id, data).subscribe((data: any) => {
      this.toastr.success('Tipo de contrato actualizado correctamente');
      this.router.navigate(['/contract-types']);
    });
  }

}
