<div class="container-fluid p-0">
  <div class="row mt-0 p-0">
    <div class="col-md-4 h-100 bg-dark text-center pt-5">
      <img src="assets/images/logo-masgas-lg.png" class="img-fluid mt-5 text-center pt-5" width="50%" alt="condominio">
      <p class="text-light text-sm mt-5 pt-3">Todos los derechos reservados © Mafigue Administración</p>
      <p class="text-light text-sm">V {{version}}</p>
    </div>
    <div class="col-md-8 offset-md-0 pt-0 mt-0 text-center bg-white">
      <div class="row mt-5 mb-5"></div>
      <div *ngIf="!accountCreated" class="row mt-5 pt-5">
        <div class="col-md-6 offset-md-3">
          <h2 class="mb-5 mt-3">Crear cuenta</h2>
          <form [formGroup]="signupForm" (ngSubmit)="signup()">
            <div class="form-group text-start mt-4">
              <label for="email" class="text-sm sr-only d-none">Correo electrónico</label>
              <input
                type="email"
                class="form-control border-2 rounded-0 border-dark pt-3 pb-3"
                formControlName="email"
                placeholder="Correo electrónico"
                #userEmail
                required
              />
              <div *ngIf="lf['email'].touched && lf['email'].invalid">
                <div *ngIf="lf['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico es requerido.</div>
                <div *ngIf="lf['email'].errors?.['email']" class="text-danger text-sm">El correo electrónico no es válido</div>
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="name" class="text-sm sr-only d-none">Nombre(s)</label>
              <input
                type="text"
                class="form-control border-2 rounded-0 border-dark pt-3 pb-3"
                formControlName="name"
                placeholder="Nombre(s)"
                #userEmail
                required
              />
              <div *ngIf="lf['name'].touched && lf['name'].invalid">
                <div *ngIf="lf['name'].errors?.['required']" class="text-danger text-sm">Su nombre es requerido.</div>
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="last_name" class="text-sm sr-only d-none">Apellidos</label>
              <input
                type="text"
                class="form-control border-2 rounded-0 border-dark pt-3 pb-3"
                formControlName="last_name"
                placeholder="Apellidos"                
                #userEmail
                required
              />
              <div *ngIf="lf['last_name'].touched && lf['last_name'].invalid">
                <div *ngIf="lf['last_name'].errors?.['required']" class="text-danger text-sm">Su apellido es requerido.</div>
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="password" class="text-sm sr-only d-none">Contraseña</label>
              <div class="input-group">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  class="form-control border-2 rounded-0 border-dark pt-3 pb-3 border-end-0"
                  formControlName="password"
                  placeholder="Contraseña"
                  #userPwd
                  required
                />
                <span class="input-group-append bg-white border-start-0">
                  <span class="input-group-text bg-transparent rounded-0 border-dark pt-3 pb-3 border-2">
                    <!-- i class="icon ion-ios-search"></!-->
                    <fa-icon (click)="togglePassword()" *ngIf="showPassword" [icon]="faEye" class="text-dark me-2"></fa-icon>
                    <fa-icon (click)="togglePassword()" *ngIf="!showPassword" [icon]="faEyeSlash" class="text-dark me-2"></fa-icon>
                  </span>
                </span>
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="password_confirmation" class="text-sm sr-only d-none">Confirmar Contraseña</label>
              <input
                type="password"
                class="form-control border-2 rounded-0 border-dark pt-3 pb-3"
                formControlName="password_confirmation"
                placeholder="Confirmar Contraseña"
                #userPwd
                required
              />              
            </div>
            <div class="form-group mt-4">
              <password-strength-meter [password]="signupForm.value.password"></password-strength-meter>
            </div>
            <small id="passwordHelpBlock" class="form-text text-muted text-start text-sm">
              Su contraseña debe tener entre 8 y 20 caracteres, contener letras, números, caracteres especiales, y no debe contener espacios, ni emojis.
            </small>

            <div *ngIf="lf['password'].touched && lf['password'].invalid" class="text-start">
              <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
              <small *ngIf="lf['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
              <small *ngIf="lf['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
              <small *ngIf="!lf['password'].errors?.['minlength'] && lf['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($@#!%*?&)</small>
            </div>
            <div *ngIf="lf['password_confirmation'].touched && lf['password_confirmation'].invalid" class="text-start">
              <div *ngIf="lf['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
              <small *ngIf="lf['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
            </div>

            <div class="form-group mt-5 text-center">
              <button type="submit" class="btn btn-dark btn-lg w-100 rounded-0 pt-3 pb-3" >
                <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="text-secondary me-2"></fa-icon>
                <fa-icon [icon]="faLock" *ngIf="!loading" class="text-light me-2"></fa-icon>
                Crear cuenta
            </button>
            </div>
          </form>
          <div class="redirectToLogin mt-5">
            <span class="text-sm"
              >¿Ya tienes cuenta?
              <span class="redirect btn btn-link text-sm" routerLink="/sign-in">Iniciar sesión</span></span
            >
          </div>
        </div>
          <!-- div class="form-group mt-3 mb-3">
            <span class="or"><span class="orInner">Ó</span></span>
          </!-->
          <!-- div class="form-group mt-4">
            <button
              type="button"
              class="btn btn-light border border-1"
              (click)="authService.GoogleAuth()"
            >
              <i class="fab fa-google-plus-g"></i>
              Ingresar con Google
            </button>
          </!-->
          
      </div>
      <div *ngIf="accountCreated" class="row">
        <div class="col-md-6 offset-md-3">
          <h2 class="mb-5 mt-3">Cuenta creada exitosamente</h2>
          <p>Por favor revise su correo electrónico y haga clic en el enlace para verificar su dirección de correo electrónico.</p>
          <span class="text-sm">Regresar?<span class="redirect btn btn-link text-sm" routerLink="/sign-in">Iniciar sesión</span></span>
        </div>
      </div>
    </div>
  </div>
</div>