import { AbstractControl } from '@angular/forms';

export function ValidateConfirmpass(control: AbstractControl) {

  if (!control.parent) {

    return null;
  }

  let parent: any = control.parent;

  if (parent.controls.password.status == 'VALID' && control.value == parent.value?.password) {

    return null;
  }

  return { noMatch: true };
}
