
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { faEdit, faCircleNotch, faTrashAlt, faPlus, faGauge } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-item-tanque',
  template: `
    <div class="row">
      <div class="col-md-4 ps-5 py-3">
        <h3 class="mb-0 mt-2">
          <a *ngIf="permissions?.modules.operation.combustibles.tanques.write" routerLink="{{tanque.id}}"><ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji> {{tanque.name}}</a>
          <span *ngIf="!permissions?.modules.operation.combustibles.tanques.write"><ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji> {{tanque.name}}</span>
        </h3>
      </div>
      <div class="col-md-4 py-3">
        <p class="mb-0" style="font-size: 13px;">Condominio</p>
        <p class="mb-0" style="font-size: 15px;">{{settlement?.name}}, Edificio:{{tanque.building}}</p>
        <p class="mb-0" style="font-size: 15px;">{{settlement?.address}}</p>
      </div>
      <div class="col-md-3 py-3">
        <p class="mb-0" style="font-size: 15px;">Capacidad: {{tanque.capacidad}} {{tanque.unidad}}</p>
        <p class="mb-0" style="font-size: 15px;">Máximo: {{tanque.limite}} %</p>
        <p class="mb-0" style="font-size: 15px;">Mínimo: {{tanque.limite_inferior}} %</p>
      </div>
      <div class="col-md-1 text-center p-0" routerLink="gauge/{{tanque.id}}">
        <!-- button type="button" class="btn btn-outline-primary" routerLink="{{tanque.id}}">
          <fa-icon [icon]="icons.faEdit" size="1x"></fa-icon>
          Editar
        </!-->
        <div class="hover-info border-start pt-3 pe-4" style="height: 100%;" *ngIf="permissions?.modules.operation.combustibles.tanques.write">
          <fa-icon [icon]="icons.faGauge" size="1x" class="center-icon"></fa-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemTanqueComponent implements OnInit {

  @Input() tanque: any;
  @Input() settlements: any;
  @Input() profile: any;
  @Input() permissions: any;

  settlement: any = null;

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    console.log(this.tanque)
    this.store.getBuilding(this.tanque.building_id).subscribe({
      next: (data: any) => {
        this.tanque.building = data.name;
        this.tanque.settlement = data.settlement;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['settlements'] && changes['settlements'].currentValue) {
      this.settlement = changes['settlements'].currentValue.find((e: any) => e.id == this.tanque.settlement_id);
    }
  }

}
