import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { IconsService } from 'src/app/shared/services/icons.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-filter-semi',
  template: `
    <form [formGroup]="filterForm">
      <div class="row mt-5">        
        <div class="col-md-2">
          <!-- h2 class="mb-0">Habitacionales</h2> {{ufc['settlement_id'].value}} -->
          <select class="form-select rounded-5 border border-1 border-secondary bg-transparent" formControlName="settlement_id" (change)="selectSettlement($event)">
            <option selected [value]="0">Condominio</option>
            <option *ngFor="let settlement of settlements" [value]="settlement.id" >{{settlement.name}}</option>
          </select>
        </div>
        <div class="col-md-2 d-none">
          <select class="form-select rounded-5 border border-1 border-dark bg-transparent" formControlName="building_id" (change)="selectBuilding($event)">
            <option selected >Edificio</option>
            <option *ngFor="let building of buildings" [value]="building.id" >{{building.name}}</option>
          </select>
        </div>
        <div class="col-md-2 d-none">
          <select class="form-select rounded-5 border border-1 border-secondary bg-transparent" formControlName="floor_id" (change)="selectFloor($event)">
            <option selected >Piso</option>
            <option *ngFor="let floor of floors" [value]="floor.id" >{{floor.name}}</option>
          </select>
        </div>
        <div class="col-md-2 d-none">
          <select class="form-select rounded-5 border border-1 border-secondary bg-transparent" formControlName="unit_id" (change)="selectUnit($event)">
            <option selected >Depto.</option>
            <option *ngFor="let unit of units" [value]="unit.id" >{{unit.name}}</option>
          </select>
        </div>
        <div class="col-2">          
          <div class="input-group">
            <input
              #dpFromDate
              class="form-control bg-transparent border border-1 border-secondary rounded-start-5"
              placeholder="yyyy-mm-dd"
              name="dpFromDate"
              [value]="formatter.format(fromDate)"
              (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            />
            <button class="btn btn-outline-secondary bi bi-calendar3 bg-transparent rounded-end-5" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="icons.faCalendarAlt"></fa-icon>
            </button>
          </div>

          <div class="dp-hidden position-absolute">
            <div class="input-group">
              <input
                #dpFromDate
                class="form-control"
                style="width: 0px !important; height: 0px !important; opacity: 0;"
                ngbDatepicker
                #datepicker="ngbDatepicker"
                [autoClose]="'outside'"
                (dateSelect)="onDateSelection($event)"
                [displayMonths]="2"
                [dayTemplate]="t"
                outsideDays="hidden"
                [startDate]="fromDate!"
                tabindex="-1" 
              />
              <ng-template #t let-date let-focused="focused">
                <span
                  class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null"
                >
                  {{ date.day }}
                </span>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="input-group">
            <input
              #dpToDate
              class="form-control bg-transparent border border-1 border-secondary rounded-start-5"
              placeholder="yyyy-mm-dd"
              name="dpToDate"
              [value]="formatter.format(toDate)"
              (input)="toDate = validateInput(toDate, dpToDate.value)"
            />
            <button class="btn btn-outline-secondary bi bi-calendar3 bg-transparent rounded-end-5" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="icons.faCalendarAlt"></fa-icon>
            </button>
          </div>
        </div>
        <div class="col-2">
          <select class="form-select rounded-5 border border-1 border-secondary bg-transparent" formControlName="sort" (change)="selectSort($event)">
            <option selected [selected]="true"></option>
            <option value="id">Folio</option>
            <option value="created_at">Fecha creación</option>
            <option value="name">Nombre unidad</option>
          </select>
        </div>
        <div class="col-2">
          <div class="btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-outline-secondary rounded-5">
              <input type="checkbox" checked autocomplete="off" formControlName="order" (change)="selectOrder($event)"> <fa-icon [icon]="icons.faSort" class="ms-2"></fa-icon> Ordenar
            </label>
          </div>
        </div>
        <div class="col-6"></div>
        <div class="col-md-2 text-end" *ngIf="searchActive">
          <button class="btn btn-link text-danger mt-2" (click)="clearFilter()">
            <fa-icon [icon]="icons.faTimes" size="lg" [spin]="loading" class="me-2"></fa-icon>
            Limpiar filtros
          </button>
        </div>
      </div>
    </form>
    <div>
      
    </div>
  `,
  styleUrls: []
})
export class FilterSemiComponent {
  @Input() profile: any;
  @Input() toDate: NgbDate | null;
  @Input() fromDate: NgbDate | null;

  @Output() dateRange = new EventEmitter<any>();
  @Output() billsSettlement = new EventEmitter<any>();
  @Output() billsBuilding = new EventEmitter<any>();
  @Output() billsFloor = new EventEmitter<any>();
  @Output() billsUnit = new EventEmitter<any>();
  @Output() billsSort = new EventEmitter<any>();
  @Output() billsOrder = new EventEmitter<any>();
  @Output() clear = new EventEmitter<any>();

  hoveredDate: NgbDate | null = null;

  faCalendarAlt = faCalendarAlt;

  loading: boolean = false;
  searchActive: boolean = false;

  settlements: any = [];
  buildings: any = [];
  floors: any = [];
  units: any = [];

  filterForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private router: Router,
    private route: ActivatedRoute,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public icons: IconsService,
    private fb: FormBuilder
  ) {
    console.log("CALENDAR ====> ", calendar.getToday());
    this.fromDate = calendar.getToday();
		this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    this.initForm();
    this.loadSettlements();
    

    this.route.queryParamMap.subscribe(params => {
      const condominio = params.get('condominio');
      const edificio = params.get('edificio');
      const piso = params.get('piso');
      const unidad = params.get('unidad');
      const sort = params.get('sort');
      const order = params.get('order');

      console.log('FILTER QUERY PARAMS')
      const queryParams = { ...this.route.snapshot.queryParams };
      const paramsToRemove = ['unidad']; // List the parameters you want to remove
      console.log("queryParams ====> ", queryParams);
      // Remove the specified query parameters
      paramsToRemove.forEach(param => delete queryParams[param]);

      console.log("paramsToRemove ====> ", paramsToRemove);

      
      if (unidad) {
        console.log('QUERY UNIDAD ====> ', unidad);
        let item: any = { target:  { value: '' }};
        item.target.value = unidad;

        this.filterForm.patchValue({ unit_id: unidad });
        this.selectUnit(item);

        if (this.buildings.length == 0) {
          this.filterForm.patchValue({ settlement_id: condominio });
          this.preloadBuildings(condominio, edificio);          
        }

        if (this.floors.length == 0) {
          this.preloadFloors(edificio, piso);
        }
        return;
      }

      if (piso) {
        console.log('QUERY PISO ====> ', piso);
        let item: any = { target:  { value: '' }};
        item.target.value = piso;

        // this.filterForm.patchValue({ floor_id: piso });
        // this.selectFloor(item);

        if (this.buildings.length == 0) {
          this.filterForm.patchValue({ settlement_id: condominio });
          this.preloadBuildings(condominio, edificio);          
        }

        if (this.floors.length == 0) {
          this.preloadFloors(edificio, piso);
        }
        return;
      }

      if (edificio) {
        console.log('QUERY EDIFICIO ====> ', edificio);
        console.log('SETTLEMENTS ==> ', this.settlements);
        let item: any = { target:  { value: '' }};
        item.target.value = edificio;

        // this.filterForm.patchValue({ building_id: edificio });
        // this.selectBuilding(item);

        if (this.buildings.length == 0) {
          this.preloadBuildings(condominio, edificio);
        }
        // if (this.settlements.length == 0) {
          // this.loadSettlements(condominio);
        // }
        return;
      }
      

      if (condominio) {
        console.log('QUERY CONDOMINIO ====> ', condominio);
        let item: any = { target:  { value: '' }};
        item.target.value = condominio;


        this.filterForm.patchValue({ settlement_id: condominio });
        this.selectSettlement(item);
        return;
      }

    });
  }

  onDateSelection(date: NgbDate) {
    console.log("date ====> ", date);
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 
        "created_at_since": `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`, 
        "created_at_to": `${this.toDate?.year}-${this.toDate?.month}-${this.toDate?.day}`
      },
      queryParamsHandling: 'merge'
    });

    this.dateRange.emit({from: this.fromDate, to: this.toDate});
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
    console.log("VALIDATE ====== currentValue ====> ", currentValue);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    
	}

  initForm() {
    this.filterForm = this.fb.group({
      settlement_id: [0],
      building_id: [''],
      floor_id: [''],
      unit_id: [''],
      created_at_since: [''],
      created_at_to: [''],
      sort: [''],
      order: ['']
    });
  }

  loadSettlements(id: any = null) {
    let settlementPayload: any = {
      page: 1,
      per_page: 300,
      utility_id: this.profile.utility_id
    };
    this.store.getSettlements(settlementPayload).subscribe((data: any) => {
      this.settlements = data.data;
      
      // if (id != null) {
        // console.log('SETTLEMENT id => ', id);
        // this.filterForm.patchValue({ settlement_id: id });
        console.log('SETTLEMENT id => ', this.filterForm.value);        
      // }
    });
    // this.filterForm.controls['settlement_id'].setValue(0);
  }

  preloadBuildings(settlement_id: any, id: any = null) {
    let buildingPayload: any = {
      page: 1,
      per_page: 50,
      settlement_id: settlement_id
    };

    this.store.getBuildings(buildingPayload).subscribe((data: any) => {
      this.buildings = data.data;
    });

    this.store.getFloors({
      page: 1,
      per_page: 50,
      building_id: id
    }).subscribe((data: any) => {
      this.floors = data.data;
    });
    

    console.log('BUILDING ID =============> ', id);
    this.filterForm.patchValue({ building_id: id });
  }

  preloadFloors(building_id: any, id: any = null) {
    let floorPayload: any = {
      page: 1,
      per_page: 50,
      building_id: building_id
    };

    this.store.getFloors(floorPayload).subscribe((data: any) => {
      this.floors = data.data;
    });

    this.store.getUnits({
      page: 1,
      per_page: 50,
      floor_id: id
    }).subscribe((data: any) => {
      this.units = data.data;
    });

    this.filterForm.patchValue({ floor_id: id });
  }

  preloadUnits(floor_id: any, id: any = null) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      floor_id: floor_id
    };

    this.store.getUnits(unitPayload).subscribe((data: any) => {
      this.units = data.data;
    });
    this.filterForm.patchValue({ unit_id: id });
  }
  
  selectSettlement(event: any) {
    let buildingPayload: any = {
      page: 1,
      per_page: 50,
      settlement_id: event.target.value
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { "condominio": event.target.value },
      queryParamsHandling: 'merge'
    });
    
    this.store.getBuildings(buildingPayload).subscribe((data: any) => {
      this.buildings = data.data;
      this.searchActive = true;
    });

    this.filterForm.patchValue({ settlement_id: event.target.value });

    this.billsSettlement.emit(event.target.value);
  }

  selectBuilding(event: any) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      building_id: event.target.value
    };
    this.store.getFloors(unitPayload).subscribe((data: any) => {
      this.floors = data.data;
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { "edificio": event.target.value },
      queryParamsHandling: 'merge'
    });

    this.units = [];

    this.billsBuilding.emit(event.target.value);
  }

  selectFloor(event: any) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      floor_id: event.target.value
    };
    this.store.getUnits(unitPayload).subscribe((data: any) => {
      this.units = data.data;
      console.log("UNITS => ", data);
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { "piso": event.target.value },
      queryParamsHandling: 'merge'
    });

    this.billsFloor.emit(event.target.value);
  }

  selectUnit(event: any) {
    let unitPayload: any = {};

    console.log('UNIT ====> ', event.target.value);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { "unidad": event.target.value },
      queryParamsHandling: 'merge'
    });
    

    this.billsUnit.emit(event.target.value);
  }

  selectSort(event: any) {
    console.log('SORT ====> ', event.target.value);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { "sort": event.target.value },
      queryParamsHandling: 'merge'
    });

    this.billsSort.emit(event.target.value);
  }

  selectOrder(event: any) {
    console.log('ORDER ====> ', event.target.checked);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { "order": event.target.checked ? 'ASC' : 'DESC' },
      queryParamsHandling: 'merge'
    });

    this.billsOrder.emit(event.target.checked ? 'ASC' : 'DESC');
  }

  clearFilter() {
    const urlWithoutQueryParams = this.router.url.substring(0, this.router.url.indexOf('?'));
    this.router.navigateByUrl(urlWithoutQueryParams);
    this.settlements = [];
    this.units = [];
    this.floors = [];
    this.buildings = [];
    this.searchActive = false;
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
    this.filterForm.reset();
    this.loadSettlements();
    this.clear.emit();
  }
}
