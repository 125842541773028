import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faCircleNotch, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-tanque',
  template: `
    <form [formGroup]="tanqueForm" (ngSubmit)="saveForm()">
      <div class="form-group required mb-4">
        <label for="name" class="form-label ms-2">Nombre del tanque</label>
        <input type="text" class="form-control form-control-lg" id="name" formControlName="name" placeholder="Nombre">
        <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
          <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mb-4">
        <label for="capacidad" class="form-label ms-2">Capacidad (lts.)</label>
        <input type="text" class="form-control form-control-lg" id="capacidad" formControlName="capacidad" placeholder="Capacidad">
        <div *ngIf="ufc['capacidad'].touched && ufc['capacidad'].invalid">
          <div *ngIf="ufc['capacidad'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mb-4">
        <label for="limite" class="form-label ms-2">Limite de carga %</label>
        <input type="text" class="form-control form-control-lg" id="limite" formControlName="limite" placeholder="limite %">
        <div *ngIf="ufc['limite'].touched && ufc['limite'].invalid">
          <div *ngIf="ufc['limite'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group required mb-4">
        <label for="limite_inferior" class="form-label ms-2">Limite inferior %</label>
        <input type="text" class="form-control form-control-lg" id="limite_inferior" formControlName="limite_inferior" placeholder="Limite inferior %">
        <div *ngIf="ufc['limite_inferior'].touched && ufc['limite_inferior'].invalid">
          <div *ngIf="ufc['limite_inferior'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mb-4">
        <label for="folio_qr" class="form-label ms-2">Folio QR</label>
        <input type="text" class="form-control form-control-lg" id="folio_qr" formControlName="folio_qr" placeholder="Folio QR">
        <div *ngIf="ufc['folio_qr'].touched && ufc['folio_qr'].invalid">
          <div *ngIf="ufc['folio_qr'].errors?.['required']" class="text-danger text-sm">El QR es requerido.</div>
        </div>
      </div>

      <div class="form-group mb-4">
        <label for="latitude" class="form-label ms-2">Latitud</label>
        <input type="text" class="form-control form-control-lg" id="latitude" formControlName="latitude" placeholder="Latitud">
        <div *ngIf="ufc['latitude'].touched && ufc['latitude'].invalid">
          <div *ngIf="ufc['latitude'].errors?.['required']" class="text-danger text-sm">La latitude es requerido.</div>
        </div>
      </div>

      <div class="form-group mb-4">
        <label for="longitude" class="form-label ms-2">Longitud</label>
        <input type="text" class="form-control form-control-lg" id="longitude" formControlName="longitude" placeholder="Longitud">
        <div *ngIf="ufc['longitude'].touched && ufc['longitude'].invalid">
          <div *ngIf="ufc['longitude'].errors?.['required']" class="text-danger text-sm">La longitude es requerido.</div>
        </div>
      </div>

      <div class="form-group mb-4">
        <label for="unidad" class="form-label ms-2">Unidad</label>
        <input type="text" class="form-control form-control-lg" id="unidad" formControlName="unidad" placeholder="e.j. Lts.">
      </div>

      <div class="form-group mb-4">
        <label for="external_id" class="form-label ms-2">Id ANOVA</label>
        <input type="text" class="form-control form-control-lg" id="external_id" formControlName="external_id" placeholder="">
      </div>
      <div class="form-group mb-4">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="parentTanque($event)">
          <label class="form-check-label" for="flexCheckDefault">
            Tiene un tanque padre?
          </label>
        </div>
      </div>
      <div class="form-group mb-4" *ngIf="hasParent">
        <label for="parent_tanque_id" class="form-label ms-2">Tanque padre</label>
        <select class="form-select form-select-lg" formControlName="parent_tanque_id">
          <option selected>Selecciona un tanque</option>
          <option *ngFor="let tanque of tanques" [value]="tanque.id">{{tanque.name}}</option>
        </select>
      </div>
      <div class="form-group required mb-4">
        <label for="name" class="form-label ms-2">Condominio</label>
        <select class="form-select form-select-lg" (change)="selectSettlement($event)" formControlName="settlement_id">
          <option value="" disabled selected>Selecciona un condominio</option>
          <option *ngFor="let settlement of settlements" value="{{settlement.id}}">{{ settlement.name }}</option>
        </select>
      </div>
      <div class="form-group required mb-4" *ngIf="buildings.length>0">
        <label for="name" class="form-label ms-2">Edificio</label>
        <select class="form-select form-select-lg" *ngIf="buildings.length>0" formControlName="building_id">
          <option value="" disabled selected>Selecciona un edificio</option>
          <option *ngFor="let building of buildings" value="{{building.id}}">{{ building.name }}</option>
        </select>
      </div>
      <div class="form-group mb-2 mt-5 text-end">
        <button type="button" class="btn btn-link me-2" routerLink="/tanques">Cancelar</button>
        <button type="submit" class="btn btn-success btn-lg rounded-5 text-dark px-4" [disabled]="loading">
          <fa-icon [icon]="faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
          <fa-icon [icon]="faSave" class="me-2" *ngIf="!loading"></fa-icon>
          Guardar
        </button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTanqueComponent implements OnInit {
  @Input() tanqueId: any;
  @Input() loading: any;
  @Input() profile: any;
  @Input() permissions: any;

  @Output() updateForm = new EventEmitter<any>();
  @Output() cancelForm = new EventEmitter<any>();
  
  tanqueForm: FormGroup = new FormGroup({});
  settlements: any = [];
  buildings: any = [];
  selectedSettlement: any = {};

  preloadSettlement: any = null;

  hasParent: boolean = false;
  tanques: any = [];

  faCircleNotch = faCircleNotch;
  faSave = faSave;

  constructor(
    private fb: FormBuilder,
    private store: SettlementsService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loadSettlements();

    console.log("tanqueId ", this.tanqueId);
    if (this.tanqueId != 0) {
      this.store.getTanque(this.tanqueId).subscribe({
        next: (data: any) => {
          console.log("============> ", data);

          this.store.getBuilding(data.building_id).subscribe((building: any) => {
            console.log(building.settlement.id);
            this.preloadSettlement = building.settlement.id;
            // this.selectedSettlement = this.settlements.filter((el: any) => el.id == building.settlement.id)[0];
          });
          // this.store.getSettlement(data.building.settlement_id).subscribe((data: any) => {});


          this.tanqueForm.patchValue({
            name: data.name,
            capacidad: data.capacidad,
            unidad: data.unidad,
            limite: data.limite,
            limite_inferior: data.limite_inferior,
            settlement_id: data.building.settlement_id,
            folio_qr: data.folio_qr,
            latitude: data.latitude,
            longitude: data.longitude,
            building_id: data.building_id,
            external_id: data.external_id,
          });
        },
        error: (error: any) => {},
      })
    }
  }

  initForm() {

    this.store.getTanques({ page: 1, per_page: 1000, profile_id: this.profile.id }).subscribe((tanque: any) => {
      console.log("tanques => ", tanque);
      this.tanques = tanque.data;
    });


    this.tanqueForm = this.fb.group({
      name: ['', Validators.required],
      capacidad: ['', Validators.required],
      unidad: [''],
      limite: ['', Validators.required],
      limite_inferior: ['', Validators.required],
      folio_qr: [''],
      latitude: [''],
      longitude: [''],
      settlement_id: [''],
      parent_tanque_id: [''],
      building_id: ['', Validators.required],
      external_id: [''],
    });
  }

  get ufc() {
    return this.tanqueForm.controls;
  }

  loadSettlements() {
    let payload: any = {
      page: 1,
      per_page: 100,
      // tipo: 0
    }
    this.store.getSettlements(payload).subscribe((data: any) => {
      this.settlements = data.data;
      if (this.preloadSettlement) {
        this.selectedSettlement = this.settlements.filter((el: any) => el.id == this.preloadSettlement)[0];
      }
      // this.loadBuildings();
    });
  }

  selectSettlement(event: any) {
    this.selectedSettlement = this.settlements.filter((el: any) => el.id == event.target.value)[0];
    console.log(event.target.value);
    console.log(this.selectedSettlement);

    this.store.getBuildingsBySettlement(this.selectedSettlement.id).subscribe((buildings: any) => {
      console.log(buildings);
      this.buildings = buildings;
      // this.preloadSettlement = building.settlement.id;
      // this.selectedSettlement = this.settlements.filter((el: any) => el.id == building.settlement.id)[0];
    });
  }

  loadBuildings() {};

  parentTanque(event: any) {
    this.hasParent = event.target.checked;
  }

  saveForm() {
    this.loading = false;
    console.log('saveForm');
    console.log(this.tanqueForm.value);
    if (this.tanqueForm.invalid) {
      this.toastr.error('Formulario invalido');
      this.tanqueForm.markAllAsTouched();
      return;
    }

    this.updateForm.emit(this.tanqueForm.value);
  }  

  cancel() {
    console.log('cancel');
    this.cancelForm.emit();
  }
}
