import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-profile-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 mt-4">
      <xng-breadcrumb></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Perfil</h1>
            <p>Configura el pefil.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Crear perfil</h4>
                    <p>Configura la perfil con los siguientes campos.</p>
                    <app-form-profile (profileData)="updateProfile($event)" [profile]="profile" [loading]="loading"></app-form-profile>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <app-form-public-record [publicRecord]="public_record" (publicRecordData)="createPublicRecord($event)"></app-form-public-record>
              </div>
            </div>
          </!-->
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ProfileCreateComponent implements OnInit {
  profile: any = null;
  public_record: any = null;
  loading: boolean = false;

  constructor(
    private store: SettlementsService,
      private toastr: ToastrService,
      private router: Router,
      private activateRoute: ActivatedRoute,
      private breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@profiles', 'Perfiles');
    this.loading = true;
    // this.activateRoute.params.subscribe(params => {
      // this.store.getProfile(params['id']).subscribe({
        // next: (res: any) => {
          // this.loading = false;
          // this.profile = res;
          // this.breadcrumbService.set('@profileEdit', this.profile.name);
        // },
        // error: (err: any) => {
          // console.log(err);
          // this.loading = false;
          // this.toastr.error('Ocurrió un error al obtener el perfil.', 'Error');
        // }
      // });
    // });
  }

  updateProfile(event: any) {
    console.log("create ", event);

    this.store.createProfile(event).subscribe({
      next: (res: any) => {
        this.toastr.success('La perfil se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/perfiles']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el perfil.', 'Error');
      }
    });
  }

  createPublicRecord(event: any) {
    console.log("create ", event);

    this.store.createPublicRecord(event).subscribe({
      next: (res: any) => {
        this.toastr.success('La perfil se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/perfiles']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el perfil.', 'Error');
      }
    });
  }
}
