import { map } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart',
  template: `<figure id="bar"></figure>`,
  styleUrls: []
})
export class BarChartComponent implements OnInit {
  @Input() multi: any;

  private svg: any;
  private margin = 50;
  private width = 550 - (this.margin * 2);
  private height = 400 - (this.margin * 2);
  
  constructor() { }

  ngOnInit(): void {
    this.createSvg();
    this.drawChart(this.multi);
    console.log("this.multi ====> ", this.multi);
  }

  private createSvg(): void {
    this.svg = d3.select("figure#bar")
    .append("svg")
    .attr("width", '100%')
    .attr("height", this.height + (this.margin * 2))
    .append("g")
    .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
  }

  drawChart(data: any) {
    console.log("data ====> ", data);    
    // data.sort((a: any, b: any) => a["Stars"] - b["Stars"]);

    let dates = data.map((d: any) => {
      let dac: any = {
        "Stars": d["avg_grade"],
        "Framework": d["task_id"],
        "Released": d["created_at"], // .toISOString().split('T')[0]
      };
      console.log("dac ====> ", dac);
      return dac; 
    });

    console.log("dates ====> ", dates);


    const x = d3.scaleBand()
      .range([0, this.width])
      // .domain(d3.groupSort(data, ([d]) => -d.Stars, (d) => d.Framework))
      .domain(dates.map((d: any) => d.Framework))
      .padding(0.2);

    // Draw the X-axis on the DOM
    this.svg.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end");

    // Create the Y-axis band scale
    const y = d3.scaleLinear()
      .domain([0, 100])
      .range([this.height, 0]);

    // Draw the Y-axis on the DOM
    this.svg.append("g")
      .call(d3.axisLeft(y));

    // Create and fill the bars
    this.svg.selectAll()
      .data(dates)
      .enter()
      .append("rect")
      .attr("x", (d: any) => x(d.Framework))
      .attr("y", (d: any) => y(d.Stars))
      .attr("width", x.bandwidth())
      .attr("height", (d: any) => this.height - y(d.Stars))
      .attr("fill", "#291528");
  }
}
