import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-filter-advanced',
  template: `
    <div>
      <div class="dp-hidden position-absolute">
        <div class="input-group">
          <input
            name="datepicker"
            class="form-control bg-transparent border-0"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1" 
          />
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <form class="row">
        <div class="col-6">          
          <div class="input-group">
            <input
              #dpFromDate
              class="form-control bg-transparent border border-1 border-secondary rounded-start-5"
              placeholder="yyyy-mm-dd"
              name="dpFromDate"
              [value]="formatter.format(fromDate)"
              (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            />
            <button class="btn btn-outline-secondary bi bi-calendar3 bg-transparent rounded-end-5" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="faCalendarAlt"></fa-icon>
            </button>
          </div>
        </div>
        <div class="col-6">
          <div class="input-group">
            <input
              #dpToDate
              class="form-control bg-transparent border border-1 border-secondary rounded-start-5"
              placeholder="yyyy-mm-dd"
              name="dpToDate"
              [value]="formatter.format(toDate)"
              (input)="toDate = validateInput(toDate, dpToDate.value)"
            />
            <button class="btn btn-outline-secondary bi bi-calendar3 bg-transparent rounded-end-5" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="faCalendarAlt"></fa-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  `,
  styleUrls: []
})
export class FilterAdvancedComponent {
  @Input() toDate: NgbDate | null;
  @Input() fromDate: NgbDate | null;
  @Output() dateRange = new EventEmitter<any>();

  hoveredDate: NgbDate | null = null;

  faCalendarAlt = faCalendarAlt;

	// fromDate: NgbDate | null;
	// toDate: NgbDate | null;
  

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    console.log("CALENDAR ====> ", calendar.getToday());
    this.fromDate = calendar.getToday();
		this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  onDateSelection(date: NgbDate) {
    console.log("date ====> ", date);
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}

    this.dateRange.emit({from: this.fromDate, to: this.toDate});
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}

}
