
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-map',
  template: `<div id="map" class="map-container"> </div>`,
  styles: [`
    .map-container {
      height: 480px;
      width: 100%;
    }
  `]
})
export class MapComponent implements OnInit {
  @Input() geojson: any;
  features: any = {};
  bolck: any = {};

  constructor(
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    this.mapService.buildMap();
    

    this.mapService.map!.on('load', () => {
      this.mapService.map!.loadImage(
      'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
      (error, image: any) => {
          if (error) throw error;
          this.mapService.map!.addImage('custom-marker', image);
          this.mapService.map!.addSource('points', {
            type: 'geojson',
            data: this.geojson
          });
          this.mapService.map!.addLayer({
            id: 'points',
            type: 'symbol',
            source: 'points',
            layout: {
              'icon-image': 'custom-marker',
              'icon-allow-overlap': true
            }
          });
        }
      );
    });
  }

  ngOnDestroy(): void {
    // this.mapService.map?.remove();
  }

}
