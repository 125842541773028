import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SettlementsService } from '../services/settlements.service';
import { IconsService } from '../shared/services/icons.service';

@Component({
  selector: '[app-item-contract]',
  template: `
  <div class="row">
    <div class="col-8">
      <p class="mb-0">{{contractName}}</p>
      <p class="mb-0 text-sm text-muted">Creado: {{contract.created_at | date: 'dd/MM/yyyy'}}</p>
      <div class="badge badge-pill badge-sm" [ngClass]="easylex?.data?.status =='UNSIGNED' ? 'bg-warning' : 'bg-success'">{{easylex?.data?.status}}</div>
    </div>
    <div class="col-4 text-end">
      <button class="btn btn-outline-primary btn-sm rounded-2" *ngIf="easylex?.data?.status =='SIGNED'" (click)="openContract()">
        <fa-icon [icon]="icons.faFilePdf"></fa-icon>
      </button>
    </div>
  </div>
    
  `,
  styles: [
  ]
})
export class ItemContractComponent implements OnInit {
  @Input() contract: any;
  @Input() contractTypes: any;

  contractName: string = '';

  easylex: any;

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) {
    
  }

  ngOnInit(): void {
    this.store.getContract(this.contract.id).subscribe((data: any) => {
      console.log('contract data => ', data);
      this.easylex = data.easylex;
    });

    console.log('contract => ', this.contract);
    console.log('contractTypes => ', this.contractTypes);

    // this.contractName = this.contractTypes.find((c: any) => c.id == this.contract.contract_type_id).name;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contractTypes']&&changes['contractTypes'].currentValue) {
      console.log('changes contract => ', changes['contractTypes'].currentValue);
      this.contractName = changes['contractTypes'].currentValue.find((c: any) => c.id == this.contract.contract_type_id)?.name;
    }
  }

  openContract() {
    console.log('open contract => ', this.contract);
    this.store.openSignedContract(this.contract.document_signed_url).subscribe((data: any) => {
      console.log('open contract => ', data);
      // const url = window.URL.createObjectURL(data);
      
      // Option 1: Automatically download the PDF
      window.open(`https://receipt-test.zazz.mx/signed-contracts/${this.contract.document_signed_url}.pdf`, '_blank');
      // const link = document.createElement('a');
      // link.href = `https://receipt-test.zazz.mx/signed-contracts/${this.contract.document_signed_url}.pdf`;
      // link.download = `${this.contract.document_signed_url}.pdf`;  // Filename for download
      // link.click();
    });

  }

}
