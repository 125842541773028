import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEdit, faPlus, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-item-refuel',
  template: `
    <div class="row">
      <div class="col-md-1 ps-4 py-3 text-center pe-0">
        <div class="form-check" *ngIf="refuel.status == false">
        <input type="checkbox" class="form-check-input form-check-input-lg border border-1 border-secondary" (change)="selectRefuel($event)">
        </div>
      </div>
      <div class="col-md-4 ps-0 py-3">        
        <h4 class="mb-0">{{refuel.folio}}</h4>        
        <p class="mb-0"># {{refuel.id}}</p>
        <p class="mb-0" style="font-size: 13px;">Tanque: {{refuel.tanque_name}}</p>
        <p class="mb-0" style="font-size: 13px;">{{refuel.settlement_name}} {{refuel.building_name}}</p>
      </div>
      <div class="col-md-3 py-3">
        <p class="mb-0">Lts. solicitados: {{refuel.liters_po}}</p>
        <p class="mb-0">Lts. suministrados: {{refuel.liters}}</p>
        <p class="mb-0">Precio Lt.: {{refuel.price}}</p>
      </div>
      <div class="col-md-3 clearfix text-end py-3">
        <h5>Fechas</h5>
        <p class="mb-0 text-muted ">Solicitud: {{refuel.ordered_at | date: 'dd/MM/yyyy HH:mm'}}</p>
        <p class="mb-0 text-muted">Suministro: {{refuel.updated_at | date: 'dd/MM/yyyy HH:mm'}}</p>
          <!-- div class="float-end">
            <div class="badge rounded-pill me-3 px-3 py-2 border border-3" [ngClass]="refuel.status == 'active' ? 'border-success text-success' : 'border-warning text-warning'">
            <p class="mb-0 text-capitalize">{{refuel.status}}</p>
          </div -->
          <!-- button class="btn btn-link text-end" routerLink="/amenity/{{amenity.id}}">
            <fa-icon [icon]="faTrashAlt" size="1x" class="text-danger"></fa-icon>
          </!-->
        <!-- /div -->
      </div>
      <!-- *ngIf="permissions?.modules.operation.combustibles.tanques.write" -->
      <div class="col-md-1 text-center p-0" routerLink="show/{{refuel.id}}">
        <div class="hover-info border-start pt-3 pe-4" style="height: 100%;">
          <fa-icon [icon]="icons.faEye" size="1x" class="center-icon"></fa-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemRefuelComponent implements OnInit {
  @Input() refuel: any;
  @Input() profile: any;
  @Input() permissions: any;
  @Output() refuelSelected = new EventEmitter<any>();

  po: any = {};

  faEdit = faEdit;
  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
      this.store.getPurchaseOrder(this.refuel.purchase_order_id).subscribe({
        next: (data: any) => {
          this.po = data;
        },
        error: (error: any) => {
          console.log("error: ", error);
        }
      });
  }

  selectRefuel(event: any) {
    console.log("event: ", event.target.checked);
    this.refuelSelected.emit({ refuel: this.refuel, checked: event.target.checked});
  }
}
