import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-form-activity',
  template: `
    <form [formGroup]="formActivity" (ngSubmit)="saveActivity()">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group required mb-4">
          <label for="name" class="form-label ms-2">Nombre</label>
          <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
          <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
            <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
          </div>
        </div>
        <div class="form-group required mb-4">
          <label for="weight" class="form-label ms-2">Peso</label>
          <input type="text" class="form-control form-control-lg rounded-3" id="weight" placeholder="" formControlName="weight">
          <div *ngIf="ufc['weight'].touched && ufc['weight'].invalid">
            <div *ngIf="ufc['weight'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
          </div>
        </div>
        <div class="form-group required mb-4">
          <label for="time" class="form-label ms-2">Duración (promedio en minutos)</label>
          <input type="text" class="form-control form-control-lg rounded-3" id="time" placeholder="" formControlName="time">
          <div *ngIf="ufc['time'].touched && ufc['time'].invalid">
            <div *ngIf="ufc['time'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
          </div>
        </div>
        <div class="form-group required mb-4 mt-3" *ngIf="activityTypes.length>0">
          <label for="name" class="form-label ms-2">Tipo de actividad</label>
          <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="activity_type_id">
            <option selected>Selecciona un tipo de actividad</option>
            <option *ngFor="let activityType of activityTypes" [value]="activityType.id">{{ activityType.title }}</option>
          </select>
        </div>
        <div class="form-group text-end">
        <button type="button" class="btn btn-link me-2" routerLink="/maintenances/activities">Cancelar</button>
          <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
        </div>
      </div>
    </div>
  </form>
  `,
  styleUrls: []
})
export class FormActivityComponent implements OnInit {
  @Input() category: any;
  @Input() loading: boolean = false;
  @Input() profile: any;
  @Output() activityData = new EventEmitter<any>();

  formActivity: FormGroup = new FormGroup({});
  activityTypes: any[] = [];

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private tasks: TasksService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.loadActivityTypes();
  }

  get ufc() {
    return this.formActivity.controls;
  }

  initForm() {
    this.formActivity = this.fb.group({
      id: ['', []],
      name: ['', Validators.required],
      weight: ['', Validators.required],
      time: ['', Validators.required],
      profile_id: ['', Validators.required],
      activity_type_id: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['category'] && changes['category'].currentValue) {
      this.formActivity.patchValue(changes['category'].currentValue);
      this.preFill();
    }
  }

  preFill() {
    console.log("WTF ===> ", this.category);
    this.formActivity.patchValue(this.category);
    this.formActivity.patchValue({
      profile_id: this.profile.profile.id
    });
  }

  loadActivityTypes() {
    console.log("loadActivityTypes => ", this.profile);
    this.tasks.getActivityTypes({ profile_id: this.profile.profile.id }).subscribe({
      next: (res: any) => {
        this.activityTypes = res.data;
      },
      error: (err: any) => {
        console.log(err);
      },
    });
    this.formActivity.patchValue({
      profile_id: this.profile.profile.id
    });
  }

  saveActivity() {
    console.log("saveActivity => ", this.formActivity.value)
    if (this.formActivity.invalid) {
      this.formActivity.markAllAsTouched();
      this.toastr.error('Revisa los campos en rojo.', 'Error');
      return;
    }

    this.activityData.emit(this.formActivity.value);
  }
}
