import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { ModalInvoiceComplementComponent } from 'src/app/components/modal-invoice-complement/modal-invoice-complement.component';
import { ModalInvoiceNotaComponent } from 'src/app/components/modal-invoice-nota/modal-invoice-nota.component';
import { ModalInvoiceCancelComponent } from 'src/app/components/modal-invoice-cancel/modal-invoice-cancel.component';
import { ModalOptionsPayComponent } from 'src/app/components/modal-options-pay/modal-options-pay.component';
import { environment } from 'src/environments/environment';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-invoice-show',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-4">        
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Factura</h1>
            <xng-breadcrumb></xng-breadcrumb>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-7">
            <div class="list-group mb-5">
              <div class="list-group-item py-3" *ngIf="invoice?.tipo != 5">
                <div class="d-flex">
                  <div>                    
                    <div class="d-flex">
                      <div class="flex-fill">
                        <h5>Emisor</h5>
                        <table class="table table-sm my-2 border-0" style="font-size: 0.8em;">
                          <tbody>
                            <tr>
                              <th>RFC</th>
                              <td class="text-end">{{emisor.Rfc}}</td>
                            </tr>
                            <tr>
                              <th>Nombre</th>
                              <td class="text-end">{{emisor.Nombre}}</td>
                            </tr>
                            <tr>
                              <th>Regimen Fiscal</th>
                              <td class="text-end">{{emisor.RegimenFiscal}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="ms-auto text-end mt-2">
                    <div *ngIf="!invoice?.pdf">
                      <button *ngIf="invoice?.tipo == 5" (click)="regenerateGenericInvoice()" class="btn btn-sm btn-outline-secondary">
                        <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                        Descargar factura
                      </button>
                      <button (click)="regenerateInvoice()" class="btn btn-sm btn-outline-secondary">
                        <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                        Descargar factura
                      </button>
                    </div>
                    <a href="{{receiptUrl}}/public/{{invoice?.pdf}}" target="_blank" *ngIf="invoice?.pdf" class="btn btn-sm btn-outline-secondary">
                      <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                      Descargar factura
                    </a>
                    <!-- button class="btn btn-sm btn-outline-secondary mt-2" *ngIf="invoice?.tipo == 0" (click)="downloadXML(invoice)">
                      <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                      Descargar XML
                    </!-->
                    <button type="button" 
                      class="btn btn-sm btn-outline-secondary mt-2"
                      *ngIf="invoice?.canceled" 
                      (click)="downloadAcuse(invoice)"
                    >
                      <fa-icon [icon]="icons.faDownload" *ngIf="!downloading_acuse"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="downloading_acuse"></fa-icon>
                      Descargar acuse
                    </button>
                    <div *ngIf="permissions.modules.admin.invoices.ingreso.write">
                      <button 
                        class="btn btn-sm btn-outline-danger mt-3" 
                        (click)="openModalDelete(invoice)" 
                        *ngIf="!invoice?.canceled"
                        [disabled]="cancelable"
                      >
                        <fa-icon [icon]="icons.faTimes" class="me-2"></fa-icon>
                        Cancelar factura
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-group-item py-3">
                <div class="d-flex text-center" *ngIf="loading">
                  <div class="flex-fill text-center my-5">
                    <fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon>
                  </div>
                </div>
                <div class="d-flex" *ngIf="!loading">
                  <div class="flex-fill">
                    <h5>Receptor</h5>
                    <table class="table table-sm my-2 border-0" style="font-size: 0.8em;">
                      <tbody>
                        <tr>
                          <th>RFC</th>
                          <td class="text-end">{{receptor.Rfc}}</td>
                        </tr>
                        <tr>
                          <th>Nombre</th>
                          <td class="text-end">{{receptor.Nombre}}</td>
                        </tr>
                        <tr>
                          <th>Regimen Fiscal</th>
                          <td class="text-end">{{receptor.RegimenFiscalReceptor}}</td>
                        </tr>
                        <tr>
                          <th>Domicilio Fiscal</th>
                          <td class="text-end">{{receptor.DomicilioFiscalReceptor}}</td>
                        </tr>
                        <tr>
                          <th>Uso CFDI</th>
                          <td class="text-end">{{receptor.UsoCFDI}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="list-group-item py-3">
                <h5>Información general</h5>
                <div class="d-flex text-center" *ngIf="loading">
                  <div class="flex-fill text-center my-5">
                    <fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon>
                  </div>
                </div>
                <div class="d-flex" *ngIf="!loading">
                  <table class="table table-sm my-2 border-0" style="font-size: 0.8em;">
                    <tbody>
                      <tr>
                        <th>Folio fiscal</th>
                        <td class="text-end">{{invoice?.uuid}}</td>
                      </tr>
                      <tr>
                        <th>Fecha de timbrado</th>
                        <td class="text-end">{{invoice?.fecha | date: 'dd/MM/yy HH:mm'}}</td>
                      </tr>
                      <tr>
                        <th>Tipo de documento</th>
                        <td class="text-end">
                          {{invoiceType(invoice?.tipo)}}
                        </td>
                      </tr>
                      <tr>
                        <th>Serie</th>
                        <td class="text-end">{{serie}}</td>
                      </tr>
                      <tr>
                        <th>Recibo</th>
                        <td class="text-end">{{folio}}</td>
                      </tr>
                      <tr>
                        <th>Forma de pago</th>
                        <td class="text-end">{{invoice?.formaPago}}</td>
                      </tr>
                      <tr>
                        <th>Método de pago</th>
                        <td class="text-end">{{invoice?.metodoPago}}</td>
                      </tr>
                      <tr>
                        <th>Uso del CFDI</th>
                        <td class="text-end">{{invoice?.usoCFDI}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>              
              <div class="list-group-item py-3">
                <div class="d-flex text-center" *ngIf="loading">
                  <div class="flex-fill text-center my-5">
                    <fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon>
                  </div>
                </div>
                <div class="" *ngIf="!loading">
                  <h5>Conceptos</h5>
                  <div class="d-flex">
                    <table class="table table-sm mt-2 border-0" style="font-size: 0.8em !important;">
                      <thead>
                        <tr>
                          <th>Cantidad</th>
                          <th>Clave</th>
                          <th scope="col" class="text-end">IVA</th>
                          <th scope="col" class="text-end">Importe</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="conceptos.length>0">
                        <tr *ngFor="let concepto of conceptos">
                          <td>{{concepto['$']['Cantidad']}}</td>
                          <td>{{concepto['$']['ClaveUnidad']}}</td>
                          <td scope="col" class="text-end">{{concepto['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'] | currency}}</td>
                          <td scope="col" class="text-end">{{concepto['$']['Importe'] | currency}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="">
                    <table class="table table-sm mt-4 border-0" style="font-size: 0.8em !important;">
                      <thead>
                        <tr>
                          <td class="text-secondary">Subtotal</td>
                          <td scope="col" class="text-end text-secondary">{{impuestos['Base'] | currency}}</td>
                        </tr>
                        <tr>
                          <td class="text-secondary">IVA</td>
                          <td scope="col" class="text-end text-secondary">{{impuestos['Importe'] | currency}}</td>
                        </tr>
                        <tr class="border-bottom-0">
                          <td class="border-bottom-0">Total</td>
                          <td scope="col" class="text-end border-bottom-0">{{total | currency}}</td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5" *ngIf="invoice">

            <div  class="list-group mb-5" *ngIf="invoice?.tipo===2">
              <div class="list-group-item py-3">
                <h5>Recibos</h5>
                <table class="table table-sm" style="font-size: 0.8em;">
                  <thead>
                    <th># Recibo</th>
                    <th class="text-center">Periodo</th>
                    <th class="text-center">IVA</th>
                    <th class="text-center">Importe</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bill of invoice?.related">
                      <td># {{bill?.id}}</td>
                      <td class="text-center">{{bill?.periodo_inicio | date: 'dd/MM/yyyy'}}</td>
                      <td class="text-center">{{bill?.taxes | currency}}</td>
                      <td class="text-center">{{bill?.total | currency}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="list-group mb-5" *ngIf="invoice?.tipo===0">
              <div class="list-group-item py-3" *ngIf="invoice.tipo!=5">
                <h5 class="mb-3">Recibo</h5>
                <div class="d-flex">
                  <div class="flex-fill">
                    <h4 class="mb-0"><a routerLink="/pagos/recibos/{{bill?.id}}"># {{bill?.id}}</a></h4>
                    <table class="table table-sm rounded-2 border-0 mt-2" style="font-size: 0.8em;">
                      <tbody>
                        <tr>
                          <th scope="col" colspan="1">
                            <span *ngIf="unitInfo?.settlement?.tipo == 0">Condominio</span>
                            <span *ngIf="unitInfo?.settlement?.tipo == 1">Comercial</span>
                          </th>
                          <td class="text-end" colspan="3">{{unitInfo?.settlement?.name}}</td>
                        </tr>
                        <tr>
                          <th scope="col" colspan="1">
                            <span *ngIf="unitInfo?.settlement?.tipo == 0">Departamento</span>
                            <span *ngIf="unitInfo?.settlement?.tipo == 1">Local</span>
                          </th>
                          <td class="text-end" colspan="3">{{unitInfo?.unit?.name}}</td>
                        </tr>
                        <tr class="border-bottom-secondary">
                          <th>Consumo</th>
                          <td scope="col" class="text-end" *ngIf="bill?.consumo_m3">{{bill?.consumo_m3 | number: '1.2-2'}} M<sup>3</sup></td>
                          <td scope="col" class="text-end" *ngIf="!bill?.consumo_m3">-- M<sup>3</sup></td>
                          <td scope="col" class="text-end" *ngIf="bill?.consumo_lt ">{{bill?.consumo_lt | number: '1.2-2'}} Lts.</td>
                          <td scope="col" class="text-end" *ngIf="!bill?.consumo_lt ">-- Lts.</td>
                        </tr>
                        <tr class="border-bottom-secondary">
                          <th>Lectura</th>
                          <td class="text-end" *ngIf="bill?.read_prev">Ant. {{bill?.read_prev }}</td>
                          <td class="text-end" *ngIf="!bill?.read_prev">Ant. -</td>
                          <td class="text-end" *ngIf="bill?.read_curr">Act. {{bill?.read_curr}}</td>
                          <td class="text-end" *ngIf="!bill?.read_curr">Act. -</td>
                        </tr>
                        <tr class="border-bottom-secondary">
                          <th>Periodo</th>
                          <td class="text-end" *ngIf="bill?.periodo_inicio">{{bill?.periodo_inicio | date: 'dd/MM/yy'}}</td>
                          <td class="text-end" *ngIf="bill?.periodo_fin">{{bill?.periodo_fin | date: 'dd/MM/yy' }}</td>
                        </tr>
                        <tr class="border-bottom-secondary">
                          <th colspan="2">Fecha de vencimiento</th>
                          <td colspan="2" class="text-end" *ngIf="bill?.due_date">{{bill?.due_date | date: 'dd/MM/yy' }}</td>
                        </tr>
                      </tbody>
                    </table>                    
                  </div>
                </div>
              </div>
              <div class="list-group-item py-3">
                <div class="d-flex">
                  <div class="flex-fill">
                    <h5 class="mb-3">Transacción / Pago</h5>
                    <div class="flex-fill text-center my-4" *ngIf="!transaction&&!invoice?.canceled">
                      <button type="button"
                        class="btn btn-sm btn-outline-secondary"
                        *ngIf="permissions.modules.admin.invoices.ingreso.write"
                        (click)="openModalPay()"
                      >Registrar pago</button>
                    </div>
                  </div>
                </div>
                <div class="d-flex" *ngIf="transaction">
                  <div class="ms-auto">
                    <div class="badge bg-light py-2 px-2 me-2 border text-center">
                      <img [src]="cardType(transaction?.payment_method_brand)" alt="Card image cap" width="44"> 
                    </div>
                  </div>
                  <div class="flex-fill ms-3">
                    <h4 class="mb-0"><i># {{transaction?.transaction_id}}</i></h4>
                    <p class="mb-0 text-sm">Monto pagado: <strong>{{transaction?.amount | currency}}</strong></p>
                    <p class="mb-0 text-sm">Método de pago: <strong>{{transaction?.payment_method_type}}</strong></p>
                    <p class="mb-0 text-sm">Fecha pago: <strong>{{bill?.paid_date | date: 'dd/MM/yy'}}</strong></p>
                  </div>
                </div>
              </div>
              
              <div class="list-group-item py-3" *ngIf="invoice">
                <div class="d-flex">
                  <div class="flex-fill">
                    <h5>Nota de crédito</h5>
                    <div class="flex-fill text-center my-4" *ngIf="!nota&&!invoice?.canceled">
                      <button type="button"
                        class="btn btn-sm btn-outline-secondary"
                        *ngIf="permissions.modules.admin.invoices.ingreso.write"
                        (click)="openModalNota()"
                      >Generar nota de crédito
                      </button>
                    </div>
                  </div>
                </div>
                <div class="" *ngIf="nota">
                  <div>
                    <table class="table table-sm" style="font-size: 0.8em;">
                      <tbody>
                        <tr>
                          <th>Folio fiscal</th>
                          <td class="text-end">{{nota.comprobante['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$']['UUID']}}</td>
                        </tr>
                        <tr>
                          <th>Serie</th>
                          <td class="text-end">{{nota.comprobante['$']['Serie']}}</td>
                        </tr>
                        <tr>
                          <th>Tipo</th>
                          <td class="text-end">{{nota.comprobante['$']['TipoDeComprobante']}}</td>
                        </tr>
                        <tr>
                          <th>Fecha</th>
                          <td class="text-end">{{nota.comprobante['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$']['FechaTimbrado'] | date: 'dd/MM/yyyy HH:mm'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-2">
                    <table class="table table-sm" style="font-size: 0.8em;">
                      <thead>
                        <tr>
                          <th>Qty.</th>
                          <th class="text-center">Clave</th>
                          <th>Descripción</th>
                          <th class="text-center">IVA</th>
                          <th>Importe</th>                        
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let concepto of nota.conceptos">
                          <td>{{concepto['$']['Cantidad']}}</td>
                          <td class="text-center">{{concepto['$']['ClaveUnidad']}}</td>
                          <td>{{concepto['$']['Descripcion']}}</td>
                          <td scope="col" class="text-cener">{{concepto['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$']['Importe'] | currency}}</td>
                          <td scope="col" class="text-end">{{concepto['$']['Importe'] | currency}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="text-end" *ngIf="nota">
                  <div class="text-end">
                    <a class="btn btn-sm btn-outline-secondary"
                      href="{{receiptUrl}}/public/FACTURA_{{nota.id}}.pdf" 
                      target="_blank"
                    >
                      <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                      Descargar
                    </a>
                    <button class="btn btn-sm btn-outline-danger ms-2" (click)="openModalDelete(nota)" *ngIf="permissions.modules.admin.invoices.ingreso.write">
                      <fa-icon [icon]="icons.faTimes" class="me-2"></fa-icon>
                      Cancelar
                    </button>
                  </div>
                </div>

                <div class="mt-4" *ngIf="notas_canceled.length>0">
                  <h5 style="font-size: 12px !important;">Notas de crédito canceladas</h5>
                  <table class="table table-sm" style="font-size: 0.8em;">
                    <tbody>
                      <tr *ngFor="let canceled of notas_canceled">
                        <td style="vertical-align: middle;">{{canceled.nota_acuse['Acuse']['Folios'][0]['UUID']}}</td>
                        <td class="text-end">
                          <button type="button" class="btn btn-sm btn-link text-secondary py-0" (click)="downloadAcuse(canceled)">
                            <fa-icon [icon]="icons.faDownload" *ngIf="!downloading_acuse"></fa-icon>
                            <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="downloading_acuse"></fa-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="list-group-item py-3">
                <div class="d-flex">
                  <div class="flex-fill">
                    <h5>Complemento de pago</h5>
                    <div class="flex-fill text-center my-4" *ngIf="!complemento">
                      <button type="button"
                        class="btn btn-sm btn-outline-secondary"
                        (click)="openModalComplemento()"
                        *ngIf="permissions.modules.admin.invoices.ingreso.write&&!invoice.canceled"
                        [disabled]="!bill?.status"
                      >Generar complemento
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-flex" *ngIf="complemento">
                  <div class="flex-fill">
                    <table class="table table-sm" style="font-size: 0.8em;">
                      <tbody>
                        <tr>
                          <th>Folio fiscal</th>
                          <td class="text-end">{{complemento?.uuid}}</td>
                        </tr>
                        <tr>
                          <th>Fecha pago</th>
                          <td class="text-end">{{complemento?.comprobante['pago20:Pago'][0]['$']['FechaPago'] | date: 'dd/MM/yyyy'}}</td>
                        </tr>
                        <tr>
                          <th>Moneda</th>
                          <td class="text-end">{{complemento?.documento['MonedaDR']}}</td>
                        </tr>
                        <tr>
                          <th>Parcialidad</th>
                          <td class="text-end">{{complemento?.documento['NumParcialidad']}}</td>
                        </tr>
                        <tr>
                          <th>Saldo</th>
                          <td class="text-end">{{complemento?.documento['ImpSaldoAnt'] | currency}}</td>
                        </tr>
                        <tr>
                          <th>Pago</th>
                          <td class="text-end">{{complemento?.documento['ImpPagado'] | currency}}</td>
                        </tr>
                        <tr>
                          <th>Insoluto</th>
                          <td class="text-end">{{complemento?.documento['ImpSaldoInsoluto'] | currency}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="d-flex" *ngIf="complemento">
                  <div class="flex-fill text-end">
                    <a href="{{receiptUrl}}/public/COMPLEMENTO_{{complemento?.invoice_id}}.pdf" target="_blank" *ngIf="complemento.pdf" class="btn btn-sm btn-outline-secondary">
                      <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                      Descargar
                    </a>
                    <button type="button" (click)="regenerateComplement(complemento)" *ngIf="!complemento.pdf" class="btn btn-sm btn-outline-secondary">
                      <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                      Descargar
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-danger ms-2" (click)="openModalDelete(complemento)"  *ngIf="permissions.modules.admin.invoices.ingreso.write">
                      <fa-icon [icon]="icons.faTimes" class="me-2"></fa-icon>
                      Cancelar
                    </button>
                  </div>
                </div>

                <div class="mt-4" *ngIf="complementos_canceled.length>0">
                  <h5 style="font-size: 12px !important;">Complementos de cancelados</h5>
                  <table class="table table-sm" style="font-size: 0.8em;">
                    <tbody>
                      <tr *ngFor="let canceled of complementos_canceled">
                        <td style="vertical-align: middle;">{{canceled.complemento_acuse['Acuse']['Folios'][0]['UUID']}}</td>
                        <td class="text-end">
                          <button type="button" class="btn btn-sm btn-link text-secondary py-0" (click)="downloadAcuse(canceled)">
                            <fa-icon [icon]="icons.faDownload" *ngIf="!downloading_acuse"></fa-icon>
                            <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="downloading_acuse"></fa-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-modal-invoice-complement 
      [profile]="profile"
      [invoice]="invoice" 
      [bill]="bill"
      [conceptos]="conceptos" 
      [receptor]="receptor"
      (updateDismiss)="updateDismissed()"
    ></app-modal-invoice-complement>
    <app-modal-invoice-nota 
      [profile]="profile"
      [invoice]="invoice"
      [bill]="bill" 
      [conceptos]="conceptos" 
      [base]="base" 
      [total]="total"
      (updateDismiss)="updateDismissed()"
    ></app-modal-invoice-nota>
    <app-modal-invoice-cancel 
      [profile]="profile"
      [invoice]="cancel_invoice"
      (updateDismiss)="updateDismissed()"
    ></app-modal-invoice-cancel>
    <app-modal-options-pay 
      [bill]="bill"
      [profile]="profile"
      (updateDismiss)="updateDismissed()"
    ></app-modal-options-pay>
    <!-- (billData)="payBill($event)" -->
  `,
  styleUrls: []
})
export class InvoiceShowComponent implements AfterViewInit {
  parser: any;
  profile: any = {};
  permissions: any = {};
  receiptUrl: string = 'https://receipt.zazz.mx'; // environment.RECIBOS_REST_WSPREFIX;
  invoice: any = null;
  bill: any = null;  
  transaction: any = null;
  unitInfo: any = null;

  emisor: any = {};
  receptor: any = {};
  conceptos: any[] = [];
  impuestos: any = {};
  serie: any = null;
  folio: any = null;
  base: number = 0;
  total: number = 0;

  complemento: any = null;
  nota: any = null;
  complementos_canceled: any[] = [];
  notas_canceled: any[] = [];
  cancel_invoice: any = null;
  loading: boolean = true;
  downloading_acuse: boolean = false;
  downloading_invoice: boolean = false;
  cancelable: boolean = false;

  @ViewChild(ModalInvoiceComplementComponent, { static: true }) modalInvoiceComplement !: ModalInvoiceComplementComponent;
  @ViewChild(ModalInvoiceNotaComponent, { static: true }) modalInvoiceNota !: ModalInvoiceNotaComponent;
  @ViewChild(ModalInvoiceCancelComponent, { static: true }) modalInvoiceCancel !: ModalInvoiceCancelComponent;
  @ViewChild(ModalOptionsPayComponent, { static: true }) modalOptionsPay !: ModalOptionsPayComponent;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService,
    public images: ImageService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.activateRoute.params.subscribe(params => {
      this.loadInvoice(params['id']);
    });
  }

  ngAfterViewInit(): void {
    this.loadLogo();
  }

  async loadLogo() {
    const img = this.images.buildURL(this.profile.logo);
    const imx: any = await this.images.getBase64ImageFromUrl(img);
    this.profile.logo = `data:image/jpeg;base64,${imx.split(',')[1]}`;
  }

  async loadInvoice(id: any) {
    this.store.getInvoice(id).subscribe({
      next: async (res: any) => {
        this.invoice = res.invoice;

        const comprobante: any = await this.processXML(this.invoice.cfdixml);
        if (comprobante) {
          this.serie = comprobante['cfdi:Comprobante']['$']['Serie'];
          this.folio = comprobante['cfdi:Comprobante']['$']['Folio'];
          this.receptor = comprobante['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
          this.emisor = comprobante['cfdi:Comprobante']['cfdi:Emisor'][0]['$'];
          this.conceptos = comprobante['cfdi:Comprobante']['cfdi:Conceptos'][0]['cfdi:Concepto'];
          this.impuestos = comprobante['cfdi:Comprobante']['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'];
          this.base = Number(this.impuestos['Base']);
          this.total = Number(this.impuestos['Base']) + Number(this.impuestos['Importe']);
        }

        if (this.invoice.tipo == 5) {
          this.breadcrumbService.set('@invoiceShow', this.invoice.id);
          return;
        } else if (this.invoice.tipo == 0) {
          this.store.getBill(this.invoice.folio).subscribe((bill: any) => {
            console.log('bill => ', bill);
            this.bill = bill;

            this.store.getUnitSettlement(bill.unit_id).subscribe((unit: any) => {
              console.log('unit => ', unit);
              this.unitInfo = unit;
            });

            this.store.getTransactionByBill(bill.id).subscribe((transaction: any) => {
              console.log('transaction => ', transaction);
              this.transaction = transaction;
            });
          });
        } else if (this.invoice.tipo === 2) {
          this.store.getBills({ invoice_id: this.invoice.id }).subscribe((bills: any) => {
            console.log('bills ===> ', bills);
            this.invoice.related = bills.data;
          });
        }

        this.complementos_canceled = [];
        this.notas_canceled = [];
        if (res.related.length > 0) {
          this.processRelated(res.related);
        }

        // this.breadcrumbService.set('@invoiceShow', this.complemento.UUID);
        // console.log("this.conceptos => ", this.conceptos);
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error(err.error.message, 'Error');
      }
    });
  }

  cardType(card: any) {
    if (card == 'VI') {
      return "/assets/images/icon-visa-sm.png";
    } else if (card == 'MC') {
      return "/assets/images/icon-mastercard-sm.png";
    } else if (card == 'AMEX') {
      return "/assets/images/icon-amex-sm.png";
    } else if (card == 'CASH') {
      return "/assets/images/icon-cash.png";
    } else {
      return "/assets/images/icon-visa-sm.png";
    }
  }

  invoiceType(type: number) {
    if (type == 0) {
      return 'Factura Ingreso';
    } else if (type == 3) {
      return 'Nota de crédito';
    } else if (type == 2) {
      return 'Factura Global';
    } else if (type == 1) {
      return '';
    } else if (type == 4) {
      return 'Cancelada';
    } else if (type == 5) {
      return 'Factura ingreso genérica';
    } else if (type == 6) {
      return 'Complemento de pago';
    } else {
      return 'Factura';
    }
  }

  async processXML(xml: any) {
    this.parser = new xml2js.Parser({
      trim: true,            
      explicitArray: true,            
    });
    return await this.parser.parseStringPromise(xml).then(function (result: any) {
      return result;
    });
  }

  async processRelated(related: any) {

    let canceled: any[] = [];
    canceled = related.filter((item: any) => item.canceled == true);
    console.log('cancelable => ', canceled.length);
    console.log('related => ', related);
    this.cancelable = related.length === canceled.length ? false : true;

    let complmentos: any[] = [];
    complmentos = related.filter((item: any) => item.tipo == 6);
    this.complemento = complmentos.find((item: any) => item.canceled == false);
    if (this.complemento) {
      const complemento_comprobante: any = await this.processXML(this.complemento.cfdixml);
      this.complemento.comprobante = complemento_comprobante['cfdi:Comprobante']['cfdi:Complemento'][0]['pago20:Pagos'][0];
      this.complemento.documento = complemento_comprobante['cfdi:Comprobante']['cfdi:Complemento'][0]['pago20:Pagos'][0]['pago20:Pago'][0]['pago20:DoctoRelacionado'][0]['$']
    }

    let complementosCanceled: any[] = [];
    complementosCanceled = related.filter((item: any) => item.tipo == 6 && item.canceled == true);
    if (complementosCanceled.length > 0) {
      complementosCanceled.map(async (complemento: any) => {
        let complement: any;
        const complemento_acuse: any = await this.processXML(complemento.acuse);
        complement = complemento;
        complement.complemento_acuse = complemento_acuse;
        this.complementos_canceled.push(complement);
      });
    }

    let notas: any[] = [];
    notas = related.filter((item: any) => item.tipo == 3);
    this.nota = notas.find((item: any) => item.canceled == false);
    if (this.nota) {
      const nota_comprobante: any = await this.processXML(this.nota.cfdixml);
      this.nota.comprobante = nota_comprobante['cfdi:Comprobante'];
      this.nota.conceptos = nota_comprobante['cfdi:Comprobante']['cfdi:Conceptos'][0]['cfdi:Concepto'];
    }

    let notasCanceled: any[] = [];
    notasCanceled = related.filter((item: any) => item.tipo == 3 && item.canceled == true);
    console.log('notasCanceled => ', notasCanceled);
    if (notasCanceled.length > 0) {
      notasCanceled.map(async (nota: any) => {
        let note: any;
        const nota_acuse: any = await this.processXML(nota.acuse);
        note = nota;
        note.nota_acuse = nota_acuse;
        this.notas_canceled.push(note);
      });
    }

  }

  downloadXML(invoice: any) {
    const blob2 = new Blob([invoice.cfdixml], { type: 'application/xml' });

    var fileURL2 = window.URL.createObjectURL(blob2);
    var link2=document.createElement('a');

    link2.href = fileURL2;
    link2.download = `${invoice.serie}_${invoice.folio}.xml`;
    link2.click();
  }

  openModalComplemento() {
    console.log('this.modalInvoiceComplement => ', this.modalInvoiceComplement);
    const modalTemplate: any | undefined = this.modalInvoiceComplement;
    if (modalTemplate) {
      this.modalInvoiceComplement.openModal(modalTemplate['modalInvoiceComplement']);
    }
  }

  openModalNota() {
    console.log('this.modalInvoiceComplement => ', this.modalInvoiceNota);
    const modalTemplate: any | undefined = this.modalInvoiceNota;
    if (modalTemplate) {
      this.modalInvoiceNota.openModal(modalTemplate['modalInvoiceNota']);
    }
  }

  openModalDelete(factura: any) {
    this.cancel_invoice = factura;
    console.log('factura => ', factura);
    const modalTemplate: any | undefined = this.modalInvoiceCancel;
    if (modalTemplate) {
      this.modalInvoiceCancel.openModal(modalTemplate['modalInvoiceCancel']);
    }
  }

  openModalPay() {
    const modalTemplate: any | undefined = this.modalOptionsPay;
    if (modalTemplate) {
      this.modalOptionsPay.openModal(modalTemplate['modalOptionsPay']);
    }
  }

  regenerateComplement(complemento: any) {
    this.store.downloadComplement({
      invoiceData: complemento,
      utilityData: this.profile
    }).subscribe({
      next: (data: any) => {
        console.log(data);
        this.loading = false;

        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        const blob2 = new Blob([this.invoice.cfdixml], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `${this.serie}_${this.folio}.xml`;
        link2.click();

        this.toastr.success('Factura descargada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        if (err.status >= 500) {
          this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
        }
        this.loading = false;
      }
    });
  }

  regenerateInvoice() {
    
    let dueDate = new Date(this.bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    console.log('this.bill => ', this.bill);

    this.store.getUnitSettlement(this.bill.unit_id).subscribe(async (response: any) => {
      this.store.getProfile(this.profile.id).subscribe(async (profile: any) => {              
        let utilityData = profile;
        if (utilityData.logo) {
          let logo: any = await this.images.getBase64ImageFromUrl(this.images.buildURL(utilityData.logo));
          utilityData.logo = logo.error ? '/assets/images/empty-image.jpg' : "data:image/jpeg;base64," + logo.split(',')[1];
        }

        await this.store.getPreviousBill(this.bill.id).subscribe(async (prevBill: any) => {
          if (prevBill != null && prevBill.medidor) {
            let prev_medidor: any = await this.images.getBase64ImageFromUrl(this.images.buildURL(prevBill.medidor));
            this.bill.medidor_prev = "data:image/jpeg;base64," + prev_medidor.split(',')[1];
          }

          if (this.bill.medidor) {
            let medidor: any = await this.images.getBase64ImageFromUrl(this.images.buildURL(this.bill.medidor));
            this.bill.medidor = "data:image/jpeg;base64," + medidor.split(',')[1];
          }

          this.store.downloadInvoice({
            bill: this.bill,
            dueDate: dueDate,
            unitData: response.unit,
            buildingData: response.building,
            utilityData: utilityData,
            invoiceData: this.invoice
          }).subscribe({
            next: (data: any) => {
              console.log('downloadInvoice => ', data.result.data);
              this.store.saveInvoicePdf(this.invoice.id, { pdf: data.filename });

              this.loading = false;

              const int8Array = new Uint8Array(data.result.data);
              const blob = new Blob([int8Array], { type: 'application/pdf' });

              var fileURL = window.URL.createObjectURL(blob);
              var link=document.createElement('a');

              link.href = fileURL;
              link.download = data.filename;
              link.click();

              const blob2 = new Blob([this.invoice.cfdixml], { type: 'application/xml' });

              var fileURL2 = window.URL.createObjectURL(blob2);
              var link2=document.createElement('a');

              link2.href = fileURL2;
              link2.download = `${this.invoice.serie}_${this.invoice.folio}.xml`;
              link2.click();
              
              return;
            }, error: (error: any) => {
              this.toastr.error('Ocurrió un error al descargar la factura', 'Error');
              console.log('createReceipt ERROR => ', error);
              this.loading = false;
            }
          });
        });
      });
    });
  }

  regenerateGenericInvoice() {
    this.store.downloadGenericInvoice({
      invoiceData: this.invoice,
      utilityData: this.profile
    }).subscribe({
      next: (data: any) => {
        console.log(data);
        this.loading = false;

        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        const blob2 = new Blob([this.invoice.cfdixml], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `${this.serie}_${this.folio}.xml`;
        link2.click();

        this.toastr.success('Factura descargada correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        if (err.status >= 500) {
          this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
        }
        this.loading = false;
      }
    });
  }

  downloadAcuse(invoice: any) {
    console.log('Download acuse invoice => ', invoice);
    console.log('downloadFile', this.invoice);
    this.downloading_acuse = true;
    const payload: any = {
      utilityData: this.profile,
      invoiceData: invoice,
    };

    this.store.createAcuseCancel(payload).subscribe({
      next: (data: any) => {
        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link=document.createElement('a');

        link.href = fileURL;
        link.download = data.filename;
        link.click();

        const blob2 = new Blob([payload.invoiceData.acuse], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `ACUSE_${payload.invoiceData.serie}_${payload.invoiceData.folio}.xml`;
        link2.click();

        this.downloading_acuse = false;
      },
      error: (err: any) => {
        console.log(err);
        this.downloading_acuse = false;
        this.toastr.error('Ocurrió un error al generar el acuse de cancelación.', 'Error');
      }
    });
  }

  updateDismissed() {
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.loadInvoice(params['id']);
    });
  }
}
