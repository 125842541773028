import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ActivatedRoute } from '@angular/router';
import { TasksService } from 'src/app/services/tasks.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-form-task',
  template: `
    <form [formGroup]="formTask" (ngSubmit)="saveTask()">
      <div class="row">
        <div class="col-md-12">
        <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Constructor</label>
            <select class="form-select form-select-lg rounded-3" (change)="setBuilder($event)" formControlName="builder_id">
              <option selected>Selecciona un constructor</option>
              <option *ngFor="let builder of builders" [value]="builder.id">{{ builder.name }}</option>
            </select>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Técnico</label>
            <select class="form-select form-select-lg rounded-3" formControlName="assignee_id" (change)="selectTechnician($event)">
              <option selected>Selecciona al técnico</option>
              <option *ngFor="let technician of technicians" [value]="technician.id">{{technician.name}} {{technician.last_name}}</option>
            </select>
          </div>

          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Condominio</label>
            <select class="form-select rounded-3 form-select-lg" formControlName="settlement_id" (change)="selectSettlement($event)">
              <option selected>Selecciona un condominio</option>
              <option *ngFor="let settlement of settlements" [value]="settlement.id">{{ settlement.name }}</option>
            </select>
            <fa-icon [icon]="faCircleNotch" size="1x" [spin]="true" class="text-secondary" *ngIf="loading"></fa-icon>
          </div>
          <div class="form-group required mb-4 mt-3" *ngIf="buildings.length>0" (change)="selectBuilding($event)">
            <label for="name" class="form-label ms-2">Edificio</label>
            <select class="form-select rounded-3 form-select-lg" formControlName="building_id">
              <option selected>Selecciona un edificio</option>
              <option *ngFor="let building of buildings" [value]="building.id">{{ building.name }}</option>
            </select>
          </div>

          <div *ngIf="depth == 0">
            <div class="form-group required mb-4 mt-3" *ngIf="pos.length>0">
              <label for="name" class="form-label ms-2">Orden de compra</label>
              <select class="form-select rounded-3 form-select-lg" formControlName="po_id">
                <option selected>Selecciona un orden de compra</option>
                <option *ngFor="let po of pos" [value]="po.id">{{ po.folio }}</option>
              </select>
            </div>
          </div>

          <div *ngIf="depth == 2">
            <div class="form-group required mb-4 mt-3" *ngIf="units.length>0">
              <label for="name" class="form-label ms-2">Departamento</label>
              <select class="form-select rounded-3 form-select-lg" formControlName="unit_id">
                <option selected>Selecciona un departamento</option>
                <option *ngFor="let unit of units" [value]="unit.id">{{ unit.name }}</option>
              </select>
            </div>
          </div>

          <div *ngIf="depth == 3">
            <div class="form-group required mb-4 mt-3" *ngIf="tanks.length>0">
              <label for="name" class="form-label ms-2">Tanque</label>
              <select class="form-select rounded-3 form-select-lg" formControlName="tank_id">
                <option selected>Selecciona un tanque</option>
                <option *ngFor="let tank of tanks" [value]="tank.id">{{ tank.name }}</option>
              </select>
            </div>
          </div>


          <div class="form-group mb-4">
            <label for="description" class="form-label ms-2">Fecha</label><!-- [min]="min" -->
            <input type="date" class="form-control form-control-lg" id="description"  formControlName="due_date" (change)="selectDate($event)">
          </div>
          <div class="form-group mb-4">
            <label for="description" class="form-label ms-2">Horario</label>
            <input type="time" class="form-control form-control-lg" id="description" formControlName="due_time" (change)="validateTime($event)">
          </div>
          <!-- div class="form-group mb-4">
            <label for="description" class="form-label ms-2">Duración</label>
            <input type="number" class="form-control" id="description" formControlName="duration">
          </!-->
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Estatus</label>
            <select class="form-select rounded-3 form-select-lg" formControlName="status">
              <option selected>Selecciona un estatus</option>
              <option *ngFor="let estats of status" [value]="estats.id">{{ estats.name }}</option>
            </select>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Prioridad</label>
            <select class="form-select rounded-3 form-select-lg" formControlName="priority">
              <option selected>Selecciona un prioridad</option>
              <option *ngFor="let priority of priorities" [value]="priority.id">{{ priority.name }}</option>
            </select>
          </div>
          
          
          <!-- div class="form-group mb-3">
            <label for="title" class="form-label ms-2">Notas</label>
            <input type="text" class="form-control" id="title" formControlName="title">
          </!-->
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/maintenances/tasks">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
        <!-- button type="button" class="btn btn-info" (click)="checkDates()">Check date</!-->
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTaskComponent implements OnInit {
  @Input() task: any = {};
  @Input() loading: boolean = false;
  @Input() skills: any[] = [];
  @Input() profile: any;
  @Input() currentDay: any;
  @Input() activities: any[] = [];
  @Output() dateData = new EventEmitter<any>();
  @Output() timeData = new EventEmitter<any>();
  @Output() taskData = new EventEmitter<any>();
  @Output() builderData = new EventEmitter<any>();
  @Output() durationData = new EventEmitter<any>();

  formTask: FormGroup = new FormGroup({});
  taskId: number = 0;
  technicians: any[] = [];
  holidays: any[] = [];

  workDays: any = [];
  workHours: any = [];

  min: string = new Date().toISOString().split('T')[0];

  priorities: any = [
    { id: 1, name: 'Baja', color: '#219C90' },
    { id: 2, name: 'Media', color: '#E9B824' },
    { id: 3, name: 'Alta', color: '#EE9322' },
    { id: 4, name: 'Urgente', color: '#D83F31' },
  ];

  status: any = [
    { id: 1, name: 'Pendiente' },
    { id: 2, name: 'En proceso' },
    { id: 3, name: 'Completada' },
    { id: 4, name: 'Cancelada' },
  ];

  settlements: any[] = [];
  buildings: any[] = [];
  units: any[] = [];
  tanks: any[] = [];
  taskCategories: any[] = [];
  builders: any[] = [];
  pos: any[] = [];

  faCircleNotch = faCircleNotch;

  relatedTasks: any[] = [];

  payload: any = {
    page: 1,
    per_page: 100,
  }

  depth: number = 0;

  constructor(
    private store: SettlementsService,
    private taskService: TasksService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dateService: DateService,
  ) {    
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;

    console.log('PROFILE => ', this.profile);
    this.payload.utility_id = this.profile.utility_id;
  }

  ngOnInit(): void {
    this.initForm();

    this.loadSettlements();

    this.store.getTaskCategories({ page: 1, per_page: 1000 }).subscribe({
      next: (data: any) => {
        this.taskCategories = data.data;
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al obtener las categorías de tareas.');
      }
    });

    this.taskService.getBuilders({ page: 1, per_page: 1000 }).subscribe({
      next: (data: any) => {
        this.builders = data.data;
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al obtener los constructores.');
      }
    });

    this.taskService.getHolidays({ profile_id: this.profile.profile.id }).subscribe({
      next: (data: any) => {
        console.log(data);
        this.holidays = data.data;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log("changes ", changes);
    if (changes['task'] && changes['task'].currentValue) {
      let task: any = changes['task'].currentValue;
      console.log("TASK ===========> ", task);
      if (task.id) {
        this.formTask.patchValue(task);
        this.formTask.patchValue({
          task_category_id: task.task_category_id,
          settlement_id: task.settlement_id,
          user_id: task.user_id,
          profile_id: this.profile.profile.id,
          assignee_id: task.assignee_id,
          due_date: task.due_date.split('T')[0],
          due_time: task.due_date.split('T')[1].split('.')[0],
        });

        this.loadAbleTechnicians(task.builder_id);
        this.builderData.emit(task.builder_id);
        // this.builderData.emit(task.builder_id);
        // this.setBuilder({ target: { value: task.builder_id } });
        // if (task.due_time) {
          // let time = task.due_time.split('T')[1].split('.')[0];
          // this.formTask.patchValue({
            // due_time: time
          // });
        // }


        if (this.task.building_id != null) {
          this.store.getBuildingsBySettlement(this.task.settlement_id).subscribe({
            next: (data) => {
              console.log('SETTLE ==> ', data);
              
              this.buildings = data;
              if (this.task?.building) {
                this.formTask.patchValue({
                  building_id: this.task.building_id
                });
              }
            },
            error: (err) => {
              console.log(err);
            }
          });
        }
      }
    }
  }

  initForm() {
    this.formTask = this.fb.group({
      id: [''],
      title: [''],
      due_date: ['', Validators.required],
      due_time: ['', Validators.required],
      status: ['', Validators.required],
      priority: ['', Validators.required],
      profile_id: [''],
      settlement_id: ['', Validators.required],
      building_id: [''],
      unit_id: [''],
      tanque_id: [''],
      po_id: [''],
      builder_id: ['', Validators.required],
      assignee_id: ['', Validators.required],
      duration: [''],
    });

    this.formTask.patchValue({
      status: 1,
      priority: 2,
    });
  }

  loadSettlements() {
    this.store.getSettlements(this.payload).subscribe({
      next: (data: any) => {
        this.settlements = data.data;
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al obtener los condominios.');
      }
    });
  }

  validateDepth(id: number) {
    let validDepth = this.builders.find((builder: any) => builder.id === id).depth 
    this.depth = validDepth;
    //
    return validDepth;
  }

  validateTime(event: any) {
    let day = new Date(this.formTask.value.due_date).getDay();
    let hour = event.target.value.split(':')[0];
    if (hour < this.workHours[day].start || hour > this.workHours[day].end) {
      this.toastr.error('El técnico no trabaja este horario.', 'Error');
      this.formTask.patchValue({
        due_time: ''
      });
      return;
    }

    // TODO: If length of task is greater than work hours, show error

    this.timeData.emit({
      time: event.target.value,
      data: this.formTask.value,
      start: this.workHours[day].start,
      end: this.workHours[day].end
    });
  }

  selectSettlement(e: any) {
    if (this.validateDepth(parseInt(this.formTask.value.builder_id)) == 0) {
      this.store.getPurchaseOrders({ settlement_id: e.target.value, status: false }).subscribe({
        next: (data: any) => {
          console.log('PO ==> ', data);
          this.calculateDuration(this.formTask.value.builder_id, 1);
          this.pos = data.data;
          // this.formTask.patchValue({
            // po_id: data.data[0].id
          // });
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else if (this.validateDepth(parseInt(this.formTask.value.builder_id)) == 1) {
      this.store.getUnitsBySettlement(e.target.value).subscribe({
        next: (data: any) => {
          console.log('UNITS ==> ', data);
          this.calculateDuration(this.formTask.value.builder_id, data.length);
          // this.units = data;
        },
        error: (err: any) => {
          console.log(err);
        }
      });      
    } else if (this.validateDepth(parseInt(this.formTask.value.builder_id)) == 2) {
      this.store.getBuildingsBySettlement(e.target.value).subscribe({
        next: (data: any) => {
          console.log('BUILDINGS ==> ', data);
          this.buildings = data;
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
    //
    
  }

  selectBuilding(e: any) {
    // this.formTask.patchValue({
      // building_id: e.target.value
    // });
    if (this.validateDepth(parseInt(this.formTask.value.builder_id)) == 2) {
      this.store.getUnitsByBuilding(e.target.value).subscribe({
        next: (data: any) => {
          console.log('UNITS ==> ', data);          
          this.units = data;
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else if (this.validateDepth(parseInt(this.formTask.value.builder_id)) == 3) {
      this.store.getTanques({ building_id: e.target.value }).subscribe({
        next: (data: any) => {
          console.log('TANQUES ==> ', data);
          this.calculateDuration(this.formTask.value.builder_id, data.length);
          this.tanks = data.data;
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else {
      return;
    }
  }

  selectUnit(e: any) {
    this.calculateDuration(this.formTask.value.builder_id, 1);
    this.formTask.patchValue({
      unit_id: e.target.value
    });
  }

  setBuilder(event: any) {
    console.log("setBuilder ", event.target.value);
    // RESET
    this.settlements = [];
    this.buildings = [];
    this.units = [];
    this.tanks = [];

    this.formTask.patchValue({
      settlement_id: '',
      building_id: '',
      unit_id: '',
      tanque_id: '',
      due_date: '',
      due_time: '',
    });

    this.loadSettlements();
    this.loadAbleTechnicians(event.target.value);

    this.builderData.emit(event.target.value);
  }

  loadAbleTechnicians(builder: any) {
    console.log("loadAbleTechnicians ", builder);
    this.taskService.getTechSkills(builder).subscribe({
      next: (data: any) => {
        console.log('technicians ==> ', data);
        this.technicians = data;
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('No se encontraron técnicos con las habilidades requeridas para completar la tarea.');
      }
    });
  }

  selectDate(event: any) {
    let date = new Date(event.target.value);

    if (this.holidays.find((item: any) => item.holiday === event.target.value)) {
      this.toastr.error('No se puede seleccionar un día festivo.', 'Error');
      this.formTask.patchValue({
        due_date: ''
      });
      return;
    }

    if (
      (date.getDay() === 0 && this.workDays[0] === 0) || 
      (date.getDay() === 1 && this.workDays[1] === 0) || 
      (date.getDay() === 2 && this.workDays[2] === 0) || 
      (date.getDay() === 3 && this.workDays[3] === 0) || 
      (date.getDay() === 4 && this.workDays[4] === 0) || 
      (date.getDay() === 5 && this.workDays[5] === 0) || 
      (date.getDay() === 6 && this.workDays[6] === 0)
      ) {
        this.toastr.error('El técnico no trabaja este día.', 'Error');
        this.formTask.patchValue({
          due_date: ''
        });
        return;
    }

    this.formTask.patchValue({
      due_date: event.target.value
    });

    console.log("DATE ", event.target.value);

    // this.taskService.getTasks({ assignee_id: this.formTask.value.assignee_id, due_date: event.target.value }).subscribe({
      // next: (data: any) => {
        // console.log(data);
        // this.relatedTasks = data.data;
      // },
      // error: (err: any) => {
        // console.log(err);
      // }
    // });
    this.dateData.emit({ totalTime: this.formTask.value.duration, date: this.formTask.value.due_date, builders: this.builders, technician: this.formTask.value.assignee_id });
    // this.dateData.emit(event.target.value);
  }

  selectTechnician(event: any) {
    this.taskService.getWorkHour(event.target.value).subscribe({
      next: (data: any) => {
        this.workDays = [
          data.monday ? 1 : 0,
          data.tuesday ? 1 : 0,
          data.wednesday ? 1 : 0,
          data.thursday ? 1 : 0,
          data.friday ? 1 : 0,
          data.saturday ? 1 : 0,
          data.sunday ? 1 : 0,
        ];
        this.workHours = [
          { start: data.monday_start, end: data.monday_end },
          { start: data.tuesday_start, end: data.tuesday_end },
          { start: data.wednesday_start, end: data.wednesday_end },
          { start: data.thursday_start, end: data.thursday_end },
          { start: data.friday_start, end: data.friday_end },
          { start: data.saturday_start, end: data.saturday_end },
          { start: data.sunday_start, end: data.sunday_end },
        ];
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  calculateDuration(depth: any, size: any) {
    console.log('calculateDuration DEPTH ', depth);
    let totalTime: number = 0;
    this.activities.forEach((activity: any) => {
      console.log('ACTIVITY ', activity);
      console.log('HOW MUCH ', activity.time * size);
      totalTime += activity.time * size;
    });

    this.formTask.patchValue({
      duration: totalTime
    });    
  }

  saveTask() {
    console.log('BEFORE =>', this.formTask.value);

    this.formTask.patchValue({
      due_date: `${this.formTask.value.due_date} ${this.formTask.value.due_time}:00`,
      profile_id: this.profile.id
    });

    console.log('AFTER =>', this.formTask.value);

    if (this.formTask.invalid) {
      this.formTask.markAllAsTouched();
      this.toastr.error('Por favor completa el formulario', 'Error');
      return;
    }

    this.taskData.emit(this.formTask.value);
  }

  checkDates() {
    const startDate1 = '2024-01-01 09:00:00';
    const endDate1 = '2024-01-01 10:00:00';
    const startDate2 = '2024-01-01 09:10:00';
    const endDate2 = '2024-01-01 12:00:00';

    const overlap = this.dateService.doDateRangesOverlap(startDate1, endDate1, startDate2, endDate2);
    console.log(overlap ? 'Dates overlap' : 'Dates do not overlap');
  }
}
