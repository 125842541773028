import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../shared/services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-sign-in-invoice',
  template: `
    <div class="container-fluid p-0" *ngIf="!loggedIn">
      <div class="row mt-0 p-0">
        <div class="col-md-4 h-100 bg-dark text-center pt-5 d-none d-sm-block" style="background: url('assets/images/building-zazz.png') no-repeat center center;">
          <img src="assets/images/logo-zazz.png" class="img-fluid mt-5 text-center pt-5" width="50%" alt="condominio">
          <h1 class="text-light text-center mt-4">SISTEMA ZAZZ</h1>
          <p class="text-light text-sm mt-5 pt-3">Todos los derechos reservados © 2024</p>
          <p class="text-light text-sm">V {{version}}</p>
        </div>
        <div class="col-12 col-md-8 offset-md-0 pt-0 mt-0 text-center bg-white px-0">
          <div class="row mt-5 mb-5 d-block d-sm-none">
            <div class="col-6 offset-3">
              <img src="assets/images/logo-zazz.png" class="img-fluid mt-5 text-center pt-5" width="50%" alt="condominio">
            </div>
          </div>
          <div class="row mt-2 mt-sm-5 pt-5">
            <div class="col-10 col-md-6 offset-1 offset-md-3">
              <h2 class="mb-5 mt-3">Iniciar sesión</h2>
              <form [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="form-group text-start mt-5">
                  <label for="email" class="text-sm sr-only d-none">Correo electrónico</label>
                  <input type="text" formControlName="email" class="form-control border-2 rounded-0 border-dark pt-3 pb-3" placeholder="Correo electrónico" #userName required>
                  <div *ngIf="lf['email'].touched && lf['email'].invalid">
                    <div *ngIf="lf['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico es requerido.</div>
                    <div *ngIf="lf['email'].errors?.['email']" class="text-danger text-sm">El correo electrónico no es válido</div>
                  </div>
                </div>
                <div class="form-group mt-4">
                  <label for="password" class="text-sm sr-only d-none">Contraseña</label>
                  <input type="password" formControlName="password" class="form-control border-2 rounded-0 border-dark pt-3 pb-3" placeholder="Contraseña" #userPassword required>
                  <div *ngIf="lf['password'].touched && lf['password'].invalid">
                    <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-start text-sm">La contraseña es requerida.</div>
                  </div>
                </div>
                <div class="form-group mt-4">
                  <div class="form-check text-start">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" [value]="terms" (change)="terms = !terms">
                    <label class="form-check-label text-start" for="flexCheckDefault">
                    Al seleccionar esta casilla acepto los términos y condiciones zazz.mx y confirmo que la persona que accede a este sitio cuenta con las credenciales y personalidad legal para confirmar formalmente la recepción y aceptación de facturas.
                    </label>
                  </div>
                </div>
                <div class="form-group mt-5">
                  <button type="submit" class="btn btn-dark btn-lg w-100 rounded-0 pt-3 pb-3" [disabled]="!terms">
                    <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="text-secondary me-2"></fa-icon>
                    <fa-icon [icon]="faLock" *ngIf="!loading" class="text-light me-2"></fa-icon>
                    Entrar
                  </button>
                </div>
              </form>
              <div class="forgotPassword mt-5">
                <a routerLink="/forgot-password" class="text-sm">¿Olvidaste tu contraseña?</a>
              </div>
            </div>
            <div class="mt-5 pt-5">
              <span class="text-sm mt-5">Pólitica de privacidad</span> <span class="text-sm mt-5 ps-3 pe-3"> | </span>
              <span class="text-sm mt-5">Términos y condiciones</span>
            </div>
          </div>        
        </div>
      </div>
    </div>
    <div class="container-fluid" *ngIf="loggedIn">
      <div class="row">
        <div class="col-12 col-sm-6 offset-0 offset-md-3 mt-5">
          <div class="card rounded-5 shadow">
            <div class="card-body p-4">
            <div class="row">
                <div class="col-12 text-center mt-4">
                  <h5>Factura</h5>
                  <h3>{{invoice?.uuid}}</h3>
                </div>
                <div class="col-12 col-md-8 offset-0 offset-sm-2 my-3">
                  <div class="form-group">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <th>Recibo</th>
                          <td class="text-end"># <i>{{invoice?.folio}}</i></td>
                        </tr>
                        <tr>
                          <th>Subtotal</th>
                          <td class="text-end">{{invoice?.sub_total | currency: 'USD'}}</td>
                        </tr>
                        <tr>
                          <th>IVA</th>
                          <td class="text-end">{{invoice?.taxes | currency: 'USD'}}</td>
                        </tr>
                        <tr>
                          <th>Total</th>
                          <td class="text-end">{{invoice?.total | currency: 'USD'}}</td>
                        </tr>
                        <tr>
                          <th>Método de pago</th>
                          <td class="text-end">{{invoice?.metodoPago}}</td>
                        </tr>
                        <tr>
                          <th>Uso</th>
                          <td class="text-end" *ngIf="invoice">{{usocfdi(invoice?.usoCFDI).id}} - {{usocfdi(invoice?.usoCFDI).name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group mb-4 mt-2 text-center" *ngIf="invoice">
                  <a href="{{receiptUrl}}/public/{{invoice.pdf}}" target="_blank" class="btn btn-link">
                    <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
                    <b>Descargar factura</b>
                  </a>
                </div>
                <div class="form-group mb-3 text-center">
                  <button class="btn btn-link" (click)="downloadXML(invoice)"><b>Descargar XML</b></button>
                </div>
                <div class="form-group mt-3 mb-3" *ngIf="!completed">
                  <button type="button" 
                    class="btn btn-outline-warning w-100 btn-lg rounded-4" 
                    (click)="confirmed(invoice)"                     
                    [disabled]="invoice?.is_confirmed"
                  >Confirmo recepción de la factura</button>
                </div>                                    
                <div class="form-group mt-3 mb-3" *ngIf="!completed">
                  <button type="button" 
                    class="btn btn-outline-success w-100 btn-lg rounded-4" 
                    (click)="accepted(invoice)" 
                    [disabled]="!invoice?.is_confirmed || invoice?.is_accepted"
                  >Confirmo aceptación de factura</button>
                </div>
                <div class="form-group" *ngIf="completed">
                  <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading">¡Factura confirmada!</h4>
                    <p>La factura ha sido confirmada y aceptada.</p>
                    <hr>
                    <p class="mb-0">Gracias por confirmar la factura.</p>
                    <p>Esta ventana se cerrará automáticamente en <strong>{{timer}}</strong> segundos.</p>
                    <button type="button" class="btn btn-link" (click)="closeTab()">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class SignInInvoiceComponent {
  loginForm!: FormGroup;
  loading: boolean = false;
  loggedIn: boolean = false;

  terms: boolean = false;
  completed: boolean = false;

  user: any;

  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  
  protected version = environment.app_version;

  timer: number = 10;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  faCircleNotch = faCircleNotch;
  faLock = faLock;

  invoice: any;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: SettlementsService,
    private route: ActivatedRoute,
    public catalog: CatalogsService,
    public icons: IconsService
  ) { }

  get lf() {
    return this.loginForm.controls;
  }

  get email() {
    return this.loginForm.get('email');
  }
  
  ngOnInit(): void { 
    if (localStorage.getItem('token')) {
      // window.location.href = '/tenant/dashboard';
    }

    this.initLoginForm();
  }

  private initLoginForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  login () {
    this.loading = true;
    if (this.loginForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      this.loading = false;
      return;
    }

    let payload = {
      user: {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      }
    };

    this.store.tenantLogin(payload).subscribe({
      next: (data: any) => {
        this.loggedIn = true;
        console.log("ROLE ???? ", data);
        if (this.SetUserData(data)) {
          localStorage.setItem('role', JSON.stringify({ role: data.role }));
          localStorage.setItem('permissions', data.role.permissions);
          // window.location.reload();
          this.loadInvoice();
        } else {
          this.toastr.error('Credenciales incorrectas, revisa tus datos e intenta de nuevo', 'Error');
          this.loading = false;
        }
      },
      error: (error: any) => {
        this.toastr.error('Credenciales incorrectas, revisa tus datos e intenta de nuevo', 'Error');
        this.loading = false;
      }
    });
  }

  SetUserData(user: any) {
    const userData: any = {
      id: user.id,
      email: user.email,
      name: user.name,
      last_name: user.last_name,
      jti: user.jti,
      role_id: user.role.id,
      profile_id: user.profile_id,
      avatar: user.avatar
    };

    this.user = userData;

    console.log("userData", userData.role_id);

    localStorage.setItem('token', user.token);
    localStorage.setItem('user', JSON.stringify(userData));

    this.store.getProfile(userData.profile_id).subscribe((data: any) => {
      localStorage.setItem('profile', JSON.stringify(data));
    });

    this.store.getRole(userData.role_id).subscribe((data: any) => {
      localStorage.setItem('role', JSON.stringify({ role: data}));
      localStorage.setItem('permissions', data.permissions);
    });

    return true;
  }

  loadInvoice() {
    this.route.params.subscribe((params) => {
      console.log('params => ', params);
      this.store.getTenantInovice(params['id']).subscribe((data: any) => {
        console.log('invoice data => ', data);
        this.invoice = data;
      });
    });
  }

  downloadXML(invoice: any) {
    const blob2 = new Blob([invoice.cfdixml], { type: 'application/xml' });

    var fileURL2 = window.URL.createObjectURL(blob2);
    var link2=document.createElement('a');

    link2.href = fileURL2;
    link2.download = `${invoice.serie}_${invoice.folio}.xml`;
    link2.click();
  }
  usocfdi(uso_cfdi: any) {
    let finder = this.catalog.usosCFDI.find((uso: any) => uso.id == uso_cfdi);
    return finder;
  }

  accepted(invoice: any) {
    this.store.setInvoiceAccepted({tenant_id: this.user.id, invoice_id: invoice.id}).subscribe({
       next: (data: any) => {
         console.log(data);
         invoice.is_accepted =  data.invoice.is_accepted;
         invoice.accepted_time =  data.invoice.accepted_time;
         this.toastr.success('Actualización correcta de la factura.', 'Éxito');
         this.completed = true;
         const intervalId = setInterval(() => {
          console.log(this.timer);
          this.timer--;
        
          if (this.timer <= 0) {
            clearInterval(intervalId);
            console.log("Countdown complete!");
            this.closeTab();
          }
        }, 1000); // 1000 milliseconds = 1 second
       },
       error: (err: any) => {
         console.log(err);
         if (err.status >= 500) {
           this.toastr.error('Ocurrió un error al actualizar la factura.', 'Error');
         }
         this.loading = false;
       }
     });
   }

   confirmed(invoice: any) {
    this.store.setInvoiceConfirmed({tenant_id: this.user.id, invoice_id: invoice.id}).subscribe({
       next: (data: any) => {
         console.log(data);
         invoice.is_confirmed =  data.invoice.is_confirmed;
         invoice.confirmed_time =  data.invoice.confirmed_time;
         this.toastr.success('Actualización correcta de la factura.', 'Éxito');
       },
       error: (err: any) => {
         console.log(err);
         if (err.status >= 500) {
           this.toastr.error('Ocurrió un error al actualizar la factura.', 'Error');
         }
         this.loading = false;
       }
     });
   }

   closeTab() {
      let redirect = '/user/sign-in';
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('profile');
      localStorage.removeItem('avatar');
      localStorage.removeItem('permissions');
      localStorage.removeItem('role');
      localStorage.removeItem('fcm_token');
      //
      window.location.href = redirect;
      // window.location.reload();

   }
}
