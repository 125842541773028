import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-form-skill',
  template: `
    <form [formGroup]="formSkill" (ngSubmit)="saveSkill()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre de la habilidad</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="weight" class="form-label ms-2">Complejidad</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="weight" placeholder="" formControlName="weight">
            <div *ngIf="ufc['weight'].touched && ufc['weight'].invalid">
              <div *ngIf="ufc['weight'].errors?.['required']" class="text-danger text-sm">La complejidad es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/inventory/skills">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormSkillComponent {
  @Input() skill: any = {};
  @Input() loading: boolean = false;
  @Input() profile: any = {};
  @Output() skillData = new EventEmitter<any>();

  formSkill: FormGroup = new FormGroup({});

  settlements: any[] = [];
  buildings: any[] = [];

  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      console.log(params);
      let payload: any = {
        page: 1,
        per_page: 100,
        tipo: 0
      }
        this.store.getSettlements(payload).subscribe({
          next: (data) => {
            this.settlements = data.data;
          },
          error: (err) => {
            console.log(err);
            this.toastr.error('Ocurrió un error al obtener los condominios.');
          }
        });
    });
  } 

  get ufc() {
    return this.formSkill.controls;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['skill'] && changes['skill'].currentValue) {
      this.formSkill.patchValue(changes['skill'].currentValue);
      this.preFill();
    }
  }

  initForm() {
    this.formSkill = this.fb.group({
      name: ['', Validators.required],
      weight: ['', Validators.required],
      profile_id: [''],
    });
  }

  preFill() {
    console.log(this.skill);
    if (this.skill.settlement) {
      console.log("this.skill.settlement.id");
      console.log(this.skill.settlement);
      this.formSkill.patchValue({
        settlement_id: this.skill.settlement.id
      });
      this.store.getBuildingsBySettlement(this.skill.settlement.id).subscribe({
        next: (data) => {
          console.log(data);
          
          this.buildings = data;
          if (this.skill.building) {
            this.formSkill.patchValue({
              building_id: this.skill.building.id
            });
          }
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
  }

  saveSkill() {
    console.log(this.formSkill.value);
    this.formSkill.patchValue({
      profile_id: this.profile.profile.id
    });

    if (this.formSkill.invalid) {
      this.formSkill.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.skillData.emit(this.formSkill.value);    
  }
}
