import { map } from 'rxjs';
import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-invoice-global',
  template: `
    <div class="row">
      <div class="col-md-8">
        <h4 class="mb-0">Id #: {{invoice.folio}}</h4>
        <p class="mb-0">Folio fiscal: {{invoice.uuid}}</p>
        <p>{{invoice.settlement_name}}</p>
      </div>
      <div class="col-md-3 text-end">
        <p class="mb-0" class="mb-0 text-sm text-muted">Creado: {{invoice.created_at | date: 'dd/MM/yyyy'}}</p>
        <div class="badge badge-pill bg-secondary rounded-5 mb-0 px-3 py-1">{{ invoiceType.name }}</div>
      </div>
      <div class="col-md-1 text-center py-3">
        <div ngbDropdown class="d-inline-block ms-2">
          <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1" id="dropdownBasic2" ngbDropdownToggle class="float-end" *ngIf="permissions?.modules.admin.invoices.global.active">
            <fa-icon [icon]="icons.faEllipsisVertical" class="m-1"></fa-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <a ngbDropdownItem (click)="generateInvoice()">
              <fa-icon [icon]="icons.faDownload"></fa-icon>
              Descargar
            </a>
            <a ngbDropdownItem routerLink="/invoices/{{invoice.id}}">
              <fa-icon [icon]="icons.faEye"></fa-icon>
              Consultar
            </a>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemInvoiceGlobalComponent {
  @Input() invoice: any;
  @Input() profile: any;
  @Input() permissions: any;
  @Input() utilityData: any;
  @Input() settlements: any;

  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  loading: boolean = false;

  billList: any = [];
  
  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService,
    public catalogs: CatalogsService
  ) { }

  generateInvoice() {

    this.store.getGlobalRelatedBills(this.invoice.id).subscribe({
      next: (data: any) => {
        this.loading = true;
        this.billList = [];

        data.data.map((bill: any) => {
          let price = bill.consumo_periodo + bill.admin_price;
          this.billList.push({
            id: bill.id,
            concepto: bill.unit_name,
            sub_total: price,
            taxes: price*0.16
          });
        });

        console.log('getGlobalRelatedBills => ', data);
        // this.downloadInvoice();
        let invoicePayload: any = {
          invoiceData: this.invoice,
          conceptos: this.billList,
          utilityData: this.utilityData,
          settlementData: this.settlements.find((settlement: any) => settlement.id == this.invoice.settlement_id),
        }
    
        console.log('invoicePayload => ', invoicePayload);

        this.downloadInvoice(invoicePayload);
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
        console.log('getGlobalRelatedBills => ', err);
      }
    });

    

    
  }

  downloadInvoice(invoicePayload: any) {
    this.store.downloadGlobal(invoicePayload).subscribe({
      next: (data: any) => {
        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        let fileURL = window.URL.createObjectURL(blob);
        let link=document.createElement('a');

        link.href = fileURL;
        link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();

        // this.store.updateInvoice(this.invoice.id, { pdf: data.filename }).subscribe((data: any) => {
          // console.log('updateInvoice => ', data);
          // this.loading = false;
        // });
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('Ocurrió un error al descargar la factura.', 'Error');
        console.log('downloadGlobal => ', err);
      }
    });
  }

  get invoiceType() {
    return this.catalogs.tipoFactura.find((x: any) => this.invoice.tipo == x.id);
  }
}
