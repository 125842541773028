import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';
import * as xml2js from 'xml2js';

@Component({
  selector: 'app-modal-invoice-nota',
  template: `
    <ng-template #modalInvoiceNota let-modal>
      <div class="modal-header">
        <div class="modal-title">Nota de crédito</div>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-sm mb-3" style="font-size: 0.8em;">
              <thead>
                <tr>
                  <th>Qty.</th>
                  <th class="text-center">Clave</th>
                  <th class="text-center">Importe</th>
                  <th class="text-end">Valor Unitario</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let concepto of conceptos">
                  <td>{{concepto['$']['Cantidad']}}</td>
                  <td class="text-center">{{concepto['$']['ClaveProdServ']}}</td>
                  <td class="text-center">{{concepto['$']['Importe'] | currency}}</td>
                  <td class="text-end">{{concepto['$']['ValorUnitario'] | currency}}</td>
                </tr>
              </tbody>
            </table>
            <form [formGroup]="invoiceNotaForm" (ngSubmit)="createNota()">
              <div class="form-group required">
                <label for="monto" class="form-label text-sm">Cantidad</label>
                <input type="number" class="form-control" formControlName="monto">
              </div>
              <div class="form-group required mt-4">
                <label for="metodoPago" class="form-label text-sm">Método de pago</label>
                <select class="form-select" formControlName="metodoPago">
                  <option value="" selected>Seleccionar...</option>
                  <option *ngFor="let metodo of metodoPago" [value]="metodo.id">{{metodo.metodo}}</option>
                </select>
                <div *ngIf="ufc['metodoPago'].touched && ufc['metodoPago'].invalid">
                  <div *ngIf="ufc['metodoPago'].errors?.['required']" class="text-danger text-sm">El método de pago es requerido.</div>
                </div>
              </div>
              <div class="form-group required mt-3">
                <label for="formaPago" class="form-label text-sm">Tipo de pago</label>
                <select class="form-select" formControlName="formaPago">
                  <option value="" selected>Seleccionar...</option>
                  <option *ngFor="let tipo of tipoPago" [value]="tipo.id">{{tipo.tipo}}</option>
                </select>
                <div *ngIf="ufc['formaPago'].touched && ufc['formaPago'].invalid">
                  <div *ngIf="ufc['formaPago'].errors?.['required']" class="text-danger text-sm">La forma de pago es requerido.</div>
                </div>
              </div>   
              <div class="form-group required mt-3">
                <label for="payment_date" class="form-label text-sm">Fecha</label>
                <input type="date" class="form-control" formControlName="payment_date">
              </div>
              <div class="form-group text-end mt-5">
                <button type="submit" class="btn btn-outline-success" [disabled]="loading">
                  <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="me-2"></fa-icon>
                  <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="loading" class="me-2"></fa-icon>
                  Generar nota de crédito
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: []
})
export class ModalInvoiceNotaComponent implements OnInit {
  @Input() profile: any;
  @Input() invoice: any;
  @Input() bill: any;
  @Input() conceptos: any;
  @Input() base: any;
  @Input() total: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalInvoiceNota') modalInvoiceNota !: ElementRef;

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  loading: boolean = false;
  invoiceData: any = {};
  invoiceNotaForm: FormGroup = new FormGroup({});

  tipoPago: any = [
    { id: '01', tipo: 'Efectivo' },
    { id: '02', tipo: 'Cheque nominativo' },
    { id: '03', tipo: 'Transferencia electrónica de fondos' },    
    // { id: '99', tipo: 'Por definir' }
  ];
  metodoPago: any = [
    { id: 'PUE', metodo: 'Pago en una sola exhibición' },
  ];

  cpts: any = [];

  utilityData: any = {};

  parser: any;
  serie: any;
  folio: any;
  receptor: any;
  complemento: any;
  impuestos: any;

  private modalRef: NgbModalRef | undefined;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public icons: IconsService
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
  }

  ngOnInit(): void {
    this.buildInvoiceNotaForm();
  }

  get ufc() { return this.invoiceNotaForm.controls; }

  buildInvoiceNotaForm() {
    this.invoiceNotaForm = this.fb.group({
      monto: ['', Validators.required],
      metodoPago: ['', Validators.required],
      formaPago: ['', Validators.required],
      payment_date: ['', [Validators.required]],
    });

    /*this.store.getProfile(this.profile.profile.utility_id).subscribe(async (data: any) => {
      this.utilityData = data;
        let imageUrl = `${this.apiUrl}/storage/${data.logo.blob.key.substring(0,2)}/${data.logo.blob.key.substring(2,4)}/${data.logo.blob.key}`;
          this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
            this.utilityData.logo = "data:image/jpeg;base64," + result.split(',')[1];
          });
        console.log('utilityData  => ', this.utilityData);
    });*/
  }

  createNota() {
    this.loading = true;
    let ctl = this;
    if (this.invoiceNotaForm.invalid) {
      this.invoiceNotaForm.markAllAsTouched();
      this.toastr.error('Por favor, completa el formulario.');
      this.loading = false;
      return;
    }

    // console.log('INVOICE ==> ', this.invoice);
    this.parser = new xml2js.Parser({
      trim: true,            
      explicitArray: true,            
    });
    this.parser.parseString(this.invoice.cfdixml, function (err: any, result: any) {
      ctl.serie = result['cfdi:Comprobante']['$']['Serie'];
      ctl.folio = result['cfdi:Comprobante']['$']['Folio'];
      ctl.receptor = result['cfdi:Comprobante']['cfdi:Receptor'][0]['$'];
      ctl.complemento = result['cfdi:Comprobante']['cfdi:Complemento'][0]['tfd:TimbreFiscalDigital'][0]['$'];
      ctl.conceptos = result['cfdi:Comprobante']['cfdi:Conceptos'][0]['cfdi:Concepto'];            
      ctl.impuestos = result['cfdi:Comprobante']['cfdi:Impuestos'][0]['cfdi:Traslados'][0]['cfdi:Traslado'][0]['$'];
      ctl.base = Number(ctl.impuestos['Base']);
      ctl.total = Number(ctl.impuestos['Base']) + Number(ctl.impuestos['Importe']);
    });
    

    this.cpts = [
      {
        Cantidad: '1',
        CodigoUnidad: 'ACT',
        CodigoProducto: '84111506',
        Producto: 'Servicios de facturación',
        PrecioUnitario: this.invoiceNotaForm.value.monto,
        Importe: this.invoiceNotaForm.value.monto,
        ObjetoDeImpuesto: '02',
        Impuestos: [
          {
            TipoImpuesto: '1',
            Impuesto: '2',
            Factor: '1',
            Base: this.invoiceNotaForm.value.monto,
            Tasa: '0.160000',
            ImpuestoImporte: this.invoiceNotaForm.value.monto*0.16,
          }
        ]
      }
    ];

    console.log('receptor => ', this.receptor);

    let payload: any = {
      Encabezado: {
        "CFDIsRelacionados": this.invoice.uuid,
        "Fecha": this.invoiceNotaForm.value.payment_date,
        "Serie": 'NC',
        "Folio": this.invoice.folio,
        "MetodoPago": "PUE",
        "FormaPago": this.invoiceNotaForm.value.formaPago,
        "Moneda": "MXN",
        "LugarExpedicion": "26015",
        "SubTotal": this.invoiceNotaForm.value.monto,
        "Total": this.invoiceNotaForm.value.monto*1.16,
        "Receptor": {
          "RFC": this.receptor['Rfc'],
            "NombreRazonSocial": this.receptor['Nombre'],
            "UsoCFDI": 'G02',
            "RegimenFiscal": this.receptor['RegimenFiscalReceptor'],
            "Direccion": {
              "Calle": "",
              "NumeroExterior": "",
              "NumeroInterior": "",
              "Colonia": "",
              "Localidad": "",
              "Municipio": "",
              "Estado": "",
              "Pais": "",
              "CodigoPostal": this.receptor['DomicilioFiscalReceptor']
            }
        }
      },
      Conceptos: this.cpts,
      complemento: {
        serie: 'NC',
        folio: this.bill.id,
        related_invoice: this.invoice.id,
      },
      profile_id: this.profile.id,
    }

    // console.log('PAYLOAD => ', payload);
    this.store.createInvoiceNotaCredito(payload).subscribe({
      next: (res: any) => {
        console.log(res);
        this.invoiceData = res;
        this.toastr.success('Complemento generado correctamente.', 'Éxito');        
        this.download();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al generar el complemento.', 'Error');
        this.loading = false;
      }
    });
  }

  download() {
    const payload = {
      bill: this.bill,
      // dueDate: dueDate,
      // unitData: unitData,
      // buildingData: data,
      invoiceData: this.invoiceData,
      utilityData: this.profile,
      conceptos: this.cpts
    };

    this.store.downloadNota(payload).subscribe({
      next: (data: any) => {
        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        var fileURL = window.URL.createObjectURL(blob);
        var link=document.createElement('a');

        link.href = fileURL;
        link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();

        const blob2 = new Blob([this.invoiceData.cfdixml], { type: 'application/xml' });

        var fileURL2 = window.URL.createObjectURL(blob2);
        var link2=document.createElement('a');

        link2.href = fileURL2;
        link2.download = `${this.invoiceData.serie}_${this.invoiceData.folio}.xml`;
        link2.click();

        this.store.updateInvoice(this.invoiceData.id, { pdf: data.filename }).subscribe((data: any) => {
          console.log('updateInvoice => ', data);

          this.loading = false;
          this.dismiss();
        });

        return;
      }, error: (error: any) => {
        console.log('createReceipt ERROR => ', error);
      }
    });
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
