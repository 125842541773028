import { Component, OnInit } from '@angular/core';
import { TasksService } from 'src/app/services/tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-activity-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Actividades</h1>
            <p>Configura las actividades que están disponibles para los servicios técnicos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Agregar actividad</h4>
                    <p>Configura la actividad con los siguientes campos.</p>
                    <app-form-activity (activityData)="updateCategory($event)" [profile]="profile" [category]="category" [loading]="loading"></app-form-activity>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="mb-3">Habilidades</h4>
                    <div class="list-group" *ngIf="skills.length>0">
                      <div class="list-group-item" *ngFor="let skill of skills">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="{{skill.id}}" (click)="toggleSkill(skill)" [checked]="skill.checked">
                          <label class="form-check-label" for="{{skill.id}}">{{skill.name}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="list-group" *ngIf="skills.length==0">
                      <div class="list-group-item">
                        <p class="mb-0 text-center">No hay habilidades disponibles</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow mt-3">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="mb-3">Herramientas</h4>
                    <div class="list-group" *ngIf="tools.length>0">
                      <div class="list-group-item" *ngFor="let tool of tools">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="{{tool.id}}" (click)="toggleTool(tool)" [checked]="tool.checked">
                          <label class="form-check-label" for="{{tool.id}}">{{tool.name}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="list-group" *ngIf="tools.length==0">
                      <div class="list-group-item">
                        <p class="mb-0 text-center">No hay herramientas disponibles</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ActivityEditComponent {
  loading: boolean = false;
  category: any = null;
  profile: any = null;
  skills: any[] = [];
  tools: any[] = [];

  constructor(
    private tasks: TasksService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.activateRoute.params.subscribe(params => {
      this.tasks.getActivity(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.category = res;
          this.loadSkills();
          this.loadTools();
        },
        error: (err: any) => {
          this.loading = false;
        }
      });
    });

    this.breadcrumbService.set('@index', 'Mantenimientos');
    this.breadcrumbService.set('@activities', 'Categoría de tareas');
    this.breadcrumbService.set('@activityEdit', 'Editar categoría');
  }

  loadSkills() {
    this.tasks.getSkills({ profile_id: this.profile.profile.id }).subscribe({
      next: (skills: any) => {
        this.skills = skills.data;
        this.category.skills.map((skill: any) => {
          this.skills.map((s: any) => {
            if (s.id == skill.id) {
              s.checked = true;
            }
          });
        });
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
      }
    });
  }

  loadTools() {
    this.tasks.getTools({ profile_id: this.profile.profile.id }).subscribe({
      next: (tools: any) => {
        this.tools = tools.data;
        this.category.tools.map((tool: any) => {
          this.tools.map((s: any) => {
            if (s.id == tool.id) {
              s.checked = true;
            }
          });
        });
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
      }
    });
  }

  toggleSkill(skill: any) {
    if (skill.checked) {
      this.removeSkill(skill);
    } else {
      this.addSkill(skill);
    } 
  }

  toggleTool(tool: any) {
    if (tool.checked) {
      this.removeTool(tool);
    } else {
      this.saveTools(tool);
    }
  }

  addSkill(skill: any) {
    this.tasks.createActivitySkills({ activity_id: this.category.id, skill_id: skill.id }).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha actualizado la categoría correctamente.', 'Categoría actualizada');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido actualizar la categoría.', 'Error');
      }
    });
  }

  saveTools(tool: any) {
    this.tasks.createActivityTools({ activity_id: this.category.id, tool_id: tool.id }).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha actualizado la categoría correctamente.', 'Categoría actualizada');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido actualizar la categoría.', 'Error');
      }
    });
  }

  removeSkill(skill: any) {
    console.log('removeSkill ', skill);
    console.log(this.category.id);
    this.tasks.deleteActivitySkills({ activity_id: this.category.id, skill_id: skill.id }).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha actualizado la categoría correctamente.', 'Categoría actualizada');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido actualizar la categoría.', 'Error');
      }
    });
  }

  removeTool(tool: any) {
    this.tasks.deleteActivityTools({ activity_id: this.category.id, tool_id: tool.id }).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha actualizado la categoría correctamente.', 'Categoría actualizada');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido actualizar la categoría.', 'Error');
      }
    });
  }
  
  updateCategory(event: any) {
    console.log(event);
    
    this.tasks.updateActivity(event.id, event).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha actualizado la categoría correctamente.', 'Categoría actualizada');
        this.router.navigateByUrl('/maintenances/activities');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido actualizar la categoría.', 'Error');
      }
    });
  }
}
