import { AuthService } from './../../shared/services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { User } from '../../models/user';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  loginForm!: FormGroup;
  loading: boolean = false;

  protected version = environment.app_version;

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  faCircleNotch = faCircleNotch;
  faLock = faLock;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: SettlementsService,
    private router: Router
  ) { }

  get lf() {
    return this.loginForm.controls;
  }

  get email() {
    return this.loginForm.get('email');
  }
  
  ngOnInit(): void { 
    if (localStorage.getItem('token')) {
      // window.location.href = '';
      this.router.navigate(['dashboard']);
    }

    this.initLoginForm();
  }

  private initLoginForm(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  login () {
    this.loading = true;
    if (this.loginForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      this.loading = false;
      return;
    }

    let payload = {
      user: {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      }
    };

    this.store.login(payload).subscribe({
      next: (data: any) => {
        // console.log(data);
        if (data.status.code == 200) {        
          if (this.SetUserData(data)) {
            this.store.getRole(data.data.role_id).subscribe((data: any) =>{
              // console.log("GET ROLE !!! ", data);
              localStorage.setItem('role', JSON.stringify({ role: data}));
              localStorage.setItem('permissions', data.permissions);

              
              // window.location.reload();
              this.router.navigate(['dashboard']);
            });                   
          }
        } else {        
          window.alert(data.message);
        }
      },
      error: (error: any) => {
        // console.log(error);
        this.toastr.error('Credenciales equivocadas por favor verifica e intenta de nuevo.', 'Error');
        this.loading = false;
      }
    });

  }

  SetUserData(user: any) {
    const userData: User = {
      id: user.data.id,
      email: user.data.email,
      name: user.data.name,
      last_name: user.data.last_name,
      jti: user.data.jti,
      role_id: user.data.role_id,
      settlement_id: user.data.settlement_id,
      profile_id: user.data.profile_id,
      avatar: user.avatar
    };

    // console.log("SET userData ==============> ", userData);

    localStorage.setItem('token', user.token);
    localStorage.setItem('user', JSON.stringify(userData));

    this.store.getProfile(userData.profile_id).subscribe((data: any) => {
      console.log("SIGN IN PROFILE data => ", data);
      localStorage.setItem('profile', JSON.stringify({ profile: data}));
    });

    this.store.getRole(userData.role_id).subscribe((data: any) =>{
      // console.log("GET ROLE !! ", data);
      localStorage.setItem('role', JSON.stringify({ role: data}));
      localStorage.setItem('permissions', data.permissions);
    });

    return true;
  }
}
