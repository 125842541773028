import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-bill-verify',
  template: `
    <div #modalBillVerify id="modalBillVerify" class="modal fade" tabindex="-1" aria-labelledby="modalBillVerify" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">Verificación de pago</div>
          </div>
          <div class="modal-body p-4">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="billVerifyForm" (ngSubmit)="verifyBill()">
                  <div class="form-group mt-3">
                    <label for="payment_date">Ticket de freshdesk</label>
                    <input type="text" class="form-control form-control-lg" formControlName="receipt">
                  </div>
                  <div class="form-group text-end mt-3">
                    <button type="submit" class="btn btn-success btn-lg rounded-5">
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalBillVerifyComponent {
  @Input() bill: any;
  @Output() billData = new EventEmitter<any>();

  billVerifyForm: any = {};

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.buildBillVerifyForm();
  }

  buildBillVerifyForm() {
    this.billVerifyForm = this.fb.group({
      state: [4, Validators.required],
      receipt: ['', Validators.required]
    })
  }

  verifyBill() {
    this.billData.emit(this.bill);
  }

}
