import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ImageService } from 'src/app/shared/services/image.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-form-technician',
  template: `
  <form [formGroup]="technicianForm" (ngSubmit)="onSubmit()"> 
      <div class="row">
        <div class="col-md-12">
        <div class="avatar-container text-center">
            <div class="image-container border border-4">
              <img [src]="avatarURL" width="100%">
            </div> 
            <label for="avatar-input-file">
              <div class="icon-wrapper">
                <input type="file" id="avatar-input-file" (change)="loadAvatar($event)" accept="image/*"/>
                <fa-icon [icon]="icons.faEdit" class="text-white"></fa-icon>
              </div>
            </label>
          </div>
          <div class="form-group">
            <label for="email">Correo electrónico</label>
            <input type="text" formControlName="email" class="form-control form-control-lg" id="email" placeholder="">
            <div *ngIf="ufc['email'].touched && ufc['email'].invalid">
              <div *ngIf="ufc['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="phone">Teléfono</label>
            <input type="text" formControlName="phone" class="form-control form-control-lg" id="phone" placeholder="">
          </div>
          <div class="form-group mt-4">
            <label for="name">Nombre</label>
            <input type="text" formControlName="name" class="form-control form-control-lg" id="name" placeholder="">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="last_name">Apellido Paterno</label>
            <input type="text" formControlName="last_name" class="form-control form-control-lg" id="last_name" placeholder="">
            <div *ngIf="ufc['last_name'].touched && ufc['last_name'].invalid">
              <div *ngIf="ufc['last_name'].errors?.['required']" class="text-danger text-sm">El apellido es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="last_name_m">Apellido Materno</label>
            <input type="text" formControlName="last_name_m" class="form-control form-control-lg" id="last_name_m" placeholder="">
            <div *ngIf="ufc['last_name_m'].touched && ufc['last_name_m'].invalid">
              <div *ngIf="ufc['last_name_m'].errors?.['required']" class="text-danger text-sm">El apellido es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-4">
            <label for="employee_number"># Empleado</label>
            <input type="text" formControlName="employee_number" class="form-control form-control-lg" id="employee_number" placeholder="">
          </div>
          <div class="form-group mt-4">
            <label for="licence">Licencia</label>
            <input type="text" formControlName="licence" class="form-control form-control-lg" id="licence" placeholder="">
          </div>
          <div class="form-group mt-4">
            <label for="licence_type">Tipo de licencia</label>
            <input type="text" formControlName="licence_type" class="form-control form-control-lg" id="licence_type" placeholder="">
          </div>
        </div>
      </div>
      <div class="form-group mt-5 text-end">
        <button type="button" routerLink="/technicians" class="btn btn-link me-2">Cancelar</button>
        <button type="submit" class="btn btn-success btn-lg rounded-5">
          <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="loading" class="ms-2 text-dark"></fa-icon>
          <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="ms-2 text-dark"></fa-icon>
          Guardar
        </button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTechnicianComponent implements OnInit {
  @Input() technician: any = {};
  @Input() roles: any = [];
  @Output() updateTechnician = new EventEmitter<any>();

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  loading: boolean = false;
  profile: any = {};
  permissions: any = {};
  technicianId: number = 0;
  technicianForm: FormGroup = new FormGroup({});
  avatarURL: any = '';

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private img: ImageService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.technicianForm = this.fb.group({
      id: [''],
      email: ['', Validators.required],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      last_name_m: [''],
      employee_number: [''],
      phone: [''],
      licence: [''],
      licence_type: [''],
      role_id: ['3'],
      password: ['P455W0rd#23'],
      confirm_password: ['P455W0rd#23'],
      profile_id: [''],
    });
  }

  get ufc() { return this.technicianForm.controls; }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['technician'] && changes['technician'].currentValue) {
      this.technicianForm.patchValue(changes['technician'].currentValue);
      this.avatarURL = changes['technician'].currentValue.avatar ? this.img.buildURL(changes['technician'].currentValue.avatar) : '';
    }
  }

  /*console.log('imgBase64Path => ', imgBase64Path);
    console.log('previewImagePath => ', this.previewImagePath);
    let payload = {
      id: this.technicianId,
      data: this.previewImagePath
    }
    // console.log('event => ', event.target.files[0]);
    this.store.updateAvatar(this.technicianForm.value.id, payload).subscribe({
      next: (data: any) => {
        this.toastr.success('Imagen actualizada correctamente.', 'Éxito');
        this.loadUser(this.technicianForm);
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al actualizar la imagen.', 'Error');
      }
    });*/

  loadAvatar(event: any): void {   
  }

  onSubmit() {
    this.loading = true;
    if (this.technicianForm.invalid) {
      this.technicianForm.markAllAsTouched();
      this.loading = false;
      this.toastr.error('Error', 'Formulario incompleto.');
      return;
    }

    this.technicianForm.patchValue({
      profile_id: this.profile.id
    });

    this.updateTechnician.emit(this.technicianForm.value);
    this.loading = false;
  }

}
