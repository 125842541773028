import { Component } from '@angular/core';

@Component({
  selector: 'app-amenity-calendar',
  template: ``,
  styleUrls: []
})
export class AmenityCalendarComponent {

}
