import { AfterViewInit, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-tenant-address',
  template: `
    <form [formGroup]="userAddressForm" (ngSubmit)="saveAddress()">
      <legend style="font-size: 19px; font-weight: 800;">Dirección</legend>
      <div class="form-group mt-3">
        <label for="address">Calle</label>
        <input type="text" formControlName="address" class="form-control form-control-lg rounded-3" id="address">
        <div *ngIf="tad['address']?.touched && tad['address']?.invalid">
          <div *ngIf="tad['address'].errors?.['required']" class="text-danger text-sm">La calle es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="outer_number">Número exterior</label>
        <input type="text" formControlName="outer_number" class="form-control form-control-lg rounded-3" id="outer_number">
        <div *ngIf="tad['outer_number']?.touched && tad['outer_number']?.invalid">
          <div *ngIf="tad['outer_number'].errors?.['required']" class="text-danger text-sm">La calle es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="state">Estado</label>
        <select class="form-select form-select-lg" id="state" formControlName="state">
          <option *ngFor="let state of catalogs.edos" [value]="state.value">{{state.name}}</option>
        </select>
        <div *ngIf="tad['state']?.touched && tad['state']?.invalid">
          <div *ngIf="tad['state'].errors?.['required']" class="text-danger text-sm">El estado es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="city">Ciudad</label>
        <input type="text" formControlName="city" class="form-control form-control-lg rounded-3" id="city">
        <div *ngIf="tad['city']?.touched && tad['city']?.invalid">
          <div *ngIf="tad['city'].errors?.['required']" class="text-danger text-sm">La ciudad es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="zip_code">Código postal</label>
        <input type="text" formControlName="zip_code" class="form-control form-control-lg rounded-3">
        <div *ngIf="tad['zip_code']?.touched && tad['zip_code']?.invalid">
          <div *ngIf="tad['zip_code'].errors?.['required']" class="text-danger text-sm">El Código Postal es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-5 mb-2 text-end">
        <button type="submit" class="btn btn-outline-primary btn-lg rounded-5">Guardar</button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormTenantAddressComponent implements AfterViewInit {
  @Input() user: any = {};
  @Input() address: any = {};
  @Input() catalogs: any = {};

  @Output() save: EventEmitter<any> = new EventEmitter();

  userAddressForm: FormGroup = new FormGroup({});
  
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.initForm();
  }

  get tad() { return this.userAddressForm.controls; }

  ngAfterViewInit(): void {
    console.log('ADDRESS ==> ', this.address);
    if (this.address) {
      this.userAddressForm.patchValue(this.address);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address'] && changes['address'].currentValue) {
      console.log('CHANGES ADDRESS ==> ', changes['address'].currentValue);
      this.userAddressForm.patchValue(changes['address'].currentValue);
    }
  }

  initForm() {
    this.userAddressForm = this.fb.group({
      id: [''],
      address: ['', [Validators.required]],
      outer_number: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      tenant_id: ['']
    });
  }

  saveAddress() {
    if (!this.userAddressForm.valid) {
      this.toastr.error('Por favor, llena todos los campos requeridos');
      return;
    }

    this.userAddressForm.patchValue({ tenant_id: this.user.id });

    this.save.emit(this.userAddressForm.value);
  }

}
