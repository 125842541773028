import { Tenant } from 'src/app/models/tenant.model';
import { Component, Input, OnInit } from '@angular/core';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from '../services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-tenant',
  template: `
  <div *ngIf="!editing" class="row">
    <div class="col-md-3">
      <!-- img src="/assets/images/avatar.png" class="mt-3 ms-1" width="80%" -->
      <div class="mt-3 ms-1" style="border-radius: 44px; overflow: hidden; width: 60px; height: 60px;">
        <img [src]="avatarURL" width="100%">
      </div>
    </div>
    <div class="col-md-7">
      <ul class="list-unstyled mt-4">
        <li class="" >
          <h4 class="mb-0">{{tenant.name}} {{tenant.last_name}}</h4>
          <p>{{tenant.email}}</p>                      
        </li>
      </ul>
    </div>
    <div class="col-md-2">                        
      <!-- button type="button" class="btn btn-link text-danger pe-0 ps-0 mt-2 float-end" (click)="deleteTenant(tenant.id)"><fa-icon [icon]="faTrashAlt"></fa-icon></!-->
      <button type="button" class="btn btn-link pe-2 ps-0 mt-2 float-end" (click)="edit()"><fa-icon [icon]="faEdit"></fa-icon></button>
    </div>
    <div class="col-md-12">                  
      <table class="table table-sm">
        <tr class="border-bottom">
          <td class="pt-3">usuario</td>
          <td class="text-end">{{tenant.usename}}</td>
        </tr>
        <tr class="border-bottom">
          <td class="pt-3">estatus</td>
          <td class="text-end">activo</td>
        </tr>
        <tr class="border-bottom">
          <td class="pt-3">activo desde</td>
          <td class="text-end">{{tenant.created_at | date: 'dd/MM/yy'}}</td>
        </tr>
      </table>
    </div>
    </div>
    <div *ngIf="editing">
      <app-form-tenant (cancelForm)="cancelEditTenant()"></app-form-tenant>
    </div>
  `,
  styleUrls: []
})
export class ItemTenantComponent implements OnInit {
  avatarURL: string = "";
  formTenant: FormGroup = new FormGroup({});
  editing: boolean = false;

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  faEdit = faEdit;
  faTrashAlt = faTrashAlt;

  @Input() tenant: any;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.buildTenantForm();
    this.loadTenant();
  }

  buildTenantForm() {
    this.formTenant = this.fb.group({
      avatar: [''],
      id: ['', Validators.required],
      email: ['', Validators.required],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      role_id: ['', Validators.required],
      settlement_id: [''],
    });
  }

  loadTenant() {
    this.store.getTenant(this.tenant.id).subscribe((data: any) => {
      console.log('data => ', data);
      let el = data.avatar;

      if (el === null) {
        this.avatarURL = "/assets/images/avatar.png";
        return;
      } else {
      this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
      }

      // console.log('url => ', this.avatarURL);

      // this.

      // this.user = data;
      // this.userForm.patchValue(data);
    });
  }

  edit() {
    // this.editing = !this.editing;
    this.router.navigate(['/tenant/profile', this.tenant.id]);
  }

  deleteTenant(tenantId: any) {
    this.store.destroyTenant(tenantId).subscribe({
      next: (data: any) => {
        console.log(data);
        this.toastr.success('Inquilino se eliminó correctamente.', 'Éxito');
        // this.loadUnit();
        // this.store.getTenantByUnit(this.unitId).subscribe((data: any) => {
          // console.log(data);      
          // this.tenants = data;
        // });
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Inquilino no pudo ser eliminado.', 'Error');
      }
    });
  }

  cancelEditTenant() {
    this.editing = false;
  }

}
