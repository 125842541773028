import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-invoice-cancel',
  template: `
    <ng-template #modalInvoiceCancel let-modal>
      <div class="modal-header">
        <div class="modal-title">Cancelación de factura</div>
      </div>
      <div class="modal-body p-4">
        <div class="list-group">
          <div class="list-group-item py-3">
            <table class="table table-sm" style="font-size: 0.8em;">
              <tbody>
                <tr>
                  <th>Folio fiscal</th>
                  <td class="text-end">{{ invoice?.uuid }}</td>
                </tr>
                <tr>
                  <th>Serie</th>
                  <td class="text-end">{{ invoice?.serie }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list-group-item py-3">
            <form [formGroup]="invoiceDeleteForm" >
              <div class="form-group">
                <label for="payment_date" class="form-label text-sm">Motivo de cancelación</label>
                <select class="form-select" formControlName="reason">
                  <option value="">Selecciona un motivo</option>
                  <option *ngFor="let reason of reasons" [value]="reason.id">{{ reason.name }}</option>
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-fill">
          <div class="text-start"></div>
          <div class="ms-auto text-end">
            <button type="button" class="btn btn-outline-danger" [disabled]="loading" (click)="cancelInvoice()">
              <fa-icon [icon]="icons.faTimes" class="me-2" *ngIf="!loading"></fa-icon>
              <fa-icon [icon]="icons.faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
              Cancelar factura
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: []
})
export class ModalInvoiceCancelComponent implements AfterViewInit {
  @Input() invoice: any;
  @Input() profile: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalInvoiceCancel') modalInvoiceCancel !: ElementRef;

  private modalRef: NgbModalRef | undefined;

  loading: boolean = false;
  invoiceDeleteForm: FormGroup = new FormGroup({});

  reasons: any = [
    { id: 1, name: "Comprobante emitido con errores con relación" },
    { id: 2, name: "Comprobante emitido con errores sin relación" },
    { id: 3, name: "No se llevó a cabo la operación" },
    { id: 4, name: "Operación nominativa relacionada en una factura global" },
  ];

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public icons: IconsService
  ) {}

  ngAfterViewInit(): void {
    this.buildInvoiceDeleteForm(); 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['invoice']&&changes['invoice'].currentValue) {
      console.log('cancel invoice ==> ', changes['invoice'].currentValue);
    }
  }

  buildInvoiceDeleteForm() {
    this.invoiceDeleteForm = this.fb.group({
      reason: ['', [Validators.required]],
    });
  }

  cancelInvoice() {
    this.loading = true;
    if (this.invoiceDeleteForm.invalid) {
      this.invoiceDeleteForm.markAllAsTouched();
      this.toastr.error('Selecciona un motivo para cancelar la factura.', 'Error');
      this.loading = false;
      return;
    }

    const payload: any = {
      id: this.invoice.id,
      reason: this.invoiceDeleteForm.value.reason,
    };

    this.store.cancelInvoice(payload).subscribe({
      next: (res: any) => {
        console.log(res);
        this.toastr.success('Factura cancelada correctamente.', 'Éxito');
        this.downloadFile();
      },
      error: (err: any) => {
        console.log(err);
        this.loading = false;
        this.toastr.error('Ocurrió un error al cancelar la factura.', 'Error');
      }
    });
  }

  async downloadFile() {
    this.store.getInvoice(this.invoice.id).subscribe((res: any) => {
      const payload: any = {
        utilityData: this.profile,
        invoiceData: res.invoice,
      };

      this.store.createAcuseCancel(payload).subscribe({
        next: (data: any) => {
          const int8Array = new Uint8Array(data.result.data);
          const blob = new Blob([int8Array], { type: 'application/pdf' });

          var fileURL = window.URL.createObjectURL(blob);
          var link=document.createElement('a');

          link.href = fileURL;
          link.download = data.filename;
          link.click();

          const blob2 = new Blob([payload.invoiceData.acuse], { type: 'application/xml' });

          var fileURL2 = window.URL.createObjectURL(blob2);
          var link2=document.createElement('a');

          link2.href = fileURL2;
          link2.download = `ACUSE_${payload.invoiceData.serie}_${payload.invoiceData.folio}.xml`;
          link2.click();

          this.invoiceDeleteForm.reset();
          this.dismiss();
          this.loading = false;
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al generar el acuse de cancelación.', 'Error');
        }
      });
    });
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
