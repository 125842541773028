import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-tanque-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Agregar tanque de gas</h1>
            <p>Configura el tanque de gas instalado en el edificio del condominio.</p>
          </div>
          <!-- div class="col-md-4 text-end">
            <button class="btn btn-primary btn-lg rounded-5"  >Agregar tanque</button>
          </!-->
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="card rounded-5 bg-light">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="card-title mb-4">Datos del tanque</h2>
                    <div class="alert alert-info">
                      <p class="mb-0">Recuerda agregar más datos posibles ya que esto ayuda a la información estadística.</p>
                    </div>
                  </div>                  
                  <div class="col-md-12 mt-4">
                    <app-form-tanque
                      [loading]="loading"
                      [profile]="profile"
                      [permissions]="permissions"
                      (updateForm)="createTanque($event)"
                      ></app-form-tanque>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- div class="col-md-4">
            <div class="card rounded-5 bg-light">
              <div class="card-body p-4"></div>
            </div>
          </!-->
        </div> 
      </div>
    </div>
  `,
  styleUrls: []
})
export class TanqueCreateComponent {
  loading: boolean = false;
  profile: any;
  permissions: any;

  payload: any = {
    page: 1,
    per_page: 100,
  };

  constructor(
    private router: Router,
    private store: SettlementsService,
    private toastr: ToastrService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.id = this.profile ? this.profile.id : 0;
  }

  createTanque(data: any) {
    this.store.createTanque(data).subscribe({
      next: (data: any) => {
        this.loading = false;
        this.toastr.success('Tanque creado', 'Tanque creado correctamente');
        this.router.navigate(['/tanques']);
      },
      error: (error: any) => {
        this.loading = false;
        this.toastr.error('Error', 'Error al crear tanque');
      }
    });
  }

}
