import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import * as bs from 'bootstrap';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-vehicles-index',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-md-11 offset-md-1 col-lg-7 offset-lg-4 col-xl-7 offset-xl-4 col-xxl-9 offset-xxl-3 mt-3">        
        <div class="row">
          <div class="col-md-10 offset-md-1 col-lg-7 offset-lg-0 col-xl-6">
            <h1>Vehículos</h1>
            <p>Configura las vehículos.</p>
          </div>
          <div class="col-md-6 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.admin.inventory.vehiculos.write" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar vehículo
            </button>
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="row mb-4">
          <div class="col-md-7">
            <!-- h2 class="mb-0">Habitacionales</!-->
          </div>
          <div class="col-md-5">
            <form [formGroup]="searchBox" (ngSubmit)="searchVehicles($event)">
              <input class="form-control form-control-lg bg-transparent rounded-5 border border-2 border-secondary" placeholder="Buscar..." formControlName="search">
            </form>
          </div>
          <div class="col-md-12 mt-5 pb-3">
            <div class="list-group rounded-5 shadow" *ngIf="vehicles.length>0">
              <div class="list-group-item bg-light p-0" *ngFor="let vehicle of vehicles | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-vehicle
                  [vehicle]="vehicle"
                  [profile]="profile"
                  [permissions]="permissions"
                  [technicians]="technicians"
                  (vehicleData)="asignVehicle($event)"
                ></app-item-vehicle> <!-- (vehicleSelected)="updateList($event)" -->
              </div>
            </div>
            <div class="list-group rounded-5 shadow" *ngIf="vehicles?.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="vehicles.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"                  
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <app-modal-vehicle-asign [technicians]="technicians" [technician]="technician" [vehicle]="selectedVehicle" (vehicleData)="saveAssignee($event)"></app-modal-vehicle-asign>
  `,
  styleUrls: []
})
export class VehiclesIndexComponent implements OnInit {
  @ViewChild('modalVehicleAsign', { read: ElementRef, static: false }) modalVehicleAsign !: ElementRef;

  loading: boolean = false;
  vehicles: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  profile: any = {};
  permissions: any = {};
  technician: any = {};
  selectedVehicle: any = {};
  technicians: any[] = [];

  payload: any = {
    page: 1,
    per_page: 20,
  };

  p: number = 1;

  constructor(
    private store : SettlementsService,
    private tasks: TasksService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    this.loadVehicles(this.payload);
    this.initForm();
    this.loadTechincians();
    // this.breadcrumbService.set('@amenidad', 'Amenidades');
  }

  loadVehicles(payload: any) {
    this.loading = true;    
    this.tasks.getVehicles(payload).subscribe({
      next: (vehicles: any) => {
        this.vehicles = vehicles.data;
        this.meta = vehicles.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.vehicles = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las amenidades.', 'Error');
        }
      }
    });
  }

  loadTechincians() {
    this.store.getUsers({ role_id: 3, page: 1, per_page: 3, profile_id: this.profile.id}).subscribe((data: any) => {
      console.log('data => ', data);
      this.technicians = data.data;
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  searchVehicles(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadVehicles(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadVehicles(this.payload);
  }

  asignVehicle(event: any) {
    console.log('asignVehicle => ', event);
    this.selectedVehicle = event.vehicle;
    this.technician = event.technician;
    new bs.Modal(<HTMLInputElement>document.getElementById('modalVehicleAsign')).show();
  }

  saveAssignee(event: any) {
    console.log('Save assignee ==> ', event);
    
    this.tasks.updateVehicle(this.selectedVehicle.id, { assignee_id: event.assignee_id }).subscribe((data: any) => {
      console.log('data => ', data);
      this.loadVehicles(this.payload);
      this.toastr.success('Vehículo asignado correctamente', 'Éxito');
      window.location.reload();
    });
  }
}
