import { Component, Input, OnInit } from '@angular/core';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-item-task-tenant',
  template: `
    <div class="row">
      <div class="col-md-8">
        <h4>{{task.builder?.name}}</h4>
        <p class="mb-0 text-muted">{{ status[task.status-1]?.name }}</p>
      </div>
      <div class="col-md-4 text-end">
        <p class="badge mb-0" [ngStyle]="{ background: priority[task.priority-1]?.color}">{{ priority[task.priority-1]?.name }}</p>
        <p class="text-end">{{task.due_date | date: 'dd/MM/yy'}}</p>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemTaskTenantComponent implements OnInit {
  @Input() task: any;

  priority: any = [
    { id: 1, name: 'Baja', color: '#219C90' },
    { id: 2, name: 'Media', color: '#E9B824' },
    { id: 3, name: 'Alta', color: '#EE9322' },
    { id: 4, name: 'Urgente', color: '#D83F31' },
  ];

  status: any = [
    { id: 1, name: 'Pendiente' },
    { id: 2, name: 'En proceso' },
    { id: 3, name: 'Completada' },
    { id: 4, name: 'Cancelada' },
  ];

  constructor(
    private tasksService: TasksService
  ) { }

  ngOnInit(): void {
    this.tasksService.getBuilders({}).subscribe((builders: any) => {
      console.log('builders', builders);
      this.task.builder = builders.data.find((builder: any) => builder.id == this.task.builder_id);
    });
  }
}
