import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  constructor() { }

  truncateTextAtWord(text: string, maxLength: number) {
    if (text.length <= maxLength) {
      return text;
    }
    // Find the last space within maxLength
    let trimmedText = text.substr(0, maxLength);
    trimmedText = trimmedText.substr(0, Math.min(trimmedText.length, trimmedText.lastIndexOf(" ")));
    return trimmedText + '...';
  }
}
