import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faPlus, faCircleNotch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-providers',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Proveedores</h1>
            <p>Configura los proveedores asociados al condominio.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar proveedor
            </button>
          </div>
        </div>
        
            <div class="row">
              <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
              </div>
              <div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchProviders($event)">
                  <input class="form-control form-control-lg rounded-5 bg-transparent" placeholder="Buscar..." formControlName="search">
                </form>
              </div>
              <div class="col-md-12 mt-5 pb-3" >
                <div class="list-group rounded-4 shadow" *ngIf="providers.length>0">
                  <div class="list-group-item bg-light" *ngFor="let provider of providers | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                    <div class="row">
                      <div class="col-md-1 text-center mt-3 ps-4">
                        <app-item-item-provider [provider]="provider"></app-item-item-provider>
                      </div>
                      <div class="col-md-8 mt-1">
                        <h4 class="mb-0">
                          {{provider.name}} {{provider.last_name}}
                        </h4>
                        <p class="mb-0 text-muted">{{provider.email}}</p>
                        <p class="mb-0 text-muted">Servicio: {{provider.service}}</p>
                      </div>
                      <div class="col-md-3 text-end pt-1">
                        <!-- div class="badge rounded-pill me-3 px-3 py-2" [ngClass]="provider.status == 'activo' ? 'bg-success' : 'bg-danger'">
                          <p class="mb-0 text-capitalize">{{provider.status}}</p>
                        </!-->
                        <button class="btn btn-outline-primary" routerLink="{{provider.id}}">
                          <fa-icon [icon]="faEdit" size="1x"></fa-icon>
                          Editar
                        </button>
                        <button class="btn btn-outline-danger ms-2" (click)="removeProvider(provider.id)">
                          <fa-icon [icon]="faTrashAlt" size="1x"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group rounded-4 shadow" *ngIf="providers.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" *ngIf="providers.length>0">
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"                  
                ></pagination-controls>
              </div>
            </div>
          
      </div>
    </div>
  `,
  styleUrls: []
})
export class ProvidersComponent {
  loading: boolean = false;
  providers: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});

  faEdit = faEdit;
  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faCircleNotch = faCircleNotch;

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {    
    this.loadProviders(this.payload);
    this.initForm();
  }

  loadProviders(payload: any) {
    this.loading = true;
    this.store.getProviders(payload).subscribe({
      next: (providers: any) => {
      console.log(providers);
      this.providers = providers.data;
      this.meta = providers.meta;
      this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.loading = false;
        this.providers = [];
        // this.toastr.warning('Ocurrió un error al cargar los proveedores.', 'Advertencia');
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  searchProviders(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadProviders(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadProviders(this.payload);
  }
  

  removeProvider(id: any) {
    let confirmation = confirm('¿Está seguro que desea eliminar el proveedor?');

    if (!confirmation) {
      console.log('cancel');
    } else {       
      this.store.destroyProvider(id).subscribe({
        next: (res: any) => {
          this.toastr.success('Proveedor eliminado correctamente.', 'Éxito');
          this.loadProviders(this.payload);
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al eliminar el proveedor.', 'Error');
        }
      });
    }
  }
}
