import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { User } from '../../models/user';
import { ValidateConfirmpass } from '../../components/confirmpass.validator';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  protected version = environment.app_version;

  signupForm!: FormGroup;
  loading: boolean = false;
  password: any;
  showPassword: boolean = false;
  accountCreated: boolean = false;

  passwordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}';

  faEye = faEye;
  faLock = faLock;
  faEyeSlash = faEyeSlash;
  faCircleNotch = faCircleNotch;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private settlementService: SettlementsService
  ) { }

  get lf() {
    return this.signupForm.controls;
  }

  get currPassword() {
    return this.signupForm.get('password');
  }

  ngOnInit(): void {
    this.initSignupForm();
  }

  initSignupForm(): void {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.passwordPattern)]],
      password_confirmation: ['', [Validators.required, ValidateConfirmpass]],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  signup() {
    this.loading = true;
    console.log("signup");
    console.log(this.signupForm.value);
    if (this.signupForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      this.loading = false;
      return;
    }

    let payload: any = {
      user: {
        email: this.signupForm.value.email,
        name: this.signupForm.value.name,
        last_name: this.signupForm.value.last_name,
        password: this.signupForm.value.password,
        password_confirmation: this.signupForm.value.password_confirmation,
      }
    };

    this.settlementService.signup(payload).subscribe({
      next: (data: any) => {
        console.log("Success");
        console.log(data);
        this.accountCreated = true;
        this.loading = false;
      },
      error: (err: any) => {
        console.log("Error");
          console.log(err);
          this.toastr.error(err.error.status.message, 'Error');
      }
    });

  }
}
