<div class="container-fluid p-0">
  <div class="row mt-0 p-0">
    <div class="col-md-4 h-100 bg-dark text-center pt-5" style="background: url('assets/images/building-zazz.png') no-repeat center center;">
      <img src="assets/images/logo-zazz.png" class="img-fluid mt-5 text-center pt-5" width="50%" alt="condominio">
      <h1 class="text-light text-center mt-4">SISTEMA ZAZZ</h1>
      <p class="text-light text-sm mt-5 pt-3">Todos los derechos reservados © 2024</p>
      <p class="text-light text-sm">V {{version}}</p>
    </div>
    <div class="col-md-8 offset-md-0 pt-0 mt-0 text-center bg-white">
      <div class="row mt-5 mb-5"></div>
      <div class="row mt-5 pt-5">
        <div class="col-md-6 offset-md-3">
          <h2 class="mb-5 mt-3">Iniciar sesión</h2>
          <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-group text-start mt-5">
              <label for="email" class="text-sm sr-only d-none">Correo electrónico</label>
              <input type="text" formControlName="email" class="form-control border-2 rounded-0 border-dark pt-3 pb-3" placeholder="Correo electrónico" #userName required>
              <div *ngIf="lf['email'].touched && lf['email'].invalid">
                <div *ngIf="lf['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico es requerido.</div>
                <div *ngIf="lf['email'].errors?.['email']" class="text-danger text-sm">El correo electrónico no es válido</div>
              </div>
            </div>
            <div class="form-group mt-4">
              <label for="password" class="text-sm sr-only d-none">Contraseña</label>
              <input type="password" formControlName="password" class="form-control border-2 rounded-0 border-dark pt-3 pb-3" placeholder="Contraseña" #userPassword required>
              <div *ngIf="lf['password'].touched && lf['password'].invalid">
                <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-start text-sm">La contraseña es requerida.</div>
              </div>
            </div>
            <div class="form-group mt-5">
              <button type="submit" class="btn btn-dark btn-lg w-100 rounded-0 pt-3 pb-3">
                <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="text-secondary me-2"></fa-icon>
                <fa-icon [icon]="faLock" *ngIf="!loading" class="text-light me-2"></fa-icon>
                Entrar
              </button>
            </div>
          </form>
          <!-- div class="form-group mt-3 mb-3">
            <span class="or"><span class="orInner">Ó</span></span>
          </!-->
          <!-- Calling GoogleAuth Api from AuthService -->
          <!-- div class="form-group mt-3 mb-3">
            <button type="button" class="btn btn-light border border-1" (click)="authService.GoogleAuth()">
              <i class="fab fa-google-plus-g"></i>
              Log in with Google
            </button>
          </!-->
          <div class="forgotPassword mt-5">
            <a routerLink="/forgot-password" class="text-sm">¿Olvidaste tu contraseña?</a>
          </div>
          <!-- div class="redirectToLogin mt-3">
              <span class="text-sm">¿No tienes cuenta aún?<a class="redirect" routerLink="/register-user"> Registrarme</a></span>
            </!-->
        </div>
        <div class="mt-5 pt-5">
          <a href="https://zazz.mx/aviso-de-privacidad/" class="text-sm mt-5">Aviso de privacidad</a> <span class="text-sm mt-5 ps-3 pe-3"> | </span>
          <a href="https://zazz.mx/terminos-y-condiciones/" class="text-sm mt-5">Términos y condiciones</a>
        </div>
      </div>        
    </div>
  </div>
</div>