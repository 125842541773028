import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-item-form-concept',
  template: `
    <form [formGroup]="conceptForm">
      <div class="row p-1 bg-light">
        <div class="col-md-10 offset-md-1 form-group ps-0 pe-0 mb-3">
          <label>Concepto</label>
          <select class="form-select" formControlName="concept" (change)="selectConcept($event)">
            <option disabled>Concepto</option>
            <option *ngFor="let concept of concepts" [value]="concept.id">{{concept.name}}</option>
          </select>
        </div>
        <!-- div class="col-md-3 form-group">
          <label>Cantidad</label>
          <input type="number" class="form-control" formControlName="amount">
        </!-->
        <div class="col-md-8 offset-md-1 form-group">
          <h5 class="mb-0">{{ concept.name }}</h5>
        </div>
        <div class="col-md-2 text-end form-group">
          <p class="mb-0"><strong><small>{{ concept.amount | currency: 'USD' }}</small></strong></p>
        </div>
        <div class="col-md-7 offset-md-1 form-check mt-3 pt-2 text-end" *ngIf="concept.id">
          <!-- input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" -->
          <label class="form-check-label" for="flexCheckDefault">
            Agregar descuento %
          </label>
        </div>
        <div class="col-md-3 text-end form-group mt-3" *ngIf="concept.id">
          <input type="number" min="0" max="100" class="form-control" formControlName="discount">
        </div>
        <div class="col-md-4 offset-md-7 form-group text-center ps-0">
          <button type="button" class="btn btn-primary mt-4" (click)="addConcept()">
            <fa-icon [icon]="faSave"></fa-icon> Agregar
          </button>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class ItemFormConceptComponent implements OnInit {
  @Input() concepts: any;
  @Output() conceptData = new EventEmitter<any>();

  cantidad: number = 0;
  concept: any = {};

  faPlus = faPlus;
  faSave = faSave;

  conceptForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formInit();
  }

  formInit() {
    this.conceptForm = this.fb.group({
      concept: [''],
      amount: [''],
      discount: [''],
      total: [''],
      clave: [''],
      codigo_unidad: [''],
      created_at: [''],
      id: [''],
      name: [''],
      sat_group: [''],
      tax: [''],
      updated_at: [''],
    });
  }

  selectConcept(event: any) {
    console.log(event.target.value);
    console.log('concepts ', this.concepts);
    this.concept = this.concepts.filter((concept: any) => concept.id == event.target.value)[0];
    console.log('concept ', this.concept);
    this.conceptForm.patchValue(this.concept);
    // this.conceptForm.controls['concept'].setValue(event.target.value);
  }

  addConcept() {
    console.log('add concept');
    this.conceptForm.patchValue({
      total: this.concept.amount - (this.concept.amount * (this.conceptForm.value.discount/100))
    })
    console.log(this.conceptForm.value);
    this.conceptData.emit(this.conceptForm.value);
  }
}
