import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-form-contract-types',
  template: `
    <form [formGroup]="contractTypeForm">
      <div class="form-group mt-3">
        <label for="name" class="form-label ms-2">Nombre</label>
        <input type="text" class="form-control form-control-lg" formControlName="name">
        <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
          <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="description" class="form-label ms-2">Descripción</label>
        <input type="text" class="form-control form-control-lg" formControlName="description">
        <div *ngIf="ufc['description'].touched && ufc['description'].invalid">
          <div *ngIf="ufc['description'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
        </div>
      </div>
      <div class="form-group mt-3">
        <label for="validate_type" class="form-label ms-2">Tipo de validación</label>
        <select class="form-select form-select-lg" formControlName="validate_type">
          <option value="" disabled selected>Selecciona una opción</option>
          <option value="DISI">DISI</option>
          <option value="FIEL">FIEL</option>
        </select>
      </div>
      <div class="form-group mt-5">
        <div class="form-check form-switch form-switch-lg">
          <input class="form-check-input" type="checkbox" role="switch" id="validate_id" formControlName="validate_id">
          <label class="form-check-label ms-3" for="validate_id">Validar Identificación</label>
        </div>
      </div>
      <div class="form-group mt-3">
        <div class="form-check form-switch form-switch-lg">
          <input class="form-check-input" type="checkbox" role="switch" id="validate_sms" formControlName="validate_sms">
          <label class="form-check-label ms-3" for="validate_sms">Validar SMS</label>
        </div>
      </div>
      <div class="form-group mt-3">
        <div class="form-check form-switch form-switch-lg">
          <input class="form-check-input" type="checkbox" role="switch" id="validate_picture" formControlName="validate_picture">
          <label class="form-check-label ms-3" for="validate_picture">Validar Fotografía</label>
        </div>
      </div>
      <div class="form-group mt-3">
        <div class="form-check form-switch form-switch-lg">
          <input class="form-check-input" type="checkbox" role="switch" id="validate_biometric" formControlName="validate_biometric">
          <label class="form-check-label ms-3" for="validate_biometric">Validar Biométrico</label>
        </div>
      </div>
      <div class="form-group mt-3">
        <div class="form-check form-switch form-switch-lg">
          <input class="form-check-input" type="checkbox" role="switch" id="send_email" formControlName="send_email">
          <label class="form-check-label ms-3" for="send_email">Enviar correo electrónico</label>
        </div>
      </div>
      <div class="form-group text-end mt-5">
        <button class="btn btn-primary btn-lg mt-3 rounded-5" (click)="onSubmit()">Guardar</button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormContractTypesComponent implements OnInit {
  @Input() contractType: any;
  @Output() contractTypeData: EventEmitter<any> = new EventEmitter<any>();

  contractTypeForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService
  ) {
    
  }

  ngOnInit(): void {
    this.initForm();
  }

  get ufc() {
    return this.contractTypeForm.controls;
  }

  initForm() {
    this.contractTypeForm = this.fb.group({
      name: [''],
      description: [''],
      validate_type: ['', Validators.required],
      validate_id: [false],
      validate_sms: [false],
      validate_picture: [false],
      validate_biometric: [false],
      send_email: [false],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contractType']&&changes['contractType'].currentValue) {
      console.log('changes contractType => ', this.contractType);
      this.contractTypeForm.patchValue(this.contractType);
    }
  }

  onSubmit() {
    console.log('submit => ', this.contractTypeForm.value);
    if (this.contractTypeForm.invalid) {
      this.toastr.error('Formulario incompleto, verifica los campos.', 'Error');
      return;
    }

    this.contractTypeData.emit(this.contractTypeForm.value);
  }

}
