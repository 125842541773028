import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-technician-create',
  template: `
  <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Perfil</h1>
            <p>Configura la información de tu cuenta.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/services/service">Agregar usuario</!-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                  <app-form-technician [technician]="technician" [roles]="roles" (updateTechnician)="saveUser($event)"></app-form-technician>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  `,
  styleUrls: []
})
export class TechnicianCreateComponent implements OnInit {
  technicianId: number = 0;
  technician: any = {};
  roles: any = [];

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { 
    this.activateRoute.params.subscribe(params => {
      this.technicianId = params['id'];
      if (this.technicianId) {
        this.loadUser(this.technicianId);
      }
    });

    this.store.getRoles().subscribe({
      next: (data: any) => {
        this.roles = data;
      },
      error: (error: any) => {}
    });
  }
  
  ngOnInit(): void {}

  loadUser(id: number) {
    this.store.getTechnician(id).subscribe({
      next: (data: any) => {
        console.log("data => ", data);
        this.technician = data;
        // this.buildForm();
      },
      error: (error: any) => {}
    });
  }

  saveUser(technician: any) {
    console.log('technician!!!! => ', technician);
    
    this.store.createUser(technician).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Usuario actualizado correctamente.');
        this.route.navigate([`/technicians`]);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar el usuario');
      }
    });
  }

}
