import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-asign-task-operator',
  template: `
    <div #modalAsignTask id="modalAsignTask" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalAsignTask" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Asignación de tarea</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="icons.faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body pt-3 pb-3">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="formTask">
                  <div class="form-group required mb-4 mt-3">
                    <label for="date" class="form-label ms-2">Operador</label>
                    <select class="form-select form-select-lg" formControlName="assignee_id" (change)="selectAssignee($event)">
                      <option selected disabled>Selecciona un técnico/operador</option>
                      <option *ngFor="let technician of technicians" [value]="technician.id">{{technician.name}}</option>
                    </select>
                    <div *ngIf="ufc['assignee_id'].touched && ufc['assignee_id'].invalid">
                      <div *ngIf="ufc['assignee_id'].errors?.['required']" class="text-danger text-sm">El técnico/operador es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group mb-4 mt-3" *ngIf="vehicle">
                    <div class="alert alert-info">Vehículo asignado: {{vehicle.name}} {{vehicle.plates}}</div>
                  </div>
                  <div class="form-group mb-4 mt-3" *ngIf="!vehicle&&!ufc['assignee_id'].invalid">
                    <div class="alert alert-warning">Operador no tiene vehículo asignado.</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveTask()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalAsignTaskOperatorComponent {
  @Input() task: any;
  @Input() technicians: any;
  @Output() asignData = new EventEmitter<any>();

  formTask: FormGroup = new FormGroup({});
  vehicle: any = null;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private taskService: TasksService,
    public icons: IconsService
  ) {
    
  }

  ngOnInit(): void {
    this.initForm();
    this.formTask.patchValue(this.task);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['task'] && changes['task'].currentValue) {

      this.formTask.patchValue(this.task);
    }
  }

  get ufc() {
    return this.formTask.controls;
  }

  initForm() {
    this.formTask = this.fb.group({
      id: [''],
      title: [''],
      due_date: [''],
      due_time: [''],
      status: ['', Validators.required],
      priority: ['', Validators.required],
      profile_id: [''],
      settlement_id: ['', Validators.required],
      building_id: [''],
      unit_id: [''],
      tanque_id: [''],
      po_id: [''],
      builder_id: ['', Validators.required],
      assignee_id: ['', Validators.required],
      duration: [''],
    });
  }

  selectAssignee(event: any) {
    console.log('SELECT ASSIGNEE ====> ', event.target.value);
    this.taskService.getUserVehicle(event.target.value).subscribe((data: any) => {
      console.log('VEHICLE ====> ', data);
      // this.formTask.patchValue({ vehicle_id: data.vehicle_id });
      this.vehicle = data[0];
    });
  }

  saveTask() {
    console.log('SAVE TASK ====> ', this.formTask);
    if (this.formTask.invalid) {
      this.toastr.error('Todos los campos son requeridos', 'Error');
      this.formTask.markAllAsTouched();
      return; 
    }

    this.formTask.patchValue({ status: 1 });
    this.asignData.emit(this.formTask.value);
  }
}
