import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';

@Component({
  selector: 'app-tenant-invoice-list',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Mis Facturas</h1>
          </div>        
          <div class="col-md-12" *ngIf="invoicesList?.length>0">
            <div class="card rounded-4 shadow mb-3" *ngFor="let invoice of invoicesList | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <app-item-tenant-invoice [invoice]="invoice" [permissions]="permissions"></app-item-tenant-invoice>
              </div>
          </div>
          <div class="col-md-12" *ngIf="invoicesList?.length==0">
            <div class="list-group rounded-5 shadow">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="invoicesList.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"                  
            ></pagination-controls>
          </div>
          <div class="col-md-12" *ngIf="invoicesList?.length==0">
            <div class="list-group-item text-center py-5" *ngIf="invoicesList.length==0">
              <img src="/assets/images/empty-state.png" width="240px">
              <h5 class="text-muted">No se encontraron registros</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TenantInvoiceListComponent implements OnInit {

  ux: any = localStorage.getItem('user');  
  user: any = this.ux != null ? JSON.parse(this.ux) : null;
  permissions: any;
  today: Date = new Date();
  invoicesList: any;
  meta: any = {};
  payload: any = {
    page: 1,
    per_page: 20,
  };
  tenantId: any;
  p: number = 1;
  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  loading: boolean = false;
  apiUrl: string = environment.AWS_REST_WSPREFIX;

  constructor(
    private toastr: ToastrService,    
    private store: SettlementsService,
    private activatedRoute: ActivatedRoute,
    public icons: IconsService,
    public catalog: CatalogsService    
  ) { 
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions') || '{}') : {};
  }

  ngOnInit(): void {
    this.loadInvoices()
  }

  loadInvoices() {
    this.activatedRoute.params.subscribe((params: any) => {
      this.tenantId = params['id']
      this.payload = {...this.payload, id: params['id']};
      this.store.getInvoiceList(this.payload).subscribe((il: any) => {
        this.invoicesList = il.data;
        this.meta = il.meta;
        this.loading = false;
      });
    });
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadInvoices();
  }
}
