import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  apiUrl: string = 'https://api.zazz.mx';//  environment.AWS_REST_WSPREFIX;

  buildURL(data: any) {
    if (data.blob) {
      return `${this.apiUrl}/storage/${data.blob.key.substring(0,2)}/${data.blob.key.substring(2,4)}/${data.blob.key}`;
    } else {
      return '';
    }
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
    
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
  

  async loadImg(data: any) {
    let imageError: string = "";
    let isImageSaved: boolean = false;
    let cardImageBase64: string = "";
    let previewImagePath: string = "";
    
    imageError = "";

    if (data.target.files && data.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (data.target.files[0].size > max_size) {
        imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        // return false;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          // console.log(img_height, img_width);
          if (img_height > max_height && img_width > max_width) {
            imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            // return false;
          } else {
            const imgBase64Path = e.target.result;
            cardImageBase64 = imgBase64Path;
            isImageSaved = true;
            previewImagePath = imgBase64Path;
            // return previewImagePath;
            
          }
        };
      };

      reader.readAsDataURL(data.target.files[0]);
    }
  }
}
