import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-providers',
  template: `
    <form [formGroup]="formProvider" (ngSubmit)="saveProvider()">
      <div class="row">
        <div class="col-md-12">
          <div class="avatar-container text-center">
            <div class="image-container border border-4">
              <img [src]="avatarURL" width="100%">
            </div> 
            <label for="avatar-input-file">
              <div class="icon-wrapper">
                <input type="file" id="avatar-input-file" (change)="loadAvatar($event)" accept="image/*"/>
                <fa-icon [icon]="faEdit" class="text-white"></fa-icon>
              </div>
            </label>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre del proveedor</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="last_name" class="form-label ms-2">Apellido</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="last_name" placeholder="" formControlName="last_name">
            <div *ngIf="ufc['last_name'].touched && ufc['last_name'].invalid">
              <div *ngIf="ufc['last_name'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="email" class="form-label ms-2">Correo electrónico</label>
            <input type="email" class="form-control form-control-lg rounded-3" id="email" placeholder="" formControlName="email">
            <div *ngIf="ufc['email'].touched && ufc['email'].invalid">
              <div *ngIf="ufc['email'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="phone" class="form-label ms-2">Teléfono</label>
            <input type="phone" class="form-control form-control-lg rounded-3" id="phone" placeholder="" formControlName="phone">
            <div *ngIf="ufc['phone'].touched && ufc['phone'].invalid">
              <div *ngIf="ufc['phone'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="service" class="form-label ms-2">Servicio</label>
            <input type="service" class="form-control form-control-lg rounded-3" id="service" placeholder="" formControlName="service">
            <div *ngIf="ufc['service'].touched && ufc['service'].invalid">
              <div *ngIf="ufc['service'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Condominio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="settlement_id">
              <option selected>Selecciona un condominio</option>
              <option *ngFor="let settlement of settlements" [value]="settlement.id">{{ settlement.name }}</option>
            </select>
          </div>
          <!-- div class="form-group required mb-4 mt-3" *ngIf="buildings.length>0">
            <label for="name" class="form-label ms-2">Edificio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="building_id">
              <option selected>Selecciona un edificio</option>
              <option *ngFor="let building of buildings" [value]="building.id">{{ building.name }}</option>
            </select>
          </!-->
          <div class="form-group required mb-4 mt-3 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/providers">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormProvidersComponent implements OnInit {

  @Input() provider: any = {};
  @Output() providerData = new EventEmitter<any>();

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  formProvider: FormGroup = new FormGroup({});

  settlements: any[] = [];
  buildings: any[] = [];
  avatarURL: any = '';

  imageError: string = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";
  previewImagePath: string = "";

  faEdit = faEdit;

  payload: any = {
    page: 1,
    per_page: 30,
    tipo: 0
  }

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {} 

  get ufc() {
    return this.formProvider.controls;
  }

  ngOnInit(): void {
    this.loadSettlements(this.payload);
    this.initForm();
  }

  loadSettlements(payload: any) {
    this.store.getSettlements(payload).subscribe({
      next: (data) => {
        this.settlements = data.data;
      },
      error: (err) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al obtener los condominios.');
      }
    });
  }

  initForm() {
    this.formProvider = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      service: ['', Validators.required],
      settlement_id: ['', Validators.required],
    });

    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['provider'].currentValue) {
      this.formProvider.patchValue(changes['provider'].currentValue);

      if (changes['provider'].currentValue.avatar) {
        let el = this.provider.avatar;
        this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
      }
    }
  }

  loadAvatar(event: any): void {

    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        // return false;
      }

      // if (!_.includes(allowed_types, event.target.files[0].type)) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        // return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          // console.log(img_height, img_width);
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            // return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            console.log('imgBase64Path => ', imgBase64Path);
            console.log('previewImagePath => ', this.previewImagePath);
            let payload = {
              id: this.provider.id,
              data: this.previewImagePath
            }
            console.log('event => ', event.target.files[0]);
            this.store.updateProviderAvatar(this.formProvider.value.id, payload).subscribe({
              next: (data: any) => {
                this.toastr.success('Avatar actualizado correctamente.');
                // this.loadUser(this.provider.id);
              },
              error: (err: any) => {
                console.log(err);
                this.toastr.error('Ocurrió un error al actualizar el avatar.');
              }
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }    
  }

  // selectSettlement(e: any) {
    // console.log('selectSettlement');
    // console.log(e.target.value);
    // this.store.getBuildingsBySettlement(e.target.value).subscribe({
      // next: (data) => {
        // console.log(data);
        // this.buildings = data;
      // },
      // error: (err) => {
        // console.log(err);
      // }
    // });
  // }

  // selectBuilding(e: any) {
    // this.formProvider.patchValue({
      // building_id: e.target.value
    // });
  // }

  saveProvider() {
    console.log(this.formProvider.value);
    if (this.formProvider.invalid) {
      this.formProvider.markAllAsTouched();
      return;
    }

    this.providerData.emit(this.formProvider.value);    
  }
}
