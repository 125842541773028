import { TasksService } from 'src/app/services/tasks.service';
import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-tenant-dashboard',
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-2 mt-sm-5 mt-md-5 mt-lg-5 mt-xl-5">
        <div class="row">
          <div class="col-md-6">
            <h1>Bienvenido de vuelta, {{tenant?.name}}</h1>
            <!-- p>Revisa los mantenimientos próximos.</!-->
          </div>
          <div class="col-md-6">
            <div class="card rounded-4 bg-light border-0 shadow mt-3 m-sm-0">
              <div class="card-body">
                <div class="row">
                  <div class="col-4 col-sm-2 col-md-2 text-center">
                    <img src="assets/images/building-avatar.png" width="50%">
                  </div>
                  <div class="col-8 col-sm-2 col-md-10">
                    <h5 class="mb-0">Condominio</h5>
                    <h3 class="mb-0">{{settlement?.settlement?.name}}</h3>
                    <p class="mb-0">{{settlement?.settlement?.address}} {{settlement?.settlement?.city}} {{settlement?.settlement?.state}} </p>
                    <h5 class="mb-0 mt-3">Edificio</h5>
                    <h3 class="mb-3">{{settlement?.building?.name}}</h3>
                    <h5 class="mb-0">Departamento</h5>
                    <h3 class="mb-3">{{settlement?.unit?.name}}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-4">
            <div class="card rounded-4 bg-light border-0 shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12" *ngIf="tenant">
                    <app-item-tenant [tenant]="tenant" class="row bg-light rounded-4 border border-1 m-2"></app-item-tenant>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-4">
            <div class="card rounded-4 bg-light border-0 shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <h5>Mantenientos programados</h5>
                    <div class="list-group mt-3" *ngIf="tasks.length>0">
                      <div class="list-group-item" *ngFor="let task of tasks">
                        <app-item-task-tenant [task]="task"></app-item-task-tenant>
                      </div>
                    </div>
                    <div class="list-group text-center mt-3" *ngIf="tasks.length==0">
                      <div class="list-group-item text-center">
                      <img src="/assets/images/empty-state.png" width="140px" class="text-center">
                      <p class="text-muted">No hay mantenimientos próximos.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TenantDashboardComponent implements OnInit {
  userLS: any = {};
  tenant: any = null;
  settlement: any = {};
  tasks: any = [];

  constructor(
    private store: SettlementsService,
    private tasksService: TasksService
  ) {
    this.userLS = JSON.parse(localStorage.getItem('user') || '{}');
  }

  ngOnInit(): void {
    this.store.getTenant(this.userLS.id).subscribe((tenant: any) => {
      this.tenant = tenant;
      this.store.getUnitSettlement(tenant.unit_id).subscribe((settlement: any) => {
        this.settlement = settlement;
        localStorage.setItem('settlement', JSON.stringify(settlement));
      });
      this.tasksService.getTasks({ unit_id: tenant.unit_id, status: 1 }).subscribe((tasks: any) => {
        this.tasks = tasks.data;
      });
    });
    
  }
}
