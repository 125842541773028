import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faCircleNotch, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ToastrService } from 'ngx-toastr';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-roles',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Roles</h1>
            <p>Configura los roles de los usuarios de la plataforma.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar rol
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 pb-3">
            <div class="list-group rounded-5 overflow-hidden shadow" *ngIf="roles.length>0">
              <div class="list-group-item p-0" *ngFor="let rol of roles">
                <div class="row">
                  <div class="col-1 ps-5 pt-4">
                    <fa-icon [icon]="icons.faUserShield" size="2x" class="text-secondary"></fa-icon>
                  </div>
                  <div class="col-md-10 text-start px-3 py-3">
                    <h3 class="mb-0">{{rol.name}}</h3>
                    <p class="mb-0">{{groups[rol.group_id - 1].name}}</p>
                  </div>
                  <div class="col-md-1 text-center p-0" routerLink="{{rol.id}}" *ngIf="permissions?.modules.operation.combustibles.tanques.write">
                    <div class="hover-info border-start pt-3 pe-4" style="height: 100%;">
                      <fa-icon [icon]="icons.faEdit" size="1x" class="center-icon"></fa-icon>
                    </div>
                  </div>
              </div>
            </div>              
            <div class="text-center mt-4 mb-4" *ngIf="roles.length == 0">
              <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true"></fa-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class RolesComponent {
  roles: any[] = [];
  profile: any = {};
  permissions: any = {};

  groups: any[] = [
    { id: 1, name: 'Administradores' },
    { id: 2, name: 'Técnicos' },
    { id: 3, name: 'Inquilinos' },
  ];

  constructor(
    private store: SettlementsService,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
  }

  ngOnInit() {
    this.store.getRoles().subscribe({
      next: (data: any[]) => {
        this.roles = data;
      }, 
      error: (error: any) => {
        this.toastr.error('No se pudieron cargar los roles', 'Error');
      }
    });
    this.breadcrumbService.set('@roles', 'Roles');
  }
}
