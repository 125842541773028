import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-refuel-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4">
          <div class="col-md-9">
            <h1>Suministro de combustible</h1>
            <p>Configura las suministro que están disponibles para los departamentos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar suministro</h4>
                    <!-- p>Configura la amenidad con los siguientes campos.</!-- -->
                    <app-form-refuel (refuelData)="updateRefuel($event)" [refuel]="refuel"></app-form-refuel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class RefuelEditComponent {

  loading: boolean = false;
  refuel: any = {};

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
  ) {
    const ctl = this;
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.store.getRefuel(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          console.log("RES REFUEL =>", res);
          ctl.refuel = res;
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener la amenidad.', 'Error');
        }
      });
    });
  }

  updateRefuel(refuel: any) {
    console.log('refuel => ', refuel);
    this.store.updateRefuel(refuel.id, refuel).subscribe({
      next: (data: any) => {
        console.log(data);
        this.toastr.success('Recarga creado correctamente.', 'Éxito');
        this.router.navigate(['/refuels']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al crear la recarga.', 'Error');
      }
    });
  }

}
