import { Component, Input, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-item-profile',
  template: `
    <div class="row">
      <div class="col-md-1 ps-4 pe-0 py-2">
        <img [src]="avatarURL" width="100%">
      </div>
      <div class="col-md-10 px-4 py-3">
        <h5 class="mb-0">
          <a routerLink="edit/{{profile.id}}" *ngIf="permissions?.modules.config.profiles.write">{{ profile.name }}</a>
          <span *ngIf="!permissions?.modules.config.profiles.write">{{profile.name}}</span>
        </h5>
        <p class="text-muted">{{ profile.rfc }}</p>
        
      </div>
      <div class="col-md-1 text-center p-0" routerLink="dashboard/{{profile.id}}" *ngIf="permissions?.modules.config.profiles.write">
        <div class="hover-info border-start pt-3 pe-4" style="height: 100%;">
          <fa-icon [icon]="icons.faGauge" size="1x" class="center-icon"></fa-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemProfileComponent implements OnInit {
  @Input() profile: any;
  @Input() permissions: any;

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  avatarURL: string = '';

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.store.getProfile(this.profile.id).subscribe((data: any) => {
      console.log("profile", data);
      let el = data.logo;
      this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
    });
    
    
  }

}
