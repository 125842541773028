import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ngxCsv } from 'ngx-csv';
import * as bs from 'bootstrap';

@Component({
  selector: 'app-tanques',
  template: `
    <app-modal-inventory-report [profile]="profile" (createReport)="downloadReport($event)"></app-modal-inventory-report>
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">    
        <div class="row mb-4 ">
          <div class="col-md-6">
            <h1>Tanques</h1>
            <p>Configura los tanques asignados a los edificios.</p>
            <xng-breadcrumb ></xng-breadcrumb>
          </div>
          <div class="col-md-6 text-end mt-2"> 
            <button class="btn btn-outline-info btn-lg rounded-5 shadow me-3 border border-3 border-info" (click)="modalReport()">Reporte inventario</button>
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.operation.combustibles.tanques.write" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar tanque</button>
          </div>
        </div>
        <div class="row mt-4 mb-5">
          <div class="col-md-3">
            <select class="form-select form-select-lg rounded-5 bg-transparent" (change)="selectSettlement($event)">
              <option selected [selected]="true">Condominio</option>
              <option *ngFor="let settlement of settlements" [value]="settlement.id" >{{settlement.name}}</option>
            </select>
          </div>
          <div class="col-md-5 offset-md-4" *ngIf="!searchActive">
            <form [formGroup]="searchBox" (ngSubmit)="searchTanks($event)">
              <input class="form-control form-control-lg rounded-5 bg-transparent" placeholder="Buscar..." formControlName="search">
            </form>
          </div>
          <div class="col-md-5 offset-md-4 text-end" *ngIf="searchActive">
            <button class="btn btn-link text-danger mt-2" (click)="clearFilter()">
              <fa-icon [icon]="icons.faTimes" size="lg" [spin]="loading" class="me-3"></fa-icon>
              Limpiar filtros
            </button>
          </div>
          <div class="col-md-12 mt-5">
            <div class="list-group rounded-4 shadow overflow-hidden" *ngIf="tanques.length>0">
              <div class="list-group-item p-0" *ngFor="let tanque of tanques | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-tanque [tanque]="tanque" [settlements]="settlements" [profile]="profile" [permissions]="permissions"></app-item-tanque>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="tanques.length==0">
            <div class="list-group-item text-center py-5" *ngIf="loading">
              <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
              <p class="text-muted">Cargando...</p>
            </div>
            <div class="list-group-item text-center py-5" *ngIf="!loading">
              <img src="/assets/images/empty-state.png" width="240px">
              <h5 class="text-muted">No se encontraron registros</h5>
            </div>
          </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="tanques.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TanquesComponent implements OnInit {
  loading: boolean = false;
  tanques: any = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  profile: any = {};
  permissions: any = {};

  settlements: any = [];
  buildings: any = [];

  searchActive: boolean = false;

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile ? this.profile.id : 0;
  }

  ngOnInit(): void {
    this.loadTanks(this.payload);
    this.initForm(); 
    this.loadSettlements();
    this.breadcrumbService.set('@tanque', 'Tanques');
  }

  loadTanks(payload: any) {
    this.loading = true;
    this.store.getTanques(payload).subscribe({
      next: (data: any) => {
        this.tanques = data.data;
        this.meta = data.meta;
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se pudieron cargar los tanques', 'Error');
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  loadSettlements() {
    let settlementPayload: any = {
      page: 1,
      per_page: 300,
      utility_id: this.profile.utility_id
    };
    this.store.getSettlements(settlementPayload).subscribe((data: any) => {
      this.settlements = data.data;
    });
  }

  selectSettlement(event: any) {
    let buildingPayload: any = {
      page: 1,
      per_page: 50,
      settlement_id: event.target.value
    };
    
    this.store.getBuildings(buildingPayload).subscribe((data: any) => {
      this.buildings = data.data;
      this.searchActive = true;
    });

    this.store.getTanquesBySettlement(event.target.value).subscribe((data: any) => {
      this.tanques = data;
    });
  }

  selectBuilding(event: any) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      building_id: event.target.value
    };

    this.store.getTanqueByBuilding(event.target.value).subscribe((data: any) => {
      this.tanques = data;
    });
  }

  searchTanks(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadTanks(this.payload);
  }

  clearFilter() {
    this.loadTanks(this.payload);
    this.settlements = [];
    this.loadSettlements();
    // this.buildings = [];
    this.searchActive = false;
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadTanks(this.payload);
  }

  goToTanque(id: number) {
    this.router.navigate(['/tanque', id]);
  }

  createTanque() {
    this.router.navigate(['/tanques/create']);
  }

  modalReport() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalInventoryReport')).show();
  }

  downloadReport(event: any) {
    console.log("DOWNLOAD REPORT => ", event);  

    console.log('GENERATE REPORT');
    this.store.getTanqueReport(this.profile.id, { 
      created_at_since: event.created_at_since,
      created_at_to: event.created_at_to
    }).subscribe((data: any) => {
      console.log("REPORT => ", data);
    
      let options = {
        title: 'Reporte de inventario',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        noDownload: false,
        showTitle: true,
        useBom: true,
        headers: [
          "Nombre",
          "Capacidad",
          "Condominio",
          "Edificio",
          "Lectura",
          "Fecha",
          "Estimación"
        ]
      };

      new ngxCsv(data, 'reporte-inventario', options);
    });
  }

}
