import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { TextService } from 'src/app/shared/services/text.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSkillsComponent } from 'src/app/components/modal-skills/modal-skills.component';
import { ModalToolsComponent } from 'src/app/components/modal-tools/modal-tools.component';
import { ModalVehiclesComponent } from 'src/app/components/modal-vehicles/modal-vehicles.component';
import { ModalWorkScheduleComponent } from 'src/app/components/modal-work-schedule/modal-work-schedule.component';
import { ModalUserPasswordComponent } from 'src/app/components/modal-user-password/modal-user-password.component';
import { ModalUserFormComponent } from 'src/app/components/modal-user-form/modal-user-form.component';
import { debounceTime, distinct, distinctUntilChanged, switchMap } from 'rxjs';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-technicians',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-3">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Técnicos</h1>
          </div>
          <div class="col-md-7 mt-2 text-end">
          <button class="btn btn-outline-secondary rounded-2 border-2" *ngIf="permissions?.modules.config.technicians.write" routerLink="/technicians/create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar técnico
            </button>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-3">
            <input class="form-control bg-transparent" placeholder="Buscar..." [formControl]="searchControl">
            <div class="list-group mt-3">
              <div class="list-group-item list-group-item-action list-group-item-light py-2" 
                *ngFor="let user of technicians | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }" 
                (click)="selectTechnician(user)"
                [ngClass]="user.id == selectedTechnician?.id ? 'active' : ''"
              >
                <p class="mb-0" style="line-height: 1;">{{user.name}} {{user.last_name}}</p>
                <p class="text-muted text-sm mb-0">{{ textService.truncateTextAtWord(user.email, 24)}}</p>
              </div>
            </div>

            <div class="list-group rounded-4 shadow" *ngIf="technicians.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>

            <div class="text-end mt-4" >
              <pagination-controls
                (pageChange)="p = $event"
                (pageChange)="pageChanged($event)"
                previousLabel="Prev."
                nextLabel="Sig."
                screenReaderPaginationLabel="Pagination"
                screenReaderPageLabel="page"
                *ngIf="technicians.length>0"
                class="text-sm"
              ></pagination-controls>
            </div>
          </div>
          <div class="col-md-9 mb-5">
            <div *ngIf="selectedTechnician">
              <div class="list-group" *ngIf="!reportView">
                <div class="list-group-item py-3">
                  <div class="d-flex">
                    <div>
                      {{loadingTechnician}}
                      <h4 class="mb-1"><span *ngIf="summary?.avg">{{summary?.avg | number: '1.2-2' }} </span> <span *ngIf="!summary?.avg"> - </span> <fa-icon [icon]="icons.faStar" class="text-warning"></fa-icon></h4>
                      <!-- p class="mb-1"><span class="ms-0">{{summary?.complete}} Completadas</span> <span class="ms-2">{{summary?.pending}} No completadas</span></!-->
                      <p class="mb-0"><strong>{{selectedTechnician.name}} {{selectedTechnician.last_name}} {{selectedTechnician.last_name_m}}</strong></p>
                      <p class="mb-0">{{selectedTechnician.email}}</p>
                      <p class="mb-0">{{selectedTechnician.phone}}</p>
                      <p class="mb-0">
                        <span *ngIf="selectedTechnician.employee_number">{{selectedTechnician.employee_number}}</span>
                        <span class="text-muted" *ngIf="!selectedTechnician.employee_number">Número de empleado no asignado</span>
                      </p>
                      <p class="mb-0">
                        <span *ngIf="selectedTechnician.licence_type">{{selectedTechnician.licence_type}} - {{selectedTechnician.licence}}</span>
                        <span class="text-muted" *ngIf="!selectedTechnician.licence_type">No se encontró licencia</span>
                      </p>
                    </div>
                    <div class="ms-auto text-end">
                      <button class="btn btn-outline-primary btn-sm" *ngIf="permissions?.modules.config.technicians.write" (click)="reportView=!reportView">Reportes</button><br>
                      <button class="btn btn-outline-secondary btn-sm mt-4" *ngIf="permissions?.modules.config.technicians.write" (click)="updateUser()">Editar</button><br>
                      <button class="btn btn-outline-warning btn-sm mt-3" *ngIf="permissions?.modules.config.technicians.write" (click)="updatePassword()">Cambiar contraseña</button>
                    </div>
                  </div>                      
                </div>
                <div class="list-group-item py-3">
                  <div class="d-flex">
                    <div>
                      <h5>Vehículo Asignado</h5>
                      <p class="mb-0" *ngIf="vehicle">{{vehicle.name}} - {{vehicle.marca}} {{vehicle.modelo}}</p>
                      <p class="text-muted" *ngIf="!vehicle">No se encontró vehículo asignado.</p>
                    </div>
                    <div class="ms-auto">
                      <button class="btn btn-outline-secondary btn-sm" *ngIf="permissions?.modules.config.technicians.write" (click)="addVehicle()">Editar</button>
                    </div>
                  </div>
                  
                  <div class="d-flex">
                    <div class="mt-4">
                      <h5>Habilidades</h5>
                      <ul class="list-unstyled" *ngIf="asignedSkills.length>0">
                        <li *ngFor="let skill of asignedSkills">{{skill?.name}}</li>
                      </ul>
                      <p class="text-muted" *ngIf="asignedSkills.length==0">No se encontraron habilidades asignadas.</p>
                    </div>
                    <div class="ms-auto mt-3">
                      <button class="btn btn-outline-secondary btn-sm" *ngIf="permissions?.modules.config.technicians.write" (click)="addSkills()">Asignar</button>
                    </div>
                  </div>
                  
                  <div class="d-flex">
                    <div class="mt-3">
                      <h5>Herramientas</h5>
                      <ul class="list-unstyled" *ngIf="tools.length>0">
                        <li *ngFor="let tool of tools">{{tool.name}}</li>
                      </ul>
                      <p class="text-muted" *ngIf="tools.length==0">No se encontraron herramientas asignadas.</p>
                    </div>
                    <div class="ms-auto">
                      <button class="btn btn-outline-secondary btn-sm" *ngIf="permissions?.modules.config.technicians.write" (click)="addTools()">Asignar</button>
                    </div>
                  </div>
                  
                </div>
                <div class="list-group-item py-3">
                  <div class="d-flex">
                    <div class="" style="width: 85%;">
                      <h5 class="mt-2">Horario laboral</h5>
                      <table class="table table-sm table-striped w-100" style="font-size: 0.9em;">
                        <tr class="border-bottom">
                          <th colspan="4">Día</th>
                          <th colspan="1" class="text-end">Inicio</th>
                          <th colspan="1" class="text-end">Fin</th>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="4">Lunes</td>
                          <td colspan="1" class="text-end">{{work_schedule?.monday_start ? work_schedule?.monday_start + ':00' : '-'}}</td>
                          <td colspan="1" class="text-end">{{work_schedule?.monday_end ? work_schedule?.monday_end + ':00' : '-'}}</td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="4">Mares</td>
                          <td colspan="1" class="text-end">{{work_schedule?.tuesday_start ? work_schedule?.tuesday_start + ':00' : '-'}}</td>
                          <td colspan="1" class="text-end">{{work_schedule?.tuesday_end ? work_schedule?.tuesday_end + ':00' : '-'}}</td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="4">Miércoles</td>
                          <td colspan="1" class="text-end">{{work_schedule?.wednesday_start ? work_schedule?.wednesday_start + ':00' : '-'}}</td>
                          <td colspan="1" class="text-end">{{work_schedule?.wednesday_end ? work_schedule?.wednesday_end + ':00' : '-'}}</td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="4">Jueves</td>
                          <td colspan="1" class="text-end">{{work_schedule?.thursday_start ? work_schedule?.thursday_start + ':00' : '-'}}</td>
                          <td colspan="1" class="text-end">{{work_schedule?.thursday_end ? work_schedule?.thursday_end + ':00' : '-'}}</td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="4">Viernes</td>
                          <td colspan="1" class="text-end">{{work_schedule?.friday_start ? work_schedule?.friday_start + ':00' : '-'}}</td>
                          <td colspan="1" class="text-end">{{work_schedule?.friday_end ? work_schedule?.friday_end + ':00' : '-'}}</td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="4">Sábado</td>
                          <td colspan="1" class="text-end">{{work_schedule?.saturday_start ? work_schedule?.saturday_start + ':00' : '-'}}</td>
                          <td colspan="1" class="text-end">{{work_schedule?.saturday_end ? work_schedule?.saturday_end + ':00' : '-'}}</td>
                        </tr>
                        <tr class="border-bottom">
                          <td colspan="4">Domingo</td>
                          <td colspan="1" class="text-end">{{work_schedule?.sunday_start ? work_schedule?.sunday_start + ':00' : '-'}}</td>
                          <td colspan="1" class="text-end">{{work_schedule?.sunday_end ? work_schedule?.sunday_end + ':00' : '-'}}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="ms-auto">
                      <button class="btn btn-outline-secondary btn-sm" *ngIf="permissions?.modules.config.technicians.write" (click)="editSchedule()">Editar</button>
                    </div>
                  </div>
                </div>
                <div class="list-group-item py-3">
                  <table class="table table-sm table-borderless w-100" *ngIf="selectedTasks.length>0" style="font-size: 0.9em;">
                    <thead>
                      <th colspan="6" class="text-start">Tarea asignada</th>
                      <th colspan="2" class="text-center">Programada</th>
                      <th colspan="2" class="text-center">Completada</th>             
                      <th colspan="2" class="text-center">Estatus</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let task of selectedTasks | paginate: { itemsPerPage: task_config.per_page, currentPage: task_p, totalItems: tasksMeta.total_entries }">
                        <td colspan="2">
                          <a routerLink="/maintenances/tasks/review/{{task.id}}" *ngIf="task.completed"># {{task.id}}</a>
                          <strong *ngIf="!task.completed"># {{task.id}}</strong>
                        </td>
                        <td colspan="4">{{getBuilder(task).name}}</td>
                        <td colspan="2" class="text-center">{{task?.due_date | date: 'dd/MM/yy'}}</td>
                        <td colspan="2" class="text-center">
                          <span *ngIf="task.completed_at">{{task.completed_at | date: 'dd/MM/yy HH:mm '}}</span>
                          <span *ngIf="!task.completed_at">--</span>
                        </td>
                        <td colspan="2" class="text-end"><span class="badge badge-pill rounded-5 mb-0 px-3 py-1 me-2" [ngStyle]="{ background: getStatus(task).color, color: getStatus(task).text}">{{ getStatus(task).name }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngIf="selectedTasks.length==0" class="text-center">
                    <p class="text-muted mt-2">No se encontraron tareas asignadas.</p>
                  </div>

                  <div class="text-end mt-3" >
                    <pagination-controls
                      (pageChange)="task_p = $event"
                      (pageChange)="taskPageChanged($event)"
                      previousLabel="Prev."
                      nextLabel="Sig."
                      screenReaderPaginationLabel="Pagination"
                      screenReaderPageLabel="page"
                      *ngIf="selectedTasks.length>0"
                      class="text-sm"
                    ></pagination-controls>
                  </div>
                </div>
              </div>

              <div class="list-group" *ngIf="reportView">
                <div class="list-group-item py-3">
                  <div class="d-flex">
                    <div>
                      <h4 class="mb-1"><span *ngIf="summary?.avg">{{summary?.avg | number: '1.2-2' }} </span> <span *ngIf="!summary?.avg"> - </span> <fa-icon [icon]="icons.faStar" class="text-warning"></fa-icon></h4>
                      <p class="mb-1"><span class="ms-0">{{summary?.complete}} Completadas</span> <span class="ms-2">{{summary?.pending}} No completadas</span></p>
                      <p class="mb-0"><strong>{{selectedTechnician.name}} {{selectedTechnician.last_name}}</strong></p>
                      <p class="mb-0">{{selectedTechnician.email}}</p>
                      <p class="mb-0">{{selectedTechnician.phone}}</p>
                    </div>
                    <div class="ms-auto text-end">
                      <button class="btn btn-outline-primary btn-sm" *ngIf="permissions?.modules.config.technicians.write" (click)="reportView=!reportView">Cerrar</button><br>
                    </div>
                  </div>                      
                </div>
                <div class="list-group-item py-3">
                  <div class="d-flex ms-auto mb-4 mt-2">
                    <select class="form-select form-select-sm" (change)="setPeriod($event)">
                      <option value="1">Enero 2024</option>
                      <option value="2">Febrero 2024</option>
                      <option value="3">Marzo 2024</option>
                      <option value="4">Abril 2024</option>
                      <option value="5">Mayo 2024</option>
                      <option value="6">Junio 2024</option>
                      <option value="7">Julio 2024</option>
                      <option value="8">Agosto 2024</option>
                      <option value="9">Septiembre 2024</option>
                      <option value="10">Octubre 2024</option>
                      <option value="11">Noviembre 2024</option>
                      <option value="12">Diciembre 2024</option>
                    </select>
                    <button class="btn btn-outline-secondary ms-3" (click)="search()">Buscar</button>
                  </div>
                  <div class="d-flex">
                    <table class="table table-sm table-striped" style="font-size: 0.9em;">
                      <tr class="border-bottom">
                        <th>#</th>
                        <th>Tarea</th>
                        <th class="text-center">Calificación</th>
                        <th class="text-center">Fecha</th>
                        <th class="text-center">Condominio</th>
                        <th class="text-center">Completada</th>
                      </tr>
                      <tr *ngFor="let grade of grades">
                        <td><a routerLink="/maintenances/tasks/review/{{grade.task?.id}}"># {{grade.task?.id}}</a></td>
                        <td>{{grade.task?.name}}</td>
                        <td class="text-center">{{grade.avg_grade | number : '1.2-2'}}</td>
                        <td>{{grade.task?.due_date | date: 'dd/MM/YY'}}</td>
                        <td class="text-center">{{grade.settlement?.name}}</td>
                        <td class="text-center">{{grade.task?.completed_at | date: 'dd/MM/YY HH:mm' }}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="ms-auto text-end mt-3">
                    <button class="btn btn-outline-primary" [disabled]="grades.length==0 || downloading" (click)="downloadReport()">Generar reporte</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="list-group-item text-center py-5" *ngIf="!selectedTechnician">
              <img src="/assets/images/empty-state.png" width="240px">
              <p class="text-muted">No hay técnico seleccionado</p>
            </div>
          </div>
          <!-- div class="col-md-12 mt-5">
            <div class="list-group rounded-4 shadow">
              <div class="list-group-item bg-light" *ngFor="let user of technicians | paginate: { itemsPerPage: task_config.per_page, currentPage: task_p, totalItems: meta.total_entries }">
                <div class="row">
                  <div class="col-md-1 pt-0 text-center">
                    <app-item-tiny-user [user]="user"></app-item-tiny-user>
                  </div>
                  <div class="col-md-8 mt-1">
                    <h4 class="mb-0">
                      {{user.name}} {{user.last_name}}
                    </h4>
                    <p class="mb-0">
                      <span class="ms-2" style="font-size: 12px;">{{user.email}}</span>
                  </div>
                  <div class="col-md-3 text-end pt-1">
                    <button type="button" routerLink="{{user.id}}" class="btn btn-outline-primary" *ngIf="permissions?.modules.config.technicians.write">
                      <fa-icon [icon]="icons.faEdit" size="1x"></fa-icon>
                      Editar
                    </button>
                    <button type="button" class="btn btn-outline-danger ms-2" (click)="removeUser(user.id)">
                      <fa-icon [icon]="icons.faTrashAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div -->
        </div>          
      </div>
    </div>
    <app-modal-work-schedule [technician]="selectedTechnician" [work_schedule]="work_schedule" [profile]="profile" (updateDismiss)="updateDismissed()"></app-modal-work-schedule>    
    <app-modal-vehicles [technician]="selectedTechnician" [profile]="profile" (vehicleData)="updateVehicle($event)" (updateDismiss)="updateDismissed()"></app-modal-vehicles>
    <app-modal-tools [technician]="selectedTechnician" [profile]="profile" (updateDismiss)="updateDismissed()"></app-modal-tools>
    <app-modal-skills [technician]="selectedTechnician" [profile]="profile" (updateDismiss)="updateDismissed()"></app-modal-skills>
    <app-modal-user-password [user]="selectedTechnician" (updateDismiss)="updateDismissed()"></app-modal-user-password>
    <app-modal-user-form [user]="selectedTechnician" [profile]="profile" (updateDismiss)="updateDismissed()"></app-modal-user-form>
  `,
  styleUrls: []
})
export class TechniciansComponent implements OnInit {

  loading: boolean = false;
  loadingTechnician: boolean = false;
  downloading: boolean = false;
  technicians: any[] = [];
  meta: any = {};
  // searchBox: FormGroup = new FormGroup({});
  searchControl = new FormControl();
  profile: any = {};
  permissions: any = {};

  selectedTechnician: any = null;
  selectedTasks: any[] = [];
  tasksMeta: any = {};
  vehicle: any = {};
  skills: any[] = [];
  asignedSkills: any[] = [];
  tools: any[] = [];
  work_schedule: any = {};

  status: any;
  summary: any = {};

  builders: any[] = [];
  taskCategories: any[] = [];

  grades: any = [];

  payload: any = {
    page: 1,
    per_page: 10,
    role_id: 3
  };

  task_config: any = {
    page: 1,
    per_page: 10
  }

  reportView: boolean = false;

  p: number = 1;
  task_p: number = 1;
  period: number = 1;

  @ViewChild(ModalSkillsComponent, { static: true }) modalSkillsComponent!: ModalSkillsComponent;
  @ViewChild(ModalToolsComponent, { static: true }) modalToolsComponent!: ModalToolsComponent;
  @ViewChild(ModalVehiclesComponent, { static: true }) modalVehiclesComponent!: ModalVehiclesComponent;
  @ViewChild(ModalWorkScheduleComponent, { static: true }) modalWorkScheduleComponent!: ModalWorkScheduleComponent;
  @ViewChild(ModalUserPasswordComponent, { static: true }) modalUserPasswordComponent!: ModalUserPasswordComponent;
  @ViewChild(ModalUserFormComponent, { static: true }) modalUserFormComponent!: ModalUserFormComponent;
  
  constructor(
    private store: SettlementsService,
    private tasks: TasksService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public icons: IconsService,
    private catalog: CatalogsService,
    public textService: TextService,
    private modalService: NgbModal
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit() {    
    this.loadUsers(this.payload);
    this.loadBuilders();

    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(query => this.store.getUsers({ page: 1, per_page: 10, name: query, role_id: 3, profile_id: this.profile.id }))
    ).subscribe((results: any) => {
      this.technicians = results.data;
      this.meta = results.meta;
    });
  }

  getStatus(task: any) {
    return this.catalog.status[task.status];
  }

  getBuilder(task: any) {
    return this.builders.find((builder: any) => builder.id == task.builder_id);
  }

  loadBuilders() {
    this.tasks.getBuilders({ page: 1, per_page: 100}).subscribe((cat: any) => {
      this.builders = cat.data;
      this.taskCategories = cat.data;
    });  
  }

  loadUsers(payload: any) {
    this.loading = true;
    this.store.getUsers(payload).subscribe({
      next: (technicians: any) => {
        console.log(technicians);
        this.technicians = technicians.data;
        this.meta = technicians.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.technicians = [];
        this.loading = false;
      }
    });
  }

  async selectTechnician(technician: any) {
    console.log('selected technician => ', technician);
    this.loadingTechnician = true;
    this.selectedTechnician = technician;
    await this.loadTasks(technician.id);
    await this.loadAsignedVehicle(technician.id);
    await this.loadSkills(technician.id);
    await this.loadTools(technician.id);
    await this.loadStats(technician.id);
    this.loadWorkSchedule(technician.id);

    // this.loadingTechnician = false;
  }

  async loadTasks(id: any) {
    console.log('loadTask event => ', id);
    this.task_config.assignee_id = id;
    this.tasks.getTasks(this.task_config).subscribe((tasks: any) => {
      console.log('tasks => ', tasks);
      this.selectedTasks = tasks.data;
      this.tasksMeta = tasks.meta;
    });
  }

  async loadAsignedVehicle(id: number) {
    this.tasks.getVehicles({ page: 1, per_page: 100, assignee_id: id }).subscribe({
      next: (data: any) => {
        console.log('vehicle => ', data);
        this.vehicle = data.data[0];
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.vehicle = {};
      }
    });
  }

  async loadSkills(id: number) {
    this.tasks.getSkills({ user_id: id }).subscribe({
      next: (data: any) => {
        this.skills = data;
      },
      error: (error: any) => {},
    });

    this.tasks.getUserSkills(id).subscribe({
      next: (data: any) => {
        console.log('asignedSkills => ', data);
        this.asignedSkills = data;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.asignedSkills = [];
      }    
    });
  }

  loadWorkSchedule(id: number) {
    this.tasks.getWorkHour(id).subscribe({
      next: (data: any) => {
        console.log('work_schedule => ', data);
        this.work_schedule = data;
      },
      error: (error: any) => {}
    });
  }

  async loadTools(id: number) {
    this.tasks.getTools({ assignee_id: id }).subscribe({
      next: (data: any) => {
        console.log('tools => ', data.data);
        this.tools = data.data;
      },
      error: (error: any) => {}
    });
  }

  async loadStats(id: number) {
    this.tasks.getTechStatSummary(id).subscribe((summary: any) => {
      console.log('summary => ', summary);
      this.summary = summary;
    });
  }

  loadTechStats() {
    this.tasks.getTechStatsSummary(this.selectedTechnician.id, {
      period: this.period
    }).subscribe(async (data: any) => {
      console.log('summary ==> ', data);
      this.grades = data;
    });
  }

  search() {
    this.loadTechStats();
  }

  pageChanged(event: any) {
    console.log('pageChanged => ', event);
    this.payload.page = event;
    this.loadUsers(this.payload);
  }

  taskPageChanged(event: any) {
    console.log('taskPageChanged => ', event);
    // this.payload.page = event;
    this.task_config.page = event;
    this.loadTasks(this.selectedTechnician.id);
  }

  removeUser(id: number) {
    this.store.destroyUser(id).subscribe({
      next: (data: any) => {
        this.toastr.success('Usuario eliminado correctamente.', 'Éxito');
        this.loadUsers(this.payload);
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al eliminar el usuario.', 'Error');        
      },
    });
  }

  updateVehicle(vehicle: any) {
    this.tasks.updateAssignedVehicle(vehicle.vehicle_id, vehicle).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Vehículo actualizado correctamente.');
        this.loadAsignedVehicle(this.selectedTechnician.id);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar el vehículo');
      }
    });
  }

  setPeriod(event: any) {
    console.log('event ==> ', event.target.value);
    this.period = event.target.value;
  }

  editSchedule() {
    const modalTemplate: any | undefined = this.modalWorkScheduleComponent['modalWorkSchedule']; // Reference modal template in child
    if (modalTemplate) {
      this.modalWorkScheduleComponent.openModal(modalTemplate);
    }
  }

  addTools() {
    const modalTemplate: any | undefined = this.modalToolsComponent['modalTools']; // Reference modal template in child
    if (modalTemplate) {
      this.modalToolsComponent.openModal(modalTemplate);
    }
  }

  addVehicle() {
    const modalTemplate: any | undefined = this.modalVehiclesComponent['modalVehicle']; // Reference modal template in child    
    if (modalTemplate) {
      this.modalVehiclesComponent.openModal(modalTemplate);
    }
  }

  addSkills() {
    const modalTemplate: any | undefined = this.modalSkillsComponent['modalSkills']; // Reference modal template in child    
    if (modalTemplate) {
      this.modalSkillsComponent.openModal(modalTemplate);
    }
	}

  updateUser() {
    const modalTemplate: any | undefined = this.modalUserFormComponent['modalUserForm']; // Reference modal template in child
    if (modalTemplate) {
      this.modalUserFormComponent.openModal(modalTemplate);
    }
  }

  updatePassword() {
    const modalTemplate: any | undefined = this.modalUserPasswordComponent['modalUserPassword']; // Reference modal template in child
    if (modalTemplate) {
      this.modalUserPasswordComponent.openModal(modalTemplate);
    }
  }

  updateDismissed() {
    this.selectTechnician(this.selectedTechnician);
  }

  async downloadReport() {

    this.downloading = true;
    const options = {
      title: 'Reporte de calificaciones de tareas',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      noDownload: false,
      showTitle: true,
      useBom: true,
      headers: ['Tarea', 'Calificación', 'Fecha', 'Condominio', 'Completada']
    };

    const calificaciones: any[] = [];
    await this.grades.map((grade: any) => {
      calificaciones.push({
        'Id': grade.task?.id,
        'Tarea': grade.task?.name,
        'Calificación': grade.avg_grade,
        'Fecha': grade.task?.due_date.split('T')[0],
        'Condominio': grade.settlement?.name,
        'Completada': grade.task?.completed_at
      });
    });

    await new ngxCsv(calificaciones, 'reporte-tecnico', options);
    this.downloading = false;
  }

}
