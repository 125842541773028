import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-invoice-config',
  template: `
    <div #modalInvoiceConfig id="modalInvoiceConfig" class="modal fade" tabindex="-1" aria-labelledby="modalInvoiceConfig" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">Configurar factura</div>
          </div>
          <div class="modal-body p-5">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="invoiceForm" (ngSubmit)="invoiceSave()">
                <div class="form-group required mt-4">
                    <label for="metodoPago" class="form-label ms-2">Método de pago</label>
                    <select class="form-select form-select-lg rounded-4" formControlName="metodoPago">
                      <option value="" selected>Seleccionar...</option>
                      <option *ngFor="let metodo of metodoPago" [value]="metodo.id">{{metodo.metodo}}</option>
                    </select>
                    <div *ngIf="ufc['metodoPago'].touched && ufc['metodoPago'].invalid">
                      <div *ngIf="ufc['metodoPago'].errors?.['required']" class="text-danger text-sm">El método de pago es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mt-3">
                    <label for="formaPago" class="form-label ms-2">Tipo de pago</label>
                    <select class="form-select form-select-lg rounded-4" formControlName="formaPago">
                      <option value="" selected>Seleccionar...</option>
                      <option *ngFor="let tipo of tipoPago" [value]="tipo.id">{{tipo.tipo}}</option>
                    </select>
                    <div *ngIf="ufc['formaPago'].touched && ufc['formaPago'].invalid">
                      <div *ngIf="ufc['formaPago'].errors?.['required']" class="text-danger text-sm">La forma de pago es requerido.</div>
                    </div>
                  </div>                  
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-success btn-lg rounded-5" (click)="invoiceSave()" [disabled]="loading">
            <fa-icon [icon]="icons.faSave" class="me-2" *ngIf="!loading"></fa-icon>
            <fa-icon [icon]="icons.faCircleNotch" *ngIf="loading" [spin]="true"></fa-icon>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalInvoiceConfigComponent {
  @Input() loading: any;
  @Output() saveInvoice = new EventEmitter<any>();

  invoiceForm: FormGroup = new FormGroup({});
  tipoPago: any = [
    { id: '01', tipo: 'Efectivo' },
    { id: '02', tipo: 'Cheque nominativo' },
    { id: '03', tipo: 'Transferencia electrónica de fondos' },
    { id: '04', tipo: 'Tarjeta de crédito' },
    { id: '05', tipo: 'Monedero electrónico' },
    { id: '06', tipo: 'Dinero electrónico' },
    { id: '08', tipo: 'Vales de despensa' },
    { id: '12', tipo: 'Dación en pago' },
    { id: '13', tipo: 'Pago por subrogación' },
    { id: '14', tipo: 'Pago por consignación' },
    { id: '15', tipo: 'Condonación' },
    { id: '17', tipo: 'Compensación' },
    { id: '23', tipo: 'Novación' },
    { id: '24', tipo: 'Confusión' },
    { id: '25', tipo: 'Remisión de deuda' },
    { id: '26', tipo: 'Prescripción o caducidad' },
    { id: '27', tipo: 'A satisfacción del acreedor' },
    { id: '28', tipo: 'Tarjeta de débito' },
    { id: '29', tipo: 'Tarjeta de servicios' },
    { id: '30', tipo: 'Aplicación de anticipos' },
    { id: '99', tipo: 'Por definir' }
  ];
  metodoPago: any = [
    { id: 'PUE', metodo: 'Pago en una sola exhibición' },
    { id: 'PPD', metodo: 'Pago en parcialidades o diferido' }
  ];

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.invoiceForm = this.fb.group({
      metodoPago: ['', Validators.required],
      formaPago: ['', Validators.required],
    });
  }

  get ufc() { return this.invoiceForm.controls; }

  invoiceSave() {
    if (this.invoiceForm.invalid) {
      this.invoiceForm.markAllAsTouched();
      this.toastr.error('Favor de llenar todos los campos requeridos', 'Error');
      return;
    }

    if (this.invoiceForm.value.metodoPago == 'PPD') {

    }

    console.log('Form', this.invoiceForm.value);

    this.saveInvoice.emit(this.invoiceForm.value);
  }

}
