import { Component, OnInit } from '@angular/core';
import { TasksService } from 'src/app/services/tasks.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-activity-create',
  template: `
     <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Actividades</h1>
            <p>Configura las actividades que están disponibles para los servicios técnicos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar actividad</h4>
                    <p>Configura la actividad con los siguientes campos.</p>
                    <app-form-activity (activityData)="createCategory($event)" [profile]="profile" [loading]="loading"></app-form-activity>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ActivityCreateComponent implements OnInit {
  loading: boolean = false;
  profile: any = null;

  constructor(
    private tasks: TasksService,
    private router: Router,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.breadcrumbService.set('@index', 'Mantenimientos');
    this.breadcrumbService.set('@activities', 'Actividades');
    this.breadcrumbService.set('@activityCreate', 'Crear actividad');
  }


  createCategory(event: any) {
    this.tasks.createActivity(event).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha creado la categoría correctamente.', 'Categoría creada');
        this.router.navigateByUrl('/maintenances/activities');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido crear la categoría.', 'Error');
      }
    });
  }
}
