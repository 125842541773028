import { NgAlertBoxService } from 'ng-alert-box-popup';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-item-task',
  template: `
    <div class="row">
      <div class="col-md-3 ps-4 pe-0 py-3">
        <h4 class="mb-0" style="font-size: 17px;">
          <a *ngIf="permissions?.modules.operation.service.tareas.write && task.status != 3 && task.status != 4" routerLink="{{task.id}}">#{{task.id}} {{builder?.name}}</a>
          <span *ngIf="task.status == 3 || task.status == 4">#{{task.id}} {{builder?.name}}</span>
        </h4>
        <p class="mb-1 text-muted" style="font-size: 15px;">{{task.title}}</p>
        <h5>
          <span class="badge badge-pill rounded-5 mb-0 px-3 py-1 me-2" [ngStyle]="{ background: status?.color, color: status?.text}">{{ status?.name }}</span>
          <span class="badge badge-pill rounded-5 mb-0 px-3 py-1" [ngStyle]="{ background: priority?.color}">{{ priority?.name }}</span>
        </h5>
      </div>
      <div class="col-md-2 py-3 px-0">
        <div class="clearfix mb-1">
          <div style="width: 32px !important; height: 32px !important; border-radius: 18px; overflow: hidden;" *ngIf="technician?.avatar" class="float-start bg-dark">
            <img [src]="avatar" width="100%" style="border-radius: 18px;">
          </div>
      
          <div class="float-start ms-2" *ngIf="technician">
            <p class="text-muted text-sm mb-0" style="font-size: 13px; line-height: 1;">#{{technician.employee_number}}</p>
            <p style="font-size: 17px; line-height: 1;" class="mb-0">{{technician.name}} {{technician.last_name}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-2 py-3 px-0">
        <p class="mb-0 text-muted" style="font-size: 12px;">Condominio</p>
        <p class="mb-0" *ngIf="settlement">{{ settlement.name }}</p>
        <p class="mb-0 text-muted" style="font-size: 14px; line-height: 1;" *ngIf="settlement">{{ settlement.address }}, {{settlement.city}}, {{settlement.state}}, {{settlement.zip}}</p>        
      </div>
      <div class="col-md-2 text-center ps-0 pe-3 py-3">
        <p class="mb-0">{{ task.created_at | date: 'dd/MM/yy' }}</p>
      </div>
      <div class="col-md-2 text-center ps-0 pe-3 py-3">
        <p class="mb-0" *ngIf="task.due_date">          
          {{ task.due_date | date: 'dd/MM/yy' }}
          <fa-icon [icon]="icons.faCalendarAlt" size="1x" class="ms-2 text-secondary"></fa-icon>
        </p>
        <p class="mb-0" *ngIf="task.due_date">
          {{ task.due_date | date: 'HH:mm' }}
          <fa-icon [icon]="icons.faClock" size="1x" class="ms-2 text-secondary"></fa-icon>
        </p>
      </div>
      <div class="col-md-1 text-center p-0" *ngIf="permissions?.modules.operation.service.tareas.write">
        <div class="hover-info border-start pt-3 pe-4" style="height: 100%; " *ngIf="task.status===3" routerLink="review/{{task.id}}">
          <fa-icon [icon]="icons.faCalculator" size="1x" class="center-icon"></fa-icon>
        </div>
        <div class="hover-danger border-start pt-3 pe-4" style="height: 100%;" *ngIf="task.status!=3&&task.status!=4" (click)="deleteTask(task)">
          <fa-icon [icon]="icons.faTrashAlt" size="1x" class="center-icon"></fa-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemTaskComponent implements OnInit {
  @Input() task: any = {};
  @Input() builders: any = [];
  @Input() profile: any;
  @Input() permissions: any;
  @Input() technicians: any;
  @Input() settlements: any;
  @Output() taskData = new EventEmitter<any>();

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  priority: any;
  status: any;
  technician: any;
  settlement: any;
  avatar: any = null;
  loadingExtra: boolean = false;
  builder: any = null;

  constructor(
    private toastr: ToastrService,
    private store: SettlementsService,
    private taskService: TasksService,
    private catalog: CatalogsService,
    public icons: IconsService,
    public alerts: NgAlertBoxService
  ) { }

  ngOnInit(): void {
    this.priority = this.catalog.priority[this.task.priority-1];
    this.status = this.catalog.status[this.task.status];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['builders'] && changes['builders'].currentValue) {
      this.builder = changes['builders'].currentValue.find((e: any) => e.id == this.task.builder_id);
    }
    if (changes['technicians'] && changes['technicians'].currentValue) {
      this.technician = changes['technicians'].currentValue.find((e: any) => e.id == this.task.assignee_id);
      if(this.technician && this.technician.avatar) {
        this.loadImg(this.technician.avatar);
      }
    }
    if (changes['settlements'] && changes['settlements'].currentValue) {
      this.settlement = changes['settlements'].currentValue.find((e: any) => e.id == this.task.settlement_id);
    }
  }

  loadImg(img: any) {
    // console.log('IMG', img);
    if (img && img.blob) {
      this.avatar = `${this.apiUrl}/storage/${img.blob.key.substring(0,2)}/${img.blob.key.substring(2,4)}/${img.blob.key}`;
    } else {
      this.avatar = '';
    }
  }

  deleteTask(task: any) {
    // this.alerts.dialog('I','Example Error');
    
    if (confirm(`Confirmar cancelación de la tarea. # ${task.id}`) == true) {
      console.log('Delete Task', task);
      this.taskData.emit(task);
    } else {
      console.log("You canceled!");
    }
  }
}
