import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-form-vehicle',
  template: `
    <form [formGroup]="formVehicle" (ngSubmit)="saveSkill()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="vehicle_type" class="form-label ms-2">Tipo de vehículo</label>
            <select class="form-select form-select-lg rounded-3" formControlName="vehicle_type">
              <option selected disabled>Selecciona un tipo de vehículo</option>
              <option *ngFor="let tipo of vehicleTypes" [value]="tipo.id">{{tipo.name}}</option>
            </select>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="description" class="form-label ms-2">Descripción</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="description" placeholder="" formControlName="description">
            <div *ngIf="ufc['description'].touched && ufc['description'].invalid">
              <div *ngIf="ufc['description'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>

          <div class="form-group mb-4 mt-3">
            <label for="modelo" class="form-label ms-2">Modelo</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="modelo" placeholder="" formControlName="modelo">
            <div *ngIf="ufc['modelo'].touched && ufc['modelo'].invalid">
              <div *ngIf="ufc['modelo'].errors?.['required']" class="text-danger text-sm">La modelo es requerido.</div>
            </div>
          </div>

          <div class="form-group mb-4 mt-3">
            <label for="marca" class="form-label ms-2">Marca</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="marca" placeholder="" formControlName="marca">
            <div *ngIf="ufc['marca'].touched && ufc['marca'].invalid">
              <div *ngIf="ufc['marca'].errors?.['required']" class="text-danger text-sm">La marca es requerido.</div>
            </div>
          </div>

          <div class="form-group mb-4 mt-3">
            <label for="year" class="form-label ms-2">Año</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="year" placeholder="" formControlName="year">
            <div *ngIf="ufc['year'].touched && ufc['year'].invalid">
              <div *ngIf="ufc['year'].errors?.['required']" class="text-danger text-sm">La año es requerido.</div>
            </div>
          </div>

          <div class="form-group mb-4 mt-3">
            <label for="color" class="form-label ms-2">Color</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="color" placeholder="" formControlName="color">
            <div *ngIf="ufc['color'].touched && ufc['color'].invalid">
              <div *ngIf="ufc['color'].errors?.['required']" class="text-danger text-sm">La color es requerido.</div>
            </div>
          </div>

          <div class="form-group mb-4 mt-3">
            <label for="plates" class="form-label ms-2">Placas</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="plates" placeholder="" formControlName="plates">
            <div *ngIf="ufc['plates'].touched && ufc['plates'].invalid">
              <div *ngIf="ufc['plates'].errors?.['required']" class="text-danger text-sm">Las placas es requerido.</div>
            </div>
          </div>
          <!-- div class="form-group required mb-4 mt-3">
            <label for="status" class="form-label ms-2">Estatus</label>
            <select class="form-select form-select-lg rounded-3" formControlName="status">
              <option value="active">Activo</option>
              <option value="inactive">Inactivo</option>
            </select>
            <div *ngIf="ufc['status'].touched && ufc['status'].invalid">
              <div *ngIf="ufc['status'].errors?.['required']" class="text-danger text-sm">El estatus es requerido.</div>
            </div>
          </!-->
          <!-- div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Condominio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="settlement_id" (change)="selectSettlement($event)">
              <option selected>Selecciona un condominio</option>
              <option *ngFor="let settlement of settlements" [value]="settlement.id">{{ settlement.name }}</option>
            </select>
            <fa-icon [icon]="faCircleNotch" size="1x" [spin]="true" class="text-secondary" *ngIf="loading"></fa-icon>
          </div>
          <div class="form-group required mb-4 mt-3" *ngIf="buildings.length>0">
            <label for="name" class="form-label ms-2">Edificio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="building_id">
              <option selected>Selecciona un edificio</option>
              <option *ngFor="let building of buildings" [value]="building.id">{{ building.name }}</option>
            </select>
          </div -->
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/inventory/vehicles">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormVehicleComponent implements OnInit {
  @Input() vehicle: any = {};
  @Input() profile: any = {};
  @Input() loading: boolean = false;
  @Output() vehicleData = new EventEmitter<any>();

  formVehicle: FormGroup = new FormGroup({});

  settlements: any[] = [];
  buildings: any[] = [];

  faCircleNotch = faCircleNotch;

  vehicleTypes: any[] = [
    { id: 1, name: 'Automóvil' },
    { id: 2, name: 'Motocicleta' },
    { id: 3, name: 'Pipa' },
    { id: 4, name: 'Otro' },
  ];

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      console.log(params);
      console.log("VEHICLE profile", this.profile);
      if (this.profile && this.profile) {
        this.formVehicle.patchValue({
          profile_id: this.profile.id
        });
      }
      let payload: any = {
        page: 1,
        per_page: 100,
        tipo: 0
      }
        this.store.getSettlements(payload).subscribe({
          next: (data) => {
            this.settlements = data.data;
          },
          error: (err) => {
            console.log(err);
            this.toastr.error('Ocurrió un error al obtener los condominios.');
          }
        });
    });
  } 

  get ufc() {
    return this.formVehicle.controls;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['vehicle'] && changes['vehicle'].currentValue) {
      this.formVehicle.patchValue(changes['vehicle'].currentValue);
      this.preFill();
    }
    if (changes['profile'] && changes['profile'].currentValue) {
      // console.log("profile", this.profile);
      this.formVehicle.patchValue({
        profile_id: this.profile.id
      });
    }
  }

  initForm() {
    this.formVehicle = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      marca: ['', Validators.required],
      modelo: ['', Validators.required],
      year: ['', Validators.required],
      color: ['', Validators.required],
      plates: ['', Validators.required],
      vehicle_type: ['', Validators.required],
      profile_id: [''],
    });
  }

  preFill() {
    console.log(this.vehicle);
    if (this.vehicle.settlement) {
      console.log("this.vehicle.settlement.id");
      console.log(this.vehicle.settlement);
      this.formVehicle.patchValue(this.vehicle);
      /*this.formVehicle.patchValue({
        settlement_id: this.vehicle.settlement.id,
      });
      this.store.getBuildingsBySettlement(this.vehicle.settlement.id).subscribe({
        next: (data) => {
          console.log(data);
          
          this.buildings = data;
          if (this.vehicle.building) {
            this.formVehicle.patchValue({
              building_id: this.vehicle.building.id,
            });
          }
        },
        error: (err) => {
          console.log(err);
        }
      });*/
    }
  }

  selectSettlement(e: any) {
    console.log('selectSettlement');
    console.log(e.target.value);
    this.store.getBuildingsBySettlement(e.target.value).subscribe({
      next: (data) => {
        console.log(data);
        this.buildings = data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  selectBuilding(e: any) {
    this.formVehicle.patchValue({
      building_id: e.target.value
    });
  }

  saveSkill() {
    console.log(this.formVehicle.value);
    console.log("profile id", this.profile.id);
    this.formVehicle.patchValue({
      profile_id: this.profile.id
    });

    if (this.formVehicle.invalid) {
      this.formVehicle.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }


    this.vehicleData.emit(this.formVehicle.value);    
  }
}
