import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faCircleNotch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBuilding, faPhoneAlt, faMailBulk, faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ToastrService } from 'ngx-toastr';
import { IconsService } from 'src/app/shared/services/icons.service';


@Component({
  selector: 'app-index-settlement',
  template: `
    <div class="col-sm-10 offset-sm-1 col-md-9 offset-md-2 col-lg-7 offset-lg-4 col-xl-7 offset-xl-4 col-xxl-9 offset-xxl-3 mt-3">
      <div class="row mb-4">
        <div class="col-sm-5 offset-sm-1 col-md-5 offset-md-0">
          <h1>Condominios</h1>
          <p>Consulta los condominios.</p>
        </div>
        <div class="col-sm-6 col-md-7 mt-4 text-end">
          <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-2 border-primary" *ngIf="permissions?.modules.admin.settlements.write" routerLink="/condominios/create/condominio">
            <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
            Agregar
          </button>
        </div>
        <div class="col-md-7">
          <h2 class="mb-0"></h2>
        </div>
        <div class="col-md-5">
          <form [formGroup]="searchBox" (ngSubmit)="searchSettlements($event)">
            <input class="form-control form-control-lg rounded-5 border-2 bg-transparent" placeholder="Buscar..." formControlName="search">
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-3 pb-3">
          <div class="list-group rounded-5 shadow" *ngIf="settlements.length > 0">
            <div class="list-group-item p-0" *ngFor="let settlement of settlements | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
              <app-item-settlement [settlement]="settlement" [profile]="profile" [permissions]="permissions"></app-item-settlement>
            </div>
          </div>
          <div class="list-group rounded-5 shadow text-center" *ngIf="settlements.length==0">
            <div *ngIf="loading" class="list-group-item bg-white py-5">
              <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
              <p class="text-muted">Cargando...</p>
            </div>
            <div class="list-group-item text-center py-5" *ngIf="!loading">
              <img src="/assets/images/empty-state.png" width="240px">
              <p class="text-muted">No se encontraron registros</p>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-end mt-3 mb-5" *ngIf="meta.total_pages > 1">
        <pagination-controls
            (pageChange)="p = $event"
            (pageChange)="pageChanged($event)"
            previousLabel="Prev."
            nextLabel="Sig."
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"                  
          ></pagination-controls>
        </div>
      </div>
    </div>    
    <!-- router-outlet></!-->
  `,
  styleUrls: []
})
export class IndexSettlementComponent implements OnInit {
  loading: boolean = false; // signal<boolean>(false);
  settlements: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  payload: any = {
    page: 1,
    per_page: 30,
    tipo: 0
  };

  searchBox: FormGroup = new FormGroup({});
  p: number = 1;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.utility_id = this.profile ? this.profile.utility_id : 0;
  }

  ngOnInit(): void {
    this.loading = true; // .set(true);
    this.loadSettlements(this.payload);
    this.initForm();
    this.breadcrumbService.set('@Parent', 'Condominios');
    this.breadcrumbService.set('@ChildOne', `Editar `);
  }

  loadSettlements(payload: any) {
    this.store.getSettlements(payload).subscribe({
      next: (settlements: any) => {
        this.settlements = settlements.data;
        this.meta = settlements.meta;
        this.loading = false; // .set(false);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.warning('No se encontraron registros', 'No econtrado');
        this.loading = false; // .set(false);
      }
    })
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadSettlements(this.payload);
  }

  searchSettlements(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;

    this.loadSettlements(this.payload);
  }
}
