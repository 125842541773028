import { SettlementsService } from 'src/app/services/settlements.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Floor } from 'src/app/models/floor.model';
import { ToastrService } from 'ngx-toastr';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-building-floors',
  template: `
    <div class="list-group clearfix">
      <div class="list-group-item rounded-4 shadow-sm" style="background-color: #f6f6f6;">
        <div class="row">
          <div class="col-md-8">
            <h3 class="text-dark float-start mt-0 mb-0" *ngIf="!editingFloor">{{floor.name}} <button class="btn btn-link btn-sm" (click)="editFloor(floor)"><fa-icon [icon]="icons.faEdit"></fa-icon></button></h3>
            <input type="text" class="form-control float-start w-50" [value]="floorName" *ngIf="editingFloor" (change)="updateFloor($event)" autofocus>
          </div>
          <div class="col-md-4 text-end">
            <button type="button" class="btn btn-sm btn-outline-danger float-end px-1 py-0 ms-2" (click)="removeFloor(floor)" *ngIf="floor.units.length==0"><fa-icon [icon]="icons.faTrashAlt"></fa-icon></button>
            <button type="button" class="btn btn-sm btn-outline-info float-end px-1 py-0" (click)="plusUnit(floor)"><fa-icon [icon]="icons.faPlus"></fa-icon></button>
          </div>
          <div class="col-md-12 px-1 py-0" *ngIf="floor.units.length>0">
            <div class="list-group rounded-4 bg-light mt-2">
              <div class="list-group-item list-group-item-light ps-2" *ngFor="let unit of floor.units">
                <div class="rounded-3 text-light float-start ms-1 bg-info p-2" style="position: relative;"> <!-- background-color: #A881D0; -->
                  <h4 class="text-center mb-0" style="font-size: 15px; max-width: 80px; min-height: 44px; line-height: 1; word-break: always;">{{unit.name}}</h4>
                </div>
                <div class="float-start ms-2">
                  <div *ngIf="unit.tenants.length>0" style="position: relative;">
                    <div *ngFor="let tenant of unit.tenants; let i = index" style="position: absolute; float: left;" [ngStyle]="{ left: getPos(i)}">
                      <app-item-tiny-tenant [tenant]="tenant"></app-item-tiny-tenant> 
                    </div>
                  </div>
                  <div *ngIf="unit.tenants.length==0">
                  
                  </div>
                </div>
                <div class="float-end pt-2">
                  <li class="nav-item dropdown list-unstyled" ngbDropdown>
                    <a class="nav-link dropdown-toggle dropdown-toggle-alt" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                    <fa-icon [icon]="icons.faEllipsisVertical" class="text-dark"></fa-icon>
                    </a>
                    <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="navbarScrollingDropdown">
                      <li ngbDropdownItem>
                        <a class="dropdown-item" *ngIf="unit.tenants.length==0" (click)="removeUnit(unit)">Eliminar</a>
                      </li>
                      <li ngbDropdownItem>
                        <a class="dropdown-item" routerLink="/{{routing}}/{{settlementId}}/unidad/{{unit.id}}">Editar</a>
                      </li>
                      <!-- li ngbDropdownItem><hr class="dropdown-divider"></!-->
                      <!-- li ngbDropdownItem><a class="dropdown-item" href="#">Something else here</a></!-->
                    </ul>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class BuildingFloorsComponent implements OnInit {
  @Input() floor: any;
  @Input() buildings: any;
  @Input() selectedBuilding: any;
  @Input() settlementId: any;
  @Input() routing: any;
  @Output() addUnit = new EventEmitter<any>();
  @Output() refreshBuilding = new EventEmitter<any>();  

  floorName: string = '';
  editingFloor: boolean = false;  

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.floorName = this.floor.name;
  }

  editFloor(floor: Floor) {
    this.editingFloor = true;
  }

  getPos(index: number) {
    return index * 20 + 'px';
  }

  updateFloor(event: any) {
    this.floor.name = event.target.value;

    this.store.updateFloor(this.floor.id, this.floor).subscribe({
      next: (data: any) => {
        this.editingFloor = false;
        this.toastr.success('Piso actualizado con correctamente.', '¡Éxito!');
      },
      error: (err: any) => {
        this.editingFloor = false;
        this.toastr.error('Piso no pudo ser actualizado.', 'Error');
      }
    });
  }

  removeFloor(floor: Floor) {
    this.store.destroyFloor(floor.id).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Piso eliminado con correctamente.', '¡Éxito!');
        this.refreshBuilding.emit();
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Piso no pudo ser eliminado.', 'Error');
      }
    });
  }

  removeUnit(unit: any) {
    this.store.destroyUnit(unit.id).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Unidad eliminada con correctamente.', '¡Éxito!');
        this.refreshBuilding.emit();
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Unidad no pudo ser eliminada.', 'Error');
      }
    });
  }

  async plusUnit(floor: any) {
    this.addUnit.emit(floor);
  }
}
