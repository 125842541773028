import { Component } from '@angular/core';

@Component({
  selector: 'app-tenant-sign-up',
  templateUrl: './tenant-sign-up.component.html',
  styleUrls: ['./tenant-sign-up.component.scss']
})
export class TenantSignUpComponent {

}
