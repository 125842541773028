import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faCircleNotch, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { ValidateConfirmpass } from '../../components/confirmpass.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-user',
  template: `
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()"> 
      <div class="row">
        <div class="col-md-12">
        <div class="avatar-container text-center">
            <div class="image-container border border-4">
              <img [src]="avatarURL" width="100%">
            </div> 
            <label for="avatar-input-file">
              <div class="icon-wrapper">
                <input type="file" id="avatar-input-file" (change)="loadAvatar($event)" accept="image/*"/>
                <fa-icon [icon]="faEdit" class="text-white"></fa-icon>
              </div>
            </label>
          </div>
          <div class="form-group required mt-4">
            <label for="email" class="form-label ms-2">Correo electrónico</label>
            <input type="text" formControlName="email" class="form-control form-control-lg" id="email" placeholder="">
          </div>
          <div class="form-group required mt-4">
            <label for="name" class="form-label ms-2">Nombre</label>
            <input type="text" formControlName="name" class="form-control form-control-lg" id="name" placeholder="">
          </div>
          <div class="form-group required mt-4">
            <label for="lastnameInput" class="form-label ms-2">Apellidos</label>
            <input type="text" formControlName="last_name" class="form-control form-control-lg" id="last_name" placeholder="">
          </div>

          <div class="form-group required mt-4">
            <label for="role" class="form-label ms-2">Rol</label>
            <select class="form-select form-select-lg" formControlName="role_id" (change)="updateRole($event)">
              <option selected disabled>Selecciona el rol</option>
              <option *ngFor="let role of roles" value="{{role.id}}">{{role.name}}</option>
            </select>
          </div>
          <div class="form-group mt-4" *ngIf="profiles.length>0">
            <label for="role" class="form-label ms-2">Perfil</label>
            <select class="form-select form-select-lg" formControlName="profile_id">
              <option selected disabled>Selecciona el perfil</option>
              <option *ngFor="let profile of profiles" value="{{profile.id}}">{{profile.name}}</option>
            </select>
          </div>
          <!-- div class="form-group mt-4" *ngIf="settlements.length>0">
            <label for="role">Condominio</label>
            <select class="form-select form-select-lg" formControlName="settlement_id">
              <option selected disabled>Selecciona el rol</option>
              <option *ngFor="let settlement of settlements" value="{{settlement.id}}">{{settlement.name}}</option>
            </select>
          </!-->
        </div>
      </div>
      <div class="form-group mt-5 text-end">
        <button type="button" routerLink="/users" class="btn btn-link me-2">Cancelar</button>
        <button type="submit" class="btn btn-success btn-lg rounded-5">
        <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="ms-2 text-dark"></fa-icon>
          Actualizar
        </button>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormUserComponent implements OnInit {
  @Input() user: any = {};
  @Input() roles: any = [];

  @Output() updateUser = new EventEmitter<any>();

  loading: boolean = false;

  userId: number = 0;
  userForm: FormGroup = new FormGroup({});
  avatarURL: any = '';

  imageError: string = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";
  previewImagePath: string = "";

  settlements: any = [];
  profiles: any = [];

  faEdit = faEdit;
  faCircleNotch = faCircleNotch;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  showPassword: boolean = false;

  profile: any = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    console.log("user => ", this.user);
    this.buildForm();
    // this.loadUser();
    this.loadProfiles();
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  get lf() {
    return this.userForm.controls;
  }

  buildForm() {
    this.userForm = this.fb.group({
      id: [''],
      email: ['', Validators.required],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      // password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      // confirm_password: ['', [Validators.required, ValidateConfirmpass]],
      role_id: ['', Validators.required],
      profile_id: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes => ', changes);
    if (changes['user'] && changes['user'].currentValue) {
      this.userForm.patchValue({
        id: changes['user'].currentValue.id,
        avatar: changes['user'].currentValue.avatar,
        email: changes['user'].currentValue.email,
        name: changes['user'].currentValue.name,
        last_name: changes['user'].currentValue.last_name,
        role_id: changes['user'].currentValue.role_id,
        profile_id: changes['user'].currentValue.profile_id,
      });
      this.loadUser(this.userForm);
      console.log(changes['user'].currentValue.role_id);
      if (changes['user'].currentValue.role_id != undefined && changes['user'].currentValue.role_id != 1) {
        // this.loadSettlements();
      }
    }
  }

  loadProfiles() {
    this.store.getProfiles({ page: 1, per_page: 100 }).subscribe((data: any) => {
      this.profiles = data.data;
    });
  }

  loadUser(user: any) {
    if (this.userForm.value.id) {
      this.store.getUser(this.userForm.value.id).subscribe((data: any) => {
        let el = data.avatar;

        this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
      });
    }
  }

  updateRole(event: any) {
    if (event.target.value != 1) {
      // this.loadSettlements();
    }
  }

  /*loadSettlements() {
    let payload: any = {
      page: 1,
      per_page: 30,
      tipo: 0
    }
    this.store.getSettlements(payload).subscribe((data: any) => {
      this.settlements = data;
    });
  }*/

  loadAvatar(event: any): void {
    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            console.log('imgBase64Path => ', imgBase64Path);
            console.log('previewImagePath => ', this.previewImagePath);
            let payload = {
              id: this.userId,
              data: this.previewImagePath
            }
            this.store.updateAvatar(this.userForm.value.id, payload).subscribe((data: any) => {              
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }    
  }

  onSubmit() {
    this.loading = true;

    if (this.userForm.invalid) {
      this.loading = false;
      this.toastr.error('Error', 'Formulario incompleto.');
      return;
    }

    this.userForm.value.password = 'P455W0rd#23';
    this.userForm.value.confirm_password = 'P455W0rd#23';
    
    this.updateUser.emit(this.userForm.value);
    this.loading = false;
  }
}
