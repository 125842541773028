import { Tenant } from './../../../models/tenant.model';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';

import * as pdfMake from 'pdfmake/build/pdfmake';  
import * as pdfFonts from 'pdfmake/build/vfs_fonts'; 
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-tenant-bills',
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-2 mt-sm-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Mis Recibos</h1>
          </div>
          <div class="col-md-7 mt-4 text-end d-none">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/payments/receipts">Generar recibos</!-->
            <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
          </div>
        </div>
        
            <div class="row pt-2">
              <!-- div class="col-md-9"></!-->
              <!-- div class="col-md-3 mb-3">
                <form [formGroup]="searchBox" (ngSubmit)="searchUsers($event)">
                  <input class="form-control form-control-lg rounded-5 bg-transparent" placeholder="Buscar..." formControlName="search">
                </form>
              </!-->
              <div class="col-md-12">
                <div class="d-none d-sm-block">
                  <div class="row">
                    <div class="col-2">
                      <h5 class="ms-3"># Recibo</h5>
                    </div>
                    <div class="col-2 text-center">
                      <h5>Consumo</h5>
                    </div>
                    <div class="col-2 text-center">
                      <h5>Total a pagar</h5>
                    </div>
                    <div class="col-2 text-center">
                      <h5>Creado</h5>
                    </div>
                    <div class="col-1 text-center">
                      <h5>Vence</h5>
                    </div>
                  </div>
                </div>
                <div class="list-group rounded-4 shadow mt-2" *ngIf="bills.length > 0">
                  <div class="list-group-item " *ngFor="let bill of bills | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries}">
                    <app-item-bill
                      [bill]="bill"
                      [profile]="profile"
                      [permissions]="permissions"
                    ></app-item-bill>
                  </div>
                </div>
                <div class="list-group rounded-4 shadow" *ngIf="bills.length==0">
                  <div class="list-group-item text-center p-5" *ngIf="!loading">
                    No existen adeudos pendientes.
                  </div>
                  <div *ngIf="loading" class="text-center m-3">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-5" *ngIf="meta.total_pages > 1">
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"
                  *ngIf="bills.length>0"
                ></pagination-controls>
              </div>
            </div>
          
      </div>
    </div>  
  `,
  styleUrls: []
})
export class TenantBillsComponent implements OnInit {
  bills: any = [];
  tenant: any = {};
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  loading: boolean = false;
  searchBox: FormGroup = new FormGroup({});

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  faTimes = faTimes;
  faCircleNotch = faCircleNotch;

  payload: any = {    
    page: 1,
    per_page: 50,
    status: false,
    state: 1
  };

  p: number = 1;

  constructor (
    private store : SettlementsService,
    private activateRoute: ActivatedRoute,
    private route: Router,
    private fb: FormBuilder
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    // this.payload.profile = this.profile.id;
  }

  ngOnInit(): void {
    this.loadBills();
    this.initForm(); 
  }

  loadBills() {
    this.loading = true;

    this.activateRoute.params.subscribe((params: any) => {
      this.store.getTenant(params['id']).subscribe((data: any) => {
        this.tenant = data;
        this.payload.unit_id = this.tenant.unit_id;
        this.payload.status = false;
        
        this.store.getBills(this.payload).subscribe({
          next: (bills: any) => {
            this.bills = bills.data;
            this.meta = bills.meta;
            this.loading = false;
          },
          error: (error: any) => {
            this.bills = [];
            this.loading = false;
          }
        });
      });
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  searchUsers(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadBills();
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadBills();
  }

  async downloadReceipt(bill: any) {
    let dueDate = new Date(bill.due_date);
    //
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    this.store.getSubscriptions(bill.subscription_id).subscribe((data: any) => {
      // console.log('getSubscription => ', data);      
      this.store.getUnit(data.unit_id).subscribe((unitData: any) => {
        // console.log('getTenantByUnit => ', data);
        this.store.getBuilding(1).subscribe(async (data: any) => {
          // console.log('getBuilding => ', data);
          if (bill.images && bill.images.length > 0) {
          let imageUrl = `${this.apiUrl}/storage/${bill.images[0].blob.key.substring(0,2)}/${bill.images[0].blob.key.substring(2,4)}/${bill.images[0].blob.key}`
          let imageUrl2 = `${this.apiUrl}/storage/${bill.images[1].blob.key.substring(0,2)}/${bill.images[1].blob.key.substring(2,4)}/${bill.images[1].blob.key}`

          await this.getBase64ImageFromUrl(imageUrl)
          .then(result => {
            let blobed = result;
            bill.image1 = blobed;
          })

          await this.getBase64ImageFromUrl(imageUrl2)
          .then(result => {
            let blobed = result;
            bill.image2 = blobed;
          })
          .catch(err => console.error(err));
        }

          const payload = {
            bill: bill,
            dueDate: dueDate,
            unitData: unitData,
            buildingData: data
          };

          this.store.createReceipt(payload).subscribe({
            next: (data: any) => {
              // console.log('createReceipt => ', data);
              const int8Array = new Uint8Array(data.result.data);
              const blob = new Blob([int8Array], { type: 'application/pdf' });
              let fileURL = window.URL.createObjectURL(blob);
              let link=document.createElement('a');
              //
              link.href = fileURL;
              link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
              link.click();
  
              return;
            }, error: (error: any) => {
              console.log('createReceipt ERROR => ', error);
            }
          });
        });
      });
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

}
