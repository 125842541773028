import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-zone',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Zona</h1>
            <p>Crea una zona de distribución.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/services/service">Agregar usuario</!-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                  <app-form-zone [zone]="zone" [roles]="roles" (updateZone)="saveZone($event)"></app-form-zone>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  `,
  styleUrls: []
})
export class EditZoneComponent implements OnInit {
  zoneId: number = 0;
  zone: any = {};
  roles: any = [];

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { 
    this.activateRoute.params.subscribe(params => {
      this.zoneId = params['id'];
      this.loadZone(this.zoneId);
    });

    this.store.getRoles().subscribe({
      next: (data: any) => {
        this.roles = data;
      },
      error: (error: any) => {}
    });
  }

  ngOnInit(): void {}

  loadZone(id: number) {
    this.store.getZone(id).subscribe({
      next: (data: any) => {
        console.log("data => ", data);
        this.zone = data;
        // this.buildForm();
      },
      error: (error: any) => {}
    });
  }

  saveZone(zone: any) {
    console.log('zone!!!! => ', zone);
    this.store.createZone(zone).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Zona creada correctamente.');
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar la zona');
      }
    });
  }

}
