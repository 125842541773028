import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-unit',
  template: `
    <form [formGroup]="unitForm" (ngSubmit)="saveUnit()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre de la unidad</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre de la unidad es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="number" class="form-label ms-2">Clave de la unidad</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="number" placeholder="" formControlName="number">
            <div *ngIf="ufc['number'].touched && ufc['number'].invalid">
              <div *ngIf="ufc['number'].errors?.['required']" class="text-danger text-sm">La clave de la unidad es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="status" class="form-label ms-2">Estado de unidad</label>
            <select class="form-select form-select-lg rounded-3" formControlName="status">
              <option value="0" selected disabled>Selecciona el estado de la unidad</option>
              <option value="1">Activo</option>
              <option value="2">Inactivo</option>
            </select>
          </div>
          <div class="form-group  mb-4">
            <label for="factor" class="form-label ms-2">Factor</label>
            <input type="number" class="form-control form-control-lg" id="factor" placeholder="" formControlName="factor">
            <div *ngIf="ufc['factor'].touched && ufc['factor'].invalid">
              <div *ngIf="ufc['factor'].errors?.['required']" class="text-danger text-sm">El factor es requerido.</div>
            </div>
          </div>
          <div class="form-group  mb-4">
            <label for="admin_price" class="form-label ms-2">Costo administrativo ($ IVA incluido)</label>
            <input type="number" class="form-control form-control-lg" id="admin_price" placeholder="" formControlName="admin_price">
            <div *ngIf="ufc['admin_price'].touched && ufc['admin_price'].invalid">
              <div *ngIf="ufc['admin_price'].errors?.['required']" class="text-danger text-sm">El costo administrativo es requerido.</div>
            </div>
          </div>
          <div class="form-group  mb-4">
            <label for="interests" class="form-label ms-2">Cargos moratorios (%)</label>
            <input type="number" class="form-control form-control-lg" id="interests" placeholder="" formControlName="interests">
            <div *ngIf="ufc['interests'].touched && ufc['interests'].invalid">
              <div *ngIf="ufc['interests'].errors?.['required']" class="text-danger text-sm">El factor es requerido.</div>
            </div>
          </div>
          <div class="form-group  mb-4">
            <label for="reconexion_fee" class="form-label ms-2">Costo de reconexión ($ IVA incluido)</label>
            <input type="number" class="form-control form-control-lg" id="reconexion_fee" placeholder="" formControlName="reconexion_fee">
            <div *ngIf="ufc['reconexion_fee'].touched && ufc['reconexion_fee'].invalid">
              <div *ngIf="ufc['reconexion_fee'].errors?.['required']" class="text-danger text-sm">El costo de reconexión es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="discount" class="form-label ms-2">Descuento por litro ($ IVA incluido)</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="discount" placeholder="" formControlName="discount">
            <div *ngIf="ufc['discount'].touched && ufc['discount'].invalid">
              <div *ngIf="ufc['discount'].errors?.['required']" class="text-danger text-sm">El número de descuento es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="discount" class="form-label ms-2">Periodicidad de recibos</label>
            <select class="form-select form-select-lg" formControlName="periodicity">
              <option value="" disabled>Selecciona una periodicidad</option>
              <option *ngFor="let period of periodicity" [value]="period.id">{{period.name}}</option>
            </select>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="credit" class="form-label ms-2">Días de crédito</label>
            <input type="number" class="form-control form-control-lg" id="credit" placeholder="" formControlName="credit">
            <div *ngIf="ufc['credit'].touched && ufc['credit'].invalid">
              <div *ngIf="ufc['credit'].errors?.['required']" class="text-danger text-sm">El crédito es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="bedrooms" class="form-label ms-2">Habitaciones</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="bedrooms" placeholder="No. de habitaciones" formControlName="bedrooms">
            <div *ngIf="ufc['bedrooms'].touched && ufc['bedrooms'].invalid">
              <div *ngIf="ufc['bedrooms'].errors?.['required']" class="text-danger text-sm">El número de habitaciones es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="bathrooms" class="form-label ms-2">Baños</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="bathrooms" placeholder="No. de baños" formControlName="bathrooms">
            <div *ngIf="ufc['bathrooms'].touched && ufc['bathrooms'].invalid">
              <div *ngIf="ufc['bathrooms'].errors?.['required']" class="text-danger text-sm">El número de baños es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="parking" class="form-label ms-2">Lugares de estacionamiento</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="parking" placeholder="No. de estacionamientos" formControlName="parking">
            <div *ngIf="ufc['parking'].touched && ufc['parking'].invalid">
              <div *ngIf="ufc['parking'].errors?.['required']" class="text-danger text-sm">Los lugares de estacionamiento es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="area" class="form-label ms-2">Área (Mts.<sup>2</sup>)</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="area" placeholder="Superficie" formControlName="area">
            <div *ngIf="ufc['area'].touched && ufc['area'].invalid">
              <div *ngIf="ufc['area'].errors?.['required']" class="text-danger text-sm">El área es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3 d-none">
            <label for="floor_id" class="form-label ms-2">Piso</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="floor_id" placeholder="Piso" formControlName="floor_id">
            <div *ngIf="ufc['floor_id'].touched && ufc['floor_id'].invalid">
              <div *ngIf="ufc['floor_id'].errors?.['required']" class="text-danger text-sm">El piso es requerido.</div>
            </div>
          </div>          
          <div class="form-group mb-4 mt-3">
            <label for="unit_type_id" class="form-label ms-2">Tipo de unidad</label>
            <select class="form-select form-select-lg rounded-3" formControlName="unit_type_id">
              <option value="0" selected disabled>Selecciona un tipo de unidad</option>
              <option value="1">Departamento</option>
              <option value="2">Casa</option>
              <option value="3">Local comercial</option>
              <option value="4">Oficina</option>
            </select>
            <div *ngIf="ufc['unit_type_id'].touched && ufc['unit_type_id'].invalid">
              <div *ngIf="ufc['unit_type_id'].errors?.['required']" class="text-danger text-sm">El tipo de unidad es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3 text-end">
            <!-- button type="button" class="btn btn-link btn-sm me-2" (click)="cancel()">Cancelar</!-->
            <button type="submit" class="btn btn-primary btn-lg rounded-5 px-4 py-2">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormUnitComponent {
  @Input() unit: any = {};
  @Input() floor_id: any;
  @Output() cancelUnit = new EventEmitter<any>();
  @Output() updateUnit = new EventEmitter<any>();

  unitForm: FormGroup = new FormGroup({});

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {
    this.buildUnitForm();
  }

  periodicity: any[] = [
    { id: 1, name: 'Semanal' },
    { id: 2, name: 'Quincenal' },
    { id: 3, name: 'Mensual' },
  ]

  buildUnitForm() {
    this.unitForm = this.fb.group({
      name: ['', Validators.required],
      number: [''],
      bedrooms: [''],
      bathrooms: [''],
      parking: [''],
      area: [''],
      unit_type_id: [''],
      floor_id: ['', Validators.required],
      factor: [''],
      admin_price: [''],
      interests: [''],
      reconexion_fee: [''],
      discount: [''],
      status: [''],
      periodicity: [''],
      credit: ['']
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['unit'] && changes['unit'].currentValue) {
      this.unitForm.patchValue(this.unit);
    }
    if (changes['floor_id'] && changes['floor_id'].currentValue) {
      this.unitForm.get('floor_id')?.setValue(this.floor_id);
    }
  }

  get ufc() {
    return this.unitForm.controls;
  }

  selectUnitType(e: any) {
    this.unitForm.patchValue({
      unit_type_id: e.target.value
    });
  }

  cancel() {
    this.cancelUnit.emit();
  }

  saveUnit() {
    console.log("save unit ", this.unitForm.value);

    if (this.unitForm.invalid) {
      this.unitForm.markAllAsTouched();
      this.toastr.error('Faltan campos por llenar', 'Error');
      return;
    }

    this.updateUnit.emit(this.unitForm.value);
  }
}
