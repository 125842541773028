import { Component } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forgot-password',
  template: `
    <div class="container-fluid p-0">
      <div class="row mt-0 p-0">
        <div class="col-md-4 h-100 bg-dark">a</div>
        <div class="col-md-8 offset-md-0 pt-0 mt-0 text-center bg-white">
          <div class="row mt-5 mb-5"></div>
          <div class="row mt-5 pt-5">
            <div class="col-md-6 offset-md-3">
              <h2 class="mb-5 mt-3">Recuperar contraseña</h2>              
              <div class="form-group">
                <input type="email" class="form-control form-control-lg" placeholder="Correo electrónico" #passwordResetEmail required>
                <p class="text-center mt-2 text-sm">Por favor ingresa tu correo electrónico para solicitar una nueva contraseña.</p>
              </div>        
              <div class="form-group mt-5">
                <button type="type" class="btn btn-dark btn-lg w-100 rounded-0 pt-3 pb-3" (click)="resetPassword(passwordResetEmail.value)">
                  Solicitar contraseña
                </button>
              </div>
              <div class="redirectToLogin mt-4 mb-2">
                <span>¿Regresar? <span class="redirect text-primary" routerLink="/sign-in"><u>Iniciar sesión</u></span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
`,
  styleUrls: []
})
export class ForgotPasswordComponent {
  constructor(
    public authService: AuthService,
    private store : SettlementsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {}

  resetPassword(email: string) {
    this.store.recoverPassword(email).subscribe({
      next: (data: any) => {      
        console.log(data);
        this.toastr.success('Correo enviado exitosamente.', 'Éxito');
        this.router.navigate(['/sign-in']);
      },
      error: (err: any) => {
        // this.toastr.error(err.error.message, 'Error');
        this.toastr.success('Correo enviado exitosamente.', 'Éxito');
        this.router.navigate(['/sign-in']);
      },
    });
  }
}

