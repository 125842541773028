import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-bar-chart-consumptions',
  template: `
    <figure id="bar-consumptions"></figure>
  `,
  styleUrls: []
})
export class BarChartConsumptionsComponent implements OnInit {
  @Input() data: any;
  
  private svg: any;
  private margin = 32;
  private width = 340 - (this.margin * 2);
  private height = 280 - (this.margin * 2);

  public yScale: any;
  public xScale: any;

  constructor() { }

  ngOnInit(): void {
    console.log('INIT BAR CHART CONSUMPTIONS')
    this.createSvg();
    this.drawChart(this.data);
  }

  private createSvg(): void {
    this.svg = d3.select("figure#bar-consumptions")
    .append("svg")
    .attr("width", '100%')
    .attr("height", this.height + (this.margin * 2))
    .append("g")
    .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
  }

  simpleGroup(array: any[], key: string) {
    const groupedData = array.reduce((acc, obj) => {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(obj);
      return acc;
    }, {});
  
    return { keys: Object.keys(groupedData), data: groupedData };
  }

  groupBy(array1: any[], array2: any[], key: string) {
    return array1.reduce((acc, obj) => {
        const groupKey = obj[key];
        const correspondingObj = array2.find(item => item[key] === groupKey);
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }
        const combinedObj = { ...obj, ...correspondingObj };
        // delete combinedObj[key];
        acc[groupKey].push(combinedObj);
        return acc;
    }, {});
  }

  private drawChart(data: any): void {
    console.log("BAR CHART data ====> ", data);
    let dates = data.consumptions.map((d: any) => {
      let dac: any = {
        "liters": d["liters"],
        "month": d["month"],
        "year": d["year"],
        "week": d["week"],
        "tanque": d["tanque_id"],
      };
      return dac; 
    });

    let readings = data.readings.map((d: any) => {
      let dac: any = {
        "litersRead": d["litersread"],
        "month": d["month"],
        "year": d["year"],
        "week": d["week"],
        "tanque": d["tanque_id"],
      };
      return dac; 
    });

    console.log('DATES ======> ', dates);
    console.log('READINGS ======> ', readings);

    const groupedData = this.groupBy(readings, dates, 'week');
    console.log('GROUPED DATA ======> ', groupedData);
    const groupdWeeks = this.simpleGroup(readings, 'week');
    console.log('GROUPED WEEKS ======> ', groupdWeeks);
    let color = d3.scaleOrdinal(d3.schemeCategory10);
    var subgroups = ['litersRead', 'liters'];

    // this.xScale.domain(dates.map(function(d) { return d.week; }));
    // this.yScale.domain([0, d3.max(dates, function(d) { return d.liters; })]);
  

    const x = d3.scaleBand()
      .range([0, this.width])
      // .domain(d3.groupSort(data, ([d]) => -d.Stars, (d) => d.Framework))
      .domain(dates.map((d: any) => d.week))
      .padding(0.2);

    // Draw the X-axis on the DOM
    this.svg.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end");

    var xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding(0.05);

    // Create the Y-axis band scale
    const y = d3.scaleLinear()
      // .domain(subgroups.map((key) => d3.max(groupedData, (d: any) => d[key])))
      .domain([0, 10000]) // d3.max(dates, (d: any) => d.liters)
      .range([this.height, 0]);

    // Draw the Y-axis on the DOM
    this.svg.append("g")
      // .call(d3.axisLeft(y))
      .call(d3.axisLeft(y).tickFormat(function(d: any){
        return `${(parseInt(d)/1000)} K`;
      }).ticks(5));

    console.log('dates => ', dates);

    const ctl = this;

    var tooltip = d3.select("figure#bar-consumptions")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10000")
      .style("visibility", "hidden")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px");

    groupdWeeks.keys.forEach((key: any) => {
      console.log('KEY ====> ', groupedData[key]);
      this.svg.append("g")
        .selectAll("g")
        .data(groupedData[key])
        .enter()
        .append("g")
          .attr("transform", function(d: any) { 
            console.log("d ====> ", d);
            return "translate(" + x(d.week) + ",0)"; 
          })
        .selectAll("rect")
        .data(function(d: any) { return subgroups.map((key) => { return {key: key, value: d[key]}; }); })
        .enter().append("rect")
          .attr("x", function(d: any) { return xSubgroup(d.key); })
          .attr("y", function(d: any) { return y(d.value); })
          .attr("width", xSubgroup.bandwidth())
          .attr("height", function(d: any) { return ctl.height - y(d.value); })
          .attr("fill", function(d: any) { return color(d.key); })
          .on("mouseover", (d: any, i: any) => {
            console.log('MOUSEOVER i ===> ', i);
            console.log('MOUSEOVER d ===> ', d);
            let tipo: any = i.key === 'liters' ? 'Consumos' : 'Lecturas';
            tooltip
              .text(`${tipo} ${i.value.toFixed(2)} lts.`)
              .style("visibility", "visible")
              .style('top', (d.offsetY - 10) + 'px')
              .style('left', (d.offsetX + 10) + 'px');
          })
          .on("mouseout", (d: any) => {
            tooltip.style("visibility", "hidden");
          });
    });
    // Create and fill the bars
    // this.svg.selectAll()
      // .data(dates)
      // .enter()
      // .append("rect")
      // .attr("x", (d: any) => x(d.week))
      // .attr("y", (d: any) => y(d.liters))
      // .attr("width", x.bandwidth())
      // .attr("height", (d: any) => this.height - y(d.liters))
      // .attr("fill", 'orange');

    // this.svg.selectAll()
      // .data(readings)
      // .enter()
      // .append("rect")
      // .attr("x", (d: any) => x(d.week))
      // .attr("y", (d: any) => y(d.liters))
      // .attr("width", x.bandwidth())
      // .attr("height", (d: any) => this.height - y(d.liters))
      // .attr('fill', 'red')
  }

}
