import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-form-amenity',
  template: `
    <form [formGroup]="formAmenity" (ngSubmit)="saveAmenity()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre de la amenidad</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="description" class="form-label ms-2">Descripción</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="description" placeholder="" formControlName="description">
            <div *ngIf="ufc['description'].touched && ufc['description'].invalid">
              <div *ngIf="ufc['description'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="status" class="form-label ms-2">Estatus</label>
            <select class="form-select form-select-lg rounded-3" formControlName="status">
              <option value="active">Activo</option>
              <option value="inactive">Inactivo</option>
            </select>
            <!-- input type="text" class="form-control form-control-lg rounded-3" id="status" placeholder="" formControlName="status" -->
            <div *ngIf="ufc['status'].touched && ufc['status'].invalid">
              <div *ngIf="ufc['status'].errors?.['required']" class="text-danger text-sm">El estatus es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Condominio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="settlement_id" (change)="selectSettlement($event)">
              <option selected>Selecciona un condominio</option>
              <option *ngFor="let settlement of settlements" [value]="settlement.id">{{ settlement.name }}</option>
            </select>
            <fa-icon [icon]="faCircleNotch" size="1x" [spin]="true" class="text-secondary" *ngIf="loading"></fa-icon>
          </div>
          <div class="form-group required mb-4 mt-3" *ngIf="buildings.length>0">
            <label for="name" class="form-label ms-2">Edificio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="building_id">
              <option selected>Selecciona un edificio</option>
              <option *ngFor="let building of buildings" [value]="building.id">{{ building.name }}</option>
            </select>
          </div>
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/amenities">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormAmenityComponent {
  @Input() amenity: any = {};
  @Input() loading: boolean = false;
  @Output() amenityData = new EventEmitter<any>();

  formAmenity: FormGroup = new FormGroup({});

  settlements: any[] = [];
  buildings: any[] = [];

  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      console.log(params);
      let payload: any = {
        page: 1,
        per_page: 100,
        tipo: 0
      }
        this.store.getSettlements(payload).subscribe({
          next: (data) => {
            this.settlements = data.data;
          },
          error: (err) => {
            console.log(err);
            this.toastr.error('Ocurrió un error al obtener los condominios.');
          }
        });
    });
  } 

  get ufc() {
    return this.formAmenity.controls;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['amenity'].currentValue) {
      this.formAmenity.patchValue(changes['amenity'].currentValue);
      this.preFill();
    }
  }

  initForm() {
    this.formAmenity = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      status: ['', Validators.required],
      building_id: ['', Validators.required],
      settlement_id: [''],
    });
  }

  preFill() {
    console.log(this.amenity);
    if (this.amenity.settlement) {
      console.log("this.amenity.settlement.id");
      console.log(this.amenity.settlement);
      this.formAmenity.patchValue({
        settlement_id: this.amenity.settlement.id
      });
      this.store.getBuildingsBySettlement(this.amenity.settlement.id).subscribe({
        next: (data) => {
          console.log(data);
          
          this.buildings = data;
          if (this.amenity.building) {
            this.formAmenity.patchValue({
              building_id: this.amenity.building.id
            });
          }
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
  }

  selectSettlement(e: any) {
    console.log('selectSettlement');
    console.log(e.target.value);
    this.store.getBuildingsBySettlement(e.target.value).subscribe({
      next: (data) => {
        console.log(data);
        this.buildings = data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  selectBuilding(e: any) {
    this.formAmenity.patchValue({
      building_id: e.target.value
    });
  }

  saveAmenity() {
    console.log(this.formAmenity.value);

    if (this.formAmenity.invalid) {
      this.formAmenity.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.amenityData.emit(this.formAmenity.value);    
  }

}
