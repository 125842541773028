import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-set-price',
  template: `
    <div #modalSetPrice id="modalSetPrice" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalSetPrice" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Precio por litro</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body pt-3 pb-3">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="formPrice">
                  <div class="form-group">
                    <label for="price">Precio</label>
                    <input type="number" class="form-control form-control-lg rounded-3" formControlName="price">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveData()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalSetPriceComponent implements OnInit {
  @Input() refuelList: any[] = [];
  @Output() priceData = new EventEmitter<any>();

  faTimes = faTimes;

  formPrice: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formPrice = this.fb.group({
      price: ['']
    });
  }

  saveData() {
    this.priceData.emit(this.formPrice.value);
  }
}
