import { Component, Input, OnInit, Output, SimpleChange, SimpleChanges, EventEmitter, ViewChild, ElementRef, inject, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { IconsService } from 'src/app/shared/services/icons.service';
// import * as bs from 'bootstrap';

@Component({
  selector: 'app-modal-skills',
  template: `
  <ng-template #modalSkills let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Habilidades</h5>
      <button type="button" (click)="dismiss()" data-dismiss="modal" aria-label="Close" class="close btn btn-link text-dark">
        <fa-icon [icon]="icons.faTimes"></fa-icon>
      </button>
    </div>
    <div class="modal-body py-3">
      <div class="list-group shadow-sm" *ngIf="skills.length>0">
        <div class="list-group-item" *ngFor="let skill of skills">
          <div class="form-check form-switch ps-1">
            {{skillSelections[skill]}}
            <input
              class="form-check-input float-end"
              type="checkbox"
              [checked]="skillSelections[skill]"
              [value]="skill.id"
              (change)="toggleSkill($event)"
            >
            <label class="form-check-label" for="flexCheckDefault">
              {{skill.id}} - {{skill.name}}
            </label>
          </div>
        </div>              
      </div>
      <div class="list-group shadow-sm" *ngIf="skills.length==0">
        <div class="list-group-item">
          <p class="mb-0 text-center text-muted">No hay habilidades disponibles</p>
        </div>
      </div>
    </div>
  </ng-template>
  `,
  styleUrls: []
})
export class ModalSkillsComponent implements OnInit {
  @Input() technician: any;
  @Input() profile: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalSkills') modalSkills !: ElementRef;

  private modalRef: NgbModalRef | undefined;

  skills: any = [];
  asigned_skills: any = [];

  skillSelections: { [key: string]: boolean } = {};

  constructor(
    private toastr: ToastrService,
    private tasks: TasksService,
    public icons: IconsService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.loadSkills();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['technician'] && changes['technician'].currentValue) {
      this.loadAsignedSkills();
    }
  }

  loadSkills() {
    this.tasks.getSkills({ profile_id: this.profile.id }).subscribe({
      next: (resp: any) => {
        this.skills = resp.data;
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error al obtener las habilidades.');
        console.log(err);
      }
    });
  }

  loadAsignedSkills() {
    if (!this.technician.id) return;
    this.tasks.getUserSkills(this.technician.id).subscribe({
      next: (resp: any) => {
        this.asigned_skills = resp;


        this.skills.map((skill: any) => {
          console.log('skill =>> ', skill);
          console.log('SKILL ', this.asigned_skills.includes(skill) );
          this.skillSelections[skill] = this.asigned_skills.includes(skill);
          // skill.checked = assigned_skills.id === skill.id
        });
        
        // this.asigned_skills.map((skill_asigned: any) => {
          // let wtf = this.skills.filter((skill: any) => skill.id == this.asigned_skills.skill_id);
          // console.log('SKILLS ', wtf);
          
          // this.skills.map((skill: any) => {
            // console.log('SKILL ASIGNED ', skill.id, skill.id == skill_asigned.skill_id); 
            // // console.log('SKILLS ', );
            // skill.checked = skill.id == skill_asigned.skill_id ? true : false;
          // });
        // });
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error al obtener las habilidades.');
        console.log(err);
      }
    });
  }

  toggleSkill(event: any) {
    let currSkill: any = this.skills.filter((x: any) => x.id == event.target.value)[0];
    // console.log("TOGGLE SKILL ", currSkill);
    if (currSkill.checked) {
      this.removeSkill(currSkill);
    } else {
      this.addSkill(currSkill);
    }
  }

  addSkill(currSkill: any) {
    this.tasks.addUserSkill({ user_id: this.technician.id, skill_id: currSkill.id }).subscribe({
      next: (resp: any) => {
        currSkill.checked = !currSkill.checked;
        this.toastr.success('Se agregó la habilidad correctamente.');        
        this.dismiss();
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error al agregar la habilidad.');
        console.log(err);
      }
    });
  }

  removeSkill(currSkill: any) {
    // console.log("REMOVE SKILL ", currSkill)
    this.tasks.removeUserSkill({ user_id: this.technician.id, skill_id: currSkill.id }).subscribe({
      next: (resp: any) => {
        console.log(resp);
        this.toastr.success('Se removió la habilidad correctamente.');
        this.dismiss();
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error al eliminar la habilidad.');
        console.log(err);
      }
    });
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
