export const environment = {
    production: true,
    firebaseConfig: {
      apiKey: "AIzaSyCGBjBAY32pbHXomeQCgfPMJuEJF-yBAC8",
      authDomain: "building-mgmt.firebaseapp.com",
      projectId: "building-mgmt",
      storageBucket: "building-mgmt.appspot.com",
      messagingSenderId: "93034737699",
      appId: "1:93034737699:web:95309be5d7c5fefe890196",
      measurementId: "G-SC3REBQ844",
      vapidKey: 'BPqmkFgCu6FdazssGx8HxDhOrUf7ZxV7nPlALN2T3gLpWQPUCGnCLu-fa6HgQrXSMDgYeAoN9wduTdN3tdw0i6c'
    },
    app_version: '1.5.1',
    api_v: 'api/v1',
    imgURL: 'https://api.zazz.mx',
    SUPPORT_REST_WSPREFIX: 'https://srv.iomx.co/droid/message',
    AWS_REST_WSPREFIX: 'https://api.zazz.mx',
    TASK_REST_WSPREFIX: 'https://tasks.zazz.mx',
    RECIBOS_REST_WSPREFIX: 'https://receipt.zazz.mx',
    MAFIGUE_TOKEN_CC: 'vn8XyROxQWnZAiu2',
    MAFIGUE_TOKEN_CA: 'AF55E7D6A8F276D8', // "DB259B805956A146",
    MAFIGUE_MERCHANT: 'mndaqdcv6r1egc0iwqvv',
    MAFIGUE_PK: 'pk_80bb3562ce454aca8e38cbb75ebcaefc',
    AMRON_TOKEN_CC: 'vn8XyROxQWnZAiu2',
    AMRON_TOKEN_CA: '122979FFFCCD4539',
    AMRON_MERCHANT: 'mj4hm2lqliumfnokyjvo',
    AMRON_PK: 'pk_19c34873008f4c37b933925ee2273631',
    AMRON_DEFAULT_CUS: 'abe7zkle8ofrfaflofig',
    ANKER_TOKEN_CC: 'vn8XyROxQWnZAiu2',
    ANKER_TOKEN_CA: 'AEC898F493DC8587',
    ANKER_MERCHANT: '',
    ANKER_PK: '',
    JIMESA_TOKEN_CC: 'vn8XyROxQWnZAiu2',
    JIMESA_TOKEN_CA: 'AF55E7D6A8F276D8',
    JIMESA_MERCHANT: '',
    JIMESA_PK: '',
    JIMESA_DEFAULT_CUS: '',
    CENTURION_TOKEN_CC: '',
    CENTURION_TOKEN_CA: '',
    CENTURION_MERCHANT: '',
    CENTURION_PK: '',
    CENTURION_DEFAULT_CUS: '',
    MAPBOX_TOKEN: 'pk.eyJ1IjoicmljaG9sb25hIiwiYSI6ImNsM24wb3dwdDA5dmczZHBhb3BiYmRnZGYifQ.D0tt4iTHsGf4riM2cazCog',
  };