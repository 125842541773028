import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-invoice-complement',
  template: `
    <ng-template #modalInvoiceComplement let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Complemento de pago</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
          <fa-icon [icon]="icons.faTimes"></fa-icon>
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="list-group">
          <div class="list-group-item py-3">
          <table class="table table-sm mb-3" style="font-size: 0.8em;">
              <thead>
                <tr>
                  <th>Qty.</th>
                  <th class="text-center">Clave</th>
                  <th class="text-center">Importe</th>
                  <th class="text-end">Valor Unitario</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let concepto of conceptos">
                  <td>{{concepto['$']['Cantidad']}}</td>
                  <td class="text-center">{{concepto['$']['ClaveProdServ']}}</td>
                  <td class="text-center">{{concepto['$']['Importe'] | currency}}</td>
                  <td class="text-end">{{concepto['$']['ValorUnitario'] | currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="list-group-item py-3">
            <form [formGroup]="invoiceComplementForm" (ngSubmit)="createComplement()">
              <div class="form-group">
                <label for="payment_date" class="form-label text-sm">Fecha de pago</label>
                <input type="date" class="form-control" formControlName="payment_date">
              </div>
              <div class="form-group text-end mt-4">
                <button type="submit" class="btn btn-sm btn-outline-success" [disabled]="loading">
                  <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="me-2"></fa-icon>
                  <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="loading" class="me-2"></fa-icon>
                  Generar complemento
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: []
})
export class ModalInvoiceComplementComponent implements OnInit {
  @Input() profile: any;
  @Input() invoice: any;
  @Input() bill: any;
  @Input() conceptos: any;
  @Input() receptor: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalInvoiceComplement') modalInvoiceComplement !: ElementRef;

  private modalRef: NgbModalRef | undefined;

  apiUrl: string = 'https://api.zazz.mx'; //environment.AWS_REST_WSPREFIX;
  loading: boolean = false;
  invoiceData: any = {};
  invoiceComplementForm: FormGroup = new FormGroup({});

  utilityData: any = {};

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public icons: IconsService
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!).profile;
    this.buildInvoiceComplementForm();
  }

  ngOnInit(): void {
    
  }

  buildInvoiceComplementForm() {
    this.invoiceComplementForm = this.fb.group({
      // name: ['', [Validators.required]],
      // clave: ['', [Validators.required]],
      payment_date: ['', [Validators.required]],
    });
  }

  createComplement() {
    this.loading = true;
    if (this.invoiceComplementForm.invalid) {
      this.invoiceComplementForm.markAllAsTouched();
      this.toastr.error('Por favor, completa el formulario.');
      this.loading = false;
      return;
    }
    this.invoiceComplementForm.value;

    let complemento: any = {
      "PagosV20": {
        "Pagos": [
          {
            "FechaPago": `${this.invoiceComplementForm.value.payment_date}T00:00:00`,
            "FormaPago": "03",
            "Moneda": "MXN",
            "DocumentosRelacionados": [
              {
                  "IdDocumento": this.invoice.uuid,
                  "Moneda": "MXN",
                  "NumeroParcialidad": 1,
                  "ObjetoDeImpuesto": "02",
                  "ImporteSaldoAnterior": this.bill.total.toFixed(2),
                  "ImportePagado": this.bill.total.toFixed(2),
                  "ImporteSaldoInsoluto": "0",
                  "Impuestos": {
                    "Trasladados": [
                        {
                          "Impuesto": "2",
                          "Factor": "1",
                          "Tasa": '0.160000',
                          "Base": this.bill.sub_total.toFixed(2),
                          "Importe": this.bill.taxes.toFixed(2)
                        }
                    ]
                  }
              }
            ],
            "Impuestos": {
              "Trasladados": [
                  {
                    "Impuesto": "2",
                    "Factor": "1",
                    "Tasa": '0.160000',
                    "Base": this.bill.sub_total.toFixed(2),
                    "Importe": this.bill.taxes.toFixed(2)
                  }
              ]
            }
          }
        ],
         "Totales":{
            "TotalTrasladosBaseIVA16": this.bill.sub_total.toFixed(2),
            "TotalTrasladosImpuestoIVA16": this.bill.taxes.toFixed(2),
            "MontoTotalPagos": this.bill.total.toFixed(2),
         }
      },
      "TipoComplemento":6
    }

    console.log('RECEPTOR => ', this.receptor);
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const tn = tzoffset + 3600000;
    const localISOTime = (new Date(Date.now() - tn)).toISOString();

    let payload: any = {
      Encabezado: {
        "Fecha": localISOTime.slice(0,19),
        "Serie": this.invoice.serie,
        "Folio": this.invoice.folio,
        "MetodoPago":"",
        "FormaPago":"",
        "Moneda":"",
        "LugarExpedicion":"26015",
        "SubTotal": "0",
        "Total": "0",
        "Receptor": {
          "RFC": this.receptor['Rfc'],
          "NombreRazonSocial": this.receptor['Nombre'],
          "RegimenFiscal": this.receptor['RegimenFiscalReceptor'],
          "UsoCFDI": "",
          "Direccion":{
            "Calle":"",
            "NumeroExterior":"",
            "NumeroInterior":"",
            "Colonia":"",
            "Localidad":"",
            "Municipio":"",
            "Estado":"",
            "Pais":"Mexico",
            "CodigoPostal": this.receptor['DomicilioFiscalReceptor']
         }
        }
      },
      Complemento: complemento,
      complemento: {
        serie: 'C',
        folio: this.bill.id,
        related_invoice: this.invoice.id,        
      },
      profile_id: this.profile.id,
    }

    console.log('PAYLOAD =====> ', payload);

    this.store.createInvoiceComplement(payload).subscribe({
      next: (res: any) => {
        console.log(res);
        this.invoiceData = res;
        this.toastr.success('Complemento generado correctamente.', 'Éxito');        
        this.download();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al generar el complemento.', 'Error');
        this.loading = false;
      }
    });
  }

  download() {
    console.log('download ====> ', this.bill);

    let dueDate = new Date(this.bill.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    this.store.getSubscriptions(this.bill.subscription_id).subscribe((data: any) => {
      console.log("SS ==> ", data);
      this.store.getUnit(data.unit_id).subscribe(async (unitData: any) => {
        console.log('unitData => ', unitData);
        this.store.getBuilding(1).subscribe(async (data: any) => {
          // if (this.bill.images && this.bill.images.length > 0) {
          /*if (this.bill.medidor){
            let imageUrl = `${this.apiUrl}/storage/${this.bill.medidor.blob.key.substring(0,2)}/${this.bill.medidor.blob.key.substring(2,4)}/${this.bill.medidor.blob.key}`
            // let imageUrl2 = `${this.apiUrl}/storage/${this.bill.image_prev[1].blob.key.substring(0,2)}/${this.bill.image_prev[1].blob.key.substring(2,4)}/${this.bill.image_prev[1].blob.key}`

            await this.getBase64ImageFromUrl(imageUrl)
            .then(result => {
              let blobed = result;
              this.bill.image = blobed;
              this.bill.image_prev = blobed;
              console.log("BLOBED => ", blobed);
            });

          }*/


          const payload = {
            bill: this.bill,
            dueDate: dueDate,
            unitData: unitData,
            buildingData: data,
            invoiceData: this.invoiceData,
            utilityData: this.profile,
          };
  
          this.store.downloadComplement(payload).subscribe({
            next: (data: any) => {
              const int8Array = new Uint8Array(data.result.data);
              const blob = new Blob([int8Array], { type: 'application/pdf' });
  
              var fileURL = window.URL.createObjectURL(blob);
              var link=document.createElement('a');

              link.href = fileURL;
              link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
              link.click();

              const blob2 = new Blob([this.invoiceData.cfdixml], { type: 'application/xml' });

              var fileURL2 = window.URL.createObjectURL(blob2);
              var link2=document.createElement('a');

              link2.href = fileURL2;
              link2.download = `${this.invoiceData.serie}_${this.invoiceData.folio}.xml`;
              link2.click();

              this.store.updateInvoice(this.invoiceData.id, { pdf: data.filename }).subscribe((data: any) => {
                console.log('updateInvoice => ', data);
                this.loading = false;
                this.dismiss();
                // window.location.reload();
              });
  
              return;
            }, error: (error: any) => {
              console.log('createReceipt ERROR => ', error);
              this.loading = false;
              // window.location.reload();
            }
          });
        });
      });
    });
  }

  /*async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    console.log("BLOB => ", blob);
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }*/

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }

}
