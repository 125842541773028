import { faEdit, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tenant-users',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Inquilinos <span class="text-muted" style="font-size: 0.5em;">({{meta?.total_entries}})</span></h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-filter-full
              [profile]="profile"
              [toDate]="toDate"
              [fromDate]="fromDate"
              [payload]="payFilterPrefs"
              (billsSettlement)="loadTenantsBySettlement($event)"
              (billsBuilding)="loadTenantsByBuilding($event)"
              (billsFloor)="loadTenantsByFloor($event)"
              (billsUnit)="loadTenantsByUnit($event)"
              (billsSort)="loadTenantsSort($event)"
              (billsOrder)="loadTenantsOrder($event)"
              (billsPerPage)="loadTenantsPerPage($event)"
              (dateRange)="updateDate($event)"
              (clear)="clearFilter()"
            ></app-filter-full>
          </div>
          <!-- div class="col-md-5">
            <form [formGroup]="searchBox" (ngSubmit)="searchUsers($event)">
              <input class="form-control form-control-lg bg-transparent" placeholder="Buscar..." formControlName="search">
            </form>
          </!-->
          <div class="col-md-12 mt-5">
            <div class="list-group rounded-4 shadow" *ngIf="users.length>0">
              <div class="list-group-item bg-light" *ngFor="let user of users | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <div class="row">
                  <div class="col-md-1 pt-0 text-center">
                    <app-item-tiny-user [user]="user"></app-item-tiny-user>
                  </div>
                  <div class="col-md-8 mt-1">
                    <h4 class="mb-0">
                      {{user.name}} {{user.last_name}}
                    </h4>
                    <p class="mb-0">
                      <span class="ms-2" style="font-size: 12px;">{{user.email}}</span>
                  </div>
                  <div class="col-md-3 text-end pt-1">
                    <button type="button" routerLink="edit/{{user.id}}" class="btn btn-outline-primary" *ngIf="permissions?.modules.config.tenants.write">
                      <fa-icon [icon]="faEdit" size="1x"></fa-icon>
                      Editar
                    </button>
                    <button type="button" class="btn btn-outline-danger ms-2" *ngIf="permissions?.modules.config.tenants.write" (click)="removeUser(user.id)">
                      <fa-icon [icon]="faTrashAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="users.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" >
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="users.length>0"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TenantUsersComponent implements OnInit {
  loading: boolean = false;
  users: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  searchBox: FormGroup = new FormGroup({});

  payload: any = {
    page: 1,
    per_page: 30,
  };

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  p: number = 1;

  payFilterPrefs: any = {};

  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);

    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;

    this.payFilterPrefs = localStorage.getItem('payFilterPrefs') != null ? JSON.parse(localStorage.getItem('payFilterPrefs')!) : this.payload;
    this.payFilterPrefs.state = 0;
    this.payFilterPrefs.profile_id = this.profile.id;
    this.payFilterPrefs.status = false;
    this.payFilterPrefs.state = 0;
    this.p = this.payFilterPrefs.page;

    this.loadUsers(this.payload);
  }

  ngOnInit() {    
    
    this.initForm(); 
  }

  loadUsers(payload: any) {
    this.loading = true;
    this.store.getTenantsQuery(payload).subscribe({
      next: (users: any) => {
        console.log('tenants >= ', users);
        this.loading = false;
        // if (users.length == 0) {
          // this.toastr.warning('No se encontraron registros', 'Error');
          // return;
        // }
        this.users = users.data;
        this.meta = users.meta;
        
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.warning('No se encontraron registros', 'Error');
        this.users = [];
        this.loading = false;
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  searchUsers(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadUsers(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    console.log('payload => ', this.payload)
    this.loadUsers(this.payload);
  }

  removeUser(id: number) {
    this.store.destroyUser(id).subscribe({
      next: (data: any) => {
        this.toastr.success('Usuario eliminado correctamente.', 'Éxito');
        this.loadUsers(this.payload);
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al eliminar el usuario.', 'Error');        
      },
    });
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payFilterPrefs.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payFilterPrefs.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadUsers(this.payFilterPrefs);
    }
  }

  loadTenantsBySettlement(settlement_id: any) {
    this.payFilterPrefs.settlement_id = settlement_id;    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadUsers(this.payFilterPrefs);
  }

  loadTenantsByBuilding(building_id: any) {
    this.payFilterPrefs.building_id = building_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadUsers(this.payFilterPrefs);
  }

  loadTenantsByFloor(floor_id: any) {
    this.payFilterPrefs.floor_id = floor_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.unit_id;

    this.loadUsers(this.payFilterPrefs);
  }

  loadTenantsByUnit(unit_id: any) {
    this.payFilterPrefs.unit_id = unit_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    this.loadUsers(this.payFilterPrefs);
  }

  loadTenantsSort(event: any) {
    this.payFilterPrefs.sort = event;
    this.loadUsers(this.payFilterPrefs);
  }

  loadTenantsOrder(event: any) {
    this.payFilterPrefs.order = event;
    this.loadUsers(this.payFilterPrefs);
  }

  loadTenantsPerPage(event: any) {
    this.payFilterPrefs.per_page = parseInt(event);
    this.loadUsers(this.payFilterPrefs);
  }

  clearFilter() {    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.settlement_id;
    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadUsers(this.payFilterPrefs);
  }

}
