import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-item-bill-invoice',
  template: `
    <div class="row">
      <div class="col-md-1">
        <input class="form-check-input me-1" type="checkbox" value="" (change)="selectBill($event)">
      </div>
      <div class="col-md-7">
        <p class="mb-0"><strong># {{bill.id}}</strong></p>
        <p class="mb-0" style="font-size: 14px;">{{bill.reference}}</p>
      </div>
      <div class="col-md-4 text-end">
        <span><div class="badge badge-pill" [ngClass]="getBillStatus(bill.status)">{{billStatus(bill.status)}}</div></span>
        <p class="mb-0 text-muted">{{bill.paid_date | date: 'dd/MM/yy'}}</p>
        <p class="mb-0"><strong>{{price | currency}}</strong></p>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemBillInvoiceComponent implements OnInit {
  @Input() bill: any;
  @Output() addBill = new EventEmitter<any>();

  price: number = 0;

  estatus: any =[
    {id: 1, name: 'Pendiente', color: 'bg-success'},
    {id: 2, name: 'Pagado', color: 'bg-warning'},
  ]

  constructor(
    // private store: SettlementsService
  ) { }

  ngOnInit(): void {
    // this.store.getBill(this.invoice.bill_id).subscribe((bill: any) => {
    //   this.bill = bill;
    // });
    this.price = this.bill.consumo_periodo + this.bill.admin_price;
  }

  getBillStatus (status: any) {
    switch (status) {
      case true:
        return 'bg-success';
      case false:
        return 'bg-warning';
      default:
        return 'bg-info';
    }
  }

  billStatus (status: any) {
    switch (status) {
      case true:
        return 'Pagado';
      case false:
        return 'Pendiente';
      default:
        return '-';
    }
  }

  selectBill(e: any) {
    this.addBill.emit(e.target.checked ? this.bill : null);
  }

}
