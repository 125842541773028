import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEdit, faPlus, faTrashAlt, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-modal-asign-task',
  template: `
    <div #modalAsignTask id="modalAsignTask" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalAsignTask" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Asignación de tarea</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body pt-3 pb-3">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="formTask">
                  <div class="form-group required mb-4">
                    <label for="due_date" class="form-label ms-2">Fecha</label><!-- [min]="min" -->
                    <input type="date" class="form-control form-control-lg" id="due_date"  formControlName="due_date"> <!--  (change)="selectDate($event)" -->
                    <div *ngIf="ufc['due_date'].touched && ufc['due_date'].invalid">
                      <div *ngIf="ufc['due_date'].errors?.['required']" class="text-danger text-sm">El técnico/operador es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                    <label for="due_time" class="form-label ms-2">Horario</label>
                    <input type="time" class="form-control form-control-lg" id="due_time" formControlName="due_time" > <!-- (change)="validateTime($event)" -->
                    <div *ngIf="ufc['due_time'].touched && ufc['due_time'].invalid">
                      <div *ngIf="ufc['due_time'].errors?.['required']" class="text-danger text-sm">El técnico/operador es requerido.</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveTask()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalAsignTaskComponent implements OnInit {
  @Input() task: any;
  @Input() technicians: any;
  @Output() asignData = new EventEmitter<any>();

  formTask: FormGroup = new FormGroup({});

  faTimes = faTimes;

  vehicle: any = null;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private taskService: TasksService
  ) {
    
  }

  ngOnInit(): void {
    this.initForm();
    this.formTask.patchValue(this.task);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['task'] && changes['task'].currentValue) {

      this.formTask.patchValue(this.task);
    }
  }

  get ufc() {
    return this.formTask.controls;
  }

  initForm() {
    this.formTask = this.fb.group({
      id: [''],
      title: [''],
      due_date: ['', Validators.required],
      due_time: ['', Validators.required],
      status: ['', Validators.required],
      priority: ['', Validators.required],
      profile_id: [''],
      settlement_id: ['', Validators.required],
      building_id: [''],
      unit_id: [''],
      tanque_id: [''],
      po_id: [''],
      builder_id: ['', Validators.required],
      assignee_id: [''],
      duration: [''],
    });
  }

  selectAssignee(event: any) {
    console.log('SELECT ASSIGNEE ====> ', event.target.value);
    this.taskService.getUserVehicle(event.target.value).subscribe((data: any) => {
      console.log('VEHICLE ====> ', data);
      // this.formTask.patchValue({ vehicle_id: data.vehicle_id });
      this.vehicle = data[0];
    });
  }

  saveTask() {
    console.log('SAVE TASK ====> ', this.formTask);
    if (this.formTask.invalid) {
      this.toastr.error('Todos los campos son requeridos', 'Error');
      this.formTask.markAllAsTouched();
      return; 
    }

    this.formTask.patchValue({ status: 1 });
    this.asignData.emit(this.formTask.value);
  }
}
