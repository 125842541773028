import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-form-payment-concept',
  template: `
    <form [formGroup]="formConcept" (ngSubmit)="saveConcept()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre del concepto</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="amount" class="form-label ms-2">Costo</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="amount" placeholder="" formControlName="amount">
            <div *ngIf="ufc['amount'].touched && ufc['amount'].invalid">
              <div *ngIf="ufc['amount'].errors?.['required']" class="text-danger text-sm">El costo es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="clave" class="form-label ms-2">Clave SAT</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="clave" placeholder="" formControlName="clave">
            <div *ngIf="ufc['clave'].touched && ufc['clave'].invalid">
              <div *ngIf="ufc['clave'].errors?.['required']" class="text-danger text-sm">La clave es requerido.</div>
            </div>
          </div>

          <div class="form-group required mb-4 mt-3">
            <label for="sat_group" class="form-label ms-2">Grupo SAT</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="sat_group" placeholder="" formControlName="sat_group">
            <div *ngIf="ufc['sat_group'].touched && ufc['sat_group'].invalid">
              <div *ngIf="ufc['sat_group'].errors?.['required']" class="text-danger text-sm">El grupo es requerido.</div>
            </div>
          </div>

          <div class="form-group required mb-4 mt-3">
            <label for="codigo_unidad" class="form-label ms-2">Código Unidad</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="codigo_unidad" placeholder="" formControlName="codigo_unidad">
            <div *ngIf="ufc['codigo_unidad'].touched && ufc['codigo_unidad'].invalid">
              <div *ngIf="ufc['codigo_unidad'].errors?.['required']" class="text-danger text-sm">El código unidad es requerido.</div>
            </div>
          </div>

          <div class="form-group required mb-4 mt-3">
            <label for="tax" class="form-label ms-2">IVA %</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="tax" placeholder="" formControlName="tax">
            <div *ngIf="ufc['tax'].touched && ufc['tax'].invalid">
              <div *ngIf="ufc['tax'].errors?.['required']" class="text-danger text-sm">El IVA es requerido.</div>
            </div>
          </div>

          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/pagos/conceptos">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormPaymentConceptComponent {
  @Input() concept: any = {};
  @Input() loading: boolean = false;
  @Output() conceptData = new EventEmitter<any>();

  formConcept: FormGroup = new FormGroup({});

  settlements: any[] = [];
  buildings: any[] = [];

  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {}

  get ufc() {
    return this.formConcept.controls;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes['concept'].currentValue) {
      this.formConcept.patchValue(changes['concept'].currentValue);
      this.preFill();
    }
  }

  initForm() {
    this.formConcept = this.fb.group({
      name: ['', Validators.required],
      clave: ['', Validators.required],
      sat_group: ['', Validators.required],
      amount: ['', Validators.required],
      codigo_unidad: ['', Validators.required],
      tax: ['', Validators.required],
    });
  }

  preFill() {
    console.log(this.concept);
    if (this.concept.settlement) {
      console.log("this.concept.settlement.id");
      console.log(this.concept.settlement);
      this.formConcept.patchValue({
        settlement_id: this.concept.settlement.id
      });
      this.store.getBuildingsBySettlement(this.concept.settlement.id).subscribe({
        next: (data) => {
          console.log(data);
          
          this.buildings = data;
          if (this.concept.building) {
            this.formConcept.patchValue({
              building_id: this.concept.building.id
            });
          }
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
  }

  saveConcept() {
    console.log(this.formConcept.value);

    if (this.formConcept.invalid) {
      this.formConcept.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.conceptData.emit(this.formConcept.value);    
  }
}
