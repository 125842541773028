import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-task-category',
  template: `
  <form [formGroup]="formTaskCategory" (ngSubmit)="saveTaskCategory()">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group mb-4">
          <label for="name" class="form-label ms-2">Nombre</label>
          <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
        </div>
        <div class="form-group text-end">
          <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
        </div>
      </div>
    </div>
  </form>
  `,
  styleUrls: []
})
export class FormTaskCategoryComponent implements OnInit {
  @Input() category: any;
  @Input() loading: boolean = false;
  @Output() categoryData = new EventEmitter<any>();

  formTaskCategory: FormGroup = new FormGroup({});

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formTaskCategory = this.fb.group({
      name: ['', Validators.required],
    });
  }

  saveTaskCategory() {
    if (this.formTaskCategory.invalid) {
      this.formTaskCategory.markAllAsTouched();
      this.toastr.error('Revisa los campos en rojo.', 'Error');
      return;
    }

    this.categoryData.emit(this.formTaskCategory.value);
  }
}
