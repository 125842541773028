import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-tiny-tenant',
  template: `
    <img [src]="avatarURL" width="44" height="44" class="rounded-5 border border-2 shadow-sm">
  `,
  styleUrls: []
})
export class ItemTinyTenantComponent implements OnInit {

  @Input() tenant: any;

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  avatarURL: string = '';

  constructor() { }

  ngOnInit(): void {
    this.loadAvatar();
  }

  loadAvatar() {
    // this.avatarURL = this.tenant.avatar ? this.tenant.avatar : 'assets/images/tenant-avatar.png';
    console.log('tenant => ', this.tenant);
    let el = this.tenant.avatar;

    if (!el) {
      this.avatarURL = 'assets/images/avatar.png';
    } else {
      this.avatarURL = `${this.apiUrl}/storage/${el.key.substring(0,2)}/${el.key.substring(2,4)}/${el.key}`;
    }
  }

}
