import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { faTimes, faEllipsisVertical, faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { DateService } from 'src/app/shared/services/date.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-calendar-event',
  template: `
    <div      
      *ngIf="isVisible"
      [style.position]="'fixed'"
      [style.top.px]="top"
      [style.left.px]="left"
      [style.width.px]="380"
      [style.zIndex]="1000"
      class="cal-event bg-white border border-gray-300 rounded-4 shadow p-2"
    >
      <div class="row">
        <div class="row pt-2 ps-4">
          <div class="col-1 offset-10 text-end">
            <a routerLink="tasks/{{selectedTask.id}}" *ngIf="selectedTask.status!=3"><fa-icon [icon]="faEdit"></fa-icon></a>
            <a routerLink="tasks/review/{{selectedTask.id}}" *ngIf="selectedTask.status==3"><fa-icon [icon]="faEye"></fa-icon></a>
          </div>
          <div class="col-1 text-center text-end">
            <fa-icon [icon]="faTimes" (click)="closeWindow()"></fa-icon>
          </div>
          <div class="col-2 mt-1">
            <div class="float-start rounded-2" style="width: 22px; height: 22px;" [ngStyle]="{ backgroundColor: parsePriority(selectedTask.priority).color }"></div>
          </div>
          <div class="col-10 ps-0">
            <h4 class="mb-0">{{ parseBuilder(selectedTask.builder_id) }}</h4>
            <p style="font-size: 15px;">{{ selectedTask.due_date | date: 'dd/MM/yy' }} {{ selectedTask.due_time | date: 'HH:mm' }} - {{ getDuration(selectedTask) }}</p>
          </div>
          <div class="col-2 mt-1"></div>
          <div class="col-10 ps-0">
            <p style="font-size: 15px;">Estado: <span class="ms-2 badge badge-pill py-2 px-3 rounded-4" [ngStyle]="{ backgroundColor: parseStatus(selectedTask.status).color }">{{ parseStatus(selectedTask.status).name }}</span></p>
          </div>          
          <div class="col-2">
            <div style="overflow: hidden; width: 28px; height: 28px; border-radius: 40px;">
              <img src="assets/images/avatar.png" width="100%" *ngIf="!parseTechnician(selectedTask.assignee_id).avatar">
              <img [src]="getAvatar(parseTechnician(selectedTask.assignee_id).avatar)" width="100%" *ngIf="parseTechnician(selectedTask.assignee_id).avatar">
            </div>
          </div>
          <div class="col-10 ps-0">
            <p class="mb-0" style="font-size: 17px;">{{ parseTechnician(selectedTask.assignee_id).name }} {{ parseTechnician(selectedTask.assignee_id).last_name }}</p>
            <p class="text-muted" style="font-size: 15px;">#NE: {{ parseTechnician(selectedTask.assignee_id).employee_number }}</p>
          </div>
          <div class="col-2">
          <img src="assets/images/building-avatar.png" width="80%">
          </div>
          <div class="col-10 ps-0">
            <h5 class="mb-0">Condominio</h5>
            <p class="mb-0" style="font-size: 17px;">{{ parseSettlement(selectedTask.settlement_id).name }}</p>
            <p style="font-size: 15px;">{{ parseSettlement(selectedTask.settlement_id).address }}</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class CalendarEventComponent implements OnInit {
  @Input() top: any;
  @Input() left: any;
  @Input() selectedTask: any;
  @Input() taskCategories: any[] = [];
  @Input() technicians: any[] = [];
  @Input() settlements: any[] = [];
  @Input() priority: any[] = [];
  @Input() status: any[] = [];
  @Input() isVisible: boolean | undefined; // = true;
  @Input() isSelected: any;

  @Output() isVisibleChange = new EventEmitter<boolean>();

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  builder: any;

  faTimes = faTimes;
  faEdit = faEdit;
  faEye = faEye;
  faEllipsisVertical = faEllipsisVertical;

  constructor(
    private eRef: ElementRef,
    private dateService: DateService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isVisible'] && changes['isVisible'].currentValue) {      
      this.isVisible = changes['isVisible'].currentValue;
    }
  }

  parseBuilder(task: any) {
    if (this.taskCategories.length==0 || !task) return '';
    return this.taskCategories.find((builder: any) => builder.id === task).name;
  }

  parsePriority(priority: any) {
    if (!priority) return '';
    return this.priority.find((p: any) => p.id === priority);
  }

  parseStatus(status: any) {
    if (!status) return '';
    return this.status.find((s: any) => s.id === status);
  }

  parseSettlement(task: any) {
    if (this.settlements.length==0 || !task) return '';
    return this.settlements.find((s: any) => s.id === task);
  }

  parseTechnician(task: any) {
    if (this.technicians.length==0 || !task) return '';    
    return this.technicians.find((t: any) => t.id === task);
  }

  getDuration(task: any) {
    let time = this.dateService.addMinutesToDate(task.due_date, task.duration);
    return time.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':');
  }

  getAvatar(el: any) {
    if (!el) return '';
    return `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
  }

  closeWindow() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    // console.log('Clicked ', this.eRef.nativeElement);
    // console.log('this.selectedTask', this.selectedTask);
    // if (this.isVisible) {
      // if (this.selectedTask.id != null) {
      if(!this.eRef.nativeElement.contains(event.target)) {
        // this.isVisible = false;
        // this.isVisibleChange.emit(this.isVisible);
        // console.log('Clicked outside');
        // this.isVisible = false;
        // Handle the outside click. For example, close the div or hide it
      } else {
        // console.log('Clicked inside => ', this.eRef.nativeElement.contains(event.target));
        // Optional: Handle the inside click if necessary
      }
    // }
    // }
  }
}
