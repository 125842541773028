import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentsService } from 'src/app/services/payments.service';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-bill-commercial',
  template: `
  <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1 *ngIf="editing"> Editando recibo</h1>
            <h1 *ngIf="!editing"> Creando recibo</h1>
            <p>Configura los servicios asociados al condominio.</p>
          </div>
          <div class="col-md-7 mt-4 text-end"></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body 0-4" *ngIf="billForm">
                <form [formGroup]="billForm" (ngSubmit)="onSubmit(billForm)">
                  <div class="row">
                    <div class="col-12 col-md-12">
                      <div class="form-group mt-3">
                        <label for="reference" class="form-control-label">Referencia</label>
                        <input type="text" formControlName="reference" class="form-control form-control-lg">
                      </div>                      
                      <div class="card cleafix mt-4 shadow-sm rounded-4 bg-light">
                        <div class="card-body">
                          <div class="clearfix">
                            <h3 class="float-start mt-1">Servicios</h3>
                            <div class="float-end">
                              <button type="button" (click)="addConcept()" class="btn btn-sm btn-outline-primary">
                                <fa-icon [icon]="icons.faPlus"></fa-icon>
                              </button>
                            </div>
                          </div>
                          <hr class="mt-1">
                          <div class="clearfix mt-2" *ngIf="addingConcept">
                            <app-item-form-concept (conceptData)="saveConcept($event)" [concepts]="concepts"></app-item-form-concept>
                          </div>
                          <div class="list-group mt-3">
                            <div class="list-group-item clearfix" *ngFor="let concept of additionalConcepts">
                              <div class="row">
                                <div class="col-md-8">
                                  <p class="mb-0">{{concept.name}}</p>
                                </div>
                                <div class="col-md-3">
                                  <p class="float-end mb-0">{{concept.amount | currency: 'USD' }}</p>
                                </div>
                                <div class="col-md-1 text-end">
                                  <button type="button" class="btn btn-outline-danger btn-sm me-2" (click)="removeConcept(concept)">
                                    <fa-icon [icon]="icons.faTrashAlt"></fa-icon>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card clearfix mt-4 shadow-md rounded-4 bg-light">
                        <div class="card-body">
                          <h3>Gas</h3>
                          <div class="list-group shadow-md mt-3">
                            <div class="list-group-item">
                              <div class="row">
                                <div class="col-md-1 text-center mt-2">
                                  <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji>
                                </div>
                                <div class="col-md-10 mt-2">
                                  <strong>{{tanque?.name}}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group mt-3  d-none">
                            <label for="periodo_inicio" class="form-control-label">Inicio periodo</label>
                            <input type="date" formControlName="periodo_inicio" class="form-control form-control-lg" min="2023-01-01" max="2023-12-31" readonly>
                          </div>
                            <div class="form-group mt-3 ">
                            <label for="periodo_fin" class="form-control-label">Fin periodo</label>
                            <input type="date" formControlName="periodo_fin" (change)="calculateDiff($event)" class="form-control form-control-lg" value="2023-06-05" min="2023-01-01" max="2023-12-31">
                          </div>
                          <div class="form-group mt-3  d-none">
                            <label for="read_prev" class="form-control-label">Lectura anterior</label>
                            <input type="number" formControlName="read_prev" class="form-control form-control-lg" readonly>
                          </div>
                          <div class="form-group mt-3">
                            <label for="read_curr" class="form-control-label">Lectura actual</label>
                            <input type="number" formControlName="read_curr" class="form-control form-control-lg">
                          </div>
                          <div class="form-group mt-3 mb-3">
                            <label for="read_prev" class="form-control-label">Medidor actual</label>
                            <input type="file" class="form-control form-control-lg" id="actual" (change)="loadImage($event)">
                          </div>
                          <div class="form-group mt-3 d-none">
                            <label for="read_prev" class="form-control-label">Medidor anterior</label>
                            <input type="file" class="form-control" id="previo" (change)="loadImage($event)" placeholder="Seleccionar imagen">
                          </div>
                        </div>
                      </div>
                      <div class="form-group mt-3">
                        <label for="description" class="form-control-label">Comentarios</label>
                        <textarea formControlName="description" class="form-control form-control-lg"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 offset-8 text-end mt-4">
                    <button type="button" class="btn btn-warning rounded-5 btn-lg w-100" (click)="calculateTotal()">
                      <fa-icon [icon]="icons.faCircleNotch" *ngIf="loading" [spin]="true" class="me-2"></fa-icon>
                      <fa-icon [icon]="icons.faCalculator" *ngIf="!loading" class="me-2"></fa-icon>
                      Calcular
                    </button>
                  </div>
                  <div class="col-12 text-end mt-2 mb-3">
                    <hr>
                    <button type="button"  routerLink="" class="btn btn-link btn-sm me-2">Cancelar</button>
                    <button type="submit" class="btn btn-outline-success rounded-5 btn-lg" [disabled]="!calculated">
                      <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="me-2"></fa-icon>
                      Generar recibo
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 offset-0 offset-md-1">
          <app-receipt-reads [bfc]="bfc"></app-receipt-reads>
            <app-receipt-breakdown
              [bfc]="bfc"
              [additionalConcepts]="additionalConcepts"
              [interests]="interests"
            ></app-receipt-breakdown>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class BillCommercialComponent {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  bill: any = {};
  bills: any[] = [];
  unit: any = {};
  settlement: any = {};
  buildings: any[] = [];
  loading: boolean = false;
  editing: boolean = false;
  calculated: boolean = false;
  addingConcept: boolean = false;
  invoiceForm: FormGroup = new FormGroup({});
  billForm: FormGroup = new FormGroup({});
  utilities: any[] = [];
  selectedBuilding: any = {};
  selectedFloor: any = {};
  selectedUnit: any = {};
  selectedUtility: any = {};
  meterImages: any = null;
  concepts: any[] = [];
  additionalConcepts: any[] = [];

  invoiceData: any = {};

  conceptos: any = [];
  // additionalConcepts: any = [];
  serie: string = 'MAER';
  // formapago: string = '';
  complemento: any = {};
  today: Date = new Date();

  factor: number = 0;
  admon: number = 0;
  price: number = 0;
  adeudos: number = 0;
  recargos: number = 0;
  descuento: number = 0;
  interests: number = 0;
  tanque: any = null;

  constructor(
    private store: SettlementsService,
    private paymentsService: PaymentsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.activateRoute.params.subscribe((params: any) => {
      this.loading = true;
      if (params['id'] != 'new') {

        this.store.getPaymentConcepts({ page: 1, per_page: 1000 }).subscribe((data: any) => {
          this.concepts = data.data;
        });

        this.store.getUnit(params['id']).subscribe((data: any) => {
          this.unit = data;
          let bills;
          let recentBill: any = null;
          let periodoInicio;
          this.bills = data.bills;

          if (data.bills.length == 0) {
            periodoInicio = new Date();
            periodoInicio.setDate(periodoInicio.getDate() + 1);
          } else {
            bills = data.bills.sort((a: any, b: any) => a.id - b.id);
            recentBill = bills.slice(-1);

            periodoInicio = new Date(recentBill[0].periodo_fin);
            periodoInicio.setDate(periodoInicio.getDate() + 1);
            this.store.getBill(recentBill[0].id).subscribe((recentBill: any) => {
              let imageUrl = `${this.apiUrl}/storage/${recentBill.medidor.blob.key.substring(0,2)}/${recentBill.medidor.blob.key.substring(2,4)}/${recentBill.medidor.blob.key}`;
              this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
                this.unit.medidor = "data:image/jpeg;base64," + result.split(',')[1];
              });
            });
          }

          this.store.getFloor(data.floor_id).subscribe((floor: any) => {
            if (data.tanque_id) {
              this.store.getTanque(data.tanque_id).subscribe((tanque: any) => { this.tanque = tanque; });
            } else {
              this.store.getTanqueByBuilding(floor.building_id).subscribe((tanque: any) => { this.tanque = tanque; });
            }
            this.store.getSettlement(floor.building.settlement_id).subscribe((settlement: any) => {
              this.settlement = settlement;
              this.factor = settlement.factor;
              this.admon = settlement.admin_price;
              this.descuento = settlement.discount;
              this.interests = settlement.interests;
              //
              let unpaidBill = data.bills.filter((x: any) => x.status == false && new Date(x.due_date) < new Date());
              let overdueBills = data.bills.filter((x: any) => x.status == true && new Date(x.due_date) < new Date(x.paid_date));
              let unpaid_bills = unpaidBill.sort((a: any, b: any) => a.id - b.id);                    
              let overdueBill = overdueBills.length > 0 ? overdueBills.reduce((a: any, b: any) => (a.paid_date > b.paid_date ? a : b)) : {};

              if (unpaid_bills.length > 0) {
                if (recentBill == null) {
                  this.adeudos = 0;                        
                } else {
                  let allAdeudos: number = 0;
                  unpaidBill.map((bill: any) => { allAdeudos += bill.total; });
                  //
                  this.adeudos = allAdeudos;
                  console.log("allAdeudos ==> ", allAdeudos);
                  this.recargos = (allAdeudos * this.interests / 100);
                }                      
              } else {
                this.adeudos = 0;
                console.log("overdueBill.total ==> ", overdueBill.total);
                if (overdueBill.total != undefined) {
                  this.recargos += (overdueBill.total * this.interests / 100);
                }
                // console.log("adeudos X==> ", this.recargos);
              }
            });
          });

          // GET price by utility_setting
          // TODO: FIXED ID based on utility_id
          // let subscription = data.subscriptions.filter((x: any) => x.utility_id == 1);
          this.store.getUtilityPrice(1).subscribe((utility: any) => {
            console.log('utility => ', utility);
            this.price = parseFloat(utility.valor);
          });

          this.billForm.patchValue({
            reference: recentBill != null ? recentBill[0].reference : '',
            read_prev: recentBill != null ? recentBill[0].read_curr : 0,
            periodo_inicio: periodoInicio.toISOString().substring(0, 10),
            subscription_id: data.subscriptions[0].id,
            unit_id: data.id
          });
          this.loading = false;
        });
      } else {
        this.store.getBuildings({ page: 1, per_page: 1000 }).subscribe((data: any[]) => {
          this.buildings = data;
          this.loading = false;
        });
      }
    });
  }

  ngOnInit() {
    this.buildForm();
    this.initForm();    
  }

  initForm() {
    this.invoiceForm = this.fb.group({
      rfc: ['', Validators.required],
      razon_social: ['', Validators.required],
      regimen: ['', Validators.required],
      uso_cfdi: ['', Validators.required],
      zip_code: ['', Validators.required],
    });

    this.invoiceForm.patchValue({
      rfc: "XAXX010101000",
      razon_social: "PUBLICO EN GENERAL",
      regimen: "616",
      uso_cfdi: "S01",
      zip_code: "04010",
    });
  }

  buildForm() {
    this.billForm = this.fb.group({
      name: ['', Validators.required],
      reference: ['', Validators.required],
      description: [''],
      status: [''],
      consumo_m3: [''],
      consumo_lt: [''],
      consumo_periodo: [''],
      consumo_total: [''],
      taxes: [''],
      sub_total: [''],
      total: [''],
      days: [''],
      read_prev: [''],
      read_curr: [''],
      admin_price: [''],
      discount: [''],
      interests: [''],
      recargos: [''],
      periodo_inicio: [''],
      periodo_fin: [''],
      due_date: [''],
      paid_date: [''],
      subscription_id: [''],
      unit_id: [''],
    });
  }

  get bfc () {
    return this.billForm.controls;
  }

  selectBuilding(event: any) {
    let filtered = this.buildings.filter(x => x.id == event.target.value);
    this.selectedBuilding = filtered[0];
  }

  selectFloor(event: any) {
    let filtered = this.selectedBuilding.floors.filter((x: { id: any; }) => x.id == event.target.value);
    this.selectedFloor = filtered[0];
  }

  selectUnit(event: any) {
    this.selectedUnit = event.target.value;
    this.utilities = this.selectedBuilding.utilities;
  }

  loadImage(event: any) {
    // this.imageError = "";
    if (event.target.files && event.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.toastr.error('Las dimensiones de la imagen no permitidas.', 'Error');
          } else {
            const imgBase64Path = e.target.result;
            // this.meterImages.push(imgBase64Path);
            // this.meterImages = imgBase64Path;
            this.meterImages = "data:image/jpeg;base64," + imgBase64Path.split(',')[1];
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  addConcept() {
    this.addingConcept = true;
  }

  saveConcept(event: any) {
    console.log('event => ', event);
    let concept = event;
    concept.total = concept.total/1.16;
    //
    this.additionalConcepts.push(event);
    this.addingConcept = false;
  }

  removeConcept(index: any) {
    this.additionalConcepts.splice(index, 1);
  }

  updateUtility(event: any) {
    this.selectedUtility = this.utilities.filter(x => x.id == event.target.value)[0];
    // 
    this.billForm.patchValue({
      name: this.selectedUtility.utility.name,
      subscription_id: this.selectedUtility.id
    });
  }

  calculateDiff(sentDate: any): void {
    var date1:any = new Date(this.billForm.value.periodo_fin);
    var date2:any = new Date(this.billForm.value.periodo_inicio);
    var diffDays:any = Math.floor((date1 - date2) / (1000 * 60 * 60 * 24));

    this.billForm.patchValue({
      days: diffDays
    });
  }

  calculateTotal(): void {
    this.loading = true;

    const factor = this.factor;
    const admon = this.admon/1.16;
    const price = this.price;
    const descuento = this.descuento;
    const adeudo = this.adeudos/1.16;
    const intereses = adeudo; //  + (adeudo * moroso);
    const recargos = this.recargos/1.16;

    console.log('factor => ', factor);
    console.log('admon => ', admon);
    console.log('price => ', price);
    console.log('descuento => ', descuento);
    console.log('adeudo => ', adeudo);
    console.log('intereses => ', intereses);

    const read_prev = this.billForm.value.read_prev;
    const read_curr = this.billForm.value.read_curr;

    if (read_curr < read_prev) {
      this.toastr.error('La lectura actual no puede ser menor a la anterior.', 'Error');
      this.loading = false;
      return;
    }

    if (!this.billForm.value.periodo_fin) {
      this.toastr.error('Debe seleccionar una fecha de fin de periodo.', 'Error');
      this.loading = false;
      return;
    }

    const consumo_m3 = read_curr - read_prev;
    const consumo_lt = consumo_m3 * factor;
    const consumo_periodo = (consumo_lt * price)/1.16;
    const discount = (consumo_lt * descuento)/1.16;
    const consumo_total = consumo_periodo - discount;

    console.log('consumo_m3 => ', consumo_m3);
    console.log('consumo_lt => ', consumo_lt);
    console.log('consumo_periodo => ', consumo_periodo);

    
    const days = this.billForm.value.days;

    let additional: number = 0;
    this.additionalConcepts.map((concept: any) => {
      // const total_wo_taxes = concept.total / 1.16;
      // additional += total_wo_taxes;
      additional += concept.total;

      /*const total = (concept.total);
        const taxes = (concept.total*0.16);

        this.conceptos.push(
          {
            Cantidad: "1",
            Serie: concept.clave.toString(),
            CodigoUnidad: "E48",
            CodigoProducto: "01010101",
            Producto: "Servicio",
            PrecioUnitario:  total.toFixed(2),
            Importe:  total.toFixed(2),
            ObjetoDeImpuesto: "02",
            Impuestos: [
              {
                TipoImpuesto: "1",
                Impuesto: "2",
                Factor: "1",
                Base:  total.toFixed(2),
                Tasa: "0.160000",
                ImpuestoImporte: taxes.toFixed(2)
              }
            ]
          }
        );  */    
    });

    let sub_total = (consumo_total + admon + adeudo + recargos + additional);
    const iva = sub_total * 0.16;
    const total = sub_total + iva;

    console.log('additional => ', additional);
    console.log('recargos => ', recargos);
    console.log('discount => ', discount);
    console.log('sub_total => ', sub_total);
    console.log('iva => ', iva);

    let datte = new Date(this.billForm.value.periodo_fin);
    datte.setDate(datte.getDate() + 6);

    this.billForm.patchValue({
      consumo_m3: consumo_m3.toFixed(2),
      consumo_lt: consumo_lt.toFixed(2),
      consumo_periodo: consumo_periodo.toFixed(2),
      consumo_total: consumo_total.toFixed(2),
      sub_total: sub_total.toFixed(2),
      admin_price: admon.toFixed(2),
      discount: discount.toFixed(2),
      taxes: iva.toFixed(2),
      total: total.toFixed(2),
      interests: intereses.toFixed(2),
      recargos: recargos.toFixed(2),
      due_date: datte.toISOString().substring(0, 10)
    });

    // Concepto GAS
    this.conceptos.push(
      {
        Cantidad: "1",
        Serie: "0000012345",
        CodigoUnidad: "LTR",
        CodigoProducto: "15111510",
        Producto: "Litros",
        PrecioUnitario:  "61.29",
        Importe:  "61.29",
        ObjetoDeImpuesto: "02",
        Impuestos: [
          {
            TipoImpuesto: "1",
            Impuesto: "2",
            Factor: "1",
            Base:  "61.29",
            Tasa: "0.160000",
            ImpuestoImporte: (61.29*0.16).toFixed(2)
          }
        ]
      }
    );

     // Concepto Adeudo
     this.conceptos.push(
      {
        Cantidad: "1",
        Serie: "0000012345",
        CodigoUnidad: "E48",
        CodigoProducto: "15111510",
        Producto: "Servicio",
        PrecioUnitario:  this.billForm.value.interests,
        Importe:  this.billForm.value.interests,
        ObjetoDeImpuesto: "02",
        Impuestos: [
          {
            TipoImpuesto: "1",
            Impuesto: "2",
            Factor: "1",
            Base:  this.billForm.value.interests,
            Tasa: "0.160000",
            ImpuestoImporte: (this.billForm.value.interests*0.16).toFixed(2)
          }
        ]
      }
    );

    // Concepto Recargos
    this.conceptos.push(
      {
        Cantidad: "1",
        Serie: "0000012345",
        CodigoUnidad: "E48",
        CodigoProducto: "15111510",
        Producto: "Servicio",
        PrecioUnitario:  this.billForm.value.recargos,
        Importe:  this.billForm.value.recargos,
        ObjetoDeImpuesto: "02",
        Impuestos: [
          {
            TipoImpuesto: "1",
            Impuesto: "2",
            Factor: "1",
            Base:  this.billForm.value.recargos,
            Tasa: "0.160000",
            ImpuestoImporte: (this.billForm.value.recargos*0.16).toFixed(2)
          }
        ]
      }
    );

    // ADMIN fee
    const admin_fee: number = Number(this.billForm.value.admin_price);
    const admin_tax: number = Number(admin_fee*0.16);
    this.conceptos.push(
      {
        Cantidad: "1",
        Serie: "0000012345",
        CodigoUnidad: "E48",
        CodigoProducto: "15111510",
        Producto: "Servicio",
        PrecioUnitario:  admin_fee.toFixed(2),
        Importe:  admin_fee.toFixed(2),
        ObjetoDeImpuesto: "02",
        Impuestos: [
          {
            TipoImpuesto: "1",
            Impuesto: "2",
            Factor: "1",
            Base:  admin_fee.toFixed(2),
            Tasa: "0.160000",
            ImpuestoImporte: admin_tax.toFixed(2)
          }
        ]
      }
    );

    this.calculated = true;
    this.loading = false;
  }

  onSubmit(form: FormGroup) {

    let subtotal_service = 0;
    this.additionalConcepts.map((concept: any) => {
      subtotal_service += parseFloat(concept.cantidad);
    });
    
    const payload = {
      reference: form.value.reference,
      description: form.value.description,
      status: false,
      consumo_m3: form.value.consumo_m3,
      consumo_lt: form.value.consumo_lt,
      consumo_periodo: form.value.consumo_periodo,
      consumo_total: form.value.consumo_total,
      sub_total: form.value.sub_total,
      total: form.value.total,
      days: form.value.days,
      read_prev: form.value.read_prev,
      read_curr: form.value.read_curr,
      admin_price: form.value.admin_price,
      interests: form.value.interests,
      recargos: form.value.recargos,
      taxes: form.value.taxes,
      discount: form.value.discount,
      periodo_inicio: form.value.periodo_inicio,
      periodo_fin: form.value.periodo_fin,
      due_date: form.value.due_date,
      paid_date: form.value.paid_date,
      subscription_id: form.value.subscription_id,
      unit_id: form.value.unit_id,
      tanque_id: this.tanque.id,
      images: this.meterImages,
      service_sub_total: subtotal_service,
      additional_services: this.additionalConcepts
    };

    console.log(payload);

    this.store.createBill(payload).subscribe({
      next: async (bill: any) => {
        console.log("bill => ", bill);

        let dueDate = new Date(bill.due_date);
        dueDate.setDate(dueDate.getDate());
        dueDate.toISOString().substring(0, 10);

        bill.medidor = this.meterImages;

        let receiptPayload = {
          bill: bill,
          dueDate: dueDate,
          unitData: this.unit,
          buildingData: this.settlement
        };

        console.log("receiptPayload => ", receiptPayload);
        console.log('billForm => ', this.billForm.value);

        const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        const localISOTime = (new Date(Date.now() - tzoffset)).toISOString();

        console.log("localISOTime => ", localISOTime);

        let encabezados: any = {
          CFDIsRelacionados: "",
          TipoRelacion: "04",
          Receptor:{
            RFC: this.invoiceForm.value.rfc,
            NombreRazonSocial: this.invoiceForm.value.razon_social,
            UsoCFDI: this.invoiceForm.value.uso_cfdi,
            RegimenFiscal: this.invoiceForm.value.regimen,
            Direccion: {
              Calle: "",
              NumeroExterior: "",
              NumeroInterior: "",
              Colonia: "",
              Localidad: "",
              Municipio: "",
              Estado: "",
              Pais: "",
              CodigoPostal: this.invoiceForm.value.zip_code
            }
          },
          InformacionFacturaGlobal:{
            Periodicidad: "02",
            Meses: "09",
            Año: 2024
          },
          Fecha: localISOTime.slice(0,19),
          Serie: this.serie.toString(),
          Folio: bill.id.toString(),
          MetodoPago:"PUE",
          FormaPago: "01",
          Moneda: "MXN",
          LugarExpedicion: this.invoiceForm.value.zip_code,
          SubTotal: this.billForm.value.sub_total,
          Total: this.billForm.value.total,
        };

        this.complemento = {
          formaPago: "01",
          serie: this.serie,
          folio: bill.id,
          usoCFDI: this.invoiceForm.value.uso_cfdi,
          metodoPago: "PUE",
        }

        let payload: any = {
          Encabezado: encabezados,
          Conceptos: this.conceptos,
          complemento: this.complemento,
        };

        if (this.invoiceForm.invalid) {
          this.toastr.error('Completa toda la información requerida del formulario.', 'Error');
          this.loading = false;
          this.invoiceForm.markAllAsTouched();
          return;
        }

        console.log(payload);

        this.store.createInvoice(payload).subscribe({
          next: (data: any) => {
            console.log(data);
            this.loading = false;
            this.invoiceData = data;
            this.download();
            this.toastr.success('Factura creada correctamente.', 'Éxito');
          },
          error: (err: any) => {
            console.log(err);
            if (err.status >= 500) {
              this.toastr.error('Ocurrió un error al crear la factura.', 'Error');
            }
            this.loading = false;
          }
        });

      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('No se pudo crear el recibo.', 'Error');
      }
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    // console.log("BLOB => ", blob);
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  download() {


    let dueDate = new Date(this.billForm.value.due_date);
    dueDate.setDate(dueDate.getDate());
    dueDate.toISOString().substring(0, 10);

    this.store.getSubscriptions(this.billForm.value.subscription_id).subscribe((data: any) => {
      console.log("SS ==> ", data);
      this.store.getUnit(data.unit_id).subscribe((unitData: any) => {
        console.log('unitData => ', unitData);
        this.store.getBuilding(1).subscribe(async (data: any) => {
          if (this.bill.images && this.bill.images.length > 0) {
          let imageUrl = `${this.apiUrl}/storage/${this.bill.images[0].blob.key.substring(0,2)}/${this.bill.images[0].blob.key.substring(2,4)}/${this.bill.images[0].blob.key}`
          let imageUrl2 = `${this.apiUrl}/storage/${this.bill.images[1].blob.key.substring(0,2)}/${this.bill.images[1].blob.key.substring(2,4)}/${this.bill.images[1].blob.key}`

          await this.getBase64ImageFromUrl(imageUrl)
          .then(result => {
            let blobed = result;
            this.bill.image1 = blobed;
            console.log("BLOBED => ", blobed);
          })

          await this.getBase64ImageFromUrl(imageUrl2)
          .then(result => {
            let blobed = result;
            this.bill.image2 = blobed;
            // console.log("BLOBED => ", blobed);
          })
          .catch(err => console.error(err));
        }

        let imgURL = "${this.apiUrl}/storage/hz/rd/hzrdkigv18aezkib148jty8mjg4a";

        await this.getBase64ImageFromUrl(imgURL)
          .then(result => {
            let blobed: any = result;
            // bill.image1 = blobed;
            
            blobed = "data:image/jpeg;base64," + blobed.split(',')[1];

            console.log("BLOBED => ", blobed);
            this.bill.image = blobed;
            this.bill.image_prev = blobed;
          });


          const payload = {
            bill: this.billForm.value,
            dueDate: dueDate,
            unitData: unitData,
            buildingData: data,
            invoiceData: this.invoiceData
          };
  
          this.store.downloadInvoice(payload).subscribe({
            next: (data: any) => {
              const int8Array = new Uint8Array(data.result.data);

              const blob = new Blob([int8Array], { type: 'application/pdf' });
  
              var fileURL = window.URL.createObjectURL(blob);
              var link=document.createElement('a');

              link.href = fileURL;
              link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
              link.click();
  
              return;
            }, error: (error: any) => {
              console.log('createReceipt ERROR => ', error);
            }
          });
        });
      });
    });
  }

  /*async getBase64PDFFromUrl(recepit: any, success: any, error: any) {
    return new Promise((resolve, reject) => {
      var arrayBuffer, uint8Array;
      var fr  = new FileReader();

      // fr.onerror = () => {
        // return reject(this);
      // };
      fr.addEventListener('error', error, false);

      if (fr.readAsBinaryString) {
      fr.addEventListener("load", function () {
          // arrayBuffer = reader.result;
          // const int8Array = new Uint8Array(data.result.data);
          // const uint8Array  = new Uint8Array(recepit);
          var strinx: string = this.result || "xx";
          if (!strinx) {
            return;
          }
          var result = new Uint8Array(strinx.length);
            for (var i = 0; i < strinx.length; i++) {
                result[i] = strinx.charCodeAt(i);
            }
          resolve(result.buffer);
      }, false);
      return fr.readAsBinaryString(recepit);
    } else {
      fr.addEventListener('load', function () {
        success(this.result);
      }, false);
      return fr.readAsArrayBuffer(recepit);
    }
  
      
      // fr.readAsArrayBuffer(recepit);
    });
  }*/
}
