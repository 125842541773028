import { Component, Input, SimpleChanges } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-unit-details',
  template: `
  <div class="card rounded-4 shadow">
    <div class="card-body">
      <div class="row" *ngIf="unit">
        <div class="col-md-12">
          {{unit.tanque?.name}}
          <!-- {{ unit | json }} -->
          <h5>Pagos</h5>
          <div id="historial" class="tab-pane historial">
                  <table class="table table-sm table-striped mt-3">
                    <thead>
                      <tr>
                        <th># Recibo</th>
                        <th>Estatus</th>
                        <th class="text-center">Fecha pago</th>
                        <th class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let bill of bills">
                        <td class=""><a href="/pagos/recibos/{{bill.id}}">{{bill.reference}}</a></td>                        
                        <td class="text-start ">{{ overduebill(bill) }}</td>
                        <td class="text-center">{{ bill.due_date | date: 'dd/MM/yy'}}</td>
                        <td class="text-end ">{{bill.total | currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
          <h5>Adeudos</h5>
          <table class="table table-sm table-striped mt-3">
            <thead>
              <tr>
                <th># Recibo</th>
                <th>Tipo</th>
                <th class="text-center">Fecha pago</th>
                <th class="text-end">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bill of debit">
                <td class=""><a href="/pagos/recibos/{{bill.id}}">{{bill.id}}</a></td>
                <td class="">{{ overduebill(bill) }}</td>
                <td class="text-center ">{{ bill.due_date | date: 'dd/MM/yy'}}</td>
                <td class="text-end ">{{bill.total | currency}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="row" *ngIf="!unit">
        <div class="col-md-12 text-center mt-4 mb-4">
          <p class="mb-0">Selecciona un departamento.</p>
        </div>        
      </div>
    </div>
  </div>
    
    <!-- div class="card rounded-5 bg-light" *ngIf="!building">
            <div *ngIf="loading">
            <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
            <p class="text-muted">Cargando...</p>
          </div>
          <p class="text-muted" *ngIf="!loading">No se encontraron resultados.</p>
        </!-->
  `,
  styleUrls: []
})
export class UnitDetailsComponent {
  @Input() unit: any;

  debit: any[] = []; 
  bills: any[] = [];

  totalDebt: number = 0;

  constructor(
    private store: SettlementsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes['unit'].currentValue) {
      this.unit = changes['unit'].currentValue;
      this.getBillInfo();
    }
  }

  getBillInfo() {
    this.store.getUnit(this.unit.id).subscribe((data: any) => {
      console.log('data ', data);
      this.unit = data;
      this.bills = data.bills.filter((bill: any) => bill.status == true);
      this.debit = data.bills.filter((bill: any) => bill.status == false);

      this.debit.map((bill: any) => {
        this.totalDebt += bill.total;
      });
    });
    // this.store.getBillInfo(this.building.id).subscribe(
    //   (res: any) => {
    //     this.billInfo = res.data;
    //     this.loading = false;
    //   },
    //   (err: any) => {
    //     this.loading = false;
    //   }
    // );
  }

  overduebill(bill: any) {
    return new Date(bill.due_date) < new Date() ? "Vencido" : 'Vigente';
  }
}
