import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as bs from 'bootstrap';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() user: any;
  @Input() profile: any;
  @Input() profileLogo: any;

  userLS: any = {};
  roleLS: any = {};
  permissionsLS: any = {};
  avatarLS: any = {};

  protected version = environment.app_version;

  isNavbarCollapsed = false;

  isCollapsed = true;
  isCollapsedA = true;
  isCollapsedM = true;
  isCollapsedP = true;
  isCollapsedF = true;
  isCollapsedI = true;

  constructor() {
    this.userLS = JSON.parse(localStorage.getItem('user') || '{}');
    this.avatarLS = JSON.parse(localStorage.getItem('avatar') || '{}');
    this.roleLS = JSON.parse(localStorage.getItem('role') || '{}').role;
    this.permissionsLS = JSON.parse(localStorage.getItem('permissions') || '{}');
    
    // console.log("SIDE this.avatarLS => ", this.avatarLS);
    // console.log("this.roleLS => ", this.roleLS);  
    // console.log("this.permissionsLS => ", this.permissionsLS);
  }

  signOut() {
    let redirect = this.roleLS?.group_id == 3 ? '/user/sign-in' : '/ingresar';
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.removeItem('avatar');
    localStorage.removeItem('permissions');
    localStorage.removeItem('role');
    localStorage.removeItem('fcm_token');
    //
    window.location.href = redirect;
    // window.location.reload();
  }

  toggleNavbar() {

  }
}
