import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ValidateConfirmpass } from '../confirmpass.validator';

@Component({
  selector: 'app-confirm-password-change',
  template: `
    <div class="container-fluid p-0">
      <div class="row mt-0 p-0">
        <div class="col-md-4 h-100 bg-dark">Hola</div>
        <div class="col-md-8 offset-md-0 pt-0 mt-0 text-center bg-white">
          <div class="row mt-5 mb-5">
          <div class="col-md-6 offset-md-3">

              <h2 class="mb-5 mt-3">Cambiar contraseña</h2>
              <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">

                <div class="form-group mt-4">
                  <label for="password" class="text-sm sr-only d-none">Contraseña</label>
                  <div class="input-group">
                    <input
                      [type]="showPassword ? 'text' : 'password'"
                      class="form-control border-2 rounded-0 border-dark pt-3 pb-3 border-end-0"
                      formControlName="password"
                      placeholder="Contraseña"
                      #userPwd
                      required
                    />
                    <span class="input-group-append bg-white border-start-0">
                      <span class="input-group-text bg-transparent rounded-0 border-dark pt-3 pb-3 border-2">
                        <!-- i class="icon ion-ios-search"></!-->
                        <fa-icon (click)="togglePassword()" *ngIf="showPassword" [icon]="faEye" class="text-dark me-2"></fa-icon>
                        <fa-icon (click)="togglePassword()" *ngIf="!showPassword" [icon]="faEyeSlash" class="text-dark me-2"></fa-icon>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="form-group mt-4">
                  <label for="password_confirmation" class="text-sm sr-only d-none">Confirmar Contraseña</label>
                  <input
                    type="password"
                    class="form-control border-2 rounded-0 border-dark pt-3 pb-3"
                    formControlName="password_confirmation"
                    placeholder="Confirmar Contraseña"
                    #userPwd
                    required
                  />              
                </div>
                <div class="form-group mt-4">
                  <password-strength-meter [password]="resetForm.value.password"></password-strength-meter>
                </div>
                <small id="passwordHelpBlock" class="form-text text-muted text-start text-sm">
                  Su contraseña debe tener entre 8 y 20 caracteres, contener letras y números, y no debe contener espacios, caracteres especiales ni emojis.
                </small>

                <div *ngIf="lf['password'].touched && lf['password'].invalid" class="text-start">
                  <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
                  <small *ngIf="lf['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
                  <small *ngIf="lf['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
                  <small *ngIf="!lf['password'].errors?.['minlength'] && lf['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($@#!%*?&)</small>
                </div>
                <div *ngIf="lf['password_confirmation'].touched && lf['password_confirmation'].invalid" class="text-start">
                  <div *ngIf="lf['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
                  <small *ngIf="lf['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
                </div>
                <div class="form-group mt-5 text-center">
              <button type="submit" class="btn btn-dark btn-lg w-100 rounded-0 pt-3 pb-3" >
                <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="text-secondary me-2"></fa-icon>
                <fa-icon [icon]="faLock" *ngIf="!loading" class="text-light me-2"></fa-icon>
                Actualizar contraseña
              </button>
            </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ConfirmPasswordChangeComponent implements OnInit {
  userId: any;

  resetForm!: FormGroup;
  loading: boolean = false;
  password: any;
  showPassword: boolean = false;

  passwordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}';

  faEye = faEye;
  faLock = faLock;
  faEyeSlash = faEyeSlash;
  faCircleNotch = faCircleNotch;

  constructor(
    private route: Router,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute,
    private store: SettlementsService,
    private fb: FormBuilder
  ) { 
    let ctl = this;
    this.activateRoute.params.subscribe(params => {
      setTimeout(function () {
        console.log("======> ", params)
        // ctl.store.confirmReset(params['token']).subscribe({
          // next: (data: any) => {
            // console.log(data);
          // },
          // error: (err: any) => {
            // console.log(err);
            // // ctl.toastr.error(err.error.message, 'Error');
            // // ctl.route.navigate(['/sign-in']);
          // }
        // });
      }, 1000);
    });

    
  }

  ngOnInit(): void {
    this.initResetForm();

    this.activateRoute.queryParams.subscribe(params => {
      console.log("======> ");
      console.log(params);
      this.userId = params['email'];
    });
  }

  initResetForm(): void {
    this.resetForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.passwordPattern)]],
      password_confirmation: ['', [Validators.required, ValidateConfirmpass]],
    });
  }

  get lf() {
    return this.resetForm.controls;
  }

  get currPassword() {
    return this.resetForm.get('password');
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  resetPassword() {
    console.log("resetPassword");
    console.log(this.resetForm.value);

    if (this.resetForm.invalid) {
      this.toastr.error('Contraseña incorrecta.', 'Error');
      this.loading = false;
      return;
    }

    this.store.updateUser(this.userId, this.resetForm.value).subscribe({
      next: (data: any) => {
        console.log(data);
        this.toastr.success('Contraseña actualizada exitosamente.', 'Éxito');
        this.route.navigate(['/sign-in']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.error.message, 'Error');
      }
    });
  }

}
