import { PaymentsService } from './../../../../services/payments.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-tenant-edit-payment-method',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Método de pago</h1>
            <p>Configura tus métodos de pago.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 offset-sm-0 offset-md-3 mt-4">
        <div class="card border border-3 border-secondary rounded-4 mb-5 shadow">
          <div class="card-body p-4">
            <form [formGroup]="payMethod" (ngSubmit)="onSubmit(payMethod)">
              <div class="row">
                <div class="col-md-12 offset-md-0">
                  
                  <fieldset class="border-0 p-3">
                    <legend class="mt-3 mb-4" style="font-size: 19px; font-weight: 800;">Datos personales</legend>
                    
                    <div class="form-group mt-3">
                      <label for="email">Correo electrónico</label>
                      <input type="text" class="form-control" formControlName="email" id="email" aria-describedby="emailHelp">
                      <div *ngIf="payM['email'].touched && payM['email'].invalid">
                        <div *ngIf="payM['email'].errors?.['required']" class="text-danger text-sm">El correo es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="phone">Teléfono</label>
                      <input type="text" class="form-control" formControlName="phone" id="phone" aria-describedby="emailHelp">
                      <div *ngIf="payM['phone'].touched && payM['phone'].invalid">
                        <div *ngIf="payM['phone'].errors?.['required']" class="text-danger text-sm">El teléfono es requerido.</div>
                      </div>
                    </div>
                  </fieldset>
    
                  <fieldset class="border-0 p-3 mt-4">
                    <legend class="mt-3 mb-4" style="font-size: 19px; font-weight: 800;">Datos de la tarjeta</legend>
                    
                    <div class="form-group mt-5">
                      <label for="card_number">Número en la tarjeta</label>
                      <input type="number" class="form-control" formControlName="card_number" id="card_number" aria-describedby="emailHelp" placeholder="XXXX-XXXX-XXXX-XXXX">
                      <div *ngIf="payM['card_number'].touched && payM['card_number'].invalid">
                        <div *ngIf="payM['card_number'].errors?.['required']" class="text-danger text-sm">Los apellidos es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <label for="name_on_card">Nombre que aparece en la tarjeta</label>
                      <input type="text" class="form-control text-uppercase" formControlName="name_on_card" id="name_on_card" aria-describedby="emailHelp">
                      <div *ngIf="payM['name_on_card'].touched && payM['name_on_card'].invalid">
                        <div *ngIf="payM['name_on_card'].errors?.['required']" class="text-danger text-sm">Los apellidos es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group mt-3">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group mt-3">
                            <label for="expiration_month">Mes</label>
                            <!-- input type="text" class="form-control" formControlName="expiration_month" id="expiration_month" -->
                            <select class="form-select" (change)="updateMonth($event)">
                              <option value="">-</option>
                              <option *ngFor="let month of months" [value]="month.value">{{month.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group mt-3">
                            <label for="expiration_year">Año</label>
                            <!-- input type="text" class="form-control" formControlName="expiration_year" id="expiration_year" -->
                            <select class="form-select" (change)="updateYear($event)">
                              <option value="">-</option>
                              <option *ngFor="let year of years" [value]="year.value">{{year.name}}</option>
                            </select>
                          </div>
                        </div>                    
                        <div class="col-md-2">
                          <div class="form-group mt-3">
                            <label for="cvv">CVV</label>
                            <input type="password" class="form-control" formControlName="cvv" id="cvv" maxlength="4">
                            <div *ngIf="payM['cvv'].touched && payM['cvv'].invalid">
                              <div *ngIf="payM['cvv'].errors?.['required']" class="text-danger text-sm">Los apellidos es requerido.</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group mt-3 text-end">
                            <p><small>MÉTODOS DE PAGO ACEPTADOS</small></p>
                            <img src="assets/images/icon-visa-sm.png" alt="Card image cap" width="54" class="float-left me-2">
                            <img src="assets/images/icon-mastercard-sm.png" alt="Card image cap" width="54" class="float-left me-2">
                            <img src="assets/images/icon-amex-sm.png" alt="Card image cap" width="54" class="float-left me-2">
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="border-0 p-3 mt-4">
                    <legend class="mt-3 mb-4" style="font-size: 19px; font-weight: 800;">Dirección</legend>
                    
                    <div class="form-group mt-3">
                      <label for="address">Calle</label>
                      <input type="text" class="form-control" formControlName="address" id="address" aria-describedby="emailHelp" placeholder="Nombre">
                    </div>
                    <div class="form-group mt-3">
                      <label for="state">Estado</label>
                      <!-- input type="text" class="form-control" formControlName="state" id="state" aria-describedby="emailHelp" placeholder="Nombre" -->
                      <select class="form-select" (change)="updateState($event)">
                        <option value="">Selecciona un estado</option>
                        <option *ngFor="let state of edos" [value]="state.value">{{state.name}}</option>
                      </select>
                    </div>
                    <div class="form-group mt-3">
                      <label for="city">Ciudad</label>
                      <input type="text" class="form-control" formControlName="city" id="city" aria-describedby="emailHelp" placeholder="Nombre">
                    </div>
                    <div class="form-group mt-3">
                      <label for="zip_code">Código postal</label>
                      <input type="text" class="form-control" formControlName="zip_code" id="zip_code" aria-describedby="emailHelp" placeholder="Nombre">
                    </div>
                  </fieldset>
                  <div class="form-group mt-4 mb-4 text-end">
                    <button type="button" class="btn btn-link me-2">Cancelar</button>
                    <button type="submit" class="btn btn-success btn-lg rounded-5">Agregar tarjeta</button>
                  </div>
                </div>
              </div>          
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TenantEditPaymentMethodComponent implements OnInit {
  payMethod: FormGroup = new FormGroup({});
  tenant: any = {};

  edos: any[] = [
    { "name": "Aguascalientes", "value": "AG" },
    { "name": "Baja California", "value": "BC" },
    { "name": "Baja California Sur", "value": "BS" },
    { "name": "Campeche", "value": "CM" },
    { "name": "Chiapas", "value": "CS" },
    { "name": "Chihuahua", "value": "CH" },
    { "name": "Ciudad de México", "value": "CX" },
    { "name": "Coahuila", "value": "CO" },
    { "name": "Colima", "value": "CL" },
    { "name": "Durango", "value": "DG" },
    { "name": "Guanajuato", "value": "GT" },
    { "name": "Estado de Guerrero", "value": "GR" },
    { "name": "Estado de Hidalgo", "value": "HG" },
    { "name": "Jalisco", "value": "JC" },
    { "name": "Estado de México", "value": "EM" },
    { "name": "Michoacán", "value": "MI" },
    { "name": "Morelos", "value": "MO" },
    { "name": "Nayarit", "value": "NA" },
    { "name": "Nuevo León", "value": "NL" },
    { "name": "Oaxaca", "value": "OA" },
    { "name": "Puebla", "value": "PU" },
    { "name": "Querétaro Arteaga", "value": "QT" },
    { "name": "Quintana Roo", "value": "QR" },
    { "name": "San Luis Potosí", "value": "SL" },
    { "name": "Sinaloa", "value": "SI" },
    { "name": "Sonora", "value": "SO" },
    { "name": "Tabasco", "value": "TB" },
    { "name": "Tamaulipas", "value": "TM" },
    { "name": "Tlaxcala", "value": "TL" },
    { "name": "Veracruz", "value": "VE" },
    { "name": "Estado de Yucatán", "value": "YU" },
    { "name": "Zacatecas", "value": "ZA "}
  ];

  years: any[] = [
    { "name": "2024", "value": "2024" },
    { "name": "2025", "value": "2025" },
    { "name": "2026", "value": "2026" },
    { "name": "2027", "value": "2027" },
    { "name": "2028", "value": "2028" },
    { "name": "2029", "value": "2029" },
    { "name": "2030", "value": "2030" },
  ];

  months: any[] = [
    { "name": "01", "value": "01" },
    { "name": "02", "value": "02" },
    { "name": "03", "value": "03" },
    { "name": "04", "value": "04" },
    { "name": "05", "value": "05" },
    { "name": "06", "value": "06" },
    { "name": "07", "value": "07" },
    { "name": "08", "value": "08" },
    { "name": "09", "value": "09" },
    { "name": "10", "value": "10" },
    { "name": "11", "value": "11" },
    { "name": "12", "value": "12" }
  ];

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private settlementsService: SettlementsService,
    private paymentsService: PaymentsService
  ) {
    this.activateRoute.params.subscribe(params => {
      
      this.settlementsService.getTenant(params['id']).subscribe((data: any) => {
        console.log(`tenant =======> `, data);
        this.tenant = data;
        this.payMethod.patchValue({
          email: this.tenant.email,
          recurrent_customer_id: this.tenant.id,
          phone: this.tenant.phone,
          country: 'MX',
          card_type: 'visa',
        });
      });
    });
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.payMethod = this.fb.group({
      recurrent_customer_id: [''],
      email: [''],
      phone: [''],
      country: [''],
      state: [''],
      city: [''],
      address: [''],
      zip_code: [''],
      name_on_card: ['', Validators.required],
      card_number: ['', [Validators.required, Validators.minLength(12)]],
      expiration_year: ['', Validators.required],
      expiration_month: ['', Validators.required],
      cvv: ['', [Validators.required, Validators.maxLength(4)]],
      card_type: ['', Validators.required]
    });
  }

  get payM() {
    return this.payMethod.controls;
  }

  updateState(event: any) {
    this.payMethod.patchValue({
      state: event.target.value
    });
  }

  updateYear(event: any) {
    this.payMethod.patchValue({
      expiration_year: event.target.value
    });
  }

  updateMonth(event: any) {
    this.payMethod.patchValue({
      expiration_month: event.target.value
    });
  }

  onSubmit(pay: any) {
    console.log(pay);
    this.paymentsService.createCustomer(pay).subscribe((data: any) => {
      console.log(`data =======> `, data);
    });
  }
}
