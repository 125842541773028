import { map } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft, faFilePdf, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Settlement } from 'src/app/models/settlement.model';
import { Unit } from 'src/app/models/unit.model';
import Popper from 'popper.js';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settlement',
  template: `
    <div class="row">
    <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 mt-4">
      <div class="row mb-4 mt-0">
        <div  *ngIf="checkRouteUrl()">
          <xng-breadcrumb ></xng-breadcrumb>
          <!-- [separator]="iconTemplate" -->
          <!-- ng-template #iconTemplate>
            <fa-icon [icon]="faChevronLeft"></fa-icon>
          </!-->
        </div>

        <!-- div class="col-md-8">
          <button class="btn btn-link btn-sm" routerLink="/settlements">
            <fa-icon [icon]="faChevronLeft" class="text-muted"></fa-icon>
            Condominios
          </button>
          <h1 class="mt-4">Condominio</h1>
          <p>Consulta a los inquilinos y la estructura de los condominios.</p>
        </!-->
      </div>    
      <div class="card border-0 rounded-4 mb-5">
        <div class="card-body p-4">
          <div class="row">
            <div class="col-md-12">              
              <form [formGroup]="settlementForm" (ngSubmit)="updateSettlement()">
                <div class="row mt-3">
                  <div class="col-md-11 ms-2">
                    <h4>Editar condominio <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="ms-2 text-info"></fa-icon></h4>
                    <p>Configura la información del condominio. Estos datos se mostrarán en todas las aplicaciones. Para más información visita nuestar guía de uso.</p>
                  </div>
                  
                  <div class="form-group required mb-4 mt-3">
                    <label for="name" class="form-label ms-2">Nombre del condominio</label>
                    <input type="text" class="form-control form-control-lg" id="name" placeholder="Nombre del condominio" formControlName="name">
                    <div *ngIf="sfc['name'].touched && sfc['name'].invalid">
                      <div *ngIf="sfc['name'].errors?.['required']" class="text-danger text-sm">El nombre del condominio es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <label for="phone" class="form-label ms-2">Teléfono</label>
                    <input type="text" class="form-control form-control-lg" id="phone" placeholder="Teléfono de contacto" formControlName="phone">
                    <div *ngIf="sfc['phone'].touched && sfc['phone'].invalid">
                      <div *ngIf="sfc['phone'].errors?.['required']" class="text-danger text-sm">El teléfono del condominio es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <label for="email" class="form-label ms-2">Correo electrónico</label>
                    <input type="email" class="form-control form-control-lg" id="email" placeholder="Correo electrónico de contacto" formControlName="email">
                    <div *ngIf="sfc['email'].touched && sfc['email'].invalid">
                      <div *ngIf="sfc['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico del condominio es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <label for="email" class="form-label ms-2">Nombre (Administrador)</label>
                    <input type="email" class="form-control form-control-lg rounded-3" id="email" placeholder="Nombre administrador de contacto" formControlName="admin_name">
                    <div *ngIf="sfc['email'].touched && sfc['email'].invalid">
                      <div *ngIf="sfc['email'].errors?.['required']" class="text-danger text-sm">El Nombre del condominio es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <label for="website" class="form-label ms-2">Sitio web</label>
                    <input type="url" class="form-control form-control-lg" id="website" placeholder="https://www.sitioweb.com" formControlName="website">
                    <div *ngIf="sfc['website'].touched && sfc['website'].invalid">
                      <div *ngIf="sfc['website'].errors?.['required']" class="text-danger text-sm">El sitio web del condominio es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <label for="description" class="form-label">Descripción</label>
                    <textarea class="form-control form-control-lg" id="description" formControlName="description" rows="3" ></textarea>
                  </div>

                  <div class="col-md-11 mt-3">
                    <div class="rounded-3 p-4 border border-1 clearfix" [ngStyle]="{ 'background-color': color }">
                      <div class="border rounded-4 float-start me-3" style="width: 84px; height: 84px; overflow: hidden;">
                        <img [src]="imageURL" width="100%">
                      </div>
                      <h3 class="text-dark mt-2">{{sfc['name'].value}}</h3>
                      <input type="file" id="logo" (change)="loadImage($event)"> <!-- class="inputfile" -->
                      <!-- label for="file"><strong>Cargar imagen</strong></!-->
                    </div>
                    <p class="mt-2 text-muted">Nota: Este archivo debe ser en .PNG y las dimensiones mínimas de la imagen es de 514 x 514 y máximas de 2080 x 2080.</p>
                  </div>
                  <div class="col-md-1 mt-3">
                    <input [value]="color" [style.background]="color" class="rounded-2 border-1 border-secondary shadow" [ngStyle]="{ 'color': color }" (change)="updateColor($event)" style="width: 34px; height: 34px;"/>
                  </div>

                  
                  <h4 class="mt-4">Configuración del condominio</h4>
                  <hr>
                  <div class="form-group required mb-4">
                    <label for="factor" class="form-label ms-2">Factor</label>
                    <input type="text" class="form-control form-control-lg" id="factor" placeholder="" formControlName="factor">
                    <div *ngIf="sfc['factor'].touched && sfc['factor'].invalid">
                      <div *ngIf="sfc['factor'].errors?.['required']" class="text-danger text-sm">El factor es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                    <label for="admin_price" class="form-label ms-2">Costo administrativo ($ IVA incluido)</label>
                    <input type="text" class="form-control form-control-lg" id="admin_price" placeholder="" formControlName="admin_price">
                    <div *ngIf="sfc['admin_price'].touched && sfc['admin_price'].invalid">
                      <div *ngIf="sfc['admin_price'].errors?.['required']" class="text-danger text-sm">El costo administrativo es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                    <label for="interests" class="form-label ms-2">Cargos moratorios (%)</label>
                    <input type="text" class="form-control form-control-lg" id="interests" placeholder="" formControlName="interests">
                    <div *ngIf="sfc['interests'].touched && sfc['interests'].invalid">
                      <div *ngIf="sfc['interests'].errors?.['required']" class="text-danger text-sm">El factor es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                    <label for="reconexion_fee" class="form-label ms-2">Costo de reconexión ($ IVA incluido)</label>
                    <input type="text" class="form-control form-control-lg" id="reconexion_fee" placeholder="" formControlName="reconexion_fee">
                    <div *ngIf="sfc['reconexion_fee'].touched && sfc['reconexion_fee'].invalid">
                      <div *ngIf="sfc['reconexion_fee'].errors?.['required']" class="text-danger text-sm">El costo de reconexión es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                    <label for="discount" class="form-label ms-2">Descuento por litro ($ IVA incluido)</label>
                    <input type="text" class="form-control form-control-lg" id="discount" placeholder="" formControlName="discount">
                    <div *ngIf="sfc['discount'].touched && sfc['discount'].invalid">
                      <div *ngIf="sfc['discount'].errors?.['required']" class="text-danger text-sm">El descuento es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                    <label for="invoice_limit" class="form-label ms-2">Días para solicitud de factura</label>
                    <input type="number" class="form-control form-control-lg" id="invoice_limit" placeholder="" formControlName="invoice_limit">
                    <div *ngIf="sfc['invoice_limit'].touched && sfc['invoice_limit'].invalid">
                      <div *ngIf="sfc['invoice_limit'].errors?.['required']" class="text-danger text-sm">Los días límite para solicitar factura es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                    <label for="credit" class="form-label ms-2">Días de crédito</label>
                    <input type="number" class="form-control form-control-lg" id="credit" placeholder="" formControlName="credit">
                    <div *ngIf="sfc['credit'].touched && sfc['credit'].invalid">
                      <div *ngIf="sfc['credit'].errors?.['required']" class="text-danger text-sm">El crédito es requerido.</div>
                    </div>
                  </div>
                  <div class="form-group required mb-4">
                        <label for="tampering_fine" class="form-label ms-2">Multa por manipulación</label>
                        <input type="number" class="form-control form-control-lg" id="tampering_fine" placeholder="" formControlName="tampering_fine">
                        <div *ngIf="sfc['tampering_fine'].touched && sfc['tampering_fine'].invalid">
                          <div *ngIf="sfc['tampering_fine'].errors?.['required']" class="text-danger text-sm">El crédito es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-4">
                        <label for="bank_name" class="form-label ms-2">Nombre Banco</label>
                        <input type="test" class="form-control form-control-lg" id="bank_name" placeholder="" formControlName="bank_name">
                        <div *ngIf="sfc['bank_name'].touched && sfc['bank_name'].invalid">
                          <div *ngIf="sfc['bank_name'].errors?.['required']" class="text-danger text-sm">El crédito es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-4">
                        <label for="bank_clabe" class="form-label ms-2">CLABE Banco</label>
                        <input type="text" class="form-control form-control-lg" id="bank_clabe" placeholder="" formControlName="bank_clabe">
                        <div *ngIf="sfc['bank_clabe'].touched && sfc['bank_clabe'].invalid">
                          <div *ngIf="sfc['bank_clabe'].errors?.['required']" class="text-danger text-sm">El crédito es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-4">
                        <label for="merchant_id" class="form-label ms-2">Motor de pago</label>
                        <select class="form-select form-select-lg" id="merchant_id" placeholder="" formControlName="merchant_id">
                          <option value="" disabled>Selecciona un motor de pago</option>
                          <option value="actnet">Actnet</option>
                          <option value="openpay">Openpay</option>
                        </select>
                        <div *ngIf="sfc['merchant_id'].touched && sfc['merchant_id'].invalid">
                          <div *ngIf="sfc['merchant_id'].errors?.['required']" class="text-danger text-sm">El crédito es requerido.</div>
                        </div>
                      </div>
                  <div class="form-group required mb-4">
                      <label for="cycle_day" class="form-label ms-2">Priodicidad de ciclo</label>
                      <select class="form-select form-select-lg" formControlName="periodicity">
                        <option value="" disabled>Selecciona una periodicidad</option>
                        <option *ngFor="let period of periodicity" [value]="period.id">{{period.name}}</option>
                      </select>
                      <div *ngIf="sfc['cycle_day'].touched && sfc['cycle_day'].invalid">
                        <div *ngIf="sfc['cycle_day'].errors?.['required']" class="text-danger text-sm">La perodicidad es requerido.</div>
                      </div>
                    </div>
                    <div class="form-group required mb-4" *ngIf="sfc['periodicity'].value == 3">
                        <label for="cycle_day" class="form-label ms-2">Inicio de ciclo (Día del mes)</label>
                        <input type="number" class="form-control form-control-lg" id="cycle_day" placeholder="" formControlName="cycle_day">
                        <div *ngIf="sfc['cycle_day'].touched && sfc['cycle_day'].invalid">
                          <div *ngIf="sfc['cycle_day'].errors?.['required']" class="text-danger text-sm">El día es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-4" *ngIf="sfc['periodicity'].value != 3">
                        <label for="cycle_day" class="form-label ms-2">Inicio de ciclo (Día de la semana)</label>
                        <select class="form-select form-select-lg" formControlName="cycle_day">
                          <option value="" disabled>Selecciona una periodicidad</option>
                          <option *ngFor="let day of weekDays" [value]="day.id">{{day.name}}</option>
                        </select>
                        <div *ngIf="sfc['cycle_day'].touched && sfc['cycle_day'].invalid">
                          <div *ngIf="sfc['cycle_day'].errors?.['required']" class="text-danger text-sm">La perodicidad es requerido.</div>
                        </div>
                      </div>
                      <div class="form-group required mb-4">
                        <label for="corte" class="form-label ms-2">Corte a partir de periodos de adeudos</label>
                        <input type="number" class="form-control form-control-lg" id="corte" placeholder="" formControlName="corte">
                        <div *ngIf="sfc['corte'].touched && sfc['corte'].invalid">
                          <div *ngIf="sfc['corte'].errors?.['required']" class="text-danger text-sm">El periodo de corte es requerido.</div>
                        </div>
                      </div>
                  <div class="form-group required mb-4">
                    <label for="zone_id" class="form-label ms-2">Zona de distribución</label>
                    <select class="form-select form-select-lg" formControlName="zone_id">
                      <option value="" disabled>Selecciona una zona</option>
                      <option *ngFor="let zone of availZones" [value]="zone.id">{{zone.name}}</option>
                    </select>
                    <div *ngIf="sfc['zone_id'].touched && sfc['zone_id'].invalid">
                      <div *ngIf="sfc['zone_id'].errors?.['required']" class="text-danger text-sm">Los días límite para solicitar factura es requerido.</div>
                    </div>
                  </div>
                  <section class="mt-3">
                    <section app-contract-unit 
                      [settlement]="settlement"                          
                    ></section>
                  </section>
                  <!-- div class="form-group mb-4" *ngIf="!sfc['contrato'].value">
                    <label for="name" class="form-label ms-2">Contrato del condominio</label>
                    <input type="file" class="form-control form-control-lg" (change)="loadPDF($event)" >
                  </!-->
                  
                  <!-- div class="form-group mb-4" *ngIf="sfc['contrato'].value">
                    <label for="name" class="form-label ms-2">Contrato del condominio</label><br>
                    <button type="button" class="btn btn-link ms-2" (click)="downloadContract()">
                      <fa-icon [icon]="faFilePdf" class="text-primary me-2"></fa-icon>
                      Descargar contrato
                    </button>
                    <button type="button" class="btn btn-link ms-2" (click)="removeContrato()">
                      <fa-icon [icon]="faTrashAlt" class="text-danger ms-2"></fa-icon>
                    </button>
                  </!-->

                  <div class="form-group mb-4" >
                    <label for="vigencia" class="form-label ms-2">Vigencia del contrato</label>
                    <input type="date" class="form-control form-control-lg" formControlName="vigencia">
                    <div *ngIf="sfc['vigencia'].touched && sfc['vigencia'].invalid">
                      <div *ngIf="sfc['vigencia'].errors?.['required']" class="text-danger text-sm">La vigencia del contrato es requerido.</div>
                    </div>
                  </div>
                  
                  <div class="form-group mb-4" *ngIf="!identificacion">
                    <label for="name" class="form-label ms-2">Identificación</label>
                    <input type="file" class="form-control form-control-lg" (change)="loadIDPDF($event)" >
                  </div>
                  <!-- *ngIf="!sfc['identification'].value" -->
                  <div class="form-group mb-4" *ngIf="identificacion">
                    <label for="name" class="form-label ms-2">Identificación</label><br>
                    <button type="button" class="btn btn-link ms-2" (click)="downloadID()">
                      <fa-icon [icon]="faFilePdf" class="text-primary me-2"></fa-icon>
                      Descargar identificación
                    </button>
                    <button type="button" class="btn btn-link ms-2" (click)="removeID()">
                      <fa-icon [icon]="faTrashAlt" class="text-danger ms-2"></fa-icon>
                    </button>
                  </div>
                  
                  <h4 class="mt-4">Dirección del condominio</h4>
                  <hr>
                  <div class="form-group mb-4">
                    <label for="name" class="form-label ms-2">Calle del condominio</label>
                    <input type="text" class="form-control form-control-lg" id="name" placeholder="Dirección del condominio" formControlName="address">
                  </div>
                  <div class="form-group mb-4">
                    <label for="country" class="form-label ms-2">País</label>
                    <input type="text" class="form-control form-control-lg" id="country" placeholder="País del condominio" formControlName="country">
                  </div>
                  <div class="form-group mb-4">
                    <label for="state" class="form-label ms-2">Estado</label>
                    <input type="text" class="form-control form-control-lg" id="state" placeholder="Estado del condominio" formControlName="state">
                  </div>
                  <div class="form-group mb-4">
                    <label for="city" class="form-label ms-2">Ciudad</label>
                    <input type="text" class="form-control form-control-lg" id="city" placeholder="Ciudad del condominio" formControlName="city">
                  </div>
                  <div class="form-group mb-4">
                    <label for="zip" class="form-label ms-2">Código postal</label>
                    <input type="number" maxlength="6" class="form-control form-control-lg" id="zip" placeholder="Código postal del condominio" formControlName="zip">
                  </div>
                  <div class="form-group mb-4">
                    <label for="latitude" class="form-label ms-2">Latitude</label>
                    <input type="number" class="form-control form-control-lg" id="latitude" placeholder="Latitude del condominio" formControlName="latitude">
                  </div>
                  <div class="form-group mb-4">
                    <label for="longitude" class="form-label ms-2">Longitude</label>
                    <input type="number" maxlength="6" class="form-control form-control-lg" id="longitude" placeholder="Longitude del condominio" formControlName="longitude">
                  </div>
                  <div class="col-12 mt-4">
                    <div class="form-group text-end">
                      <button type="button" class="btn btn-link me-2" routerLink="/condominios">Cancelar</button>
                      <button type="submit" class="btn btn-success btn-lg rounded-5 w-25 border-0 p-3" style="font-weight: 400 !important; font-size: 17px;">Actualizar</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>        
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class SettlementComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  units: Unit[] = [];
  settlement: Settlement | undefined;
  buildings: any[] = [];
  floors: any[] = [];
  color: any = "";
  profile: any = {};

  loading: boolean = false;
  imageURL: any = '';
  link: string = '';
  identificacion: string = '';

  imageError: string = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";
  previewImagePath: string = "";

  settlementForm: FormGroup = new FormGroup({});

  availZones: any[] = [];

  periodicity: any[] = [
    { id: 1, name: 'Semanal' },
    { id: 2, name: 'Quincenal' },
    { id: 3, name: 'Mensual' },
  ]

  weekDays: any[] = [
    { id: 1, name: 'Lunes' },
    { id: 2, name: 'Martes' },
    { id: 3, name: 'Miércoles' },
    { id: 4, name: 'Jueves' },
    { id: 5, name: 'Viernes' },
    { id: 6, name: 'Sábado' },
    { id: 7, name: 'Domingo' },
  ]

  faInfoCircle = faInfoCircle;
  faCircleNotch = faCircleNotch;
  faChevronLeft = faChevronLeft;
  faFilePdf = faFilePdf;
  faTrashAlt = faTrashAlt;

  constructor(
    private store: SettlementsService,
    private route: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.loading = true;
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.activatedRoute.params.subscribe((params: any) => {
      this.loading = true;
      if (params['id'] != 'new') {
        this.store.getSettlement(params['id']).subscribe({
          next: (data: any) => {
            console.log(data);

            this.settlement = data;
            this.settlementForm.patchValue(data);
            this.color = data.code;
            this.loading = false;

            if (data.image) {
              this.imageURL = `${this.apiUrl}/storage/${data.image?.blob.key.substring(0,2)}/${data.image?.blob.key.substring(2,4)}/${data.image?.blob.key}`;
            }

            if (data.contrato) {
              console.log(data.contrato);
              this.link = `${this.apiUrl}/storage/${data.contrato?.blob.key.substring(0,2)}/${data.contrato?.blob.key.substring(2,4)}/${data.contrato?.blob.key}`;
            }

            if (data.identificacion) {
              console.log(data.identificacion);
              this.identificacion = `${this.apiUrl}/storage/${data.identificacion?.blob.key.substring(0,2)}/${data.identificacion?.blob.key.substring(2,4)}/${data.identificacion?.blob.key}`;
            }

            console.log("link => ", this.link);
            /*this.store.getBuildingsBySettlement(this.settlement.id).subscribe((data: any) => {
              console.log(data);
              this.buildings = data;
              this.buildings.map((building: any) => {
                this.store.getFloorsByBuilding(building.id).subscribe((data: any) => {
                  console.log(data);
                  building.floors = data;
                  building.floors.map((floor: any) => {
                    this.store.getUnitsByFloor(floor.id).subscribe((data: any) => {
                      console.log(data);
                      floor.units = data;
                      // this.units = this.units.concat(data);
                    });
                  });
                });
              });
            });*/
          },
          error: (error: any) => {
            this.loading = false;
            console.log(error);
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.buildSettlementForm();
    this.loadZones();
    this.breadcrumbService.set('@condominioName', this.settlementForm.value.name ? this.settlementForm.value.name : 'Editando condominio');
  }

  checkRouteUrl() {
    this.breadcrumbService.set('@condominioName', this.settlementForm.value.name ? this.settlementForm.value.name : 'Editando condominio');
    return this.route.url == `/condominios/${this.settlement?.id}`;
  }

  buildSettlementForm() {
    this.settlementForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      phone: [''],
      email: [''],
      website: [''],
      admin_name: [''],
      description: [''],
      slug: [''],
      code: [''],
      factor: ['', Validators.required],
      admin_price: ['', Validators.required],
      discount: ['', Validators.required],
      interests: ['', Validators.required],
      invoice_limit: ['', Validators.required],
      reconexion_fee: ['', Validators.required],
      periodicity: ['', Validators.required],
      cycle_day: ['', Validators.required],
      country: [''],
      city: [''],
      state: [''],
      zip: [''],
      identificacion: [''],
      contrato: [''],
      vigencia: ['', Validators.required],
      tipo: [''],
      latitude: [''],
      longitude: [''],
      utility_id: [''],
      zone_id: [''],
      corte: [''],
      tampering_fine: [''],
      merchant_id: [''],
      bank_clabe: [''],
      bank_name: [''],
      credit: ['', Validators.required]
    });
  }

  get sfc () {
    return this.settlementForm.controls;
  }

  loadZones() {
    console.log("PROFILE ===> ", this.profile.profile.utility_id);
    this.store.getUtilitySettings({ utility_id: this.profile.profile.utility_id }).subscribe((data: any) => {
      console.log("ZONE =====> ", data.data);
      let zones: any = data.data;
      zones.map((zone: any) => {
        console.log("zone => ", zone.zone_id);
        if (zone.zone_id) {
          console.log("ABLE TO PUSH");
          this.store.getZone(zone.zone_id).subscribe((data: any) => {
            console.log("ZONE DATA =====> ", data);
            this.availZones.push(data);
          });
        }        
      });

      console.log("ZONES AVAILABLE =======> ", this.availZones);
    });
  }

  loadImage(event: any): void {
    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        // return false;
      }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          // console.log(img_height, img_width);
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            // return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            console.log('imgBase64Path => ', imgBase64Path);
            console.log('previewImagePath => ', this.previewImagePath);
            let payload = {
              id: this.settlement?.id,
              data: this.previewImagePath
            }
            // console.log('event => ', event.target.files[0]);
            this.store.updateSettlementImage(this.settlement?.id, payload).subscribe((data: any) => {
              console.log('data => ', data);
              this.toastr.success('Se actualizó la imagen correctamente.', 'Éxito');
              // this.loadUser(this.userId);
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  loadPDF(event: any): void {
    const reader = new FileReader();
    const ctl = this;
    var base64;
    var fileToLoad = event.target.files[0];
    reader.onload = function(fileLoadedEvent) {
      base64 = fileLoadedEvent.target!.result;
      // Print data in console
      console.log(base64);
      ctl.settlementForm.patchValue({
        contrato: base64
      });
      let payload = {
        id: ctl.settlement?.id,
        data: base64
      }

      ctl.store.updateSettlementContrato(ctl.settlement?.id, payload).subscribe((data: any) => {
        console.log('data => ', data);
        ctl.toastr.success('Se actualizó la imagen correctamente.', 'Éxito');
        // this.loadUser(this.userId);
      });
    };
    // Convert data to base64
    reader.readAsDataURL(fileToLoad);
  }

  loadIDPDF(event: any): void {
    const reader = new FileReader();
    const ctl = this;
    var base64;
    var fileToLoad = event.target.files[0];
    reader.onload = function(fileLoadedEvent) {
      base64 = fileLoadedEvent.target!.result;
      // Print data in console
      console.log(base64);
      ctl.settlementForm.patchValue({
        identification: base64
      });
      let payload = {
        id: ctl.settlement?.id,
        data: base64
      }
      ctl.store.updateSettlementIdentificacion(ctl.settlement?.id, payload).subscribe((data: any) => {
        console.log('data => ', data);
        ctl.toastr.success('Se actualizó la imagen correctamente.', 'Éxito');
        // this.loadUser(this.userId);
      });
    };
    // Convert data to base64
    reader.readAsDataURL(fileToLoad);
  }

  linkPDF() {
    let el = this.settlementForm.value.contrato;
    this.link = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
  }

  downloadContract() {
    let contrato: any = this.settlementForm.value.contrato;
    let imageUrl = `${this.apiUrl}/storage/${contrato.blob.key.substring(0,2)}/${contrato.blob.key.substring(2,4)}/${contrato.blob.key}`;
      this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
        let contratBase64 = "data:application/octet-stream;base64," + result.split(',')[1];

        const link = document.createElement('a');
        link.href = contratBase64;
        link.download = 'contrato.pdf';
        link.click();
      });
  }

  removeContrato() {
    this.settlementForm.patchValue({
      contrato: null,
    });
    this.link = '';
  }

  downloadID() {
    let identificacion: any = this.settlementForm.value.identificacion;
    let imageUrl = `${this.apiUrl}/storage/${identificacion.blob.key.substring(0,2)}/${identificacion.blob.key.substring(2,4)}/${identificacion.blob.key}`;
      this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
        let contratBase64 = "data:application/octet-stream;base64," + result.split(',')[1];

        const link = document.createElement('a');
        link.href = contratBase64;
        link.download = 'identificacion.pdf';
        link.click();
      });
  }

  removeID() {
    this.settlementForm.patchValue({
      identification: null,
    });
    this.identificacion = '';
  }

  updateColor(e: any): void {
    console.log(e.target);
    this.settlementForm.patchValue({
      code: e.target.value
    });
  }

  updateSettlement() {
    this.loading = true;
    this.settlementForm.patchValue({
      code: this.color
    });
    console.log(this.settlementForm.value);

    if (this.settlementForm.invalid) {
      this.settlementForm.markAllAsTouched();
      this.toastr.error('No está completa la información.', 'Error');
      this.loading = false;
      return;
    }

    this.store.updateSettlement(this.settlement?.id, this.settlementForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Se ha actualizado el condominio.', 'Éxito');
        this.loading = false;
        this.route.navigate(['/condominios']);
      },
      error: (e: any) => {
        this.toastr.error(`Ocurrió un error por favor intenta de nuevo más tarde. \n ${e}`, 'Error');
        this.loading = false;
      }     
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    let res = await fetch(imageUrl);
    let blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
