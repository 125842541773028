import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-user-form',
  template: `
  <ng-template #modalUserForm let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Usuario</h5>
      <button type="button" (click)="dismiss()" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
        <fa-icon [icon]="icons.faTimes"></fa-icon>
      </button>
    </div>
    <div class="modal-body py-3 px-4">
      <form [formGroup]="technicianForm" (ngSubmit)="onSubmit()">
        <div class="avatar-container text-center">
          <div class="image-container border border-4">
            <img [src]="avatarURL" width="100%">
          </div> 
          <label for="avatar-input-file">
            <div class="icon-wrapper">
              <input type="file" id="avatar-input-file" (change)="loadAvatar($event)" accept="image/*"/>
              <fa-icon [icon]="icons.faEdit" class="text-white"></fa-icon>
            </div>
          </label>
        </div>
        <div class="form-group">
          <label class="form-label" for="email">Correo electrónico</label>
          <input type="text" formControlName="email" class="form-control" id="email" placeholder="">
          <div *ngIf="ufc['email'].touched && ufc['email'].invalid">
            <div *ngIf="ufc['email'].errors?.['required']" class="text-danger text-sm">El correo electrónico es requerido.</div>
          </div>
        </div>
        <div class="form-group mt-3">
          <label class="form-label" for="phone">Teléfono</label>
          <input type="text" formControlName="phone" class="form-control" id="phone" placeholder="">
        </div>
        <div class="form-group mt-3">
          <label class="form-label" for="name">Nombre</label>
          <input type="text" formControlName="name" class="form-control" id="name" placeholder="">
          <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
            <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
          </div>
        </div>
        <div class="form-group mt-3">
          <label class="form-label" for="last_name">Apellido Paterno</label>
          <input type="text" formControlName="last_name" class="form-control" id="last_name" placeholder="">
          <div *ngIf="ufc['last_name'].touched && ufc['last_name'].invalid">
            <div *ngIf="ufc['last_name'].errors?.['required']" class="text-danger text-sm">El apellido es requerido.</div>
          </div>
        </div>
        <div class="form-group mt-3">
          <label class="form-label" for="last_name_m">Apellido Materno<span class="text-muted text-sm ms-2">(opcional)</span></label>
          <input type="text" formControlName="last_name_m" class="form-control" id="last_name_m" placeholder="">
          <div *ngIf="ufc['last_name_m'].touched && ufc['last_name_m'].invalid">
            <div *ngIf="ufc['last_name_m'].errors?.['required']" class="text-danger text-sm">El apellido es requerido.</div>
          </div>
        </div>
        <div class="form-group mt-3">
          <label class="form-label" for="employee_number"># Empleado<span class="text-muted text-sm ms-2">(opcional)</span></label>
          <input type="text" formControlName="employee_number" class="form-control" id="employee_number" placeholder="">
        </div>
        <div class="form-group mt-3">
          <label class="form-label" for="licence">Licencia<span class="text-muted text-sm ms-2">(opcional)</span></label>
          <input type="text" formControlName="licence" class="form-control" id="licence" placeholder="">
        </div>
        <div class="form-group mt-3 mb-3">
          <label class="form-label" for="licence_type">Tipo de licencia<span class="text-muted text-sm ms-2">(opcional)</span></label>
          <input type="text" formControlName="licence_type" class="form-control" id="licence_type" placeholder="">
        </div>        
      </form>
    </div>
    <div class="modal-footer">
      <div class="form-group text-end">
        <button type="submit" class="btn btn-outline-success" (click)="onSubmit()">
          <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="loading" class="ms-3"></fa-icon>
          <fa-icon [icon]="icons.faSave" *ngIf="!loading" class="ms-3"></fa-icon>
          Guardar
        </button>
      </div>
    </div>
  </ng-template>
  `,
  styleUrls: []
})
export class ModalUserFormComponent implements AfterViewInit {
  @Input() user: any;
  @Input() profile: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalUserForm') modalUserForm !: ElementRef;

  private modalRef: NgbModalRef | undefined;

  loading: boolean = false;
  avatarURL: any = '';

  technicianForm: FormGroup = new FormGroup({});
  profiles: any = [];
  roles: any = [];
  imageError: string = "";

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService,
    private modalService: NgbModal
  ) { }

  ngAfterViewInit(): void {
    this.buildForm();
    this.loadProfiles();
  }

  buildForm() {
    this.technicianForm = this.fb.group({
      id: [''],
      email: ['', Validators.required],
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      last_name_m: [''],
      employee_number: [''],
      phone: [''],
      licence: [''],
      licence_type: [''],
      role_id: ['3'],
      profile_id: [''],
    });
  }

  get ufc() { return this.technicianForm.controls; }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user'] && changes['user'].currentValue) {
      this.technicianForm.patchValue(changes['user'].currentValue);
      // this.avatarURL = changes['technician'].currentValue.avatar ? this.img.buildURL(changes['technician'].currentValue.avatar) : '';
    }
  }

  loadProfiles() {
    this.store.getProfiles({ page: 1, per_page: 100 }).subscribe((data: any) => {
      this.profiles = data.data;
    });
  }

  updateRole(event: any) {
    if (event.target.value != 1) {
      // this.loadSettlements();
    }
  }

  onSubmit() {
    this.loading = true;
    if (this.technicianForm.invalid) {
      this.technicianForm.markAllAsTouched();
      this.loading = false;
      this.toastr.error('Error', 'Formulario incompleto.');
      return;
    }

    this.technicianForm.patchValue({
      profile_id: this.profile.id
    });

    
    this.store.updateUser(this.user.id, this.technicianForm.value).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Usuario actualizado correctamente.');
        this.dismiss();
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar el usuario');
      }
    });

    this.loading = false;
  }

  loadAvatar(event: any): void {
    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
      }

      const reader = new FileReader();
      /*reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            console.log('imgBase64Path => ', imgBase64Path);
            console.log('previewImagePath => ', this.previewImagePath);
            let payload = {
              id: this.userId,
              data: this.previewImagePath
            }
            this.store.updateAvatar(this.userForm.value.id, payload).subscribe((data: any) => {              
            });
          }
        };
      };*/

      reader.readAsDataURL(event.target.files[0]);
    }    
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }

}
