import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TasksService } from 'src/app/services/tasks.service';
import {DndDropEvent} from 'ngx-drag-drop';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-builder',
  template: `
    <div class="row">
      <div class="col-md-7">
        <div class="card border-0 rounded-4 shadow">
          <div class="card-body p-4">
            <form [formGroup]="builderForm">
              <div class="row">
                <div class="card mb-3 rounded-4 shadow">
                  <div class="card-body">
                  <h5 class="mb-0">Nombre</h5>
                    <input type="text" class="form-control form-control-lg mt-3" formControlName="name">
                    <div class="form-group mt-3">
                      <label for="depth" class="form-control-label">Detalle</label>
                      <select class="form-select form-select-lg" formControlName="depth">
                        <option value="1">Condominio</option>
                        <option value="2">Departamento</option>
                        <option value="3">Tanques</option>
                      </select>
                    </div>
                  </div>                  
                </div>
                <div class="card mb-3 rounded-4 shadow">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-12">
                      <h5 class="mb-0">Check-in</h5>
                        <div class="form-check form-switch mt-2 ps-1">
                          <!-- [checked]="permissions.menu.admin" (change)="updateMenu($event)" -->
                          <input class="form-check-input form-check-input-lg float-start me-3 ms-0" type="checkbox" formControlName="proximity">
                          <label class="form-check-label" for="flexCheckDefault">
                            <span>Validación de proximidad</span>
                          </label>
                        </div>
                        <div class="form-group mt-3">
                          <select class="form-select form-select-lg" formControlName="checkin">
                            <option value="" disabled selected>Selecciona un check-in</option>
                            <option *ngFor="let checkin of checkins" [value]="checkin.id">{{checkin.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-3 rounded-4 shadow">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-12">
                      <h5 class="mb-0">Actividades</h5>
                        <div class="list-group">
                          <div class="list-group-item" *ngFor="let activity of setActivities">
                            <div class="row">
                              <div class="col-6">
                                {{activity.name}}
                              </div>
                              <div class="col-6 text-end">
                                {{activity.weight}}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="list-group border-2 mt-3 border-secondary" style="border-style: dashed;">
                          <section
                            dndDropzone
                            (dndDragover)="onDragover($event)"
                            (dndDrop)="onDrop($event)"
                            class="p-3"
                            >

                            Arrastra las actividades aquí

                            <!--optional placeholder element for dropzone-->
                            <!--will be removed from DOM on init-->
                            <div
                              style="border: 1px gray solid; border-radius: 5px; padding: 15px;"
                              dndPlaceholderRef>
                              Actividad
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-0 rounded-4 shadow">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-12">
                      <h5 class="mb-0 ">Check-out</h5>
                      <div class="form-group mt-3">
                          <select class="form-select form-select-lg" formControlName="checkout">
                            <option value="" disabled selected>Selecciona un check-out</option>
                            <option *ngFor="let checkout of checkouts" [value]="checkout.id">{{checkout.name}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-3 text-end">
                  <button type="button" class="btn btn-success btn-lg rounded-4" (click)="saveBuilder()">Guardar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card border-0 rounded-4 shadow">
          <div class="card-header">
            <h5 class="mb-0">Actividades</h5>
          </div>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-md-12">
                <div class="list-group" *ngIf="activities.length>0">
                  <div class="list-group-item" *ngFor="let activity of activities">
                    <div
                      [dndDraggable]="draggable.data"
                      [dndDisableIf]="draggable.disable"
                      (dndStart)="onDragStart($event)"
                      (dndCopied)="onDraggableCopied($event)"
                      (dndLinked)="onDraggableLinked($event)"
                      (dndMoved)="onDraggableMoved($event)"
                      (dndCanceled)="onDragCanceled($event)"
                      (dndEnd)="onDragEnd(activity)">
                      {{activity.name}}
                      <!--if [dndHandle] is used inside dndDraggable drag can only start from the handle-->
                      <div
                        *ngIf="draggable.handle"
                        dndHandle>HANDLE
                      </div>

                      <!-- draggable ({{draggable.effectAllowed}}) <span [hidden]="!draggable.disable">DISABLED</span> -->

                      <!--optionally select a child element as drag image-->
                      <!-- div dndDragImageRef>Arrastrando</ -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class FormBuilderComponent {
  loading: boolean = false;
  activities: any[] = [];
  checkins: any[] = [];
  checkouts: any[] = [];
  setActivities: any[] = [];

  @Input() builder: any = {};
  @Output() builderData = new EventEmitter<any>();
  

  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };

  builderForm: FormGroup = new FormGroup({});

  constructor(
    private tasksService: TasksService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
  this.initForm();
    this.loadActivities();
  }

  initForm() {
    this.builderForm = this.fb.group({
      name: ['', Validators.required],
      status: [true],
      checkin: ['', Validators.required],
      checkout: [''],
      depth: [''],
      proximity: [false],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changes ==> ", changes);
    if (changes['builder'] && changes['builder'].currentValue) {
      this.builderForm.patchValue(changes['builder'].currentValue);
      this.builderForm.controls['checkin'].setValue(changes['builder'].currentValue.checkin_type);
      console.log("this.builderForm ==> ", this.builderForm.value);
      
      this.setActivities = changes['builder'].currentValue.activities;
    }
  }

  loadActivities() {
    this.tasksService.getActivities({}).subscribe({
      next: (activities: any) => {
        this.checkins = activities.data.filter((activity: any) => activity.activity_type_id === 1);
        this.checkouts = activities.data.filter((activity: any) => activity.activity_type_id === 2);
        this.activities = activities.data.filter((activity: any) => activity.activity_type_id === 3);

        console.log("this.checkins ==> ", this.checkins);
      },
      error: (err: any) => {},
    });
  }
  
  loadCheckins() {
    this.tasksService.getActivities({}).subscribe({
      next: (checkins: any) => {
        this.checkins = checkins.data;
      },
      error: (err: any) => {},
    });
  }

  saveBuilder() {
    let acts: any = [];
    this.setActivities.map((activity: any) => {
      acts.push(activity.id);
    });

    console.log('form value ==> ', this.builderForm.value);

    if (this.builderForm.invalid) {
      this.toastr.error('Por favor, completa los campos requeridos', 'Error');
      return;
    }

    let payload: any = {
      profile_id: 1,
      name: this.builderForm.value.name,
      status: this.builderForm.value.status,
      checkin_type: parseInt(this.builderForm.value.checkin),
      checkout: parseInt(this.builderForm.value.checkout),
      proximity: this.builderForm.value.proximity,
      depth: parseInt(this.builderForm.value.depth),
      activities: acts
    };

    if (payload.activities.length === 0) {
      this.toastr.error('Por favor, selecciona al menos una actividad', 'Error');
      return;
    }

    console.log("payload ==> ", payload);
    this.builderData.emit(payload);
  }

  onDragStart(event: DragEvent) {

    console.log("drag started", JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {
    this.setActivities.push(event);
    console.log("drag ended", JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {

    console.log("draggable copied", JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {

    console.log("draggable linked", JSON.stringify(event, null, 2));
  }

  onDraggableMoved(event: DragEvent) {

    console.log("draggable moved", JSON.stringify(event, null, 2));
  }

  onDragCanceled(event: DragEvent) {

    console.log("drag cancelled", JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {

    console.log("dragover", JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent) {
    
    console.log("dropped", JSON.stringify(event, null, 2));
  }
}
