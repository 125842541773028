import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-form-order',
  template: `
    <form [formGroup]="formOrder" (ngSubmit)="saveorder()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="folio" class="form-label ms-2">Folio</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="folio" placeholder="" formControlName="folio">
            <div *ngIf="ufc['folio'].touched && ufc['folio'].invalid">
              <div *ngIf="ufc['folio'].errors?.['required']" class="text-danger text-sm">El folio es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="description" class="form-label ms-2">Descripción</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="description" placeholder="" formControlName="description">
            <div *ngIf="ufc['description'].touched && ufc['description'].invalid">
              <div *ngIf="ufc['description'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="liters" class="form-label ms-2">Litros solicitados</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="liters" placeholder="" formControlName="liters">
            <div *ngIf="ufc['liters'].touched && ufc['liters'].invalid">
              <div *ngIf="ufc['liters'].errors?.['required']" class="text-danger text-sm">El precio es requerido.</div>
            </div>
          </div>
          <!-- div class="form-group required mb-4 mt-3">
            <label for="price" class="form-label ms-2">Precio Lt.</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="price" placeholder="" formControlName="price">
            <div *ngIf="ufc['price'].touched && ufc['price'].invalid">
              <div *ngIf="ufc['price'].errors?.['required']" class="text-danger text-sm">El precio es requerido.</div>
            </div>
          </!-->
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Condominio</label>
            <select class="form-select form-select-lg rounded-3" formControlName="settlement_id" (change)="selectSettlement($event)">
              <option selected>Selecciona un condominio</option>
              <option *ngFor="let settlement of settlements" [value]="settlement.id">{{ settlement.name }}</option>
            </select>
            <fa-icon [icon]="faCircleNotch" size="1x" [spin]="true" class="text-secondary" *ngIf="loading"></fa-icon>
          </div>
          <div class="form-group required mb-4 mt-3" *ngIf="buildings.length>0">
            <label for="name" class="form-label ms-2">Edificio</label>
            <select class="form-select form-select-lg rounded-3" (change)="selectBuilding($event)">
              <option selected>Selecciona un edificio</option>
              <option *ngFor="let building of buildings" [value]="building.id">{{ building.name }}</option>
            </select>
          </div>
          <div class="form-group required mb-4 mt-3" *ngIf="selectedBuilding.id">
            <label for="tanque_id" class="form-label ms-2">Tanque</label>
            <select class="form-select form-select-lg rounded-3" formControlName="tanque_id">
              <option selected>Selecciona un tanque</option>
              <option *ngFor="let tanque of selectedBuilding.tanques" [value]="tanque.id">{{ tanque.name }}</option>
            </select>
          </div>
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/orders">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormOrderComponent {
  @Input() order: any = {};
  @Input() loading: boolean = false;
  @Output() orderData = new EventEmitter<any>();

  formOrder: FormGroup = new FormGroup({});

  settlements: any[] = [];
  buildings: any[] = [];
  selectedBuilding: any = {};

  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      console.log(params);
      
      let payload: any = {
        page: 1,
        per_page: 100,
        tipo: 0
      }
        this.store.getSettlements(payload).subscribe({
          next: (data) => {
            this.settlements = data.data;
          },
          error: (err) => {
            console.log(err);
            this.toastr.error('Ocurrió un error al obtener los condominios.');
          }
        });
    });
  } 

  get ufc() {
    return this.formOrder.controls;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes['order'].currentValue) {
      this.formOrder.patchValue(changes['order'].currentValue);
      this.preFill();
    }
  }

  initForm() {
    this.formOrder = this.fb.group({
      folio: ['', Validators.required],
      description: ['', Validators.required],
      // price: ['', Validators.required],
      liters: ['', Validators.required],
      status: false,
      tanque_id: ['', Validators.required],
      settlement_id: [''],
    });
  }

  preFill() {
    console.log(this.order);
    if (this.order.settlement) {
      console.log("this.order.settlement.id");
      console.log(this.order.settlement);
      this.formOrder.patchValue({
        settlement_id: this.order.settlement.id
      });
      this.store.getBuildingsBySettlement(this.order.settlement.id).subscribe({
        next: (data) => {
          console.log(data);
          
          this.buildings = data;
          if (this.order.building) {
            this.formOrder.patchValue({
              building_id: this.order.building.id
            });
          }
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
  }

  selectSettlement(e: any) {
    console.log('selectSettlement');
    console.log(e.target.value);
    this.store.getBuildingsBySettlement(e.target.value).subscribe({
      next: (data) => {
        console.log(data);
        this.buildings = data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  selectBuilding(e: any) {
    console.log('selectBuilding');
    console.log(e.target.value);
    let xx = this.buildings.filter((building: any) => { 
      console.log('guilding.id == <', building.id);
      return building.id == e.target.value;
    })[0];
    console.log(xx);
    this.selectedBuilding = xx; 
  }

  saveorder() {
    console.log(this.formOrder.value);

    if (this.formOrder.invalid) {
      this.formOrder.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.orderData.emit(this.formOrder.value);    
  }
}
