// import { Bill } from './../../../../models/bill.model';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-transaction-success',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
              <!-- h1>Pago exitoso</!-->
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/services/service">Agregar servicio</!-->
          </div>

          <div class="col-12 col-md-7 offset-sm-0 offset-md-3 mt-5">
            <div class="card border rounded-4 bg-white shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h2 class="card-title mb-4">Pago exitoso</h2>
                    <div class="alert alert-success">
                      <p class="mb-0">El pago se realizó de manera exitosa.</p>                  
                    </div>
                    <div class="card rounded-4 mt-4">
                      <div class="card-body" *ngIf="!loading">
                        <div class="row">
                          <div class="col-md-12 mb-3">
                            <h3>Comprobante de pago</h3>
                          </div>
                          <div class="col-md-6">
                            <h5><i># {{transaction.transaction_id}}</i></h5>
                            <p>Recibo: {{bill.id}}</p>
                          </div>
                          <div class="col-md-6 text-end">
                            <p class="mb-0 text-muted">Fecha transacción</p>
                            {{transaction.created_at | date: 'dd/MM/yyyy'}}
                            {{transaction.created_at | date: 'HH:mm'}}
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-6">
                            <!-- img [src]="cardType(transaction.payment_method_brand)" alt="Card image cap" width="58" class="float-left me-2" -->
                          </div>
                          <div class="col-md-6 text-end">
                            <p class="mb-0">Total pagado:</p>
                            <p>{{transaction.amount | currency: 'MXN'}}</p>
                          </div>
                          <hr class="mt-3">
                          <div class="col-md-12 text-center mt-3 mb-3">
                            <p class="mb-0">Cuentas con 7 días para generar tu factura</p>
                          </div>
                        </div>
                      </div>
                      <div class="card-body" *ngIf="loading">
                        Cargando...
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  `,
  styleUrls: []
})
export class TransactionSuccessComponent implements OnInit {
  billId: number = 0;
  bill: any = {};
  transaction: any = {};
  loading: boolean = true;

  constructor(
    private router: Router,
    private payService: PaymentsService,
    private activatedRoute: ActivatedRoute,
    private settlementsService: SettlementsService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log('queryParams => ', params['id']);
      if (params['id'] && params['id'] !== '' && params['id'] !== undefined) {
        this.settlementsService.updateTransaction(params['id'], { status_code: 3, error_code: '00', error_message: 'Approved'}).subscribe((transaction: any) => {
          console.log('transaction => ', transaction);
          this.transaction = transaction;
          this.loading = false;
        });
      }
    });
    this.activatedRoute.params.subscribe(params => {
    console.log('params => ', params);
      this.billId = params['id'];
      this.settlementsService.getBill(this.billId).subscribe((data: any) => {
        console.log('data => ', data);
        this.bill = data;        
      });
    });
  }

  ngOnInit(): void {
      
  }

  getTransactionStatus(id: any) {
    // this.payService.getTransactionStatus(id).subscribe({
      // next: (data: any) => {
          // console.log('data => ', data);
      // },
      // error: (error: any) => {}
    // });
  }

  cardType(cardType: string) {
    let card: string = '';

    console.log('cardType => ', cardType);

    if (cardType === 'VI') {
      card = "assets/images/icon-visa-sm.png";
    } else if (cardType === 'MC') {
      card = "assets/images/icon-mastercard-sm.png";
    } else if (cardType === 'AMEX') {
      card = "assets/images/icon-amex-sm.png";
    }
    
    return card;

  }

}
