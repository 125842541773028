import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-vehicles-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-9 offset-xl-3 mt-4">
      <xng-breadcrumb></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Vehículo</h1>
            <p>Configura las vehículo que están disponibles para los técnicos.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar vehículo</h4>
                    <p>Configura el vehículo con los siguientes campos.</p>
                    <app-form-vehicle (vehicleData)="updateVehicle($event)" [profile]="profile" [vehicle]="vehicle" [loading]="loading"></app-form-vehicle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class VehiclesEditComponent implements OnInit {
  vehicle: any = null;
  lsp: any = JSON.parse(localStorage.getItem('profile')!);
  profile: any = {};
  loading: boolean = false;

  constructor(
    private store: SettlementsService,
    private tasks: TasksService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.profile = this.lsp.profile;
  }

  ngOnInit(): void {
    this.breadcrumbService.set('@vehicleEdit', 'Editar vehículo');
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.tasks.getVehicle(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.vehicle = res;
          this.breadcrumbService.set('@vehicleEdit', this.vehicle.name);
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener el vehículo.', 'Error');
        }
      });
    });
  }

  updateVehicle(event: any) {
    console.log("updateVehicle ", event);

    this.tasks.updateVehicle(this.vehicle.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('El vehículo se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/inventory/vehicles']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el vehículo.', 'Error');
      }
    });
  }
}
