import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-item-order',
  template: `
    <div class="row">
      <div class="col-md-3 ps-5 py-3">
        <h3 class="mb-0">
          <a *ngIf="!order.status&&permissions.modules.operation.combustibles.pedidos.write" routerLink="{{order.id}}" ># {{order.folio}}</a>
          <span *ngIf="order.status"># {{order.folio}}</span>
        </h3>
        <div class="badge badge-sm rounded-pill bg-warning" *ngIf="!order.status">
          <p class="mb-0 text-capitalize">Pendiente</p>
        </div>
        <div class="badge rounded-pill bg-success" *ngIf="order.status">
          <p class="mb-0 text-capitalize"><small>Entregado</small></p>
        </div>        
      </div>
      <div class="col-md-4 py-3">
        <p class="mb-0" style="font-size: 13px;">Tanque: {{order.tanque?.name}}</p>
        <p class="mb-0" style="font-size: 13px;">{{order.settlement?.name}} {{order.settlement?.address}}</p>
        <p class="mb-0">Litros: {{ order.liters }}</p>
        <p class="mb-0">Precio Lt.: {{ order.price }}</p>
      </div>
      <div class="col-md-4 py-3">
      <p class="mb-0"><strong>Fecha de creación:</strong> {{order.created_at | date: 'dd/MM/yy HH:mm'}}</p>
        <p class="mb-0"><strong>Fecha de carga:</strong>
          <span *ngIf="order.status">
            {{ order.updated_at | date: 'dd/MM/yy HH:mm' }}
          </span>
        </p>        
      </div>      
      <div class="col-md-1 text-center p-0" *ngIf="!order.status&&permissions.modules.operation.combustibles.pedidos.write" (click)="deleteOrder(order)">
        <div class="hover-danger border-start pt-3 pe-4" style="height: 100%;">
          <fa-icon [icon]="icons.faTrashAlt" size="1x" class="center-icon"></fa-icon>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemOrderComponent implements OnInit {
  @Input() order: any = {};
  @Input() profile: any;
  @Input() permissions: any;
  @Output() refreshOrders = new EventEmitter();

  faEdit = faEdit;
  faTrashAlt = faTrashAlt;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.store.getSettlement(this.order.settlement_id).subscribe({
      next: (data: any) => {
        console.log("settlement: ", data);
        this.order.settlement = data;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
    this.store.getTanque(this.order.tanque_id).subscribe({
      next: (data: any) => {
        this.order.tanque = data;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  deleteOrder(order: any) {
    this.store.destroyPurchaseOrder(order.id).subscribe({
      next: (data: any) => {        
        this.refreshOrders.emit();
        this.toastr.success('Pedido eliminado correctamente.', 'Exito');
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.error.message, 'Error');
      }
    });
  }

}
