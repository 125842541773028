import { Component, Input } from '@angular/core';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-item-sm-tenant',
  template: `
    <div class="row">
      <div class="col-2 text-center ps-2 pe-0 py-2">
        <img [src]="avatarURL" width="44" height="44" class="rounded-5 border border-2 shadow-sm">
      </div>
      <div class="col-9 ps-0 py-2">
        <h4 class="mb-0">{{tenant.name}} {{tenant.last_name}}</h4>
        <p class="mb-0">{{tenant.email}}</p>                      
      </div>
      <div class="col-1 pe-2">
        <button type="button" class="btn btn-link pe-2 ps-0 mt-0 float-end" routerLink="/tenant/profile/{{tenant.id}}"><fa-icon [icon]="icons.faEdit"></fa-icon></button>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemSmTenantComponent {
  @Input() tenant: any;

  avatarURL: string = '';

  constructor(
    public icons: IconsService,
    public images: ImageService
  ) { }

  ngOnInit(): void {
    this.loadAvatar();
  }

  loadAvatar() {
    let el = this.tenant.avatar;

    if (!el) {
      this.avatarURL = 'assets/images/avatar.png';
    } else {
      this.avatarURL = `${this.images.apiUrl}/storage/${el.key.substring(0,2)}/${el.key.substring(2,4)}/${el.key}`;
    }
  }
}
