import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-zone',
  template: `
  <form [formGroup]="zoneForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group required mt-4">
          <label for="name" class="form-label ms-2">Nombre zona</label>
          <input type="text" formControlName="name" class="form-control form-control-lg" id="name" placeholder="">
        </div>
        <div class="form-group required mt-4">
          <label for="description" class="form-label ms-2">Descripción zona</label>
          <input type="text" formControlName="description" class="form-control form-control-lg" id="description" placeholder="">
        </div>
      </div>
    </div>
    <div class="form-group mt-5 text-end">
      <button type="button" routerLink="/zonas" class="btn btn-link me-2">Cancelar</button>
      <button type="submit" class="btn btn-success btn-lg rounded-5">
      <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="ms-2 text-dark"></fa-icon>
        Guardar
      </button>
    </div>
  </form>
`,
  styleUrls: []
})
export class FormZoneComponent implements OnInit {
  @Input() zone: any = {};
  @Input() roles: any = [];

  @Output() updateZone = new EventEmitter<any>();

  loading: boolean = false;

  zoneForm: FormGroup = new FormGroup({});

  faEdit = faEdit;
  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.zoneForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

  onSubmit() {
    this.loading = true;

    if (this.zoneForm.invalid) {
      this.loading = false;
      this.toastr.error('Error', 'Formulario incompleto.');
      return;
    }
    
    this.updateZone.emit(this.zoneForm.value);
    this.loading = false;
  }
}
