import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { SettlementsService } from 'src/app/services/settlements.service';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-index-chart',
  template: `<figure id="line" #line></figure>`,
  styles: [`
    .dashed-line {
      stroke-dasharray: 5, 5; /* Change these values to adjust the dash pattern */
    }
  `]
})
export class IndexChartComponent implements OnInit, OnChanges {
  @Input() public data: any;
  @Input() public tanque: any;
  @Input() public start_date: any;
  @Input() public end_date: any;
  @ViewChild('line', { static: true }) line: ElementRef<any> | undefined;
  
  private width = 1000;
  private height = 340;
  private margin = 44;

  public svg: any;
  public svgInner: any;
  public yScale: any;
  public xScale: any;
  public xAxis: any;
  public yAxis: any;
  public lineGroup: any;

  lineOpacity = "0.9";
  lineOpacityHover = "0.85";
  otherLinesOpacityHover = "0.1";
  lineStroke = "1.5px";
  lineStrokeHover = "2.5px";

  circleOpacity = '0.85';
  circleOpacityOnLineHover = "0.25"
  circleRadius = 6;
  circleRadiusHover = 8;
  duration = 250;

  readingItems: any[] = [];
  
  constructor(
    public chartElem: ElementRef,
    private dateService: DateService,
    private store: SettlementsService
  ) {}

  ngOnInit(): void {
    this.createSvg();
    
    // window.addEventListener('resize', () => this.drawChart(this.data));
    // this.drawChart(this.data)
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log("INDEX CHART == CHANGES ===> ", changes['data']);
    if (changes['data'] && changes['data'].currentValue) {
      console.log('ITEM ====================> ', this.line?.nativeElement.closest('.card').getBoundingClientRect().width);
      this.width = this.line?.nativeElement.closest('.card').getBoundingClientRect().width - 60;
      console.log("SIMPLE CHANGES ===> ", this.data.readings);
      this.drawChart(this.data);
    }
    if (changes.hasOwnProperty('data') && this.data.length > 0) {
      console.log("CHANGES ===> ", this.data);
      this.drawChart(this.data);

      window.addEventListener('resize', () => this.drawChart(this.data));
    }

    // if (changes.hasOwnProperty('start_date') && changes.hasOwnProperty('end_date')) {
      // console.log("DATE !!! CHANGES ===> ", this.start_date, this.end_date);
      // this.drawChart(this.data);
    // }
  }

  private createSvg(): void {

    const dateValues: (Date)[] = this.data
      .map((row: any) => d3.timeParse("%Y-%m-%d")(row.date))
      .filter((date: Date): date is Date => date !== null);

    this.svg = d3
      .select('figure#line')
      .append('svg')
      .attr("width", (this.width+this.margin)+"px")
      .attr("height", (this.height+this.margin)+"px")
      .append('g')
      .attr("transform", `translate(${this.margin}, ${this.margin})`);
  }

  drawChart(data: any) {
    console.log("drawChart DATA ====> ", data);
    if (data != undefined && data.length === 0) {
      return;
    }

    this.svg.selectAll("*").remove();    
    let startDate = new Date(`${this.start_date.year}-${this.start_date.month}-${this.start_date.day}`);
    let endDate = new Date(`${this.end_date.year}-${this.end_date.month}-${this.end_date.day}`);

    let processed_data = data.readings.map((d: any) => {
      return {
        id: d.id,
        date: new Date(d.created_at),
        valor: d.lts_estimated        
      }
    });

    let processed_consumptions = data.consumptions.map((d: any) => {
      return {
        date: new Date(d.created_at),
        valor: d.liters
      }
    });

    let processed_prediction = data.prediction.map((d: any) => {
      return {
        date: new Date(d.date),
        valor: Math.round(d.valor)
      }
    });

    let processed_children = data.children.map((child: any) => {
      return child.map((d: any) => {
        return {
          date: new Date(d.created_at),
          valor: Math.round(d.lts_estimated)
        }
      });
    });

    this.xScale = d3.scaleTime()      
      .domain([startDate, endDate])
      .range([0, this.width-this.margin]);


    // console.log('SAFE HABOR DATA ==> ', this.data.children);
    // this.yScale = d3.scaleLinear()
      // .domain([0, this.tanque.capacidad])
      // // .domain([0, d3.max([this.tanque.capacidad, d3.max(data.readings.map((d: any) => d.lts_estimated))])
      // // .domain([0, d3.max(processed_data.map((d: any) => d.lts_estimated) ])
      // .range([this.height-this.margin, 0]);

    this.yScale = d3.scaleLinear()
      .domain([0, this.tanque.capacidad])
      // .domain([0, d3.max([this.tanque.capacidad, d3.max(data.readings.map((d: any) => d.lts_estimated))])
      // .domain([0, d3.max(processed_data.map((d: any) => d.lts_estimated) ])
      .range([this.height-this.margin, 0]);

    // console.log('PROCCESD CHILDREN ===> ', processed_children);
    // console.log('PROCCESD DATA ===> ', processed_data);
    console.log('PROCCESD CONSUMPTIONS ===> ', processed_consumptions);
    // console.log('PROCCESD PREDICTION ===> ', processed_prediction);

    let ctl = this;
    let color = d3.scaleOrdinal(d3.schemeCategory10);

    const lines = this.svg
      .append('g')      
      .attr('class', 'lines');
    
    const cricles = this.svg
      .append('g')
      .attr('class', 'circles')
      

    

    lines
      .datum(processed_prediction) // Red line prediction
      .append('path')
      .attr("d", d3.line()
        .x(function(d: any) { return ctl.xScale(d.date) })
        .y(function(d: any) { return ctl.yScale(d.valor) })
        )
      .style('fill', 'none')
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("class", "dashed-line")
      .style('opacity', this.lineOpacity);

    processed_children.forEach((child: any, i: any) => {
      lines
        .datum(child)
        .append('path')
        .attr("d", d3.line()
          .x(function(d: any) { return ctl.xScale(d.date) })
          .y(function(d: any) { return ctl.yScale(d.valor) })
          )
        .style('fill', 'none')
        .attr("stroke", color(i))
        .attr("stroke-width", 2)
        .style('opacity', 0.6)
    });
      
    lines
      .datum(processed_data) // Main line data
      .append('path')
      .attr("d", d3.line()
        .x(function(d: any) { return ctl.xScale(d.date) })
        .y(function(d: any) { return ctl.yScale(d.valor) })
        )
      .style('fill', 'none')
      .attr("stroke", "orange")
      .attr("stroke-width", 4)
      .style('opacity', "1");

    cricles
      .selectAll(".circle")
      .data(processed_data)
      .enter()
      .append('circle')      
      .attr("class", "circle")        
      .attr("cx", (d: any) => ctl.xScale(d.date))
      .attr("cy", (d: any) => ctl.yScale(d.valor))
      .attr("r", ctl.circleRadius)
      .style('opacity', ctl.circleOpacity)
      .attr('fill', 'orange')
      .on("mouseover", (d: any, i: any) => {
        console.log("MOUSE OVER ====> ", d, i);
        // console.log("READING ITEMS ===> ", ctl.readingItems.find((x: any) => x.id === i.id));
        // let loading = false;
        // if (ctl.readingItems.find((x: any) => x.id === i.id) === undefined) {          
          // if (!loading) {
            // loading = true;
            // ctl.store.getReading(i.id).subscribe((data: any) => {
              // console.log("TANQUE ===> ", data);            
              // ctl.readingItems.push(data);
              // loading = false;
            // });
          // }
        // } else {
          // console.log("Already loaded");
          // console.log("READING ITEMS ===> ", ctl.readingItems);
        // }
        // d3.select('figure#line')
          // .transition()
          // .duration(ctl.duration)
          // .attr("r", ctl.circleRadiusHover);
        tooltip
          .text("Week: " + i.date + " - " + i.valor + " liters")
          .style("visibility", "visible")
          .style('top', (d.offsetY - 10) + 'px')
          .style('left', (d.offsetX + 10) + 'px');
      })
      .on("mouseout", function(d: any) {
        tooltip.style("visibility", "hidden");
      });

      // .selectAll("circles")
       //.enter()
      // .append("g")
      // .style("fill", (d: any, i: any) => color(i))
      // .selectAll("circle")
      // .data((d: any) => d.values).enter()
      // .append("g")

      /*.on("mouseover", function(d: any) {
          d3.select('figure#line')     
            .style("cursor", "pointer")
            .append("text")
            .attr("class", "text")
            .text(`${d.valor}`)
            .attr("x", (d: any) => ctl.xScale(d.date))
            .attr("y", (d: any) => ctl.yScale(d.valor) - 10);
        })
      .on("mouseout", function(d: any) {
          d3.select('figure#line')
            .style("cursor", "none")  
            .transition()
            .duration(ctl.duration)
            .selectAll(".text").remove();
        })*/

    var tooltip = d3.select("figure#line")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10000")
      .style("visibility", "hidden")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .text("I'm a circle!");

    /* Add Axis into SVG */
    var xAxis = d3.axisBottom(this.xScale).ticks(15);
    var yAxis = d3.axisLeft(this.yScale).ticks(10);

    this.svg.append("g")
      .attr("class", "x axis")
      .attr("transform", `translate(0, ${this.height-this.margin})`)
      .call(xAxis);

    this.svg.append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append('text')
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#000")
      .text("Litros");

      this.width = this.chartElem.nativeElement.getBoundingClientRect().width;
      this.svg.attr('width', this.width);    
  }
}
