import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';

@Component({
  selector: 'app-item-transaction',
  template: `
    <div class="d-none d-sm-block">
      <div class="row">        
        <div class="col-2 mb-2">
          <input type="checkbox" class="form-check-input float-start mt-3 ms-3" *ngIf="permissions?.modules?.admin?.billing?.pagos?.write" (change)="selectTransaction($event)">
          <h4 class="mb-0"># {{bill?.id}} </h4>
          <!-- p class="mb-0 text-muted">Recibo {{bill.bill_id}} <i>{{bill.reference}}</i> </!-->
          <div class="badge badge-pill rounded-5 px-3 py-2" [ngStyle]="{ background: catalogs.trxStatus[bill.status_code].color }">
            {{ catalogs.trxStatus[bill.status_code].name }}
          </div>
        </div>
        <div class="col-3">
          <p class="mb-0">{{bill?.settlement_name}}, {{bill?.building_name}}, {{bill?.unit_name}}</p>
        </div>
        <div class="col-2 text-center mt-2">
          <p class="mb-0" style="font-size: 17px;">{{bill?.consumo_lt | number: '1.2-2'}} Lts.</p>
        </div>
        <div class="col-2 text-center mt-2">
          <p class="mb-0" style="font-size: 17px;">{{bill?.amount | currency: 'USD'}}</p>
        </div>
        <div class="col-2 text-center mt-2">
          <p class="mb-0 ms-2" style="font-size: 17px;">{{bill?.paid_date | date: 'dd/MM/yy'}}</p>
        </div>
        <div class="col-md-1 text-center mt-2">
          <div ngbDropdown class="d-inline-block ms-2">
            <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1" id="dropdownBasic2" ngbDropdownToggle >
              <fa-icon [icon]="icons.faEllipsisVertical" class="m-1"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
              <a ngbDropdownItem href="{{receiptUrl}}/public/{{bill.pdf}}" target="_blank" [disabled]="!bill.pdf" *ngIf="!bill.invoiced">
                <fa-icon [icon]="icons.faDownload"></fa-icon>
                Descargar recibo
              </a>
              <!-- (click)="dispatchPayment()"  *ngIf="this.bill.date_status!='expirado'" -->
              <button ngbDropdownItem (click)="downloadPaymentReceipt(bill)" >
                <fa-icon [icon]="icons.faDownload"></fa-icon>
                Descargar pago
              </button>
              <button ngbDropdownItem *ngIf="evalInvoice()" routerLink="/tenant/facturar/{{bill.id}}">
                <fa-icon [icon]="icons.faFilePdf"></fa-icon>
                Solicitar factura
              </button>
            </div>
          </div>
          <!-- button class="btn btn-outline-primary ms-2" *ngIf="!bill?.invoiced&&!bill.adv"  [disabled]="invoicable">Facturar</!-->
        </div>      
      </div>
    </div>
    <div class="d-block d-sm-none">
      <div class="row py-3 px-2">
        <div class="col-7">
          <h4 class="mb-0"># {{bill?.id}}</h4>
        </div>
        <div class="col-5 text-end">
          <div class="badge badge-pill badge-lg rounded-5 px-3 py-2" [ngStyle]="{ background: catalogs.trxStatus[bill.status_code].color }">
            {{ catalogs.trxStatus[bill.status_code].name }}
          </div>
        </div>
        <div class="col-12 mt-3">
          <p class="mb-0">{{bill?.settlement_name}}, {{bill?.building_name}}, {{bill?.unit_name}}</p>
          <table class="table table-sm mt-2">
            <tbody>
              <tr>
                <td class="text-start">Recibo</td>
                <td class="text-end"><h5># {{bill.bill_id}}</h5></td>
              </tr>
              <tr>
                <td class="text-start">Consumo</td>
                <td class="text-end"><h5>{{bill.consumo_lt | number: '1.2-2'}} Lts.</h5></td>
              </tr>
              <tr>
                <td class="text-start">Pago</td>
                <td class="text-end"><h5>{{bill?.amount | currency: 'USD'}}</h5></td>
              </tr>
              <tr>
                <td class="text-start">Fecha de pago</td>
                <td class="text-end"><h5>{{bill?.paid_date | date: 'dd/MM/yy'}}</h5></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-3 text-center">
          <button title="Descargar pago" class="btn btn-sm btn-outline-primary rounded-3" (click)="downloadPaymentReceipt(bill)" *ngIf="bill.status_code == '3'">
            <fa-icon [icon]="icons.faDownload"></fa-icon>
          </button>
        </div>
        <div class="col-3 text-center">
          <a title="Descargar recibo" class="btn btn-sm btn-outline-primary rounded-3" href="{{receiptUrl}}/public/{{bill.pdf}}" target="_blank">
            <fa-icon [icon]="icons.faDownload"></fa-icon>            
          </a>
        </div>
        <div class="col-6 text-end"> <!-- *ngIf="evalInvoice()" -->
          <button class="btn btn-sm btn-primary rounded-3" *ngIf="evalInvoice()" routerLink="/tenant/facturar/{{bill.id}}">
            <fa-icon [icon]="icons.faFilePdf"></fa-icon>
            Facturar
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemTransactionComponent implements OnInit {
  @Input() bill: any;
  @Input() permissions: any;
  @Output() transactionSelected = new EventEmitter<any>();

  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  invoicable: boolean = true;

  constructor(
    private store: SettlementsService,
    public icons: IconsService,
    public catalogs: CatalogsService
  ) { }

  ngOnInit(): void {}

  cardType(card: any) {
    if (card == 'VI' || card == 'visa') {
      return "/assets/images/icon-visa-sm.png";
    } else if (card == 'MC' || card == 'mastercard') {
      return "/assets/images/icon-mastercard-sm.png";
    } else if (card == 'AMEX') {
      return "/assets/images/icon-amex-sm.png";
    } else if (card == 'CASH') {
      return "/assets/images/icon-cash.png";
    } else if (card == 'SALDO') {
      return "/assets/images/icon-cash.png";
    } else {
      return "/assets/images/icon-visa-sm.png";
    }
  }

  selectTransaction(event: any) {
    this.transactionSelected.emit({ transaction: this.bill, checked: event.target.checked});
  }

  downloadPaymentReceipt(bill: any) {
    console.log('downloadPaymentReceipt => ', bill);

    this.store.getUnitSettlement(bill.unit_id).subscribe((data: any) => {
      this.store.getProfile(data.settlement.profile_id).subscribe((profile: any) => {
        console.log('profile => ', profile);
        this.store.downloadPaymentReceipt({ bill: bill }).subscribe((data: any) => {
          const int8Array = new Uint8Array(data.result.data);
            const blob = new Blob([int8Array], { type: 'application/pdf' });
    
            var fileURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
    
            link.href = fileURL;
            link.download = data.filename;
            link.click();
        });
      });
    });
  }

  evalInvoice() {
    let ability = false;

    const currentDate = new Date();
    const pastDate = new Date(this.bill.paid_date);
    // console.log('currentDate => ', currentDate);
    // console.log('pastDate => ', pastDate);
    // console.log('currentDate.getDate() => ', currentDate.getDate());
    currentDate.setDate(pastDate.getDate() + 7);

  // Compare the target date with the past date
    // console.log('WTF ===> ', pastDate);
    const out_of_range: Boolean = new Date() > pastDate;
    // console.log('out_of_range => ', out_of_range);
    
    if (this.permissions.menu.tenants && this.bill.status_code=='3' && !this.bill.invoiced && !out_of_range) {
      ability = true;
    }

    return ability;
  }
}
