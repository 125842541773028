import { Component, Input, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-item-price',
  template: `
    <div class="row pt-1">
      <div class="col-md-4">
        <h3 class="mb-0" [ngClass]="index == 0 ? 'text-dark' : 'text-muted'">{{setting.valor | currency: 'MXN'}}</h3>
      </div>
      <div class="col-md-4">
        <p class="mb-0" [ngClass]="index == 0 ? 'text-dark' : 'text-muted'">{{setting.zona_id}}</p>
      </div>
      <div class="col-md-4 text-end">                            
        <p class="mb-0" [ngClass]="index == 0 ? 'text-dark' : 'text-muted'">{{setting.created_at | date: 'dd/MM/yyyy'}}</p>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemPriceComponent implements OnInit {
  @Input() setting: any;
  @Input() index: any;

  constructor(
    private store: SettlementsService,
  ) { }

  ngOnInit(): void {
    this.store.getZone(this.setting.zone_id).subscribe((res: any) => {
      this.setting.zona_id = res.name;
    });
  }

}
