import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faEdit, faBuilding, faPhoneAlt, faMailBulk, faLink, faPlus, faTrashAlt, faChevronUp, faChevronDown, faEllipsisVertical, faEye } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-item-balance',
  template: `
    <div class="row">
      <div class="col-9">  
        <h4>DEPT. {{settlement?.unit?.name}}</h4>      
        <p class="mb-0" style="">{{settlement?.settlement?.name}}</p>
        <p class="mb-0 text-muted" style="font-size: 14px;">{{settlement?.settlement?.address}} {{settlement?.settlement?.city}}</p>
        <p class="mb-0 text-muted" style="font-size: 14px;">Edificio: {{settlement?.building?.name}}, Depto.{{settlement?.unit?.name}}</p>
      </div>
      <div class="col-3 text-end">
        <h3 class="mb-0">{{balance.amount | currency: 'USD'}}</h3>
        <p class="text-sm text-muted text-end"><b>SALDO</b></p>
        <p class="mb-0">{{balance.updated_at | date: 'dd/MM/yyyy'}}</p>
        <p class="mb-0 text-sm text-muted">Última actualización</p>
        <!-- p class="mb-0 text-sm text-muted" style="font-size: 11px;">{{balance.unit_id}}</!-->
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemBalanceComponent implements OnInit {
  @Input() balance: any;

  settlement: any = {};
  unit: any = {};

  constructor(
    private store: SettlementsService,
  ) { }

  ngOnInit(): void {
    // console.log("settlement ===> ", this.settlement);
    this.store.getUnitSettlement(this.balance.unit_id).subscribe((settlement: any) => {
      this.settlement = settlement;
    });
    this.loadData();
  }

  loadData() {
    this.store.getUnit(this.balance.unit_id).subscribe((data: any) => {
      console.log("unit => ", data);
      this.unit = data;
    });
  }

}
