import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-receipt-reads',
  template: `
    <div class="card border-0 rounded-5 shadow mb-3">
      <div class="card-body text-dark mb-3 p-4 pb-0">
        <h3>Detalle de la lectura</h3>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-sm rounded-2 border-0 mt-2">
              <tbody>
                <tr class="border-bottom-secondary">
                  <th>Consumo</th>
                  <td scope="col" class="text-end" *ngIf="bfc['consumo_m3'].value">{{bfc['consumo_m3'].value | number: '1.2-2'}} M<sup>3</sup></td>
                  <td scope="col" class="text-end" *ngIf="!bfc['consumo_m3'].value">-- M<sup>3</sup></td>
                  <td scope="col" class="text-end" *ngIf="bfc['consumo_lt'].value ">{{bfc['consumo_lt'].value | number: '1.2-2'}} Lts.</td>
                  <td scope="col" class="text-end" *ngIf="!bfc['consumo_lt'].value ">-- Lts.</td>
                </tr>
                <tr class="border-bottom-secondary">
                  <th>Lectura</th>
                  <td class="text-end" *ngIf="bfc['read_prev'].value">Ant. {{bfc['read_prev'].value }}</td>
                  <td class="text-end" *ngIf="!bfc['read_prev'].value">Ant. -</td>
                  <td class="text-end" *ngIf="bfc['read_curr'].value">Act. {{bfc['read_curr'].value}}</td>
                  <td class="text-end" *ngIf="!bfc['read_curr'].value">Act. -</td>
                </tr>
                <tr class="border-bottom-secondary">
                  <th>Periodo</th>
                  <td class="text-end" *ngIf="bfc['periodo_inicio']">{{bfc['periodo_inicio'].value | date: 'dd/MM/yy'}}</td>
                  <td class="text-end" *ngIf="bfc['periodo_fin']">{{bfc['periodo_fin'].value | date: 'dd/MM/yy' }}</td>
                </tr>
                <tr class="border-bottom-secondary">
                  <th colspan="2">Fecha de vencimiento</th>
                  <td colspan="2" class="text-end" *ngIf="bfc['due_date']">{{bfc['due_date'].value | date: 'dd/MM/yy' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 col-md-6">
            <img [src]="prev_medidor" width="100%" class="rounded-3 shadow-sm">
          </div>
          <div class="col-6 col-md-6">
            <img [src]="medidor" width="100%" class="rounded-3 shadow-sm">
          </div>
        </div>
      <div>
    </div>
  `,
  styleUrls: []
})
export class ReceiptReadsComponent implements OnInit {
  @Input() bfc: any;
  @Input() medidor: any;
  @Input() prev_medidor: any;

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  imageUrl: string = '';
  prevUrl: string = '';

  constructor() { }

  ngOnInit(): void {
    console.log('bfc', this.bfc);
    /*if (this.medidor != null) {
      console.log('medidor', this.medidor);
      let imageUrl = `${this.apiUrl}/storage/${this.medidor.blob.key.substring(0,2)}/${this.medidor.blob.key.substring(2,4)}/${this.medidor.blob.key}`;
      this.imageUrl = imageUrl;
    }*/
  }
}
