import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-modal-set-bill-date',
  template: `
    <div #modalSetBillDate id="modalSetBillDate" class="modal fade" tabindex="-1" aria-labelledby="modalSetBillDate" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">Modificar fecha de pago de recibo</div>
          </div>
          <div class="modal-body p-4">
            <form [formGroup]="billForm" (ngSubmit)="actualizarFecha()">              
              <div class="form-group">
                <label for="payment_date">Fecha de pago</label>
                <input type="date" class="form-control form-control-lg" formControlName="paid_date">
              </div>
              <div class="form-group text-end mt-4">
                <button type="submit" class="btn btn-primary rounded-5">Actualizar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalSetBillDateComponent implements OnInit {
  @Input() bill: any;

  billForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.billForm = this.fb.group({
      paid_date: ['']
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['bill']&& changes['bill'].currentValue) {
      console.log('bill => ', this.bill.paid_date.split('T')[0]);
      this.billForm.patchValue({
        paid_date: this.bill.paid_date.split('T')[0]
      });
    }
  }

  actualizarFecha() {
    console.log('actualizar fecha => ', this.billForm.value);
    this.store.updateBillPaidDate(this.bill.bill_id, this.billForm.value).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Fecha de pago actualizada correctamente');
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error al actualizar la fecha de pago');
      }
    });
  }

}
