import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  permissions: any = null;
  role: any = null;

  constructor(
    public authService: AuthService,
    public router: Router
  ){ }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {      
      
    return this.checkAuth(state);
  }

  private checkAuth(state: RouterStateSnapshot): boolean {
    let allowed: boolean = false;
    // console.log('ROUTER ========>', state.url);
    let currentAuth = localStorage.getItem('token');
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.role = localStorage.getItem('role') != null ? JSON.parse(localStorage.getItem('role')!) : null;

    if (currentAuth == null || this.permissions == null) {
      this.router.navigate(['sign-in']);
      allowed = false;
    } else {
      // console.log("HASS SESSION ====> ", currentAuth);
      let url = state.url.split('/').filter(Boolean);

      // console.log('URL ======> ', url[0]);
      // console.log('ROLE ======> ', this.role.role.group_id);
      let dic = this.dict(url[0]);
      let seg: any = dic == 'tenants' ? this.tenantSerializer(url[1]) : this.serializer(url[0]);
      
      // console.log('dict ===> ', dic);
      // console.log('seg ===> ', seg);
      // admin        
      // console.log('PERMISSIONS ====> ', this.permissions.modules);
      // console.log('PERMISSIONS ====> ', this.permissions.modules[dic!][seg!]);

      let check: any = this.permissions.modules[dic!][seg!];
      if (check == undefined) {
        if (this.role.role.group_id == 3) {
          // this.router.navigate(['tenant/dashboard']);
        } else {
          this.router.navigate(['dashboard']);
        }
        allowed = false;
      } else {
        allowed = this.permissions.modules[dic!][seg!].active;
      }

    }
    return true; // allowed;
  }

  private dict(item: any) {
    let group = null;
    if (item == 'dashboard' || item == 'condominios' || item == 'comercials' || item == 'pagos' || item == 'invoices' || item == 'inventory' || item == 'contratos' || item == 'reports') {
      group = 'admin';
    } else if (item == 'technicians' || item == 'users' || item == 'tenants' || item == 'perfiles' || item == 'roles' || item == 'contract-types') {
      group = 'config';
    } else if (item == 'maintenances' || item == 'amenidades' || item == 'providers' || item == 'services' || item == 'combustibles') {
      group = 'operation';
    } else if (item == 'tenant') {
      group = 'tenants';
    }
    return group;
  }

  private serializer(item: any) {
    let segment = null;
    if (item == 'dashboard') {
      segment = 'dashboard';
    }
    if (item == 'condominios') {
      segment = 'settlements';
    }
    if (item == 'comercials') {
      segment = 'commercial';
    }
    if (item == 'pagos') {
      segment = 'billing';
    }
    if (item == 'invoices') {
      segment = 'invoices';
    }
    if (item == 'inventory') {
      segment = 'inventory';
    }
    if (item == 'reports') {
      segment = 'reports';
    }
    if (item == 'inventory') {
      segment = 'inventory';
    }
    if (item == 'technicians') {
      segment = 'technicians';
    }
    if (item == 'users') {
      segment = 'admins';
    }
    if (item == 'tenants') {
      segment = 'tenants';
    }
    if (item == 'perfiles') {
      segment = 'profiles';
    }
    if (item == 'roles') {
      segment = 'roles';
    }
    if (item == 'maintenances') {
      segment = 'service';
    }
    if (item == 'amenidades') {
      segment = 'amenities';
    }
    if (item == 'providers') {
      segment = 'providers';
    }
    if (item == 'services') {
      segment = 'services';
    }
    if (item == 'tanques') {
      segment = 'tanques';
    }
    if (item == 'refuels') {
      segment = 'suministros';
    }
    if (item == 'orders') {
      segment = 'pedidos';
    }
    if (item == 'zonas') {
      segment = 'zonas';
    }
    if (item == 'combustibles') {
      segment = 'combustibles';
    }
    if (item == 'contratos') {
      segment = 'contracts';
    }
    if (item == 'contract-types') {
      segment = 'contract_types';
    }
    
    return segment;
  }

  private tenantSerializer(item: any) {
    let segment = null;
    if (item == 'tenant') {
      segment = 'tenant';
    }
    if (item == 'profile') {
      segment = 'profile';
    }
    if (item == 'dashboard') {
      segment = 'dashboard';
    }
    if (item == 'payments') {
      segment = 'payments';
    }
    if (item == 'bills') {
      segment = 'billing';
    }
    if (item == 'payment-methods') {
      segment = 'payment_methods';
    }
    return segment;
  }
  
}
