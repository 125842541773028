import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { CommonService } from './common.service';
import { Building } from '../models/building.model';
import { Settlement } from '../models/settlement.model';
import { User } from '../models/user';
import { Utility } from '../models/utility.model';

@Injectable({
  providedIn: 'root'
})
export class SettlementsService extends CommonService {
  
  constructor(
    private httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  // CRUD Settlements
  getSettlements(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements?${this.parseQueryParams(data)}`)
    .pipe(
      catchError(this.errorHandler)
    )
    .pipe(map((settlementsData: any) => {
      const settlements: any = [];
      settlementsData.data.forEach((settlement_serialized: any) => {
        const settlement: any = {
          id: settlement_serialized.id,
          name: settlement_serialized.name,
          address: settlement_serialized.address,
          phone: settlement_serialized.phone,
          email: settlement_serialized.email,
          website: settlement_serialized.website,
          description: settlement_serialized.description,
          slug: settlement_serialized.slug,          
          code: settlement_serialized.code,
          factor: settlement_serialized.factor,
          admin_price: settlement_serialized.admin_price,
          discount: settlement_serialized.discount,
          interests: settlement_serialized.interests,
          invoice_limit: settlement_serialized.invoice_limit,
          city: settlement_serialized.city,
          country: settlement_serialized.country,
          state: settlement_serialized.state,
          zip: settlement_serialized.zip,
          latitude: settlement_serialized.latitude,
          longitude: settlement_serialized.longitude,
          tipo: settlement_serialized.tipo,
          image: settlement_serialized.image,
          credit: settlement_serialized.credit,
          tampering_fine: settlement_serialized.tampering_fine,
          merchant_id: settlement_serialized.merchant_id,
          updated_at: settlement_serialized.updated_at,
          created_at: settlement_serialized.created_at,
        }
        settlements.push(settlement);
      });
      return { data: settlements, meta: settlementsData.meta };
    }));
  }

  // getComercialSettlements(): Observable<any> {
    // return this.httpClient.get(`${this.apiURL}/settlements/comercial`);
  // }

  createSettlement(data: any): Observable<any> {
    const options = this.getHeaders(localStorage.getItem('token'));
    return this.httpClient.post(`${this.apiURL}/settlements`, data, options);
  }

  getSettlement(id: any): Observable<any> {
    const options = this.getHeaders(localStorage.getItem('token'));
    return this.httpClient.get(`${this.apiURL}/settlements/${id}`, options);
  }

  getSettlementNested(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements/${id}/nested`);
  }

  updateSettlement(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/settlements/${id}`, data);
  }

  updateSettlementImage(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/settlements/image/${id}`, data);
  }

  updateSettlementContrato(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/settlements/contrato/${id}`, data);
  }

  updateSettlementIdentificacion(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/settlements/identificacion/${id}`, data);
  }
  
  // CRUD Buildings
  getBuildings(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/buildings?${this.parseQueryParams(data)}`);
  }

  getBuilding(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/buildings/${id}`)
    .pipe(
      catchError(this.errorHandler)
    )
    .pipe(map((buildingData: any) => {
      const building_serialized = buildingData;
      const building: Building = {
        id: building_serialized.id,
        name: building_serialized.name,
        clave: building_serialized.clave,
        settlement: building_serialized.settlement,
        floors: building_serialized.floors,
        utilities: building_serialized.utilities,
        tanques: building_serialized.tanques,
        updated_at: building_serialized.updated_at,
        created_at: building_serialized.created_at,
      }
      return building;
    }));
  }

  createBuilding(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/buildings`, data);
  }

  updateBuilding(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/buildings/${id}`, data);
  }

  destroyBuilding(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/buildings/${id}`);
  }

  getUnitsBySettlement(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements/${id}/units`);
  }

  getUnitsByBuilding(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/buildings/${id}/units`);
  }

  

  // CRUD Services

  getServices(): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/services`);
  }

  getService(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/services/${id}`);
  }

  createService(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/services`, data);
  }

  updateService(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/services/${id}`, data);
  }

  destroyService(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/services/${id}`);
  }

  // CRUD Amenities

  getAmenities(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/amenities?${this.parseQueryParams(data)}`);
  }

  getAmenity(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/amenities/${id}`);
  }

  createAmenity(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/amenities`, data);
  }

  updateAmenity(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/amenities/${id}`, data);
  }

  destroyAmenity(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/amenities/${id}`);
  }

  // CRUD Providers

  getProviders(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/suppliers?${this.parseQueryParams(data)}`);
  }

  getProvider(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/suppliers/${id}`);
  }

  createProvider(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/suppliers`, data);
  }

  updateProvider(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/suppliers/${id}`, data);
  }

  destroyProvider(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/suppliers/${id}`);
  }

  updateProviderAvatar(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/suppliers/update_avatar/${id}`, data);
  }

  destroyProviderAvatar(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/suppliers/delete_avatar/${id}`);
  }


  // CRUD Floors
  getFloors(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/floors?${this.parseQueryParams(data)}`);
  }

  getFloor(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/floors/${id}`);
  }

  createFloor(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/floors`, data)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  updateFloor(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/floors/${id}`, data);
  }

  destroyFloor(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/floors/${id}`);
  }

  // CRUD Units
  getUnits(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/units?${this.parseQueryParams(data)}`);
  }

  getUnit(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/units/${id}`);
  }

  createUnit(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/units`, data);
  }

  updateUnit(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/units/${id}`, data);
  }

  destroyUnit(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/units/${id}`);
  }

  // getUnitByFloor(id: any): Observable<any> {
    // return this.httpClient.get(`${this.apiURL}/units/${id}/by-floor`);
  // }

  updateUnitContrato(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/units/update_contrato/${id}`, data);
  }

  getUnitSettlement(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/units/settlement/${id}`);
  }

  // CRUD Tenants
  getTenants(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenants?${this.parseQueryParams(data)}`);
  }

  getTenantsQuery(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenants/query?${this.parseQueryParams(data)}`);
  }

  getTenant(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenants/${id}`);
  }

  createTenant(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenants`, data);
  }

  updateTenant(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/tenants/${id}`, data);
  }

  destroyTenant(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/tenants/${id}`);
  }

  // Tenant Address

  getTenantAddress(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenant_addresses/${id}`);
  }

  createTenantAddress(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenant_addresses`, data);
  }

  updateTenantAddress(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/tenant_addresses/${id}`, data);
  }

  destroyTenantAddress(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/tenant_addresses/${id}`);
  }

  // Tentant Fiscal

  getTenantFiscal(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenant_fiscals/${id}`);
  }

  createTenantFiscal(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenant_fiscals`, data);
  }

  updateTenantFiscal(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/tenant_fiscals/${id}`, data);
  }

  destroyTenantFiscal(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/tenant_fiscals/${id}`);
  }



  // CRUD Payment methods

  getPaymentMethods(): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/payment_methods`);
  }

  getPaymentMethodsByTenant(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/payment_methods/by-tenant/${data}`);
  }


  // CRUD Utilities
  getUtilities(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/utilities?${this.parseQueryParams(data)}`)
    .pipe(
      catchError(this.errorHandler)
    )
    .pipe(map((utilitiesData: any) => {
      const utilities: any = [];
      utilitiesData.data.forEach((utility_serialized: any) => {
        const utility: Utility = {
          id: utility_serialized.id,
          name: utility_serialized.name,
          description: utility_serialized.description,
          status: utility_serialized.status,
          building_id: utility_serialized.building_id,
          utility_setting: utility_serialized.utility_setting,
          updated_at: utility_serialized.updated_at,
          created_at: utility_serialized.created_at,
        }
        utilities.push(utility);
      });
      return { data: utilities, meta: utilitiesData.meta };
    }));
  }

  getUtility(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/utilities/${id}`);
  }

  createUtility(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/utilities`, data);
  }

  updateUtility(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/utilities/${id}`, data);
  }

  destroyUtility(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/utilities/${id}`);
  }

  // CRUD Utility Settings

  getUtilitySettings(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/utility_settings?${this.parseQueryParams(data)}`);
  }

  createUtilitySetting(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/utility_settings`, data);
  }

  getUtilityPrice(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/utility_settings/last`, data);
  }

  // CRUD Tanques

  getTanques(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/query?${this.parseQueryParams(data)}`);
  }

  getTanque(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/${id}`);
  }

  getTanqueByBuilding(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/building/${id}`);
  }

  createTanque(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tanques`, data);
  }

  updateTanque(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/tanques/${id}`, data);
  }

  destroyTanque(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/tanques/${id}`);
  }

  getTanquesBySettlement(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/${id}/settlement`);
  }

  getTanquesByBuilding(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/${id}/building`);
  }

  getTanqueReport(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/report/${id}?${this.parseQueryParams(data)}`);
  }

  getTanqueEstimation(id: any, data: any): Observable<any> {
    // return this.httpClient.get(`http://localhost:3000/api/v1/tanques/estimation/${id}`);
    return this.httpClient.get(`${this.apiURL}/tanques/estimation/${id}?${this.parseQueryParams(data)}`);
  }

  getTanqueAnova(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/anova/${id}?${this.parseQueryParams(data)}`);
  }

  getChildrenTanks(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/children/${id}`);
  }

  // CRUD Payment Concepts

  getPaymentConcepts(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/payment_concepts?${this.parseQueryParams(data)}`);
  }

  getPaymentConcept(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/payment_concepts/${id}`);
  }

  createPaymentConcept(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/payment_concepts`, data);
  }

  updatePaymentConcept(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/payment_concepts/${id}`, data);
  }

  destroyPaymentConcept(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/payment_concepts/${id}`);
  }

  // QUERIES
  getBuildingsBySettlement(settlementId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements/${settlementId}/buildings`);
  }

  getFloorsByBuilding(buildingId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/buildings/${buildingId}/floors`);
  }

  getUnitsByFloor(floorId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/floors/${floorId}/units`);
  }

  getAmenityBySettlement(settlementId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements/${settlementId}/amenities`);
  }

  getServiceBySettlement(settlementId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements/${settlementId}/services`);
  }

  getUtilityBySettlement(settlementId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements/${settlementId}/utilities`);
  }

  getBill(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/${id}`);
  }

  getBillsQuery(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/query?${this.parseQueryParams(data)}`);
  }
  

  getBillStatusByUnit(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills/status/by-unit`, data);
  }

  getBillsBySettlement(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/${id}/settlement?${this.parseQueryParams(data)}`);
  }

  // getBillsByBuilding(id: any, data: any): Observable<any> {
    // return this.httpClient.get(`${this.apiURL}/bills/${id}/building?${this.parseQueryParams(data)}`);
  // }

  // getBillsByFloor(id: any, data: any): Observable<any> {
    // return this.httpClient.get(`${this.apiURL}/bills/${id}/floor?${this.parseQueryParams(data)}`);
  // }

  getBillsByUnit(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/${id}/unit?${this.parseQueryParams(data)}`);
  }

  // getBillByUnit(unitId: any, data: any): Observable<any> {
    // return this.httpClient.get(`${this.apiURL}/bills/unit/${unitId}?${this.parseQueryParams(data)}`);
  // }

  getBillByUnitSummary(unitId: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/unit/${unitId}/summary?${this.parseQueryParams(data)}`);
  }

  getBillCalculation(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills/calculation`, data);
  }

  getBillCalculationLts(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills/calculation-lts`, data);
  }

  getBillLatest(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/latest/${id}`);
  }

  getBillsPortafolio(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/portafolio?${this.parseQueryParams(data)}`);
  }

  getBillsPortafolioSettlement(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills/portafolio/settlement`, data);
  }

  getBillsPortafolioProfile(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills/portafolio/profile`, data);
  }

  createBillCustom(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills/create-custom`, data);
  }

  // CONSUMPTIONS

  getConsumptionsByTanque(tanqueId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/consumptions/tanque/${tanqueId}`);
  }

  getConsumptionsByProfile(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/consumptions/profile?${this.parseQueryParams(data)}`);
  }

  getConsumptionsBySettlement(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/consumptions/settlement?${this.parseQueryParams(data)}`);
  }

  getTenantByUnit(unitId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/units/${unitId}/tenants`);
  }

  getUnitByTanque(tanqueId: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tanques/units/${tanqueId}`);
  }

  // CRUD Task Categories

  getTaskCategories(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/task_categories?${this.parseQueryParams(data)}`);
  }

  getTaskCategory(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/task_categories/${id}`);
  }

  createTaskCategory(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/task_categories`, data);
  }

  updateTaskCategory(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/task_categories/${id}`, data);
  }

  destroyTaskCategory(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/task_categories/${id}`);
  }

  // CRUD Tasks

  getTasks(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tasks?${this.parseQueryParams(data)}`);
  }

  getTask(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tasks/${id}`);
  }

  createTask(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tasks`, data);
  }

  updateTask(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/tasks/${id}`, data);
  }

  destroyTask(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/tasks/${id}`);
  }

  // CRUD Schedules

  getSchedules(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/schedules?${this.parseQueryParams(data)}`);
  }

  // CRUD Users
  getUsers(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/users?${this.parseQueryParams(data)}`);
  }

  getUser(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/users/${id}`);
  }

  createUser(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/users`, data);
  }

  updateUser(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/users/${id}`, data);
  }

  updateAvatar(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/users/avatar/${id}`, data);
  }

  updateTenantAvatar(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/tenants/update_avatar/${id}`, data);
  }

  destroyAvatar(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/users/avatar/${id}`);
  }
 
  destroyUser(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/users/${id}`);
  }

  // CRUD Roles
  getRoles(): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/roles`);
  }

  getRole(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/roles/${id}`);
  }

  createRole(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/roles`, data);
  }

  updateRole(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/roles/${id}`, data);
  }

  destroyRole(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/roles/${id}`);
  }

  // CRUD Profile

  getProfiles(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/profiles?${this.parseQueryParams(data)}`);
  }

  getProfile(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/profiles/${id}`);
  }

  createProfile(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/profiles`, data);
  }

  updateProfile(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/profiles/${id}`, data);
  }

  destroyProfile(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/profiles/${id}`);
  }

  // CRUD Refeul

  getRefuels(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/refuels?${this.parseQueryParams(data)}`);
  }

  queryRefuels(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/refuels/query?${this.parseQueryParams(data)}`);
  }

  queryRefuelsSettlement(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/refuels/settlement?${this.parseQueryParams(data)}`);
  }

  queryRefuelsBuilding(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/refuels/building?${this.parseQueryParams(data)}`);
  }

  queryRefuelsTanque(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/refuels/tanque?${this.parseQueryParams(data)}`);
  }

  getRefuel(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/refuels/${id}`);
  }

  createRefuel(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/refuels`, data);
  }

  updateRefuel(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/refuels/${id}`, data);
  }

  destroyRefuel(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/refuels/${id}`);
  }

  // CRUD PurchaseOrders

  getPurchaseOrders(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/purchase-orders?${this.parseQueryParams(data)}`);
  }

  getPurchaseOrder(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/purchase-orders/${id}`);
  }

  createPurchaseOrder(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/purchase-orders`, data);
  }

  updatePurchaseOrder(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/purchase-orders/${id}`, data);
  }

  destroyPurchaseOrder(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/purchase-orders/${id}`);
  }
  // CRUD tenant-invoices

  getTenantInvoices(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenant_invoices?${this.parseQueryParams(data)}`);
  }

  getInvoiceList(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenant_invoices/list`, data);
  }

  getTenantInovice(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenant_invoices/${id}`);
  }

  setInvoiceOpened(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenant_invoices/opened`, data);
  }

  setInvoiceRead(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenant_invoices/read`, data);
  }

  setInvoiceConfirmed(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenant_invoices/confirmed`, data);
  }

  setInvoiceAccepted(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenant_invoices/accepted`, data);
  }
  

  // CRUD invoices

  getInvoices(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices?${this.parseQueryParams(data)}`);
  }

  getInvoice(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/${id}`);
  }

  getInvoiceGlobalLast(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/global/last?${this.parseQueryParams(data)}`);
  }

  createInvoice(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/invoices`, data);
  }

  createGenericInvoice(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/invoices/generic`, data);
  }

  createInvoiceComplement(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/invoices/complemento`, data);
  }

  createInvoiceGlobal(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/invoices/global`, data);
  }

  createInvoiceNotaCredito(data: any): Observable<any> {
    // return this.httpClient.post(`http://localhost:3000/api/v1/invoices/nota_credito`, data);
    return this.httpClient.post(`${this.apiURL}/invoices/nota_credito`, data);
  }

  getInvoiceStatus(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/status/${id}`);
  }

  updateInvoice(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/invoices/${id}`, data);
  }

  saveInvoicePdf(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/invoices/save_pdf/${id}`, data);
  }

  destroyInvoice(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/invoices/${id}`);
  }

  cancelInvoice(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/invoices/cancel`, data);
  }

  getInvoicesQuery(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/query?${this.parseQueryParams(data)}`);
  }

  getInvoicesQueryGeneric(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/query_generic?${this.parseQueryParams(data)}`);
  }

  getInvoicesQueryGenericUuid(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/query_generic_uuid?${this.parseQueryParams(data)}`);
  }

  getInvoicesQueryGlobal(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/query_global?${this.parseQueryParams(data)}`);
  }

  getInvoicesQueryCanceled(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/query_canceled?${this.parseQueryParams(data)}`);
  }

  getInvoiceQueryCancel(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/query/cancel?${this.parseQueryParams(data)}`);
  }

  getInvoiceBySettlement(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/${id}/settlement?${this.parseQueryParams(data)}`);
  }

  getInvoiceByBuilding(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/${id}/building?${this.parseQueryParams(data)}`);
  }

  getInvoiceByFloor(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/${id}/floor?${this.parseQueryParams(data)}`);
  }

  getInvoiceByUnit(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/${id}/unit?${this.parseQueryParams(data)}`);
  }

  getBillByInvoice(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/${id}/invoice`);
  }

  getGlobalInvoiceBySettlement(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/global_settlement?${this.parseQueryParams(data)}`);
  }

  getGlobalRelatedBills(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/invoices/global/related/${id}`);
  }

  // CRUD Subscriptions

  getSubscriptions(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/subscriptions/${id}`);
  }

  getSubscription(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/subscriptions/unit/${id}`);
  }

  createSubscription(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/subscriptions`, data);
  }

  // CRUD Technicians

  getTechnicians(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/technicians?${this.parseQueryParams(data)}`);
  }

  getTechnician(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/technicians/${id}`);
  }

  createTechnician(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/technicians`, data);
  }

  updateTechnician(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/technicians/${id}`, data);
  }

  destroyTechnician(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/technicians/${id}`);
  }

  // CRUD Reading

  getReadings(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/readings?${this.parseQueryParams(data)}`);
  }

  getReading(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/readings/${id}`);
  }

  getReadingByTanque(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/readings/tanque/${id}`);
  }

  getWorkedByProfile(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/readings/worked-by-profile?${this.parseQueryParams(data)}`);
  }

  getWorkedBySettlement(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/readings/worked-by-settlement?${this.parseQueryParams(data)}`);
  }

  createReading(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/readings`, data);
  }

  updateReading(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/readings/${id}`, data);
  }

  destroyReading(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/readings/${id}`);
  }

  // CRUD Ability

  getAbilities(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/abilities?${this.parseQueryParams(data)}`);
  }

  getAbility(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/abilities/${id}`);
  }

  createAbility(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/abilities`, data);
  }

  updateAbility(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/abilities/${id}`, data);
  }

  destroyAbility(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/abilities/${id}`);
  }

  // CRUD Bills
  getBills(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills?${this.parseQueryParams(data)}`);
  }

  createBill(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills`, data);
  }

  createAdvancedBill(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/bills/advanced-bill`, data);
  }

  updateBill(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/bills/${id}`, data);
  }

  publishBill(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/bills/publish/${id}`, data);
  }

  unpublishBill(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/unpublish/${id}`);
  }

  getPreviousBill(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/previous/${id}`);
  }

  getBillsByUtility(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/utility/?${this.parseQueryParams(data)}`);
  }

  getBillsByUtilitySummary(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/paid/?${this.parseQueryParams(data)}`);
  }

  getBillsByProfile(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/profile?${this.parseQueryParams(data)}`);
  }

  updateFailedPayment(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/failed/${id}`);
  }

  destroyBill(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/bills/${id}`);
  }

  getAccountStatementBySettlement(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/settlement-statement?${this.parseQueryParams(data)}`);
  }

  updateBillPaidDate(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/bills/update_date/${id}`, data);
  }

  // CRUD Zones

  getZones(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/zones?${this.parseQueryParams(data)}`);
  }

  getZone(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/zones/${id}`);
  }

  createZone(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/zones`, data);
  }

  updateZone(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/zones/${id}`, data);
  }

  destroyZone(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/zones/${id}`);
  }

  getInvoicableBills(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/invoiceable?${this.parseQueryParams(data)}`);
  }

  getSettlementInvoices(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/settlement/invoice/${id}?${this.parseQueryParams(data)}`);
  }

  unit_subscriptions_last(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/units/bills/last/${id}`);
  }

  getTransaction(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/${id}`);
  }

  getTransactionBill(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/${id}`);
  }

  getTransactionByBill(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/bill/${id}`);
  }

  createTransaction(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/transactions/local`, data);
  }

  // ID = transaction_id
  updateTransaction(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/transactions/${id}`, data);
  }

  getTransactionsQuery(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/query?${this.parseQueryParams(data)}`);
  }

  getTransactionsBySettlement(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/${id}/settlement?${this.parseQueryParams(data)}`);
  }

  getTransactionsByBuilding(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/${id}/building?${this.parseQueryParams(data)}`);
  }

  getTransactionsByFloor(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/${id}/floor?${this.parseQueryParams(data)}`);
  }

  getTransactionsByUnit(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/${id}/unit?${this.parseQueryParams(data)}`);
  }

  getLastTransactionByUnit(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/transactions/last/${id}`);
  }
  
  login(data: any): Observable<any> {
    let login = this.httpClient.post(`${this.apiURL}/users/login`, data);

    // if (login.)

    return login;
  }

  signup(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/users/signup`, data);
  }

  tenantLogin(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/tenants/login`, data);
  }

  verifyEmail(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/users/confirmation?confirmation_token=${data}`, data);
  }

  recoverPassword(data: any): Observable<any> {
    let payload: any = {
      user: {
        email: data,
      }      
    };

    return this.httpClient.post(`${this.apiURL}/users/password`, payload);
  }

  confirmReset(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/users/password/edit?reset_password_token=${data}`);
  }

  uploadReceipt(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/bills/upload_receipt/${id}`, data);
  }

  saveReceipt(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/bills/save_receipt/${id}`, data);
  }
  
  createReceipt(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/recibos`, options);
  }

  downloadInvoice(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/invoice`, options);
  }

  downloadGenericInvoice(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/generic`, options);
    // return this.httpClient.post(`http://localhost:3100/generic`, options);
  }

  downloadComplement(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/complement`, options);
  }

  downloadGlobal(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/global`, options);
  }

  downloadNota(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/notacredit`, options);
  }

  downloadPaymentReceipt(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/recibo`, options);
    // return this.httpClient.post(`http://localhost:3100/recibo`, options);
  }

  createAcuseCancel(data: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain; charset=utf-8',
        'Accept': 'application/pdf'
      }),
      responseType: 'text' as const,
      body: data
    };

    return this.httpClient.post(`${this.receiptURL}/acuse`, options);
  }

  // CRUD Balances

  getBalances(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/balances?${this.parseQueryParams(data)}`);
  }

  getBalance(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/balances/${id}`);
  }

  getBalancesByProfile(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/bills/profile?${this.parseQueryParams(data)}`);
  }

  getBalancesBySettlement(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/balances/${id}/settlement?${this.parseQueryParams(data)}`);
  }

  getBalancesByBuilding(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/balances/${id}/building?${this.parseQueryParams(data)}`);
  }

  getBalancesByFloor(id: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/balances/${id}/floor?${this.parseQueryParams(data)}`);
  }

  getBalancesByUnit(unitId: any, data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/balances/unit/${unitId}?${this.parseQueryParams(data)}`);
  }

  searchInquilinos(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/tenants?${this.parseQueryParams(data)}`);
  }

  // CRUD Gas Meter

  getGasMeters(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/gas_meters?${this.parseQueryParams(data)}`);
  }

  getGasMeter(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/gas_meters/${id}`);
  }

  createGasMeter(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/gas_meters`, data);
  }

  updateGasMeter(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/gas_meters/${id}`, data);
  }

  destroyGasMeter(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/gas_meters/${id}`);
  }

  getGasMeterReadings(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/gas_meter_reads?${this.parseQueryParams(data)}`);
  }

  getGasMeterReading(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/gas_meter_reads/${id}`);
  }

  // CRUD Gas Meter Maintenance

  getGasMeterMaintenances(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/gas_meter_maintenances?${this.parseQueryParams(data)}`);
  }

  getGasMeterMaintenance(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/gas_meter_maintenances/${id}`);
  }

  createGasMeterMaintenance(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/gas_meter_maintenances`, data);
  }

  updateGasMeterMaintenance(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/gas_meter_maintenances/${id}`, data);
  }

  destroyGasMeterMaintenance(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/gas_meter_maintenances/${id}`);
  }

  setPrice(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/refuels/set_price`, data);
  }

  // CRUD Contract Types

  getContractTypes(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/contract_types?${this.parseQueryParams(data)}`);
  }

  getContractType(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/contract_types/${id}`);
  }

  createContractType(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/contract_types`, data);
  }

  updateContractType(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/contract_types/${id}`, data);
  }

  destroyContractType(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/contract_types/${id}`);
  }

  // CRUD Contracts

  getContracts(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/contracts?${this.parseQueryParams(data)}`);
  }

  getContract(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/contracts/${id}`);
  }

  createContract(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/contracts`, data);
  }

  updateContract(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/contracts/${id}`, data);
  }

  destroyContract(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/contracts/${id}`);
  }

  openSignedContract(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/contracts/download_signed_contract/${id}`);
  }

  // CRUD Public Records

  getPublicRecords(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/public_records?${this.parseQueryParams(data)}`);
  }

  getPublicRecord(id: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/public_records/${id}`);
  }

  createPublicRecord(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/public_records`, data);
  }

  updatePublicRecord(id: any, data: any): Observable<any> {
    return this.httpClient.put(`${this.apiURL}/public_records/${id}`, data);
  }

  destroyPublicRecord(id: any): Observable<any> {
    return this.httpClient.delete(`${this.apiURL}/public_records/${id}`);
  }

  // REPORTES

  getContratos(data: any): Observable<any> {
    return this.httpClient.get(`${this.apiURL}/settlements/contracts?${this.parseQueryParams(data)}`);
  }

  downloadTransactions(data: any): Observable<any> {
    return this.httpClient.post(`${this.apiURL}/transactions/download`, data);
  }
  
  getVideo(data: any): Observable<any> {
    return this.httpClient.get(`${this.videoURL}/videos/zazz${data}.mp4`);
  }
  
  
}
