import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-building-units',
  template: `
    <div (click)="selectUnit()">
    <div class="rounded-3 text-light float-start ms-1 bg-info" style="width: 44px; height: 44px;"> <!-- background-color: #A881D0; -->
          <h4 class="text-center" style="line-height: 2.5;">{{unit.name}}</h4>
        </div>
    </div>
  `,
  styleUrls: []
})
export class BuildingUnitsComponent {
  @Input() unit: any;
  @Output() unitData = new EventEmitter<any>();

  constructor() { }

  selectUnit() {
    console.log("TRIGGERº");
    this.unitData.emit(this.unit);
  }
}
