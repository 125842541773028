import { Component, Input } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-pie-chart-portfolio',
  template: `
    <figure id="pie-portfolio"></figure>
  `,
  styleUrls: []
})
export class PieChartPortfolioComponent {
  @Input() data: any;
  
  private svg: any;
  width = 250;
  height = 250;
  margin = 20;
  radius: any;

  constructor() { }

  ngOnInit(): void {
    
    this.createSvg();
    this.drawChart(this.data);
  }

  private createSvg(): void {
    const myElement = document.getElementById('portafolioChart');
    if (myElement) {
      this.width = myElement.getBoundingClientRect().width;
      this.height = myElement.getBoundingClientRect().width;
      // console.log('WIDTH => ', this.width);
    }
    this.radius = Math.min(this.width, this.height) / 2 - this.margin;

    this.svg = d3.select("figure#pie-portfolio")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("transform", "translate(" + this.width / 2 + "," + this.height / 2 + ")");
  }

  private drawChart(datax: any): void {
    // set the color scale
    // console.log('PIE PORTAFOLIO DATA =====> ', datax);
    // let data: any = [
      // { name: "a", value: 9 },
      // { name: "b", value: 20 },
      // { name: "c", value: 30 },
      // { name: "d", value: 8 },
      // { name: "e", value: 12 }
    // ];

    let data: any = [
      { name: "Cartera vencida", value: datax.past_due },
      { name: "Cartera al corriente", value: datax.current },
    ]

    const color = d3.scaleOrdinal()
      .domain(data.map((d: any) => d.name))
      .range(d3.schemeCategory10);

    const pie = d3.pie()
      .value((d: any) => d.value);

    const data_ready = pie(data);

    this.svg.selectAll('slices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', d3.arc()
        .innerRadius(0)
        .outerRadius(this.radius)
      )
      .attr('fill', (d: any) => color(d.data.name))
      // .attr("stroke", "black")
      // .style("stroke-width", "2px")
      .style("opacity", 0.7);

    this.svg.selectAll('labels')
      .data(data_ready)
      .enter()
      .append('text')
      .text((d: any) => d.data.name)
      .attr('transform', (d: any) => {
        // console.log('D => ', d);
        const pos = d3.arc()
              .innerRadius(40) 
              .outerRadius(45) 
              .startAngle(d.startAngle) 
              .endAngle(d.endAngle);
        const pox = pos.centroid(d);
        // console.log('POS => ', pos);
        // console.log('POX => ', pox);
        return `translate(${pox[0]}, ${pox[1]})`;
      })
      .style('text-anchor', 'middle')
      .style('font-size', '12px');
  }
  

  
}
