import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-show',
  template: ``,
  styleUrls: []
})
export class ProfileShowComponent {

}
