import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-form-tool',
  template: `
    <form [formGroup]="formTool" (ngSubmit)="saveTool()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="description" class="form-label ms-2">Descripción</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="description">
            <div *ngIf="ufc['description'].touched && ufc['description'].invalid">
              <div *ngIf="ufc['description'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="make" class="form-label ms-2">Marca</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="make">
            <div *ngIf="ufc['make'].touched && ufc['make'].invalid">
              <div *ngIf="ufc['make'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="model" class="form-label ms-2">Modelo</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="model">
            <div *ngIf="ufc['model'].touched && ufc['model'].invalid">
              <div *ngIf="ufc['model'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="serial_number" class="form-label ms-2">Número de serie</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="serial_number">
            <div *ngIf="ufc['serial_number'].touched && ufc['serial_number'].invalid">
              <div *ngIf="ufc['serial_number'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/inventory/tools">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormToolComponent implements OnInit {
  @Input() tool: any = {};
  @Input() profile: any = {};
  @Input() loading: boolean = false;
  @Output() toolData = new EventEmitter<any>();

  formTool: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private store: SettlementsService,
    private tasks: TasksService,
    private route: ActivatedRoute
  ) {}

  get ufc() {
    return this.formTool.controls;
  }

  ngOnInit(): void {
    this.initForm();
    console.log("this.profile", this.profile);
    this.formTool.patchValue({
      profile_id: this.profile.profile.id
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tool'] && changes['tool'].currentValue) {
      console.log('tool => ', changes['tool'].currentValue);
      this.formTool.patchValue(changes['tool'].currentValue);
    }
  }

  initForm() {
    this.formTool = this.fb.group({
      name: ['', []],
      description: ['', []],
      make: ['', []],
      model: ['', []],
      serial_number: ['', []],
      profile_id: ['', []]
    });
  }

  saveTool() {
    this.formTool.patchValue({
      profile_id: this.profile.profile.id
    });

    if (this.formTool.invalid) {
      this.formTool.markAllAsTouched();
      this.toastr.error('Revisa los campos en rojo.', 'Error');
      return;
    }
    
    this.toolData.emit(this.formTool.value);
  }

}
