<nav class="navbar navbar-expand-lg navbar-dark bg-dark d-lg-none fixed-top">
  <div class="container">
    <a class="navbar-brand" href="#"></a>
    <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed;" data-toggle="collapse" data-target="#mobileNav" aria-controls="mobileNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="mobileNav" [ngClass]="{ 'show': isNavbarCollapsed }">
      <ul class="nav nav-pills flex-column mb-auto mt-0 mt-sm-3" >    
        <li class="nav-item mt-0 mt-sm-5" *ngIf="permissionsLS?.menu?.admin">
          <!-- button class="btn d-inline-flex align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#admin" aria-expanded="false" aria-current="true">
            <h4 class="text-light d-md-none d-lg-block">Administración</h4>
          </!-->
          <div class="collapse show" id="admin" >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li *ngIf="permissionsLS?.modules.admin.dashboard.active">
              <a routerLink="dashboard" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="dart" [size]="20" class="float-start"></!-->
                <img src="assets/icons/dashboard_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Vista general</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.admin.settlements.active">
              <a routerLink="condominios/{{userLS.settlement_id}}" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="office" [size]="20" class="float-start"></!--> 
                <img src="assets/icons/settlement_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Condominios</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.admin.commercial.active">
              <a routerLink="comercials/{{userLS.settlement_id}}" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="department_store" [size]="20" class="float-start"></!--> 
                <img src="assets/icons/commercial_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Comercial</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.admin.billing.active">
              <button class="btn d-inline-flex align-items-center rounded collapsed" (click)="isCollapsedP = !isCollapsedP"
              [attr.aria-expanded]="!isCollapsedP" aria-controls="collapseExample">
                <!-- ngx-emoji emoji="receipt" [size]="20" class="nav-icon float-start"></!-->
                <img src="assets/icons/payments_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-2 ms-4 text-light d-md-none d-lg-block float-start">Pagos</span>
              </button>
    
              <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedP">
                <ul class="list-unstyled fw-normal pb-1 small ms-4">
                  <li *ngIf="permissionsLS?.modules.admin.billing.emitted.active">
                    <a routerLink="pagos/pendientes" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="inbox_tray" [size]="18" class="nav-icon float-start"></!-->
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Recibos no emitidos</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.billing.nopagos.active">
                    <a routerLink="pagos/recibos" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="dollar" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Recibos no pagados</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.billing.pagos.active">
                    <a routerLink="pagos" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="receipt" [size]="18" class="nav-icon float-start"></!-->
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Recibos Pagados</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.billing.saldos.active">
                      <a routerLink="pagos/balances" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="moneybag" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Saldos</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.billing.conceptos.active">
                    <a routerLink="pagos/conceptos" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="bookmark_tabs" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Conceptos</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li *ngIf="permissionsLS?.modules.admin.invoices.active">
              <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsedF = !isCollapsedF"
              [attr.aria-expanded]="!isCollapsedF" aria-controls="collapseExample">
                <!-- ngx-emoji emoji="bookmark_tabs" [size]="20" class="nav-icon float-start"></!-->
                <img src="assets/icons/invoices_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Facturación</span>
              </button>
              <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedF">
                <ul class="list-unstyled fw-normal pb-1 small ms-4">
                  <li *ngIf="permissionsLS?.modules.admin.invoices.dashboard.active">
                    <a routerLink="invoices" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="dart" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Dashboard</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.invoices.ingreso.active">
                    <a routerLink="invoices/ingresos" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="blue_book" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas Ingreso</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.invoices.complementos.active">
                    <a routerLink="invoices/complementos" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="green_book" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Complementos</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.invoices.global.active">
                    <a routerLink="invoices/globales" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="orange_book" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas globales</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.invoices.notas.active">
                    <a routerLink="invoices/creditnote" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="closed_book" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Notas de crédito</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.invoices.canceladas.active">
                    <a routerLink="invoices/canceladas" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="closed_book" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas canceladas</span>
                    </a>
                  </li>    
                  <li *ngIf="permissionsLS?.modules?.admin?.invoices?.acceptance?.active">
                    <a routerLink="invoices/aceptacion" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="closed_book" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas confirmación</span>
                    </a>
                  </li>              
                </ul>
              </div>
            </li>
            <li *ngIf="permissionsLS?.modules.admin.inventory.active">
              <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsedI = !isCollapsedI"
              [attr.aria-expanded]="!isCollapsedI" aria-controls="collapseExample">
                <!-- ngx-emoji emoji="bookmark_tabs" [size]="20" class="nav-icon float-start"></!-->
                <img src="assets/icons/inventory_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Inventario</span>
              </button>
              <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedI">
                <ul class="list-unstyled fw-normal pb-1 small ms-4">
                  <li *ngIf="permissionsLS?.modules.admin.inventory.vehiculos.active">
                    <a routerLink="inventory/vehicles" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="racing_motorcycle" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/vehicles_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Vehículos</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.inventory.skills.active">
                    <a routerLink="inventory/skills" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="test_tube" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/skills_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Habilidades</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.admin.inventory.tools.active">
                    <a routerLink="inventory/tools" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="toolbox" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/skills_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Herramientas</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li *ngIf="permissionsLS?.modules?.admin?.contracts?.active">
              <a routerLink="contratos" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="minidisc" [size]="20" class="float-start"></!--> 
                <img src="assets/icons/reports_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Contratos</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.admin.reports.active">
              <a routerLink="reports" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="minidisc" [size]="20" class="float-start"></!--> 
                <img src="assets/icons/reports_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Reportes</span>
              </a>
            </li>
          </ul>
        </div>
        </li>
        <li class="nav-item mt-3" *ngIf="permissionsLS?.menu?.operation">
          <button class="btn d-inline-flex align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#inicio" aria-expanded="false" aria-current="true">
            <h4 class="text-light d-md-none d-lg-block">Operación</h4>
          </button>
          <div class="collapse show" id="inicio">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            
            <li *ngIf="permissionsLS?.modules.operation.service.active">
              <button class="btn d-inline-flex align-items-center rounded collapsed" (click)="isCollapsedM = !isCollapsedM"
              [attr.aria-expanded]="!isCollapsedM" aria-controls="collapseExample">
                <!-- ngx-emoji emoji="calendar" [size]="20" class="nav-icon float-start"></!-->
                <img src="assets/icons/maintenance_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Servicio técnico</span>
              </button>
    
              <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedM">
                <ul class="list-unstyled fw-normal pb-1 small ms-4">
                  <li *ngIf="permissionsLS?.modules.operation.service.calendario.active">
                    <a routerLink="maintenances" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="calendar" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/calendar_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Calendario</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.operation.service.tareas.active">
                    <a routerLink="maintenances/tasks" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/tasks_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tareas</span>
                    </a>
                  </li>
    
                  <li *ngIf="permissionsLS?.modules.operation.service.unassigned?.active">
                    <a routerLink="maintenances/tasks/unassigned" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/unassigned_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tareas no asignadas</span>
                    </a>
                  </li>
    
                  <li *ngIf="permissionsLS?.modules.operation.service.refuel?.active">
                    <a routerLink="maintenances/tasks/suministro" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/po_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tareas de suministro</span>
                    </a>
                  </li>
                  <!-- li>
                    <a routerLink="maintenances/categories" class="nav-link link-light clearfix">
                      <ngx-emoji emoji="bookmark" [size]="18" class="nav-icon float-start"></ngx-emoji> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Categorías</span>
                    </a>
                  </!-->
                  <li *ngIf="permissionsLS?.modules.operation.service.builder.active">
                    <a routerLink="maintenances/builders" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="building_construction" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/builder_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Constructor</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.operation.service.activities.active">
                    <a routerLink="maintenances/activities" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="paperclip" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/activities_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Actividades</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.operation.service.activityTypes.active">
                    <a routerLink="maintenances/activity-types" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="bookmark" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tipos de actividades</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.operation.service.holidays.active">
                    <a routerLink="maintenances/holidays" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="calendar" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Días festivos</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li *ngIf="permissionsLS?.modules.operation.services.active">
              <a routerLink="services" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="hammer_and_wrench" [size]="20" class="nav-icon float-start"></!-->
                <img src="assets/icons/services_gold.png" class="float-start" style="width: 20px;">
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Servicios</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="permissionsLS?.modules.operation.amenities.active">
              <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsedA = !isCollapsedA"
              [attr.aria-expanded]="!isCollapsedA" aria-controls="collapseExample">
                <!-- ngx-emoji emoji="bellhop_bell" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Amenidades</span>
              </button>
    
              <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedA">
                <ul class="list-unstyled fw-normal pb-1 small ms-4">
                  <li>
                    <a routerLink="amenidades/calendario" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="calendar" [size]="18" class="nav-icon float-start"></!--> 
                      <img src="assets/icons/calendar_gold.png" class="float-start" style="width: 20px;">
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Calendario</span>
                    </a>
                  </li>
                  <li>
                    <a routerLink="amenidades/solicitudes" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Solicitudes</span>
                    </a>
                  </li>
                  <li>
                    <a routerLink="amenidades" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="bookmark" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Catálogo</span>
                    </a>
                  </li>              
                </ul>
              </div>
            </li>
            <li class="nav-item" *ngIf="permissionsLS?.modules.operation.providers.active">
              <a routerLink="providers" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="package" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Proveedores</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="permissionsLS?.modules.operation.combustibles.active">
    
              <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                <!-- ngx-emoji emoji="fuelpump" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 text-light d-md-none d-lg-block float-start">Combustible</span>
              </button>
    
              <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsed">
                <ul class="list-unstyled fw-normal pb-1 small ms-4">
                  <li *ngIf="permissionsLS?.modules.operation.combustibles.tanques.active">
                    <a routerLink="combustibles/tanques" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="fuelpump" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Lista de tanques</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.operation.combustibles.suministros.active">
                    <a routerLink="combustibles/refuels" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="oil_drum" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Suministro</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.operation.combustibles.pedidos.active">
                    <a routerLink="combustibles/orders" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="outbox_tray" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Pedidos</span>
                    </a>
                  </li>
                  <li *ngIf="permissionsLS?.modules.operation.combustibles.zonas.active">
                    <a routerLink="combustibles/zonas" class="nav-link link-light clearfix">
                      <!-- ngx-emoji emoji="earth_americas" [size]="18" class="nav-icon float-start"></!--> 
                      <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Zonas</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        </li>    
        
        <li class="nav-item mt-3" *ngIf="permissionsLS?.menu?.config">
          <button class="btn d-inline-flex align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#config" aria-expanded="false" aria-current="true">
            <h4 class="text-light d-md-none d-lg-block">Configuración</h4>
          </button>
          <div class="collapse show" id="config">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li *ngIf="permissionsLS?.modules.config.technicians.active">
              <a routerLink="technicians" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="mechanic" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Técnicos</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.config.admins.active">
              <a routerLink="users" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="office_worker" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Administradores</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.config.tenants.active">
              <a routerLink="tenants" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="technologist" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 float-start">Inquilinos</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.config.profiles.active">
              <a routerLink="perfiles" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="technologist" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Perfiles</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.config.roles.active">
              <a routerLink="roles" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="mechanical_arm" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Roles</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules?.config?.contract_types?.active">
              <a routerLink="contract-types" class="nav-link link-light clearfix">
                <!-- ngx-emoji emoji="mechanical_arm" [size]="20" class="nav-icon float-start"></!-->
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tipo de contratos</span>
              </a>
            </li>
          </ul>
        </div>
        </li>
      </ul>
      
      <ul class="nav nav-pills flex-column mb-auto" *ngIf="permissionsLS?.menu?.tenants">
        <li class="nav-item mt-0 mt-sm-5">
          <!-- button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-tenant" aria-expanded="true">
            <h3 class="text-light">Inquilinos</h3>
          </!-->
          <div class="collapse show" id="home-tenant">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li *ngIf="permissionsLS?.modules.tenants.dashboard.active">
              <a routerLink="tenant/dashboard" class="nav-link link-light clearfix" (click)="isNavbarCollapsed=!isNavbarCollapsed">
                <!-- ngx-emoji emoji="dart" class="nav-icon float-start" ></!-->
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Inicio</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.tenants.profile.active">
              <a routerLink="tenant/profile/{{user.id}}" class="nav-link link-light clearfix" (click)="isNavbarCollapsed=!isNavbarCollapsed">
                <!-- ngx-emoji emoji="technologist" [size]="20" class="nav-icon float-start" ></!--> 
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Mi perfil</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.tenants.payment_methods.active">
              <a routerLink="tenant/payment-methods/{{user.id}}" class="nav-link link-light clearfix" (click)="isNavbarCollapsed=!isNavbarCollapsed">
                <!-- ngx-emoji emoji="credit_card" [size]="22" class="nav-icon float-start"></!--> 
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Métodos de pago</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.tenants.billing.active">
              <a routerLink="tenant/bills/{{user.id}}" class="nav-link link-light clearfix" (click)="isNavbarCollapsed=!isNavbarCollapsed">
                <!-- ngx-emoji emoji="dollar" [size]="22" class="nav-icon float-start" ></!--> 
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Mis recibos</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.tenants.payments.active">
              <a routerLink="tenant/payments/{{user.id}}" class="nav-link link-light clearfix" (click)="isNavbarCollapsed=!isNavbarCollapsed">
                <!-- ngx-emoji emoji="receipt" [size]="22" class="nav-icon float-start" ></!--> 
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Historial de pagos</span>
              </a>
            </li>
            <li *ngIf="permissionsLS?.modules.tenants.billing.active">
              <a routerLink="tenant/invoice/list/{{user.id}}" class="nav-link link-light clearfix" (click)="isNavbarCollapsed=!isNavbarCollapsed">
                <!-- ngx-emoji emoji="dollar" [size]="22" class="nav-icon float-start" ></!--> 
                <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Mis facturas</span>
              </a>
            </li>
            <li class="nav-item text-light bg-dark list-unstyled pt-0 ptm-sm-3 pb-0 pb-sm-3 px-2 px-sm-2">
              <a routerLink="" class="nav-link link-light clearfix" (click)="signOut()">
                <span class="mt-0 pb-2 ms-3 d-md-none d-lg-block float-start logout">Cerrar sesión</span>
              </a>
            </li>
          </ul>
        </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="d-flex flex-column flex-shrink-0 p-3 bg-dark h-100 fixed-top main-nav d-none d-md-block" style="overflow-y: scroll;">
  <div class="text-light ps-2 pt-3 fixed-top bg-dark main-nav" *ngIf="roleLS.group_id == 3">
    <div class="float-start rounded-5 bg-light ms-3 avatar">
      <img src="{{avatarLS.avatar}}" width="100%">
    </div>
    <div class="float-start ms-3 mt-1 pt-1 me-2 d-md-none d-lg-block username">{{user.name}} {{user.last_name}}</div>
    <!-- div *ngIf="roleLS.group_id==3" class="mt-3">
      <a routerLink="tenant/profile/{{user.id}}">
        <ngx-emoji emoji="gear" class="mt-1" style="position: relative; top: 6px;"></ngx-emoji>
      </a>
    </!-->
  </div>


  <div class="text-light ps-2 pt-3 fixed-top bg-dark main-nav" *ngIf="profile">
      <div class="float-start rounded-3 bg-light ms-3 avatar text-center pt-1 me-3" style="width: 44px; height: 44px;">
        <img [src]="profileLogo" width="86%">
      </div>
      <div class="ps-3 me-2 d-md-none d-lg-block username text-wrap align-middle" style="line-height: 1.2; height: 44px;">
        <!-- h5 class="mb-0">PERFIL</!-->
        <p class="mb-0 align-middle" style="font-size: 1em !important;">{{profile.name}}</p>
      </div>
      <!-- div class="mt-2 float-end d-md-none d-lg-block" *ngIf="userLS.role_id == 1">
          <a routerLink="users/profile/{{user.id}}">
              <ngx-emoji emoji="gear"></ngx-emoji>
          </a>
      </!-->      
  </div>
  <!-- div class="mt-4 mb-3 ms-2 me-3" style="margin-top: 100px !important;" >
      <input class="form-control rounded-3 bg-transparent" type="text" placeholder="Buscar" aria-label="Search">
  </!-->
  <ul class="nav nav-pills flex-column mb-auto mt-3">    
    <li class="nav-item mt-0 mt-sm-5" *ngIf="permissionsLS?.menu?.admin">
      <button class="btn d-inline-flex align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#admin" aria-expanded="false" aria-current="true">
        <h4 class="text-light d-md-none d-lg-block">Administración</h4>
      </button>
      <div class="collapse show" id="admin">
      <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
        <li *ngIf="permissionsLS?.modules.admin.dashboard.active">
          <a routerLink="dashboard" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="dart" [size]="20" class="float-start"></!-->
            <img src="assets/icons/dashboard_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Vista general</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.admin.settlements.active">
          <a routerLink="condominios/{{userLS.settlement_id}}" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="office" [size]="20" class="float-start"></!--> 
            <img src="assets/icons/settlement_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Condominios</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.admin.commercial.active">
          <a routerLink="comercials/{{userLS.settlement_id}}" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="department_store" [size]="20" class="float-start"></!--> 
            <img src="assets/icons/commercial_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Comercial</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.admin.billing.active">
          <button class="btn d-inline-flex align-items-center rounded collapsed" (click)="isCollapsedP = !isCollapsedP"
          [attr.aria-expanded]="!isCollapsedP" aria-controls="collapseExample">
            <!-- ngx-emoji emoji="receipt" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/payments_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-2 ms-4 text-light d-md-none d-lg-block float-start">Pagos</span>
          </button>

          <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedP">
            <ul class="list-unstyled fw-normal pb-1 small ms-4">
              <li *ngIf="permissionsLS?.modules.admin.billing.emitted.active">
                <a routerLink="pagos/pendientes" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="inbox_tray" [size]="18" class="nav-icon float-start"></!-->
                  <img src="assets/icons/non_emitted_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Recibos no emitidos</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.billing.nopagos.active">
                <a routerLink="pagos/recibos" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="dollar" [size]="18" class="nav-icon float-start"></!-->
                  <img src="assets/icons/non_paid_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Recibos no pagados</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.billing.pagos.active">
                <a routerLink="pagos" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="receipt" [size]="18" class="nav-icon float-start"></!-->
                  <img src="assets/icons/paid_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Recibos Pagados</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.billing.saldos.active">
                  <a routerLink="pagos/balances" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="moneybag" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/saldos_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Saldos</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.billing.conceptos.active">
                <a routerLink="pagos/conceptos" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="bookmark_tabs" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/concepts_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Conceptos</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="permissionsLS?.modules.admin.invoices.active">
          <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsedF = !isCollapsedF"
          [attr.aria-expanded]="!isCollapsedF" aria-controls="collapseExample">
            <!-- ngx-emoji emoji="bookmark_tabs" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/invoices_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Facturación</span>
          </button>
          <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedF">
            <ul class="list-unstyled fw-normal pb-1 small ms-4">
              <li *ngIf="permissionsLS?.modules.admin.invoices.dashboard.active">
                <a routerLink="invoices" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="dart" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/dashboard_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Dashboard</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.invoices.ingreso.active">
                <a routerLink="invoices/ingresos" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="blue_book" [size]="18" class="nav-icon float-start"></!-->
                  <img src="assets/icons/invoices_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas Ingreso</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.invoices.complementos.active">
                <a routerLink="invoices/complementos" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="green_book" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/invoices_complement_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Complementos</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.invoices.global.active">
                <a routerLink="invoices/globales" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="orange_book" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/invoices_global_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas globales</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.invoices.notas.active">
                <a routerLink="invoices/creditnote" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="closed_book" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/invoices_credit_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Notas de crédito</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.invoices.canceladas.active">
                <a routerLink="invoices/canceladas" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="closed_book" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/invoice_cancel_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas canceladas</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules?.admin?.invoices?.acceptance?.active">
                <a routerLink="invoices/aceptacion" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="closed_book" [size]="18" class="nav-icon float-start"></!--> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Facturas confirmación</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="permissionsLS?.modules.admin.inventory.active">
          <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsedI = !isCollapsedI"
          [attr.aria-expanded]="!isCollapsedI" aria-controls="collapseExample">
            <!-- ngx-emoji emoji="bookmark_tabs" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/inventory_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Inventario</span>
          </button>
          <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedI">
            <ul class="list-unstyled fw-normal pb-1 small ms-4">
              <li *ngIf="permissionsLS?.modules.admin.inventory.vehiculos.active">
                <a routerLink="inventory/vehicles" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="racing_motorcycle" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/vehicles_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Vehículos</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.inventory.skills.active">
                <a routerLink="inventory/skills" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="test_tube" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/skills_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Habilidades</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.admin.inventory.tools.active">
                <a routerLink="inventory/tools" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="toolbox" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/skills_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Herramientas</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="permissionsLS?.modules?.admin?.contracts?.active">
          <a routerLink="contratos" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="minidisc" [size]="20" class="float-start"></!--> 
            <img src="assets/icons/reports_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Contratos</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.admin.reports.active">
          <a routerLink="reports" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="minidisc" [size]="20" class="float-start"></!--> 
            <img src="assets/icons/reports_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Reportes</span>
          </a>
        </li>        
      </ul>
    </div>
    </li>
    <li class="nav-item mt-3" *ngIf="permissionsLS?.menu?.operation">
      <button class="btn d-inline-flex align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#inicio" aria-expanded="false" aria-current="true">
        <h4 class="text-light d-md-none d-lg-block">Operación</h4>
      </button>
      <div class="collapse show" id="inicio">
      <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
        
        <li *ngIf="permissionsLS?.modules.operation.service.active">
          <button class="btn d-inline-flex align-items-center rounded collapsed" (click)="isCollapsedM = !isCollapsedM"
          [attr.aria-expanded]="!isCollapsedM" aria-controls="collapseExample">
            <!-- ngx-emoji emoji="calendar" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/maintenance_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Servicio técnico</span>
          </button>

          <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedM">
            <ul class="list-unstyled fw-normal pb-1 small ms-4">
              <li *ngIf="permissionsLS?.modules.operation.service.calendario.active">
                <a routerLink="maintenances" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="calendar" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/calendar_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Calendario</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.operation.service.tareas.active">
                <a routerLink="maintenances/tasks" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/tasks_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tareas</span>
                </a>
              </li>

              <li *ngIf="permissionsLS?.modules.operation.service.unassigned?.active">
                <a routerLink="maintenances/tasks/unassigned" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/unassigned_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tareas no asignadas</span>
                </a>
              </li>

              <li *ngIf="permissionsLS?.modules.operation.service.refuel?.active">
                <a routerLink="maintenances/tasks/suministro" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/po_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tareas de suministro</span>
                </a>
              </li>
              <!-- li>
                <a routerLink="maintenances/categories" class="nav-link link-light clearfix">
                  <ngx-emoji emoji="bookmark" [size]="18" class="nav-icon float-start"></ngx-emoji> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Categorías</span>
                </a>
              </!-->
              <li *ngIf="permissionsLS?.modules.operation.service.builder.active">
                <a routerLink="maintenances/builders" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="building_construction" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/builder_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Constructor</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.operation.service.activities.active">
                <a routerLink="maintenances/activities" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="paperclip" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/activities_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Actividades</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.operation.service.activityTypes.active">
                <a routerLink="maintenances/activity-types" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="bookmark" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/activity_type_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tipos de actividades</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.operation.service.holidays.active">
                <a routerLink="maintenances/holidays" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="calendar" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/holiday_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Días festivos</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li *ngIf="permissionsLS?.modules.operation.services.active">
          <a routerLink="services" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="hammer_and_wrench" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/services_gold.png" class="float-start" style="width: 20px;">
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Servicios</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissionsLS?.modules.operation.amenities.active">
          <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsedA = !isCollapsedA"
          [attr.aria-expanded]="!isCollapsedA" aria-controls="collapseExample">
            <!-- ngx-emoji emoji="bellhop_bell" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/amenity_gold.png" class="float-start" style="width: 20px;"> 
            <span class="mt-0 pb-1 ms-4 text-light d-md-none d-lg-block float-start">Amenidades</span>
          </button>

          <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsedA">
            <ul class="list-unstyled fw-normal pb-1 small ms-4">
              <li>
                <a routerLink="amenidades/calendario" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="calendar" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/calendar_gold.png" class="float-start" style="width: 20px;">
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Calendario</span>
                </a>
              </li>
              <li>
                <a routerLink="amenidades/solicitudes" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="clipboard" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/solicitudes_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Solicitudes</span>
                </a>
              </li>
              <li>
                <a routerLink="amenidades" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="bookmark" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/catalog_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Catálogo</span>
                </a>
              </li>              
            </ul>
          </div>
        </li>
        <li class="nav-item" *ngIf="permissionsLS?.modules.operation.providers.active">
          <a routerLink="providers" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="package" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/provider_gold.png" class="float-start" style="width: 20px;"> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Proveedores</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="permissionsLS?.modules.operation.combustibles.active">

          <button class="btn d-inline-flex align-items-center rounded collapsed clearfix" (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <!-- ngx-emoji emoji="fuelpump" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/combustible_gold.png" class="float-start" style="width: 20px;"> 
            <span class="mt-0 pb-2 ms-4 text-light d-md-none d-lg-block float-start">Combustible</span>
          </button>

          <div class="collapse" id="customize-collapse" [ngbCollapse]="isCollapsed">
            <ul class="list-unstyled fw-normal pb-1 small ms-4">
              <li *ngIf="permissionsLS?.modules.operation.combustibles.tanques.active">
                <a routerLink="combustibles/tanques" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="fuelpump" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/tank_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Lista de tanques</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.operation.combustibles.suministros.active">
                <a routerLink="combustibles/refuels" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="oil_drum" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/supply_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Suministro</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.operation.combustibles.pedidos.active">
                <a routerLink="combustibles/orders" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="outbox_tray" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/po_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Pedidos</span>
                </a>
              </li>
              <li *ngIf="permissionsLS?.modules.operation.combustibles.zonas.active">
                <a routerLink="combustibles/zonas" class="nav-link link-light clearfix">
                  <!-- ngx-emoji emoji="earth_americas" [size]="18" class="nav-icon float-start"></!--> 
                  <img src="assets/icons/zone_gold.png" class="float-start" style="width: 20px;"> 
                  <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Zonas</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    </li>    
    
    <li class="nav-item mt-0 mt-sm-3" *ngIf="permissionsLS?.menu?.config">
      <button class="btn d-inline-flex align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#config" aria-expanded="false" aria-current="true">
        <h4 class="text-light d-md-none d-lg-block">Configuración</h4>
      </button>
      <div class="collapse show" id="config">
      <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
        <li *ngIf="permissionsLS?.modules.config.technicians.active">
          <a routerLink="technicians" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="mechanic" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/tech_gold.png" class="float-start" style="width: 20px;"> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Técnicos</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.config.admins.active">
          <a routerLink="users" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="office_worker" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/invoices_gold.png" class="float-start" style="width: 20px;"> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Administradores</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.config.tenants.active">
          <a routerLink="tenants" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="technologist" [size]="20" class="nav-icon float-start"></!-->
            <img src="assets/icons/tenant_gold.png" class="float-start" style="width: 20px;"> 
            <span class="mt-0 pb-2 ms-4 float-start">Inquilinos</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.config.profiles.active">
          <a routerLink="perfiles" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="technologist" [size]="20" class="nav-icon float-start"></!-->
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Perfiles</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.config.roles.active">
          <a routerLink="roles" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="mechanical_arm" [size]="20" class="nav-icon float-start"></!-->
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Roles</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules?.config?.contract_types?.active">
          <a routerLink="contract-types" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="mechanical_arm" [size]="20" class="nav-icon float-start"></!-->
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Tipo de contratos</span>
          </a>
        </li>
      </ul>
    </div>
    </li>
  </ul>
  
  <ul class="nav nav-pills flex-column mb-auto" *ngIf="permissionsLS?.menu?.tenants">
    <li class="nav-item mt-0 mt-sm-5">
      <!-- button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-tenant" aria-expanded="true">
        <h3 class="text-light">Inquilinos</h3>
      </!-->
      <div class="collapse show" id="home-tenant">
      <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
        <li *ngIf="permissionsLS?.modules.tenants.dashboard.active">
          <a routerLink="tenant/dashboard" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="dart" class="nav-icon float-start" ></!-->
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Inicio</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.tenants.profile.active">
          <a routerLink="tenant/profile/{{user.id}}" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="technologist" [size]="20" class="nav-icon float-start" ></!--> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Mi perfil</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.tenants.payment_methods.active">
          <a routerLink="tenant/payment-methods/{{user.id}}" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="credit_card" [size]="22" class="nav-icon float-start"></!--> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Métodos de pago</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.tenants.billing.active">
          <a routerLink="tenant/bills/{{user.id}}" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="dollar" [size]="22" class="nav-icon float-start" ></!--> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Mis recibos</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.tenants.payments.active">
          <a routerLink="tenant/payments/{{user.id}}" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="receipt" [size]="22" class="nav-icon float-start" ></!--> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Historial de pagos</span>
          </a>
        </li>
        <li *ngIf="permissionsLS?.modules.tenants.payments.active">
          <a routerLink="tenant/invoice/list/{{user.id}}" class="nav-link link-light clearfix">
            <!-- ngx-emoji emoji="receipt" [size]="22" class="nav-icon float-start" ></!--> 
            <span class="mt-0 pb-2 ms-4 d-md-none d-lg-block float-start">Mis facturas</span>
          </a>
        </li>
      </ul>
    </div>
    </li>
  </ul>
  <li class="nav-item text-light bg-dark list-unstyled fixed-bottom pt-0 ptm-sm-3 pb-0 pb-sm-3 px-2 px-sm-2">
    <a routerLink="" class="nav-link link-light clearfix" (click)="signOut()">
      <!-- ngx-emoji emoji="man-running" [size]="20" class="nav-icon float-start"></!-->
      <img src="assets/icons/logout_gold.png" class="float-start nav-icon" style="width: 20px;">
      <span class="mt-0 pb-2 ms-3 d-md-none d-lg-block float-start logout">Cerrar sesión</span>
      <span class="float-end mt-1 d-md-none d-lg-block"><small style="font-size: 13px;"><b>v{{version}}</b></small></span>
    </a>
  </li>
</div>