import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEdit, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-profile',
  template: `
    <form [formGroup]="formProfile" (ngSubmit)="saveProfile()">
      <div class="row">
        <div class="col-md-12">
        <div class="avatar-container text-center">
            <div class="image-container border border-4">
              <img [src]="avatarURL" width="100%">
            </div> 
            <label for="avatar-input-file">
              <div class="icon-wrapper">
                <input type="file" id="avatar-input-file" (change)="loadAvatar($event)" accept="image/*"/>
                <fa-icon [icon]="faEdit" class="text-white"></fa-icon>
              </div>
            </label>
          </div>

          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre del perfil</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="rfc" class="form-label ms-2">RFC</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="rfc" placeholder="" formControlName="rfc">
            <div *ngIf="ufc['rfc'].touched && ufc['rfc'].invalid">
              <div *ngIf="ufc['rfc'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="razon_social" class="form-label ms-2">Razón social</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="razon_social" placeholder="" formControlName="razon_social">
            <div *ngIf="ufc['razon_social'].touched && ufc['razon_social'].invalid">
              <div *ngIf="ufc['razon_social'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="regimen_fiscal" class="form-label ms-2">Régimen fiscal</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="regimen_fiscal" placeholder="" formControlName="regimen_fiscal">
            <div *ngIf="ufc['regimen_fiscal'].touched && ufc['regimen_fiscal'].invalid">
              <div *ngIf="ufc['regimen_fiscal'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="address" class="form-label ms-2">Dirección</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="address" placeholder="" formControlName="address">
            <div *ngIf="ufc['address'].touched && ufc['address'].invalid">
              <div *ngIf="ufc['address'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="phone" class="form-label ms-2">Teléfono</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="phone" placeholder="" formControlName="phone">
            <div *ngIf="ufc['phone'].touched && ufc['phone'].invalid">
              <div *ngIf="ufc['phone'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="email" class="form-label ms-2">Correo electrónico</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="email" placeholder="" formControlName="email">
            <div *ngIf="ufc['email'].touched && ufc['email'].invalid">
              <div *ngIf="ufc['email'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="zip_code" class="form-label ms-2">Código postal</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="zip_code" placeholder="" formControlName="zip_code">
            <div *ngIf="ufc['zip_code'].touched && ufc['zip_code'].invalid">
              <div *ngIf="ufc['zip_code'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="utility_id" class="form-label ms-2">Servicio</label>
            <select class="form-select form-select-lg" formControlName="utility_id">
              <option *ngFor="let utility of utilities" [value]="utility.id">{{utility.name}}</option>
            </select>
          </div>
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/perfiles">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormProfileComponent implements OnInit {
  @Input() profile: any = {};
  @Input() loading: boolean = false;
  @Output() profileData = new EventEmitter<any>();

  formProfile: FormGroup = new FormGroup({});

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  settlements: any[] = [];
  buildings: any[] = [];
  utilities: any[] = [];

  avatarURL: any = '';

  imageError: string = "";
  isImageSaved: boolean = false;
  cardImageBase64: string = "";
  previewImagePath: string = "";

  faEdit = faEdit;
  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(params => {
      console.log(params);
      let payload: any = {
        page: 1,
        per_page: 100,
        tipo: 0
      }
        this.store.getSettlements(payload).subscribe({
          next: (data) => {
            this.settlements = data.data;
          },
          error: (err) => {
            console.log(err);
            this.toastr.error('Ocurrió un error al obtener los condominios.');
          }
        });
    });
  } 

  get ufc() {
    return this.formProfile.controls;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadUtilities();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['profile'] && changes['profile'].currentValue) {
      // this.formProfile.patchValue({
        // profile_id: this.profile.profile.id
      // });
      this.preFill();
    }
  }

  initForm() {
    this.formProfile = this.fb.group({
      name: ['', Validators.required],
      rfc: ['', Validators.required],
      razon_social: ['', Validators.required],
      regimen_fiscal: ['', Validators.required],
      address: [''],
      phone: [''],
      email: [''],
      zip_code: [''],
      utility_id: [''],
      logo: [null],
    });
  }

  preFill() {
    if (this.profile) {
      this.formProfile.patchValue(this.profile);

      let el = this.profile.logo;
      if (el) {
        this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;        
      }
    }
  }

  loadUtilities() {
    this.loading = true;
    this.store.getUtilities({}).subscribe({
      next: (utilities: any) => {
        this.utilities = utilities.data;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.loading = false;
        this.utilities = [];
        this.toastr.error('Ocurrió un error al obtener las amenidades.', 'Error');
      }
    });
  }

  selectSettlement(e: any) {
    console.log('selectSettlement');
    console.log(e.target.value);
    this.store.getBuildingsBySettlement(e.target.value).subscribe({
      next: (data) => {
        console.log(data);
        this.buildings = data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  selectBuilding(e: any) {
    this.formProfile.patchValue({
      building_id: e.target.value
    });
  }

  loadAvatar(event: any): void {

    this.imageError = "";

    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (event.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';

        // return false;
      }

      // if (!_.includes(allowed_types, event.target.files[0].type)) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        // return false;
      // }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          // console.log(img_height, img_width);
          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            // return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.previewImagePath = imgBase64Path;
            console.log('imgBase64Path => ', imgBase64Path);
            console.log('previewImagePath => ', this.previewImagePath);
            // let payload = {
              // id: this.userId,
              // data: this.previewImagePath
            // }
            // console.log('event => ', event.target.files[0]);
            // this.store.updateAvatar(this.userForm.value.id, payload).subscribe((data: any) => {              
            // });
            this.formProfile.patchValue({
              logo: this.previewImagePath
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }    
  }

  saveProfile() {
    console.log(this.formProfile.value);

    if (this.formProfile.invalid) {
      this.formProfile.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.profileData.emit(this.formProfile.value);    
  }
}
