import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-vehicle',
  template: `
    <div class="row">
      <div class="col-md-5 py-3 ps-5">
        <h4 class="mb-0">
          <a routerLink="{{vehicle.id}}"  *ngIf="permissions?.modules.admin.inventory.vehiculos.write">{{vehicle.name}}</a>
          <span  *ngIf="!permissions?.modules.admin.inventory.vehiculos.write">{{vehicle.name}}</span>          
        </h4>
        <p class="mb-0">{{vehicle.description}}</p>
        <h5>
          <span class="badge badge-pill rounded-5 mb-0 px-3 py-1 me-2" [ngStyle]="{ background: assigned(vehicle.assignee_id).color, color: assigned(vehicle.assignee_id).text }">{{assigned(vehicle.assignee_id).name}}</span>
        </h5>
      </div>
      <div class="col-md-4 py-3">
        <div class="clearfix mb-1">
          <div style="width: 32px !important; height: 32px !important; border-radius: 18px; overflow: hidden;" *ngIf="technician?.avatar" class="float-start bg-dark">
            <img [src]="avatar" width="100%" style="border-radius: 18px;">
          </div>
          <div class="float-start ms-2" *ngIf="technician">
            <p class="text-muted text-sm mb-0" style="font-size: 13px; line-height: 1;">#{{technician.employee_number}}</p>
            <p style="font-size: 17px; line-height: 1;" class="mb-0">{{technician.name}} {{technician.last_name}}</p>
          </div>
        </div>

        <p class="mb-0">{{vehicle.marca}} {{vehicle.modelo}}</p>
        <p class="mb-0">Placas: {{vehicle.plates}}</p>
      </div>
      <div class="col-md-2 text-end ps-0 pe-3 py-3">
        <p class="mb-0 text-sm text-muted">Actualizado: {{vehicle.updated_at | date: 'dd/MM/yyyy HH:mm'}}</p>
      </div>
      <div class="col-md-1 text-center p-0" *ngIf="permissions?.modules.admin.inventory.vehiculos.write">
      <div class="hover-info border-start pt-3 pe-4" style="height: 100%;" (click)="asignVehicle()">
        <fa-icon [icon]="icons.faEdit" size="1x" class="center-icon"></fa-icon>
      </div>
    </div>
    
  `,
  styleUrls: []
})
export class ItemVehicleComponent {
  @Input() vehicle: any;
  @Input() technicians: any;
  @Input() profile: any;
  @Input() permissions: any;
  @Output() vehicleData = new EventEmitter<any>();

  apiUrl: string = environment.AWS_REST_WSPREFIX;

  avatar: any = null;
  technician: any = {};

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    if (this.vehicle.assignee_id) {
      this.store.getUser(this.vehicle.assignee_id).subscribe((data: any) => {
        console.log("technician => ", data);
        this.technician = data;
        this.loadImg(data.avatar);
      });
    }    
  }

  loadImg(img: any) {
    // console.log('IMG', img);
    if (img && img.blob) {
      this.avatar = `${this.apiUrl}/storage/${img.blob.key.substring(0,2)}/${img.blob.key.substring(2,4)}/${img.blob.key}`;
    } else {
      this.avatar = '';
    }
  }

  assigned(id: any) {
    if (id) {
      return { name: 'Asignado', color: '#2D2756', text: '#ADA3F0' };
    } else {
      return { name: 'Sin asignar', color: '#6C757D', text: '#ADA3F0' };
    }
  }

  asignVehicle() {
    this.vehicleData.emit({ vehicle: this.vehicle, technician: this.technician });
  }

}
