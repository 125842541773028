import { Component } from '@angular/core';

@Component({
  selector: 'app-amenity-tasks',
  template: ``,
  styleUrls: []
})
export class AmenityTasksComponent {

}
