import { Component } from '@angular/core';

@Component({
  selector: 'app-invoice-create',
  template: ``,
  styleUrls: []
})
export class InvoiceCreateComponent {

}
