import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-vehicles',
  template: `
    <ng-template #modalVehicle let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Vehículos</h5>
        <button type="button" (click)="dismiss()" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
          <fa-icon [icon]="icons.faTimes"></fa-icon>
        </button>
      </div>
      <div class="modal-body p-4">
        <div class="row">
          <div class="col-md-12 mt-3 mb-3" *ngIf="vehicles.length>0">
            <label class="form-control-label" for="vehicle">Vehículo</label>
            <select class="form-select" (change)="selectVehicle($event)">
              <option selected disabled>Selecciona un vehículo</option>
              <option *ngFor="let vehicle of vehicles" [value]="vehicle.id">
                {{vehicle.name}} (placas: {{vehicle.plates}})
              </option>
            </select>
          </div>
          <div class="col-md-12 text-end" *ngIf="vehicles.length==0">              
            <p class="mb-0 text-center text-muted" style="font-size: 13px;">No hay vehículos disponibles</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="saveVehicle()">Guardar</button>
      </div>
    </ng-template>
  `,
  styleUrls: []
})
export class ModalVehiclesComponent implements OnInit {
  @Input() technician: any;
  @Input() profile: any;
  @Output() vehicleData = new EventEmitter<any>();
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalVehicle') modalVehicle !: ElementRef;

  private modalRef: NgbModalRef | undefined;

  vehicles: any = [];
  selectedVehicle: number = 0;

  constructor(
    private tasks: TasksService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.loadVehicles();
  }

  loadVehicles() {
    this.tasks.getVehicles({ profile_id: this.profile.id }).subscribe({
      next: (resp: any) => {
        console.log('VEHICLES ===> ', resp);
        this.vehicles = resp.data;
      },
      error: (err: any) => {
        this.toastr.error(err.error.message, 'Error');
      }
    });
  }

  selectVehicle(vehicle: any) {
    console.log(vehicle.target.value);
    this.selectedVehicle = vehicle.target.value;
  }

  saveVehicle() {
    if (this.selectedVehicle == 0) {
      this.toastr.error('Selecciona un vehículo', 'Error');
      return;
    }

    let payload: any = {
      vehicle_id: this.selectedVehicle,
      assignee_id: this.technician.id
    }

    this.dismiss();
    this.vehicleData.emit(payload);
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
