import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faTrashAlt, faTriangleExclamation, faCircleExclamation, faEllipsisVertical, faEye } from '@fortawesome/free-solid-svg-icons';

import * as bs from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-item-bill-pending',
  template: `
    <div class="row">      
      <div class="col-md-2 mt-2">
        <fa-icon [icon]="icons.faChevronRight" (click)="showDetails=!showDetails" *ngIf="!showDetails" class="float-start mt-2" style="font-size: 11px;"></fa-icon>
        <fa-icon [icon]="icons.faChevronDown" (click)="showDetails=!showDetails" *ngIf="showDetails" class="float-start mt-2"></fa-icon>
        <h3 class="mb-1 pt-1 float-start">
          <span class="mb-0 ms-2"># {{bill.id}} </span><br>
          <i class="text-sm text-muted">{{bill.reference}}</i>
        </h3>
      </div>
      <div class="col-3 mt-0">
        <p class="mb-0" style="font-size: 17px;">{{bill?.unit_name}}</p>
        <p class="mb-0 text-sm" style="font-size: 15px;">{{bill?.settlement_name}}, {{bill?.building_name}}</p>
      </div>
      <div class="col-2 text-center mt-2">
        <p class="mb-0">
          <span class="text-danger" *ngIf="bill.consumo_lt < 0"><fa-icon [icon]="faCircleExclamation"></fa-icon></span>
          {{bill.consumo_lt | number: '1.2-2'}} Lts.
        </p>
      </div>
      <div class="col-2 text-center mt-2">
        <p class="mb-0">{{bill.total | currency: 'USD'}}</p>
      </div>
      
      
      <!-- div class="col-md-1 text-end mt-2">
        <div class="badge rounded-pill bg-danger py-2 px-2" *ngIf="bill.date_status==='expirado'" placement="bottom" ngbTooltip="Este recibo expiró, ya no se puede pagar.">
          <fa-icon [icon]="faCircleExclamation" style="font-size: 12px;" ></fa-icon>
        </div>
        <div class="badge rounded-pill bg-warning py-2 px-2" *ngIf="bill.date_status==='vencido'" placement="bottom" ngbTooltip="Este recibo no ha sido pagado aún, puede generar intereses por falta de pago.">
          <fa-icon [icon]="faTriangleExclamation" style="font-size: 12px;"></fa-icon>
        </div>
      </!-->
      <div class="col-md-2 text-center mt-2">
        <!-- p class="mb-0 text-muted" style="text-size: 10px;">creado:</!-->
        <p class="mb-1 ms-3">{{bill.created_at | date: 'dd/MM/yy'}}</p>
        <!-- p class="mb-0 text-sm">vence:</!-->
        <!-- p>{{bill.due_date | date: 'dd/MM/yy'}}</!-->
      </div>
      <div class="col-md-1 mt-2 text-end">        
        <div ngbDropdown class="d-inline-block" *ngIf="permissions.modules.admin.billing.nopagos.write">
          <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1" id="dropdownBasic1" ngbDropdownToggle >
            <fa-icon [icon]="faEllipsisVertical" class="m-1"></fa-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem routerLink="/pagos/recibos/{{bill.id}}">
              <fa-icon [icon]="faEye"></fa-icon>
              Consultar recibo</button>
            <!-- button ngbDropdownItem>Emitir recibo</!-->
            <button ngbDropdownItem class="text-danger" (click)="cancelBill(bill)">
              <fa-icon [icon]="faTrashAlt" class="m-1"></fa-icon>
              Cancelar recibo</button>
            <!-- button ngbDropdownItem (click)="resendBill()">Reenviar correo</!-->
          </div>
        </div>        
      </div>
    </div>
    <div class="row" *ngIf="showDetails">
      <div class="col-md-3">
        <img [src]="bill.unit_image" class="img-fluid rounded-3" alt="unit image">
      </div>
    <div class="col-md-3 text-end">
        <table class="table table-sm rounded-2 border-0 mt-2" style="font-size: 12px;">
          <tbody>
            <tr class="border-bottom-secondary">
              <th class="text-start">Consumo</th>
              <td scope="col" class="text-end" *ngIf="bill.consumo_m3">{{bill.consumo_m3 | number: '1.2-2'}} M<sup>3</sup></td>
              <td scope="col" class="text-end" *ngIf="!bill.consumo_m3">-- M<sup>3</sup></td>
              <td scope="col" class="text-end" *ngIf="bill.consumo_lt">{{bill.consumo_lt | number: '1.2-2'}} Lts.</td>
              <td scope="col" class="text-end" *ngIf="!bill.consumo_lt">-- Lts.</td>
            </tr>
            <tr class="border-bottom-secondary">
              <th class="text-start">Lectura</th>
              <td class="text-end" *ngIf="bill.read_prev">Ant. {{bill.read_prev}}</td>
              <td class="text-end" *ngIf="!bill.read_prev">Ant. -</td>
              <td class="text-end" *ngIf="bill.read_curr">Act. {{bill.read_curr}}</td>
              <td class="text-end" *ngIf="!bill.read_curr">Act.-</td>
            </tr>
            <tr class="border-bottom-secondary">
              <th class="text-start">Periodo</th>
              <td class="text-end" *ngIf="bill.periodo_inicio">{{bill.periodo_inicio | date: 'dd/MM/yy'}}</td>
              <td class="text-end" *ngIf="bill.periodo_fin">{{bill.periodo_fin | date: 'dd/MM/yy' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-2">
        <table class="table table-sm rounded-2 border-0 mt-2" style="font-size: 12px;">
          <tbody>
            <tr class="border-bottom-secondary">
              <th class="text-start">Servicios</th>
              <td scope="col" class="text-end">{{services}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <th class="text-start">Subtotal</th>
              <td class="text-end">{{bill.sub_total | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <th class="text-start">Total a pagar</th>
              <td class="text-end" >{{bill.total | currency: 'USD'}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemBillPendingComponent {
  @Input() bill: any;
  @Input() profile: any;
  @Input() permissions: any;
  @ViewChild("btnOptionsPay", { read: ElementRef, static: false }) btnOptionsPay !: ElementRef;

  lsUser: any = localStorage.getItem('user');
  user: any = this.lsUser != null ? JSON.parse(this.lsUser) : null;

  settlement: any = null;
  services: number = 0;
  showDetails: boolean = false;

  faEye = faEye;
  faTrashAlt = faTrashAlt;
  faTriangleExclamation = faTriangleExclamation;
  faCircleExclamation = faCircleExclamation;
  faEllipsisVertical = faEllipsisVertical;

  can_pay: boolean = false;

  constructor(
    private route: Router,
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) { }

  checkPermissions() {
    switch (this.user.role_id) {
      case 1:
        this.can_pay = false;
        break;
      case 2:
        this.can_pay = false;
        break;
      case 4:
        this.can_pay = true;
        break;
      default:
        this.can_pay = false;
        break;
    }
  }

  ngOnInit(): void {

    this.checkPermissions();

    // this.store.getUnitSettlement(this.bill.unit_id).subscribe((settlement: any) => {
      // this.settlement = settlement;
    // });

    // console.log(" this.bill.additional_services ", this.bill.additional_services);
    // this.bill.additional_services = JSON.parse(this.bill.additional_services);

    const due_date = new Date(this.bill.due_date);
    const today = new Date();
    let expiredDate = new Date(due_date.getFullYear(), due_date.getMonth()+1, 0)

    expiredDate.setDate(expiredDate.getDate() - 3);

    if (due_date >= today) {
      // this.bill.date_status = 'vencido';
    } else if (due_date < today) {
      if (today >= expiredDate) {
        this.bill.date_status = 'expirado';
      } else {
        this.bill.date_status = 'vencido';
      }
    } else if (today >= expiredDate) {
      this.bill.date_status = 'expirado';
    }
  }

  // toggleData() {
    // this.
  // }

  gotoPayment(bill: any) {
    this.route.navigate(['/tenant/bill', bill.id]);
  }

  cancelBill(bill: any) {
    console.log('destroy bill', bill);
    this.store.updateBill(bill.id, { state: 4 }).subscribe((response: any) => {
      console.log('response', response);
      this.toastr.success('Recibo cancelado con éxito');
    });    
  }

  
}
