import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faEdit, faBuilding, faPhoneAlt, faMailBulk, faLink, faPlus, faTrashAlt, faChevronUp, faChevronDown, faEllipsisVertical, faEye } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-item-settlement',
  template: `
    <div class="row">
      <div class="col-2 col-md-2 col-lg-1 text-center py-4 ps-4">
        <img src="assets/images/building-avatar.png" width="30%">
      </div>
      <div class="col-6 col-md-8 col-lg-10 py-3">
        <h4 class="mb-0" style="font-size: 19px;">{{settlement.name}}</h4>
        <p class="mb-0 text-muted" style="font-size: 14px;">{{settlement.address}}, {{settlement.city}}, {{settlement.state}}, {{settlement.zip}}</p>
        <!-- p class="mb-0 text-sm text-muted" style="font-size: 11px;">Actualizado: {{settlement.updated_at | date: 'dd/MM/yyyy HH:mm'}}</!-->
      </div>
      <!-- div class="col-4 col-md-5 col-lg-3 clearfix text-center">        
        <button class="btn btn-link text-secondary rounded-5 me-2" style="font-size: 13px;" (click)="toggleData()">
          <span *ngIf="!showData">
            Mostrar detalles
            <fa-icon [icon]="icons.faChevronDown" class="m-1"></fa-icon>
          </span>
          <span *ngIf="showData">
            Ocultar detalles
            <fa-icon [icon]="icons.faChevronUp" class="m-1"></fa-icon>
          </span>
        </button>
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-outline-secondary rounded-5 btn-sm dropdown-toggle py-1 px-2" id="dropdownBasic1" ngbDropdownToggle >
            <fa-icon [icon]="icons.faEllipsisVertical" class="m-1"></fa-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem routerLink="edit/{{settlement.id}}" *ngIf="permissions.modules.admin.settlements.write">
              <fa-icon [icon]="icons.faEdit" size="1x" class="me-2"></fa-icon>
              Editar
            </button>
            <button ngbDropdownItem routerLink="{{settlement.id}}" *ngIf="permissions.modules.admin.settlements.write">
              <fa-icon [icon]="icons.faGears"></fa-icon>
              Configuración
            </button>
            <button ngbDropdownItem routerLink="{{settlement.id}}/dashboard">
              <fa-icon [icon]="icons.faBuilding" class="m-1"></fa-icon>
              Dashboard
            </button>
            <button ngbDropdownItem routerLink="recibos/{{settlement.id}}" *ngIf="permissions.modules.admin.billing.active">
              <fa-icon [icon]="icons.faMoneyBill" class="m-1"></fa-icon>
              Pagos
            </button>
          </div>
        </div>
      </!-->
      <div class="col-1 col-md-1 col-lg-1 text-center">
        <div class="hover-info border-start p-0 " style="height: 100%; ">
          <div ngbDropdown class="d-inline-block w-100 p-0" style="height: 100%; " >
            <button type="button" class="btn btn-link w-100 btn-lg dropdown-toggle py-1 px-2" style="height: 100%; " id="dropdownBasic1" ngbDropdownToggle >
              <fa-icon [icon]="icons.faEllipsisVertical" class="m-1"></fa-icon>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="toggleData()" *ngIf="permissions.modules.admin.settlements.active">
              <fa-icon [icon]="icons.faEye" size="1x" class="p-2"></fa-icon>
                Mostrar detalles
              </button>
              <button ngbDropdownItem routerLink="edit/{{settlement.id}}" *ngIf="permissions.modules.admin.settlements.write">
                <fa-icon [icon]="icons.faEdit" size="1x" class="p-2"></fa-icon>
                Editar
              </button>
              <button ngbDropdownItem routerLink="{{settlement.id}}" *ngIf="permissions.modules.admin.settlements.write">
                <fa-icon [icon]="icons.faGears" size="1x" class="p-2"></fa-icon>
                Configuración
              </button>
              <button ngbDropdownItem routerLink="{{settlement.id}}/dashboard">
                <fa-icon [icon]="icons.faBuilding" size="1x" class="p-2"></fa-icon>
                Dashboard
              </button>
              <button ngbDropdownItem routerLink="recibos/{{settlement.id}}" *ngIf="permissions.modules.admin.billing.active">
                <fa-icon [icon]="icons.faMoneyBill" size="1x" class="p-2"></fa-icon>
                Pagos
              </button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row" *ngIf="showData">
      <div class="col-md-12 mt-2">
        <div class="list-group-item rounded-3">
          <div class="text-center" *ngIf="loading">
            <fa-icon [icon]="icons.faCircleNotch" size="1x" [spin]="true" class="text-secondary"></fa-icon>
            <p class="text-muted" style="font-size: 13px;">Cargando...</p>
          </div>
          <div class="row" *ngIf="!loading">
            <div class="col-md-6">
              <ul style="font-size: 13px;">
                <li *ngFor="let building of buildings">
                  {{building.name}}
                  <ul>
                    <li>{{building.countTanques}} Tanques</li>
                    
                    <ul>
                      <li *ngFor="let tanque of building.tanques">
                        {{tanque.name}}
                      </li>
                    </ul>
                    <li>{{building.countUnits}} Departamentos
                      <ul>
                        <li *ngFor="let unit of building.units">
                          Depto. {{unit.name}}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <p class="mb-0">
                <fa-icon [icon]="icons.faPhoneAlt" style="font-size: 12px;"></fa-icon>
                <span class="ms-2" style="font-size: 12px;">{{settlement.phone ? settlement.phone : '--'}}</span>
              </p>
              <p class="mb-0">
                <fa-icon [icon]="icons.faMailBulk" style="font-size: 12px;"></fa-icon>
                <span class="ms-2" style="font-size: 12px;">{{settlement.email ? settlement.email : '--'}}</span>
              </p>
              <p class="mb-0">
                <fa-icon [icon]="icons.faLink" style="font-size: 12px;"></fa-icon>
                <span class="ms-2" style="font-size: 12px;">{{settlement.website ? settlement.website : '--'}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemSettlementComponent implements OnInit {
  @Input() settlement: any;
  @Input() profile: any;
  @Input() permissions: any;

  loading: boolean = false;

  buildings: any = [];
  countUnits: number = 0;
  countTanques: number = 0;

  showData: boolean = false;

  constructor(
    private store: SettlementsService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    // console.log("settlement ===> ", this.settlement);
  }

  toggleData() {
    this.loading = true;
    this.showData = !this.showData;
    if (this.buildings.length==0) {
      this.loadData();
    }
  }

  loadData() {
    // console.log('trigger loadData');
    this.store.getSettlement(this.settlement.id).subscribe((data: any) => {
      // console.log("settlement ===> ", data);
    });

    this.store.getBuildingsBySettlement(this.settlement.id).subscribe((data: any) => {
      let buildings: any = data.sort((a: any, b: any) => a.id - b.id);
      buildings.map((building: any) => {
        let build: any = building;
        console.log("building => ", building);
        let countUnits: number = 0;
        let countTanques: number = 0;
        building.floors.map((floor: any) => {
          countUnits += floor.units.length
          build.units = floor.units;
        });
        build.tanques = building.tanques;
        build.countUnits = countUnits;
        build.countTanques = building.tanques.length;

        this.buildings.push(build);          
      });
      this.loading = false;
    });


  }

}
