import { CategoryIndexComponent } from './admin/pages/maintenances/categories/category-index/category-index.component';
import { Payment } from './models/payment.model';
import { AuthGuard } from './shared/guard/auth.guard';
import { TechnicianCreateComponent } from './admin/pages/technicians/technician-create/technician-create.component';
import { ServiceComponent } from './admin/pages/services/service/service.component';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettlementComponent } from './admin/pages/settlement/settlement.component';
import { DashboardComponent } from './admin/pages/dashboard/dashboard.component';
import { ServicesComponent } from './admin/pages/services/services.component';
import { AmenitiesComponent } from './admin/pages/amenities/amenities.component';
import { PaymentsComponent } from './admin/pages/payments/payments.component';
import { ProvidersComponent } from './admin/pages/providers/providers.component';
import { TenantsComponent } from './admin/pages/tenants/tenants.component';
import { TenantComponent } from './admin/pages/tenants/tenant/tenant.component';
import { ReceiptsComponent } from './admin/pages/payments/receipts/receipts.component';
import { UtilitesComponent } from './admin/pages/utilites/utilites.component';
import { UsersComponent } from './admin/pages/users/users.component';
import { ProfileComponent } from './admin/pages/users/profile/profile.component';
import { RolesComponent } from './admin/pages/roles/roles.component';
import { MaintenancesComponent } from './admin/pages/maintenances/maintenances.component';
import { BillComponent } from './admin/pages/bills/bill/bill.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { TenantSignInComponent } from './components/tenant-sign-in/tenant-sign-in.component';
import { TenantSignUpComponent } from './components/tenant-sign-up/tenant-sign-up.component';
import { PaymentComponent } from './admin/pages/payments/payment/payment.component';
import { TenantProfileComponent } from './tenant/pages/tenant-profile/tenant-profile.component';
import { TenantPaymentMethodsComponent } from './tenant/pages/tenant-payment-methods/tenant-payment-methods.component';
import { TenantPaymentMethodComponent } from './tenant/pages/tenant-payment-methods/tenant-payment-method/tenant-payment-method.component';
import { TenantBillsComponent } from './tenant/pages/tenant-bills/tenant-bills.component';
import { TenantBillComponent } from './tenant/pages/tenant-bills/tenant-bill/tenant-bill.component';
import { TenantPaymentsComponent } from './tenant/pages/tenant-payments/tenant-payments.component';
import { TenantDashboardComponent } from './tenant/pages/tenant-dashboard/tenant-dashboard.component';
import { TenantEditPaymentMethodComponent } from './tenant/pages/tenant-payment-methods/tenant-edit-payment-method/tenant-edit-payment-method.component';
import { TransactionSuccessComponent } from './tenant/pages/tenant-transactions/transaction-success/transaction-success.component';
import { ShowBillComponent } from './admin/pages/bills/show-bill/show-bill.component';
import { BillTenantComponent } from './admin/pages/tenants/bill-tenant/bill-tenant.component';
import { IndexSettlementComponent } from './admin/pages/settlement/index-settlement/index-settlement.component';
import { TanquesComponent } from './admin/pages/tanques/tanques.component';
import { TanqueCreateComponent } from './admin/pages/tanques/tanque-create/tanque-create.component';
import { CreateSettlementComponent } from './admin/pages/settlement/create-settlement/create-settlement.component';
import { TanqueEditComponent } from './admin/pages/tanques/tanque-edit/tanque-edit.component';
import { ProviderCreateComponent } from './admin/pages/providers/provider-create/provider-create.component';
import { ProviderEditComponent } from './admin/pages/providers/provider-edit/provider-edit.component';
import { AmenityCreateComponent } from './admin/pages/amenities/amenity-create/amenity-create.component';
import { AmenityComponent } from './admin/pages/amenities/amenity/amenity.component';
import { ServiceCreateComponent } from './admin/pages/services/service-create/service-create.component';
import { ConfirmPasswordChangeComponent } from './components/confirm-password-change/confirm-password-change.component';
import { RoleCreateComponent } from './admin/pages/roles/role-create/role-create.component';
import { RoleEditComponent } from './admin/pages/roles/role-edit/role-edit.component';
import { UnpaidComponent } from './admin/pages/payments/unpaid/unpaid.component';
import { ComercialSettlementsComponent } from './admin/pages/settlement/comercial-settlements/comercial-settlements.component';
import { BillsSettlementComponent } from './admin/pages/settlement/bills-settlement/bills-settlement.component';
import { TanqueGaugeComponent } from './admin/pages/tanques/tanque-gauge/tanque-gauge.component';
import { TechniciansComponent } from './admin/pages/technicians/technicians/technicians.component';
import { TechnicianEditComponent } from './admin/pages/technicians/technician-edit/technician-edit.component';
import { RefuelsComponent } from './admin/pages/refuels/refuels.component';
import { OrdersComponent } from './admin/pages/orders/orders.component';
import { RefuelEditComponent } from './admin/pages/refuels/refuel-edit/refuel-edit.component';
import { RefuelCreateComponent } from './admin/pages/refuels/refuel-create/refuel-create.component';
import { OrderEditComponent } from './admin/pages/orders/order-edit/order-edit.component';
import { OrderCreateComponent } from './admin/pages/orders/order-create/order-create.component';
import { InvoicesComponent } from './admin/pages/invoices/invoices.component';
import { InvoiceCreateComponent } from './admin/pages/invoices/invoice-create/invoice-create.component';
import { TenantInvoiceComponent } from './tenant/pages/tenant-invoice/tenant-invoice.component';
import { TenantInvoiceListComponent } from './tenant/pages/tenant-invoice-list/tenant-invoice-list.component';
import { PaymentConceptsComponent } from './admin/pages/payment-concepts/payment-concepts.component';
import { ConceptCreateComponent } from './admin/pages/payment-concepts/concept-create/concept-create.component';
import { ConceptEditComponent } from './admin/pages/payment-concepts/concept-edit/concept-edit.component';
import { TaskIndexComponent } from './admin/pages/maintenances/tasks/task-index/task-index.component';
import { TaskEditComponent } from './admin/pages/maintenances/tasks/task-edit/task-edit.component';
import { TaskCreateComponent } from './admin/pages/maintenances/tasks/task-create/task-create.component';
import { CategoryCreateComponent } from './admin/pages/maintenances/categories/category-create/category-create.component';
import { CategoryEditComponent } from './admin/pages/maintenances/categories/category-edit/category-edit.component';
import { BillsTenantsComponent } from './admin/pages/tenants/bills-tenants/bills-tenants.component';
import { CommercialsComponent } from './admin/pages/commercials/commercials.component';
import { CommercialComponent } from './admin/pages/commercials/commercial/commercial.component';
import { BillCommercialComponent } from './admin/pages/commercials/bill-commercial/bill-commercial.component';
import { InvoiceShowComponent } from './admin/pages/invoices/invoice-show/invoice-show.component';
import { AmenityCalendarComponent } from './admin/pages/amenities/amenity-calendar/amenity-calendar.component';
import { AmenityTasksComponent } from './admin/pages/amenities/amenity-tasks/amenity-tasks.component';
import { AmenityTaskEditComponent } from './admin/pages/amenities/amenity-task-edit/amenity-task-edit.component';
import { AmenityTaskCreateComponent } from './admin/pages/amenities/amenity-task-create/amenity-task-create.component';
import { InvoiceComplementComponent } from './admin/pages/invoices/invoice-complement/invoice-complement.component';
import { InvoiceGlobalComponent } from './admin/pages/invoices/invoice-global/invoice-global.component';
import { InvoiceCreateGlobalComponent } from './admin/pages/invoices/invoice-create-global/invoice-create-global.component';
import { InvoiceDashboardComponent } from './admin/pages/invoices/invoice-dashboard/invoice-dashboard.component';
import { ProfileIndexComponent } from './admin/pages/profiles/profile-index/profile-index.component';
import { ProfileCreateComponent } from './admin/pages/profiles/profile-create/profile-create.component';
import { ProfileEditComponent } from './admin/pages/profiles/profile-edit/profile-edit.component';
import { ProfileShowComponent } from './admin/pages/profiles/profile-show/profile-show.component';
import { InvoiceCreditnoteComponent } from './admin/pages/invoices/invoice-creditnote/invoice-creditnote.component';
import { InvoiceCreditnoteCreateComponent } from './admin/pages/invoices/invoice-creditnote-create/invoice-creditnote-create.component';
import { TenantUsersComponent } from './admin/pages/tenants/tenant-users/tenant-users.component';
import { IndexZoneComponent } from './admin/pages/zones/index-zone/index-zone.component';
import { CreateZoneComponent } from './admin/pages/zones/create-zone/create-zone.component';
import { EditZoneComponent } from './admin/pages/zones/edit-zone/edit-zone.component';
import { IndexReportsComponent } from './admin/pages/reports/index-reports/index-reports.component';
import { IndexBalancesComponent } from './admin/pages/payments/balances/index-balances/index-balances.component';
import { GenerateComponent } from './admin/pages/payments/generate/generate.component';
import { VehiclesIndexComponent } from './admin/pages/tasks/vehicles/vehicles-index/vehicles-index.component';
import { VehiclesCreateComponent } from './admin/pages/tasks/vehicles/vehicles-create/vehicles-create.component';
import { VehiclesEditComponent } from './admin/pages/tasks/vehicles/vehicles-edit/vehicles-edit.component';
import { ToolIndexComponent } from './admin/pages/tasks/tools/tool-index/tool-index.component';
import { ToolCreateComponent } from './admin/pages/tasks/tools/tool-create/tool-create.component';
import { ToolEditComponent } from './admin/pages/tasks/tools/tool-edit/tool-edit.component';
import { SkillIndexComponent } from './admin/pages/tasks/skills/skill-index/skill-index.component';
import { SkillCreateComponent } from './admin/pages/tasks/skills/skill-create/skill-create.component';
import { SkillEditComponent } from './admin/pages/tasks/skills/skill-edit/skill-edit.component';
import { BuilderIndexComponent } from './admin/pages/tasks/builder/builder-index/builder-index.component';
import { BuilderCreateComponent } from './admin/pages/tasks/builder/builder-create/builder-create.component';
import { BuilderEditComponent } from './admin/pages/tasks/builder/builder-edit/builder-edit.component';
import { ActivityTypeIndexComponent } from './admin/pages/tasks/activity-types/activity-type-index/activity-type-index.component';
import { ActivityTypeCreateComponent } from './admin/pages/tasks/activity-types/activity-type-create/activity-type-create.component';
import { ActivityTypeEditComponent } from './admin/pages/tasks/activity-types/activity-type-edit/activity-type-edit.component';
import { ActivityIndexComponent } from './admin/pages/tasks/activities/activity-index/activity-index.component';
import { ActivityCreateComponent } from './admin/pages/tasks/activities/activity-create/activity-create.component';
import { ActivityEditComponent } from './admin/pages/tasks/activities/activity-edit/activity-edit.component';
import { DashboardTechniciansComponent } from './admin/pages/technicians/dashboard-technicians/dashboard-technicians.component';
import { HolidaysComponent } from './admin/pages/tasks/holidays/holidays.component';
import { TaskReviewComponent } from './admin/pages/maintenances/tasks/task-review/task-review.component';
import { DashboardSettlementComponent } from './admin/pages/settlement/dashboard-settlement/dashboard-settlement.component';
import { InvoiceCanceledComponent } from './admin/pages/invoices/invoice-canceled/invoice-canceled.component';
import { InvoiceShowGlobalComponent } from './admin/pages/invoices/invoice-show-global/invoice-show-global.component';
import { UserCreateComponent } from './admin/pages/users/user-create/user-create.component';
import { UnassignedComponent } from './admin/pages/maintenances/tasks/unassigned/unassigned.component';
import { TaskRefuelComponent } from './admin/pages/maintenances/tasks/task-refuel/task-refuel.component';
import { DashboardCommercialComponent } from './admin/pages/commercial/dashboard-commercial/dashboard-commercial.component';
import { TasksDashboardComponent } from './admin/pages/maintenances/tasks/tasks-dashboard/tasks-dashboard.component';
import { InvoiceUploaderComponent } from './admin/pages/invoices/invoice-uploader/invoice-uploader.component';
import { InvoiceGenericComponent } from './admin/pages/invoices/invoice-generic/invoice-generic.component';
import { TransactionFailComponent } from './tenant/pages/tenant-transactions/transaction-fail/transaction-fail.component';
import { CreateCustomBillComponent } from './admin/pages/bills/create-custom-bill/create-custom-bill.component';
import { ProfileDashboardComponent } from './admin/pages/profiles/profile-dashboard/profile-dashboard.component';
import { TransactionsPaymentsComponent } from './admin/pages/payments/transactions-payments/transactions-payments.component';
import { RefuelShowComponent } from './admin/pages/refuels/refuel-show/refuel-show.component';
import { SignInInvoiceComponent } from './components/sign-in-invoice/sign-in-invoice.component';
import { InvoiceAcceptanceComponent } from './admin/pages/invoices/invoice-acceptance/invoice-acceptance.component';
import { ContractIndexComponent } from './admin/pages/contracts/contract-index/contract-index.component';
import { ContractEditComponent } from './admin/pages/contracts/contract-edit/contract-edit.component';
import { ContractTypesIndexComponent } from './admin/pages/contracts/contract-types-index/contract-types-index.component';
import { ContractTypesEditComponent } from './admin/pages/contracts/contract-types-edit/contract-types-edit.component';


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'sign-in', component: TenantSignInComponent },
  { path: 'ingresar', component: SignInComponent },
  { path: 'register-user', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address/:token', component: VerifyEmailComponent },
  { path: 'confirm-password-change/:token', component: ConfirmPasswordChangeComponent },
  { path: 'user/sign-in', component: TenantSignInComponent },
  { path: 'user/sign-up', component: TenantSignUpComponent },
  { path: 'sign-in/:id', component: SignInInvoiceComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'condominios',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Condominios', alias: 'Condominios'} },
    children: [
      { path: '', component: IndexSettlementComponent, canActivate: [AuthGuard], data: { breadcrumb: {alias: 'Index'} } },
      { path: 'create/:type', component: CreateSettlementComponent, canActivate: [AuthGuard], data: { breadcrumb: {alias: 'ChildOne'} } },
      { path: 'edit/:id', component: SettlementComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editando Condominio', alias: 'condominioName'} } },
      { path: 'settlement/bills/:id', component: BillsSettlementComponent, canActivate: [AuthGuard] },      
      { path: ':id',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'settlementName'}},
        children: [
          { path: '', component: TenantsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'buildingName'} }},          
          { path: 'unidad/:id', component: TenantComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'unitName'} } },
          { path: 'dashboard', component: DashboardSettlementComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'dashboard'} } },
        ]         
      },
      {
        path: 'recibos/:id',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'billSettlementName' } },
        children: [
          { path: '', component: BillsTenantsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'unitName'} } },
        ]
      },
    ]
  },
  { path: 'comercials',
    canActivate: [AuthGuard],
    data: { breadcrumb: { alias: 'comercialsIndex' } },
    children: [
      { path: '', component: ComercialSettlementsComponent, canActivate: [AuthGuard], data: { breadcrumb: {alias: 'Index'} } },
      { path: 'create', component: CreateSettlementComponent, canActivate: [AuthGuard], data: { breadcrumb: {alias: 'ChildOne'} } },
      { path: 'edit/:id', component: SettlementComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editando Condominio', alias: 'condominioName'} } },
      { path: ':id', canActivate: [AuthGuard], data: { breadcrumb: { alias: 'comercialName'} },
        children: [
          { path: '', component: CommercialsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'buildingName'} }},
          { path: 'unidad/:id', component: CommercialComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'unitName'} } },        
          { path: 'dashboard', component: DashboardCommercialComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'dashboard'} } },
        ]
      },
      {
        path: 'recibos/:id',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'billSettlementName'}},
        children: [
          { path: '', component: BillCommercialComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'unitName'} } },
        ]
      },
    ]
  },  
  { path: 'services',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Servicios', alias: 'Servicios'} },
    children: [
      { path: '', component: ServicesComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} }},
      { path: 'create', component: ServiceCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear servicio', alias: 'Crear'} }},
      { path: ':id', component: ServiceComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'editarServicio'} } },
    ]
  },
  { path: 'amenidades',
    canActivate: [AuthGuard],
    data: { breadcrumb: { alias: 'amenidad'} },
    children: [
      { path: '', component: AmenitiesComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Catálogo de amenidades', alias: 'Index'} } },
      { path: 'create', component: AmenityCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear Amenidad', alias: 'createAmenity'} } },
      { path: ':id', component: AmenityComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'editAmenidad'} } },
      { path: 'calendario', component: AmenityCalendarComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'calendario'} } },
      { path: 'solicitudes',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: AmenityTasksComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'solicitudes'} } },
          { path: 'create', component: AmenityTaskCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'solicitudCreate'} } },
          { path: ':id', component: AmenityTaskEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'solicitudEdit'} } },
        ]
      }
    ]
  },
  {
    path: 'combustibles',
    canActivate: [AuthGuard],
    data: { breadcrumb: { alias: 'combustibles'} },
    children: [
      { path: 'tanques',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'tanques'} },
        children: [
          { path: '', component: TanquesComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Tanques'} } },
          { path: 'create', component: TanqueCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear tanque', alias: 'Crear'} }  },
          { path: ':id', component: TanqueEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'editarTanque'} } },
          { path: 'gauge/:id', component: TanqueGaugeComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'consumoTanque'} } },      
        ]
      },
      { path: 'refuels',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Recargas', alias: 'refuels'} },
        children: [
          { path: '', component: RefuelsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} }},
          { path: 'create', component: RefuelCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear recarga', alias: 'Crear'} }},
          { path: ':id', component: RefuelEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'editarRecarga'} }},
          { path: 'show/:id', component: RefuelShowComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'showRecarga'} }},
        ]
      },
      { path: 'orders',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Pedidos', alias: 'ordenes'} },
        children: [
          { path: '', component: OrdersComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} }},
          { path: 'create', component: OrderCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear pedido', alias: 'Crear'} }},
          { path: ':id', component: OrderEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editar pedido', alias: 'editarPedido'} }},
        ]
      },
      {
        path: 'zonas',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Zonas', alias: 'zonas'} },
        children: [
          { path: '', component: IndexZoneComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
          { path: 'create', component: CreateZoneComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'ChildOne'} } },
          { path: ':id', component: EditZoneComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editando zona', alias: 'condominioName'} } }
        ],
      },
    ]
  },  
  { path: 'providers',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Proveedores', alias: 'proveedores'} },
    children: [
      { path: '', component: ProvidersComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
      { path: 'create', component: ProviderCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear proveedor', alias: 'Crear'} } },
      { path: ':id', component: ProviderEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editar', alias: 'editarProveedor'} } },
    ]
  },  
  { path: 'maintenances',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: MaintenancesComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Servicio técnico', alias: 'index'} }},
      { path: 'tasks',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'tasks'} },
        children: [
          { path: '', component: TaskIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Servicio técnico', alias: 'tasks'} } },
          { path: 'dashboard', component: TasksDashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Tablero', alias: 'dashboard'} } },
          { path: 'unassigned', component: UnassignedComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Tareas no asignadas', alias: 'unassigned' }}},
          { path: 'suministro', component: TaskRefuelComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Tareas de suministro', alias: 'tasks refuel' }}},
          { path: 'create', component: TaskCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'taskCreate'} } },
          { path: ':id', component: TaskEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'taskEdit'} } },
          { path: 'review/:id', component: TaskReviewComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'taskReview'} } },
        ]
      },
      {
        path: 'builders',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'builders'} },
        children: [
          { path: '', component: BuilderIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Constructor', alias: 'builders'} } },
          { path: 'create', component: BuilderCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'builderCreate'} } },
          { path: ':id', component: BuilderEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'builderEdit'} } },
        ],
      },
      { path: 'categories',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: CategoryIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'categories'} } },
          { path: 'create', component: CategoryCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'categoryCreate'} } },
          { path: ':id', component: CategoryEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'categoryEdit'} } },
        ]
      },
      {
        path: 'activities',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'activities'} },
        children: [
          { path: '', component: ActivityIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Actividades', alias: 'activities'} } },
          { path: 'create', component: ActivityCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'activityCreate'} } },
          { path: ':id', component: ActivityEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'activityEdit'} } },
        ],
      },
      {
        path: 'activity-types',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'activityTypes'} },
        children: [
          { path: '', component: ActivityTypeIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Tipo de actividad', alias: 'activityTypes'} } },
          { path: 'create', component: ActivityTypeCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'activityTypeCreate'} } },
          { path: ':id', component: ActivityTypeEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'activityTypeEdit'} } },
        ]
      },
      {
        path: 'holidays',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'holidays'} },
        children: [
          { path: '', component: HolidaysComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Festivos', alias: 'holidays'} } },
        ]
      }
    ]
  },
  { path: 'technicians',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: TechniciansComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Técnicos', alias: 'technicians'} }},
      { path: 'create', component: TechnicianCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Creando técnico', alias: 'technicianCreate'} }},
      { path: ':id', component: TechnicianEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editando técnico', alias: 'technicianEdit'} }},
      { path: 'dashboard/:id', component: DashboardTechniciansComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Tablero', alias: 'dashboard'} }},
    ]
  },
  { path: 'roles',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Roles'}},
    children: [
      { path: '', component: RolesComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Rol', alias: 'roles'} }},
      { path: 'create', component: RoleCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Creando rol', alias: 'roleCreate'} }},
      { path: ':id', component: RoleEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editando rol', alias: 'roleEdit'} }},
    ]
  },
  {
    path: 'contract-types',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Tipo de contratos', alias: 'contract types'} },
    children: [
      { path: '', component: ContractTypesIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Tipo de contratos', alias: 'contract types'} }},
      { path: ':id', component: ContractTypesEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Editando contrato', alias: 'contractEdit'} }},
    ]
  },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Usuarios', alias: 'users'} }},
  { path: 'users/create', component: UserCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear usuario', alias: 'userCreate'} }},
  { path: 'users/profile/:id', component: ProfileComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Perfil', alias: 'profile'} }},
  // { path: 'unpaid', component: UnpaidComponent, canActivate: [AuthGuard]},
  { path: 'pagos',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: PaymentsComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Pagos', alias: 'Index'} }},
      { path: 'pendientes', component: UnpaidComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Recibos', alias: 'Index'} }},      
      {
        path: 'recibos',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Recibos', alias: 'bills'} },
        children: [
          { path: '', component: ReceiptsComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Recibos', alias: 'Index'} }},
          // { path: 'new', component: BillComponent, canActivate: [AuthGuard]},
          { path: 'new/:id', component: CreateCustomBillComponent, canActivate: [AuthGuard]},
          { path: ':id', component: ShowBillComponent, canActivate: [AuthGuard]},
          { path: 'unit/:id', component: BillTenantComponent, canActivate: [AuthGuard]},
        ]
      },
      {
        path: 'transacciones',
        component: TransactionsPaymentsComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Transacciones', alias: 'transactions'} },
      },
      {
        path: 'generar',
        component: GenerateComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Generar', alias: 'generate'} },
      },
      {
        path: 'balances',
        component: IndexBalancesComponent,
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Saldos', alias: 'balances'} },
      },
      {
        path: 'conceptos',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'concepto'} },
        children: [
          { path: '', component: PaymentConceptsComponent, canActivate: [AuthGuard] },
          { path: 'create', component: ConceptCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Crear concepto', alias: 'Crear'} } },
          { path: ':id', component: ConceptEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'editarConcepto'} } },
        ]
      },
      // { path: 'receipts', component: ReceiptsComponent, canActivate: [AuthGuard] },
      // { path: 'pay/:id', component: PaymentComponent, canActivate: [AuthGuard]},
    ]
  },
  // { path: 'bills/commercial/:id', component: BillCommercialComponent, canActivate: [AuthGuard]},
  { path: 'invoices',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: InvoiceDashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Facturas', alias: 'invoices'} }},
      { path: 'ingresos', component: InvoicesComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Facturas', alias: 'invoices'} }},
      { path: 'generic', component: InvoiceGenericComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Facturas', alias: 'invoices'} }},
      { path: 'uploader', component: InvoiceUploaderComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Uploader', alias: 'uploader'} } },
      { path: 'create', component: InvoiceCreateComponent, canActivate: [AuthGuard]},
      { path: 'complementos', component: InvoiceComplementComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Complementos', alias: 'complementos'} }},
      { path: 'aceptacion', component: InvoiceAcceptanceComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Aceptación', alias: 'aceptacion'} }},
      { path: 'globales',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Globales', alias: 'globales'} },
        children: [
          { path: '', component: InvoiceGlobalComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'invoiceCreate'} } },
          { path: 'create', component: InvoiceCreateGlobalComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'invoiceCreate'} } },
          { path: ':id', component: InvoiceShowGlobalComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'invoiceShow'} } },
        ]
      },
      { path: 'canceladas', component: InvoiceCanceledComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Canceladas', alias: 'Canceladas'} }},
      { path: 'creditnote', 
        canActivate: [AuthGuard],
        children: [
          { path: '', component: InvoiceCreditnoteComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'creditNote'} } },
          { path: 'create', component: InvoiceCreditnoteCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'creditNoteCreate'} } },
        ]
      }, 
      { path: ':id', component: InvoiceShowComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'invoiceShow'} }},
      
    ]
  },
  {
    path: 'contratos',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Contratos', alias: 'contratos'} },
    children: [
      { path: '', component: ContractIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'contracts'} } },
      // { path: 'create', component: ContractCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'contractCreate'} } },
      { path: ':id', component: ContractEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'contractEdit'} } },
    ]
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    component: IndexReportsComponent,
    data: { breadcrumb: { label: 'Reportes', alias: 'reportes'} },
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Inventario', alias: 'inventory'} },
    children: [
      { path: 'vehicles',
        data: { breadcrumb: { label: 'Vehículos', alias: 'vehicles'} },
        children: [
          { path: '', component: VehiclesIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'vehicles'} } },
          { path: 'create', component: VehiclesCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'vehicleCreate'} } },
          { path: ':id', component: VehiclesEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'vehicleEdit'} } },
        ]
      },
      {
        path: 'tools',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Herramientas', alias: 'tools'} },
        children: [
          { path: '', component: ToolIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'tools'} } },
          { path: 'create', component: ToolCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'toolCreate'} } },
          { path: ':id', component: ToolEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'toolEdit'} } },
        ]
      },
      {
        path: 'skills',
        canActivate: [AuthGuard],
        data: { breadcrumb: { label: 'Habilidades', alias: 'skills'} },
        children: [
          { path: '', component: SkillIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'skills'} } },
          { path: 'create', component: SkillCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'skillCreate'} } },
          { path: ':id', component: SkillEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'skillEdit'} } },
        ]
      }
    ],
  },
  { path: 'perfiles',
    canActivate: [AuthGuard],
    children: [
      { path: '', component: ProfileIndexComponent, canActivate: [AuthGuard], data: { breadcrumb: { label: 'Perfiles', alias: 'profiles'} }},
      { path: 'create', component: ProfileCreateComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'profileCreate'} }},
      { path: 'edit/:id', component: ProfileEditComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'profileEdit'} }},
      { path: ':id', component: ProfileShowComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'profileShow'} }},
      { path: 'dashboard/:id', component: ProfileDashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'profileDashboard'} }},
    ]
  },
  { path: 'tenants',
    canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Inquilinos', alias: 'Inquilinos'}},
    children: [
      { path: '', component: TenantUsersComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'}} },
      { path: 'edit/:id', component: TenantProfileComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Editar'} } },
    ]
  },
  // Tenant routes
  { path: 'tenant',
    canActivate: [AuthGuard],
    data: { breadcrumb: { alias: 'Inquilino'} },
    children: [
      { path: 'profile/:id', component: TenantProfileComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Perfil'} }},
      { path: 'dashboard', component: TenantDashboardComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Dashboard'} }},
      { path: 'payment-methods',
        canActivate: [AuthGuard],
        data: { breadcrumb: { alias: 'Metodos de pago' }},
        children: [
          { path: ':id',
            canActivate: [AuthGuard],
            data: { breadcrumb: { alias: 'Index'}},
            children: [
              { path: '', component: TenantPaymentMethodsComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Index'} } },
              { path: 'add', component: TenantPaymentMethodComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Crear'} } },
              { path: 'edit/:id', component: TenantEditPaymentMethodComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Editar'} } },
            ]
          },
        ]
      },      
      { path: 'bills/:id', component: TenantBillsComponent, canActivate: [AuthGuard]},
      { path: 'bill/:id', component: TenantBillComponent, canActivate: [AuthGuard]},
      { path: 'payments/:id', component: TenantPaymentsComponent, canActivate: [AuthGuard]},
      { path: 'checkout/:id', component: TenantBillComponent, canActivate: [AuthGuard]},
      { path: 'payment/success/:id', component: TransactionSuccessComponent, canActivate: [AuthGuard]},
      { path: 'payment/fail/:id', component: TransactionFailComponent, canActivate: [AuthGuard]},
      { path: 'facturar/:id', component: TenantInvoiceComponent, canActivate: [AuthGuard]},
      { path: 'invoice/list/:id', component: TenantInvoiceListComponent, canActivate: [AuthGuard], data: { breadcrumb: { alias: 'Mis Facturas'} }},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
