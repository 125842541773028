import { Component, Input } from '@angular/core';
import { CurrencyToTextPipe } from 'src/app/pipes/currency-to-text.pipe';

@Component({
  selector: 'app-receipt-tiny',
  template: `
    <div class="card border-0 rounded-4 shadow mb-3">
      <div class="card-body text-dark mb-0 p-4 pb-3">
        <h3>Resumen</h3>
        <!-- table class="table table-lg mt-2 border-0" *ngIf="additionalConcepts.length>0">
          <tbody class="text-dark">
            <tr>
              <td colspan="2" scope="col" class="text-start"><b>Servicios</b></td>
            </tr>
            <tr *ngFor="let additional of additionalConcepts">
              <td class="ps-4">{{additional.name}}</td>
              <td colspan="2" scope="col" class="text-end">{{additional.total | currency: 'USD'}}</td>                
            </tr>
          </tbody>
        </!-->

        <!-- table class="table table-lg mt-2 border-0">
          <tbody class="text-dark">
            <tr>
              <td colspan="3" scope="col" class="text-start"><b>Gas</b></td>
            </tr>
            <tr>
              <td class="ps-4 text-secondary">Consumo</td>
              <td colspan="2" scope="col" class="text-end text-secondary">{{bfc['consumo_periodo'].value | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td class="ps-4 text-secondary">Descuento(s)</td>
              <td colspan="2" scope="col" class="text-end text-secondary">-{{bfc['discount'].value | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td class="ps-4">Total consumo</td>
              <td colspan="2" scope="col" class="text-end">{{bfc['consumo_total'].value | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <td scope="col" class="text-start">Adeudos</td>
              <td colspan="2" scope="col" class="text-end" *ngIf="bfc['interests']">{{bfc['interests'].value | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <td scope="col" class="text-start">Recargos ({{interests}}% Int.)</td>
              <td colspan="2" scope="col" class="text-end" *ngIf="bfc['recargos']">{{bfc['recargos'].value | currency: 'USD'}}</td>
            </tr>
            <tr class="border-bottom-secondary">
              <td scope="col" class="text-start">Admon.</td>
              <td colspan="2" scope="col" class="text-end" *ngIf="bfc['admin_price']">{{bfc['admin_price'].value | currency: 'USD'}}</td>
            </tr>
          </tbody>
        </!-->

        <table class="table table-lg mt-4 border-0">
          <thead>
            <tr>
              <td class="text-secondary">Subtotal</td>
              <td colspan="2" scope="col" class="text-end text-secondary">{{bfc['total'].value | currency: 'USD'}}</td>
            </tr>
            <tr>
              <td class="text-secondary">IVA</td>
              <td colspan="2" scope="col" class="text-end text-secondary">--</td>
            </tr>
            <tr class="border-bottom-0">
              <th class="border-bottom-0">Total</th>
              <th colspan="2" scope="col" class="text-end border-bottom-0">{{bfc['total'].value | currency: 'USD'}}</th>              
            </tr>
          </thead>
        </table>
        <!-- p class="text-center">{{bfc['total'].value | currencyToText}}</!-->
      </div>
    </div>
  `,
  styleUrls: []
})
export class ReceiptTinyComponent {
  @Input() bfc: any;
  @Input() interests: any;
  // @Input() admon: any;
  // @Input() adeudos: any;
  @Input() additionalConcepts: any;

  consumo_total: number = 0;
}
