import { Component, OnInit, OnDestroy } from '@angular/core';
import { SettlementsService } from './services/settlements.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { faBell, faCircleInfo, faCog, faSignOutAlt, faCaretDown, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import * as bs from 'bootstrap';
import { ImageService } from './shared/services/image.service';


@Component({
  selector: 'app-root',
  template: `
    <div class="d-flex main-container" role="main" (window:resize)="onResize($event)">      
      <div class="container p-0">        
        <app-sidebar *ngIf="user" [user]="user" [profile]="profile" [profileLogo]="profileLogo"></app-sidebar>
        
        <div class="row" *ngIf="user">
          <div class="col-sm-10 offset-sm-1 col-md-9 offset-md-2 col-lg-7 offset-lg-4 col-xl-10 offset-xl-3 col-xxl-8 offset-xxl-3 mt-3">
            <div class="row">
              <div class="col-12 col-md-10 col-xl-6">
                <div ngbDropdown class="d-inline-block" *ngIf="permissions?.menu?.profile">
                  <button type="button" class="btn btn-link btn-sm ps-0" id="dropdownBasic1" ngbDropdownToggle >
                    Perfil - {{profile?.name}} <fa-icon [icon]="faCaretDown" class="icon text-primary" size="1x" class="p-2 text-dark"></fa-icon>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" >
                    <button ngbDropdownItem *ngIf="permissions?.modules.config.profiles.active">Ver perfiles</button>
                    <button ngbDropdownItem *ngIf="permissions?.modules.config.profiles.write">Crear perfil</button>
                    <hr *ngIf="permissions?.modules.config.profiles.active">
                    <button ngbDropdownItem *ngFor="let profile of profiles" (click)="changeProfile(profile)">{{profile?.name}}</button>
                    <!-- button ngbDropdownItem (click)="resendBill()">Reenviar correo</!-->
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2 col-xl-5 text-end">
                <!-- fa-icon [icon]="faCircleInfo" class="icon" size="2x" class="text-dark" (click)="dispatchHelp()"></!-->
                <!-- fa-icon [icon]="faBell" class="icon" size="2x" class="p-2 text-secondary"></!-->
                <div class="rounded-5 bg-light avatar shadow-sm pt-2 text-center d-inline-block me-2" title="Notificaciones" style=" width: 34px; height: 34px;" (click)="dispatchHelp()">
                  <ngx-emoji emoji="bell" [size]="18" class=""></ngx-emoji>
                </div>
                <div class="rounded-5 bg-warning avatar shadow-sm pt-2 text-center d-inline-block me-2" title="Ayuda" style=" width: 34px; height: 34px;" (click)="dispatchHelp()">
                  <ngx-emoji emoji="bulb" [size]="18" class=""></ngx-emoji>
                </div>

                <div ngbDropdown class="d-inline-block" style="top: -4px;">
                  <button type="button" class="btn btn-link btn-sm ps-0" id="dropdownBasic2" ngbDropdownToggle >
                    <div class="rounded-5 bg-light avatar shadow-sm float-end" style="width: 34px; height: 34px;">
                      <img [src]="avatarURL" width="90%">
                    </div>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2" >
                    <button ngbDropdownItem>
                      <fa-icon [icon]="faEnvelope" class="icon" size="1x" class="p-2 text-dark"></fa-icon>
                      {{user.email}}
                    </button>
                    <button ngbDropdownItem>
                      <fa-icon [icon]="faUser" class="icon" size="1x" class="p-2 text-dark"></fa-icon>
                      {{user.name}} {{user.last_name}}
                    </button>
                    <button ngbDropdownItem routerLink="users/profile/{{user.id}}" class="clearfix">
                      <fa-icon [icon]="faCog" class="icon" size="1x" class="p-2 text-dark"></fa-icon>
                      Mi perfil
                    </button>
                    <button ngbDropdownItem>
                      <fa-icon [icon]="faSignOutAlt" class="icon" size="1x" class="p-2 text-dark" (click)="signOut()"></fa-icon>
                      Cerrar sesión
                    </button>
                    <hr>
                    <button ngbDropdownItem>
                      Versión {{version}}
                    </button>
                  </div>
                </div>

                
              </div>
            </div>            
          </div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- div *ngIf="role?.group_id != 3">
      <app-video-player [profile]="profile" [permissions]="permissions"></app-video-player>
    </!-->
    <!-- app-notify-floater></!-->
  `,
  styleUrls: []
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private apiUrl: string = environment.AWS_REST_WSPREFIX;
  protected version = environment.app_version;

  ux: any = localStorage.getItem('user')
  user: any = this.ux != null ? JSON.parse(this.ux) : null;
  profile: any = localStorage.getItem('profile');
  profiles: any = [];
  role: any = {};
  permissions: any = {};
  profileLogo: any = '';

  superadmin: boolean = false;
  avatarURL: string = '';
  avatarLS: any = null;

  faBell = faBell;
  faCog = faCog;
  faCircleInfo = faCircleInfo;
  faSignOutAlt = faSignOutAlt;
  faCaretDown = faCaretDown;
  faEnvelope = faEnvelope;
  faUser = faUser;

  constructor(
    private store: SettlementsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private imgService: ImageService
  ) {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ux = localStorage.getItem('user');
        this.user = this.ux != null ? JSON.parse(this.ux) : null;
        this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
        this.role = localStorage.getItem('role') != null ? JSON.parse(localStorage.getItem('role')!).role : null;

        // this.store.getRole(this.role.id).subscribe((data: any) =>{
          // // console.log('data => ', data);
          // this.permissions = JSON.stringify(localStorage.setItem('permissions', JSON.parse(data.permissions)));
        // });

        // console.log('permissions => ', JSON.parse(localStorage.getItem('permissions')!));
        this.permissions = JSON.parse(localStorage.getItem('permissions')!);
        // console.log('menu => ', this.permissions?.menu);

        this.superadmin = this.role.id == 1 ? true : false;

        if (this.profile != null) {
          this.profileLogo = this.profile.logo != null ? this.imgService.buildURL(this.profile.logo) : 'assets/images/logo-zazz.png';
          this.loadProfiles();

          if (this.avatarLS == null) {
            this.store.getUser(this.user.id).subscribe((data: any) => {
              let el = data.avatar;
              if (el != null) {
                this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
                localStorage.setItem('avatar', JSON.stringify({ avatar: this.avatarURL}));
              } else {
                this.avatarURL = 'assets/images/logo-zazz.png';
              }
            });
          } else {
            this.avatarLS = JSON.parse(localStorage.getItem('avatar') || '{}');
          }
        }
      }
    });
  }

  ngOnInit(): void {    
  }

  onResize(event: any) {}

  

  loadProfiles() {
    this.store.getProfiles({ page: 1, per_page: 100 }).subscribe({
      next: (data: any) => {
        this.profiles = data.data;
      },
      error: (e: any) => {}
    });
  }

  changeProfile(profile: any) {
    this.profile = profile;
    let currProfile = this.profiles.find((el: any) => profile.id == el.id);

    // console.log('currProfile => ', currProfile);

    localStorage.setItem('profile', JSON.stringify({ profile: currProfile}));
    localStorage.removeItem('payFilterPrefs');
    this.store.updateUser(this.user.id, { profile_id: profile.id }).subscribe({
      next: (data: any) => {
        localStorage.setItem('user', JSON.stringify(data));
        window.location.reload();
      }
    });    
  }

  signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.removeItem('avatar');
    localStorage.removeItem('permissions');
    localStorage.removeItem('role');
    this.router.navigate(['sign-in']);
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  dispatchHelp() {
    // let btn: any = this.btnOptionsPay.nativeElement.classList;    
    // new bs.Modal(this.btnOptionsPay.nativeElement).show();
    new bs.Modal(<HTMLInputElement>document.getElementById('modalVideoPlayer')).show();
  }
}
