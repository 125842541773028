import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faCircleNotch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBuilding, faPhoneAlt, faMailBulk, faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-comercial-settlements',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-md-11 offset-md-1 col-lg-7 offset-lg-4 col-xl-7 offset-xl-4 col-xxl-9 offset-xxl-3 mt-3">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Comercial</h1>
            <p>Consulta los establecimientos comerciales.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">            
          <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="/comercials/create">
          <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
            Agregar comercio
          </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7"></div>
          <div class="col-md-5">
            <form [formGroup]="searchBox" (ngSubmit)="searchSettlements($event)">
              <input class="form-control form-control-lg rounded-5 border-2 bg-transparent" placeholder="Buscar..." formControlName="search">
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <div class="list-group rounded-5 shadow bg-light" *ngIf="settlements.length > 0">
              <div class="list-group-item p-3" *ngFor="let settlement of settlements | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-commercial [settlement]="settlement"></app-item-commercial>
              </div>
            </div>
            <div class="list-group rounded-5 shadow" *ngIf="settlements.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-3">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="meta.total_pages > 1"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ComercialSettlementsComponent implements OnInit {
  loading: boolean = false;
  settlements: any[] = [];
  profile: any = {};
  meta: any = {};
  payload: any = {
    page: 1,
    per_page: 100,
    tipo: 1
  };

  searchBox: FormGroup = new FormGroup({});

  p: number = 1;

  faCircleNotch = faCircleNotch;
  faBuilding = faBuilding;
  faEdit = faEdit;
  faPhoneAlt = faPhoneAlt;
  faMailBulk = faMailBulk;
  faLink = faLink;
  faPlus = faPlus;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.payload.utility_id = this.profile.profile ? this.profile.profile.utility_id : 0;
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadSettlements(this.payload);
    this.initForm();
  }

  loadSettlements(payload: any) {
    this.store.getSettlements(payload).subscribe({
      next: (settlements: any) => {
        console.log('settlements => ', settlements);
        this.settlements = settlements.data;
        this.meta = settlements.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.settlements = [];
        this.loading = false;
      }
    })
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadSettlements(this.payload);
  }

  searchSettlements(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadSettlements(this.payload);
  }
}
