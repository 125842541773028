import { Component } from '@angular/core';

@Component({
  selector: 'app-amenity-task-edit',
  template: ``,
  styleUrls: []
})
export class AmenityTaskEditComponent {

}
