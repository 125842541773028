import { SettlementsService } from './../../services/settlements.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { faCircleNotch, faSave } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-refuel',
  template: `
    <form [formGroup]="formRefuel" (ngSubmit)="saveRefuel()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="tanque_id" class="form-label ms-2">Tanque</label>
            <select class="form-select form-select-lg" formControlName="tanque_id">
              <option *ngFor="let tanque of tanques" [value]="tanque.id">{{tanque.name}}</option>
            </select>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="folio" class="form-label ms-2">Folio</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="folio">
            <div *ngIf="ufc['folio'].touched && ufc['folio'].invalid">
              <div *ngIf="ufc['folio'].errors?.['required']" class="text-danger text-sm">El folio es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="pre_charge" class="form-label ms-2">Lectura pre-carga %</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="pre_charge">
            <div *ngIf="ufc['pre_charge'].touched && ufc['pre_charge'].invalid">
              <div *ngIf="ufc['pre_charge'].errors?.['required']" class="text-danger text-sm">La lectura de pre carga es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-3" *ngIf="pre_charge_image==null">
            <label for="pre_charge_image" class="form-control-label">Evidencia de pre carga</label>
            <input type="file" class="form-control form-control-lg" id="previo" placeholder="Cargar imagen" (change)="loadImage($event)">
            <div *ngIf="ufc['pre_charge_image'].touched && ufc['pre_charge_image'].invalid">
              <div *ngIf="ufc['pre_charge_image'].errors?.['required']" class="text-danger text-sm">La evidencia es requerida.</div>
            </div>
            
          </div>
          <div class="form-group mt-3" *ngIf="pre_charge_image">
          <label for="pre_charge_image" class="form-control-label">Evidencia de pre carga</label>
          <div class="row mt-2">
            <div class="col-md-3">
            <img [src]="pre_charge_image" width="140">
            </div>
            <div class="col-md-8 mt-3">
            <button type="button" class="btn btn-outline-warning ms-2">Cambiar imagen</button>
            </div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3" *ngIf="post_charge_image==null">
            <label for="post_chage" class="form-label ms-2">Lectura post-carga %</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="post_charge">
            <div *ngIf="ufc['post_charge'].touched && ufc['post_charge'].invalid">
              <div *ngIf="ufc['post_charge'].errors?.['required']" class="text-danger text-sm">La lectura post carga es requerido.</div>
            </div>
          </div>
          
          <div class="form-group mt-3" *ngIf="!post_charge_image">
            <label for="post_charge_image" class="form-control-label">Evidencia de post carga</label>
            <input type="file" class="form-control form-control-lg" id="actual" (change)="loadPostImage($event)" placeholder="Cargar imagen">
            <div *ngIf="ufc['post_charge_image'].touched && ufc['post_charge_image'].invalid">
              <div *ngIf="ufc['post_charge_image'].errors?.['required']" class="text-danger text-sm">La evidencia es requerida.</div>
            </div>
          </div>

          <div class="form-group mt-3" *ngIf="post_charge_image">
          <label for="post_charge_image" class="form-control-label">Evidencia de post carga</label>
          <div class="row mt-2">
            <div class="col-md-3">
            <img [src]="post_charge_image" width="140">
            </div>
            <div class="col-md-8 mt-3">
            <button type="button" class="btn btn-outline-warning ms-2">Cambiar imagen</button>
            </div>
            </div>
          </div>

          <div class="form-group mt-3" *ngIf="!ticket_image">
            <label for="ticket_image" class="form-control-label">Evidencia de ticket carga</label>
            <input type="file" class="form-control form-control-lg" id="actual" (change)="loadTkImage($event)" placeholder="Cargar imagen">
            <div *ngIf="ufc['ticket_image'].touched && ufc['ticket_image'].invalid">
              <div *ngIf="ufc['ticket_image'].errors?.['required']" class="text-danger text-sm">La evidencia es requerida.</div>
            </div>
          </div>

          <div class="form-group mt-3" *ngIf="ticket_image">
            <label for="ticket_image" class="form-control-label">Evidencia de ticket carga</label>
            <div class="row mt-2">
            <div class="col-md-3">
            <img [src]="ticket_image" width="140">
            </div>
            <div class="col-md-8 mt-3">
            <button type="button" class="btn btn-outline-warning ms-2">Cambiar imagen</button>
            </div>
            </div>
          </div>

          <div class="form-group required mb-4 mt-3">
            <label for="liters" class="form-label ms-2">Litros cargados</label>
            <input type="number" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="liters">
            <div *ngIf="ufc['liters'].touched && ufc['liters'].invalid">
              <div *ngIf="ufc['liters'].errors?.['required']" class="text-danger text-sm">Los litros es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-3">
            <label for="read_prev" class="form-control-label">Orden de compra</label>
            <select class="form-select form-select-lg" formControlName="purchase_order_id">
              <option *ngFor="let po of purchaseOrders" [value]="po.id">{{po.folio}}</option>
            </select>
          </div>
          <!-- div class="form-group mt-3">
            <label for="read_prev" class="form-control-label">Medidor actual</label>
            <input type="file" class="form-control" id="actual" (change)="loadImage($event)">
          </!-->
          <div class="form-group mb-2 mt-5 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/refuels">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5 text-dark px-4" [disabled]="loading">
              <fa-icon [icon]="faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
              <fa-icon [icon]="faSave" class="me-2" *ngIf="!loading"></fa-icon>
              Guardar
            </button>
          </div>

        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormRefuelComponent implements OnInit {
  @Input() refuel: any = {};
  @Output() refuelData = new EventEmitter<any>();

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  formRefuel: FormGroup = new FormGroup({});
  loading: boolean = false;

  settlements: any[] = [];
  buildings: any[] = [];
  tanques: any[] = [];
  purchaseOrders: any[] = [];

  faCircleNotch = faCircleNotch;
  faSave = faSave;

  pre_charge_image: any = '';
  post_charge_image: any = '';
  ticket_image: any = '';

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {}

  get ufc() {
    return this.formRefuel.controls;
  }

  ngOnInit(): void {
    this.initForm();
    this.loadTanques({ page: 1, per_page: 200});
    this.loadPurchaseOrders();
  }

  initForm() {
    this.formRefuel = this.fb.group({
      id: [''],
      folio: ['', Validators.required],
      pre_charge: ['', Validators.required],
      post_charge: ['', Validators.required],
      liters: ['', Validators.required],
      pre_charge_image: [''],
      post_charge_image: [''],
      ticket_image: [''],
      tanque_id: ['', Validators.required],
      purchase_order_id: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['refuel'].currentValue) {
      console.log("refuel => ", changes['refuel'].currentValue);
      this.formRefuel.patchValue(changes['refuel'].currentValue);
      this.loadImages(changes['refuel'].currentValue);
    }
  }

  loadTanques(payload: any) {
    this.loading = true;
    this.store.getTanques(payload).subscribe({
      next: (data: any) => {
        console.log(data);
        this.tanques = data.data;
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
      }
    });
  }

  loadPurchaseOrders() {
    this.loading = true;
    this.store.getPurchaseOrders({}).subscribe({
      next: (data: any) => {
        console.log(data);
        this.purchaseOrders = data.data;
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
      }
    });
  }

  loadImages(refuel: any) {
    console.log("loadImages => ", refuel);
    if (refuel) {      
      let pre = refuel.pre_charge_image;
      let post = refuel.post_charge_image;
      let tk = refuel.ticket_image;

      console.log("pre =>");
      console.log(post)

      this.pre_charge_image = pre ? `${this.apiUrl}/storage/${pre.blob.key.substring(0,2)}/${pre.blob.key.substring(2,4)}/${pre.blob.key}` : null;
      this.post_charge_image = post ? `${this.apiUrl}/storage/${post.blob.key.substring(0,2)}/${post.blob.key.substring(2,4)}/${post.blob.key}` : null;
      this.ticket_image = tk ? `${this.apiUrl}/storage/${tk.blob.key.substring(0,2)}/${tk.blob.key.substring(2,4)}/${tk.blob.key}` : null;
    }
  }

  saveRefuel() {
    console.log(this.formRefuel.value);
    if (this.formRefuel.invalid) {
      this.formRefuel.markAllAsTouched();
      this.toastr.error('Completa el formulario', 'Error');
      return;
    }

    this.refuelData.emit(this.formRefuel.value);    
  }

  loadImage(event: any) {
    // this.imageError = "";
    if (event.target.files && event.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.toastr.error('Las dimensiones de la imagen no permitidas.', 'Error');
          } else {
            const imgBase64Path = e.target.result;
            // this.meterImages.push(imgBase64Path);
            this.formRefuel.patchValue({
              pre_charge_image: imgBase64Path
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  loadPostImage(event: any) {
    // this.imageError = "";
    if (event.target.files && event.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.toastr.error('Las dimensiones de la imagen no permitidas.', 'Error');
          } else {
            const imgBase64Path = e.target.result;
            // this.meterImages.push(imgBase64Path);
            this.formRefuel.patchValue({
              post_charge_image: imgBase64Path
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  loadTkImage(event: any) {
    // this.imageError = "";
    if (event.target.files && event.target.files[0]) {
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = (rs: any): void => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          if (img_height > max_height && img_width > max_width) {
            this.toastr.error('Las dimensiones de la imagen no permitidas.', 'Error');
          } else {
            const imgBase64Path = e.target.result;
            // this.meterImages.push(imgBase64Path);
            this.formRefuel.patchValue({
              ticket_image: imgBase64Path
            });
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

}
