import { Component, Input } from '@angular/core';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: '[app-item-contract-types]',
  template: `
    <div class="row p-3">
      <div class="col-8">
        <h4 class="mb-0">{{contract_type.name}}</h4>
        <p class="mb-0">{{contract_type.description}}</p>
      </div>
      <div class="col-4 text-end">
        <button class="btn btn-outline-primary" routerLink="{{contract_type.id}}">
          <fa-icon [icon]="icons.faEdit" class="me-2"></fa-icon>
          Editar
        </button>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemContractTypesComponent {
  @Input() contract_type: any;

  constructor(
    public icons: IconsService
  ) { }

}
