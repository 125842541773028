import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-role-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 mt-4">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <!-- button class="btn btn-link btn-sm" routerLink="/settlements">
              <fa-icon [icon]="faChevronLeft" class="text-muted"></fa-icon>
              Condominios
            </!-->
            <h1 class="mt-4">Roles y permisos</h1>
            <p>Agrega la configuración de acuerdo a los permisos.</p>
          </div>
        </div>    
        <div class="card border-0 rounded-4 mb-5">
          <div class="card-body p-4">
            <div class="row">
              <div class="col-md-12"> 
                <app-form-role (roleData)="createRole($event)" [role]="role"></app-form-role> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class RoleCreateComponent implements OnInit {
  role: any = null;
  faChevronLeft = faChevronLeft;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.set('@roleCreate', 'Crear rol');
  }

  createRole(event: any) {
    this.store.createRole(event).subscribe({
      next: (response: any) => {
        this.toastr.success('Rol creado correctamente');
        this.router.navigate(['/roles']);
      },
      error: (error: any) => {
        this.toastr.error('No se pudo crear el rol');
      }
    });
  }

}
