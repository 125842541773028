import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DateService } from 'src/app/shared/services/date.service';
import { FullCalendarComponent } from '@fullcalendar/angular';


@Component({
  selector: 'app-task-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-9 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Crear Tarea</h1>
            <!-- p>Agregar tareas</!-->
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <app-form-task
                      (taskData)="createTask($event)"
                      (builderData)="updateBuilder($event)"
                      (dateData)="updateDate($event)"
                      (timeData)="updateTime($event)"
                      (durationData)="updateDuration($event)"
                      [profile]="profile"
                      [skills]="skills"
                      [currentDay]="currentDay"
                      [activities]="selectedBuilder?.activities"
                      [loading]="loading"
                    ></app-form-task>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="selectedBuilder">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="mb-2">Constructor </h5>
                    <div class="list-group mt-3">
                      <div class="list-group-item rounded-2" *ngFor="let activity of selectedBuilder.activities">
                        <div class="row">
                          <div class="col-md-8">
                            {{ activity.name }}
                          </div>
                          <div class="col-md-4 text-end">
                            {{ activity.weight }}
                          </div>
                        </div>
                      </div>

                      <h5 class="mb-2 mt-4">Habilidades requeridas</h5>
                      <div *ngIf="skills.length>0">
                        <div class="list-group-item" *ngFor="let skill of skills">
                          <div class="row">
                            <div class="col-md-8">
                              {{ skill.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="skills.length==0">
                        <div class="list-group">
                          <div class="list-group-item text-center">No se requieren habilidades</div>
                        </div>
                      </div>

                      <h5 class="mb-2 mt-4">Herramientas requeridas</h5>
                      <div *ngIf="tools.length>0">
                        <div class="list-group-item" *ngFor="let tool of tools">
                          <div class="row">
                            <div class="col-md-8">
                              {{ tool.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="tools.length==0">
                        <div class="list-group">
                          <div class="list-group-item text-center">No se requieren herramientas</div>
                        </div>
                      </div>
                    </div>

                    <h5 class="mb-2 mt-4">Resumen</h5>
                    <table class="table table-striped mt-3">
                      <tr>
                        <td colspan="2" class="border-bottom"><strong>Tiempo estimado</strong></td>
                        <td class="text-end border-bottom">{{totalTime}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-bottom"><strong>Peso total</strong></td>
                        <td class="text-end border-bottom">{{totalWeight}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-bottom"><strong>Total de habilidades</strong></td>
                        <td class="text-end border-bottom">{{totalSkills}}</td>
                      </tr>
                      <tr>
                        <td colspan="2" class="border-bottom"><strong>Total de herramientas</strong></td>
                        <td class="text-end border-bottom">{{totalTools}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow mt-4">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TaskCreateComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
  
  loading: boolean = false;
  tecnicos: any[] = [];
  selectedBuilder: any = null;
  totalTime: number = 0;
  totalWeight: number = 0;
  totalSkills: number = 0;
  totalTools: number = 0;
  profile: any = {};
  permissions: any = {}; 

  dateTasks: any[] = [];

  skills: any[] = [];
  tools: any[] = [];
  fullTasks: any[] = [];

  currentDay = new Date().toISOString().split('T')[0];

  payload: any = {
    page: 1,
    per_per: 100,
    role_id: 3,
  };

  calendarOptions: CalendarOptions = {    
    plugins: [dayGridPlugin, timeGridPlugin],
    locale: esLocale,
    selectable: true,
    nowIndicator: true,
    displayEventTime: true,
    eventDisplay: 'block',
    eventTimeFormat: { // like '14:30:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
  };

  
  constructor(
    private store: SettlementsService,
    private taskService: TasksService,
    private router: Router,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    private dateService: DateService,
  ) { 
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    // this.profile = JSON.parse(localStorage.getItem('profile') || '{}');
    this.store.getUsers(this.payload).subscribe({
      next: (data: any) => {
        this.tecnicos = data.data;
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('No se encontraron usuarios técnicos.', 'Error');
      }
    });
    this.breadcrumbService.set('@index', 'Servicio técnico');
    this.breadcrumbService.set('@tasks', 'Tareas');
    this.breadcrumbService.set('@taskCreate', 'Crear tarea');
  }

  handleDateClick(arg: any) {
    console.log('date click! ', arg.event.id);
    // console.log('fullTasks ', this.fullTasks);
    // this.task = this.fullTasks.filter((task: any) => task.id === Number(arg.event.id))[0];
    // console.log('task ', task);    
  }

  updateBuilder(event: any) {
    this.skills = [];
    this.tools = [];
    this.taskService.getBuilder(event).subscribe({
      next: (builder: any) => {
        this.selectedBuilder = builder;
        builder.activities.map((activity: any) => {
          this.totalTime += activity.time;
          this.totalWeight += activity.weight;
          this.taskService.getActivity(activity.id).subscribe({
            next: (res: any) => {
              res.skills.map((skill: any) => {
                this.totalSkills++;
                this.skills.push(skill);
              });
              res.tools.map((tool: any) => {
                this.totalTools++;
                this.tools.push(tool);
              });
            },
            error: (err: any) => {
              console.log(err);
            }          
          });
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  updateDuration(event: any) {
    console.log('getDuration ', event);
    this.totalTime = event;

    this.dateTasks.map((task: any) => {
      this.dateService.doDateRangesOverlap(task.start, task.end, task.start, task.end);
    });
  }

  updateDate(event: any) {
    console.log('getDate ', event);
    this.currentDay = event.date;

    this.totalTime = event.totalTime;

    // this.calendarOptions.initialView = 'dayGridDay';
    // this.calendarOptions.initialDate = this.currentDay;
    const calendarApi = this.calendarComponent!.getApi();
    calendarApi.changeView('timeGridDay');
    calendarApi.gotoDate(event.date);
    calendarApi.setOption('businessHours', { daysOfWeek: [1, 2, 3, 4, 5], startTime: '08:00', endTime: '18:00' });


    this.taskService.getTasks({ assignee_id: event.technician, due_date: event.date, status: [1,2] }).subscribe({
      next: async (data: any) => {
        // console.log('TASKS ', data);
        let tasks: any[] = [];
        this.fullTasks = data.data;
        await data.data.map((task: any) => {
          // console.log("task ", task.due_time.split('T')[1].split('.')[0]);

          let endDate = this.dateService.addMinutesToDate(task.due_date, task.duration);

          console.log('endDate ===Z  ', endDate);

          let t: any = {
            id: task.id,
            title: event.builders.find((i: any) => i.id == task.builder_id).name,
            start: `${task.due_date.split('.')[0]}`,
            end: `${endDate.split('.')[0]}`,
            display: 'block',
            startEditable: true,
          };

          // console.log('task ', t);
          this.dateTasks.push(t);
        });

        console.log('tasks >===> ', this.dateTasks);
        this.calendarOptions.events = this.dateTasks;
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  updateTime(event: any) {
    console.log('getTime ', event);
    console.log('getDate', this.calendarOptions.events);
    this.calendarOptions.events = [];
    let newDateTasks: any[] = [];

    // TODO: validate if overlaps
    let events: any = this.calendarOptions.events!;
    let overlap = false;
    // console.log('events ===> ', this.dateTasks);
    let endDate = this.dateService.addMinutesToDate(`${event.data.due_date}T${event.data.due_time}`, this.totalTime);
    if (this.dateTasks.length > 0) {
      for (let i = 0; i < this.dateTasks.length; i++) {
        let task = this.dateTasks[i];
        
        // let endDate = this.dateService.addMinutesToDate(`${task.data.due_date}T${task.data.due_time}`, task.totalTime);
        let t: any = {
          id: task.id,
          title: task.title,
          start: `${task.start}`,
          end: `${task.end}`,
          display: 'block',
          startEditable: true,
        };
        // console.log('REBUILD t ===> ', t);
        newDateTasks.push(t);
        // console.log(task.start, task.end, `${event.data.due_date}T${event.data.due_time}`, `${endDate.split('.')[0]}`);
        overlap = this.dateService.doDateRangesOverlap(task.start, task.end, `${event.data.due_date}T${event.data.due_time}`, `${endDate.split('.')[0]}`);
        // console.log('overlap ===> ', overlap);
      }
    }

    if (overlap) {
      this.toastr.error('Ya existe una tarea programada en esa hora.', 'Error');
      return;
    }

    
    console.log('new TAsk endDate => ', endDate);
    let t: any = {
      id: 63,
      title: "(Nueva Tarea)", // event.builders.find((i: any) => i.id == task.builder_id).name,
      start: `${event.data.due_date}T${event.data.due_time}:00`, // `${event.data.due_date.split('.')[0]}`,
      end: `${endDate.split('.')[0]}`,
      display: 'block',
      startEditable: true,
    };
    //
    let validateWorkHours = this.dateService.doDateRangesOverlap(t.start, t.end, `${event.data.due_date}T${event.end}:00`, `${event.data.due_date}T23:59:59`);
    // 
    if (validateWorkHours) {
      this.toastr.error('La tarea se sale del horario laboral del técnico.', 'Error');
      return;
    } else {
      newDateTasks.push(t);
    }

    // console.log('t ===> ', t);
    // console.log('newDateTasks ===> ', newDateTasks);
    //
    this.calendarOptions.events = newDateTasks;
    return;
  }

  createTask(event: any) {
    this.taskService.createTask(event).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha creado la tarea correctamente.', 'Tarea creada');
        this.router.navigateByUrl('/maintenances/tasks');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido crear la tarea.', 'Error');
      }
    });
  }
}
