import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import * as bs from 'bootstrap';

@Component({
  selector: 'app-item-unassigned',
  template: `
  
    <div class="row">
    <div class="col-md-5 ps-4 pe-0 py-3">
      <h4 class="mb-0" style="font-size: 17px;">
          {{builder?.name}}          
        </h4>
        <p class="mb-1 text-muted" style="font-size: 17px;">{{task.title}}</p>
        <h5>
          <span class="badge badge-pill rounded-5 mb-0 px-3 py-1 me-2" [ngStyle]="{ background: status?.color, color: status?.text}">{{ status?.name }}</span>
          <span class="badge badge-pill rounded-5 mb-0 px-3 py-1" [ngStyle]="{ background: priority?.color}">{{ priority?.name }}</span>
        </h5>
      </div>
      <div class="col-md-4 py-3 px-0">
        <div class="clearfix mb-1">
          <div style="width: 32px !important; height: 32px !important; border-radius: 18px; overflow: hidden;" *ngIf="technician?.avatar" class="float-start bg-dark">
            <img [src]="avatar" width="100%" style="border-radius: 18px;">
          </div>
          <div class="float-start ms-2" *ngIf="technician">
            <p class="text-muted text-sm mb-0" style="font-size: 13px; line-height: 1;">#{{technician.employee_number}}</p>
            <p style="font-size: 17px; line-height: 1;" class="mb-0">{{technician.name}} {{technician.last_name}}</p>
          </div>
        </div>
        
        <p class="mb-0 text-muted" style="font-size: 12px;">Condominio</p>
        <p class="mb-0" *ngIf="settlement">{{ settlement.name }}</p>
        <p class="mb-0 text-muted" style="font-size: 14px; line-height: 1;" *ngIf="settlement">{{ settlement.address }}, {{settlement.city}}, {{settlement.state}}, {{settlement.zip}}</p>        
        <div *ngIf="loadingExtra" class="text-start">
          <fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon>
        </div>
        <div *ngIf="!loadingExtra" class="text-start mt-2">
          <div *ngFor="let tank of tanques">
            <ul class="list-unstyled mb-0">
              <li class="text-muted"><fa-icon [icon]="icons.faGasPump" class="me-2"></fa-icon>{{tank.name}} (RI: {{tank.folio_qr}})</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-2 text-end ps-0 pe-3 py-3">
        <p class="mb-0 text-muted" style="font-size: 13px;">Fecha de creación</p>
        <p class="mb-0" style="font-size: 17px;"><fa-icon [icon]="icons.faCalendarAlt" size="1x" class="me-3 text-secondary"></fa-icon>{{ task.created_at | date: 'dd/MM/yy - HH:mm' }}</p>
      </div>
      <div class="col-md-1 text-center p-0" (click)="asignTask()" *ngIf="permissions?.modules.operation.service.tareas.write">
        <div class="hover-info border-start pt-3 pe-4" style="height: 100%;">
          <fa-icon [icon]="icons.faHelmetSafety" size="1x" class="center-icon"></fa-icon>
        </div>
      </div>
    </div>
    <app-modal-asign-task [task]="task" [technicians]="technicians" (taskData)="updateTask($event)"></app-modal-asign-task>
  `,
  styleUrls: []
})
export class ItemUnassignedComponent implements OnInit {
  @Input() task: any;
  @Input() profile: any;
  @Input() permissions: any;
  @Input() technicians: any;
  @Input() settlements: any;
  @Input() builders: any;
  @Output() taskData = new EventEmitter<any>();

  builder: any = null;
  priority: any;
  status: any;
  technician: any;
  settlement: any;
  tanques: any;
  avatar: any = null;
  loadingExtra: boolean = false;

  constructor(
    private store: SettlementsService,
    private catalog: CatalogsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.priority = this.catalog.priority[this.task.priority-1];
    this.status = this.catalog.status[this.task.status];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['builders'] && changes['builders'].currentValue) {
      this.setBuilder(changes['builders'].currentValue);
    }
    if (changes['settlements'] && changes['settlements'].currentValue) {
      console.log('settlements ==> ', changes['settlements'].currentValue);
      this.settlement = changes['settlements'].currentValue.find((e: any) => e.id == this.task.settlement_id);
      if (this.settlement) this.getTanques(this.settlement.id);
    }
  }

  setBuilder(builders: any) {
    this.builder = builders.find((e: any) => e.id == this.task.builder_id);
  }

  getTanques(stl_id: any) {
    this.loadingExtra = true;
    this.store.getTanquesBySettlement(stl_id).subscribe({
      next: (data: any) => {
        console.log('data ==> ', data);
        this.tanques = data;
        this.loadingExtra = false;
      },
      error: (err: any) => {
        console.log('error ==> ', err);
        this.toastr.error('Error al obtener los tanques de la unidad', 'Error');
        this.loadingExtra = false;
      }
    });
  }

  asignTask() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalAsignTask')).show();
  }

  updateTask(data: any) {
    this.taskData.emit(data);
  }
}
