import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { faEdit, faPlus, faTrashAlt, faSave, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-creditnote-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Nota de crédito</h1>
            <p>Generar nota de crédito.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/payments/receipts">Generar recibos</!-->
            <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card rounded-4 shadow mb-4">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="alert alert-info">
                      <p class="mb-0">Recuerda de llenar la información de facturación correctamente, ya que no se podrá modificar posteriormente.</p>
                    </div>
                    <form [formGroup]="invoiceForm" (ngSubmit)="submit()">
                      <div class="col-md-12">
                        <div class="form-group required mb-4 mt-3">
                          <label for="rfc" class="form-label ms-2">RFC</label>
                          <input type="text" class="form-control form-control-lg" formControlName="rfc">
                          <div *ngIf="ufc['rfc'].touched && ufc['rfc'].invalid">
                            <div *ngIf="ufc['rfc'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                          </div>
                        </div>
                        <div class="form-group required mb-4 mt-3">
                          <label for="razon_social" class="form-label ms-2">Razón social</label>
                          <input type="text" class="form-control form-control-lg" formControlName="razon_social">
                          <div *ngIf="ufc['razon_social'].touched && ufc['razon_social'].invalid">
                            <div *ngIf="ufc['razon_social'].errors?.['required']" class="text-danger text-sm">La razón social es requerida.</div>
                          </div>
                        </div>
                        <div class="form-group required mb-4 mt-3">
                          <label for="zip_code" class="form-label ms-2">Código Postal</label>
                          <input type="text" class="form-control form-control-lg" formControlName="zip_code">
                          <div *ngIf="ufc['zip_code'].touched && ufc['zip_code'].invalid">
                            <div *ngIf="ufc['zip_code'].errors?.['required']" class="text-danger text-sm">El código postal es requerido.</div>
                          </div>
                        </div>
                        <div class="form-group required mb-4 mt-3">
                          <label for="regimen" class="form-label ms-2">Régimen fiscal</label>
                          <select class="form-select form-select-lg" formControlName="regimen">
                            <option *ngFor="let regimen of regimenes" [value]="regimen.id">{{regimen.id}} - {{regimen.name}}</option>
                          </select>
                          <div *ngIf="ufc['regimen'].touched && ufc['regimen'].invalid">
                            <div *ngIf="ufc['regimen'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                          </div>
                        </div>
                        <div class="form-group required mb-4 mt-3">
                          <label for="uso_cfdi" class="form-label ms-2">Uso CFDI</label>
                          <select class="form-select form-select-lg" formControlName="uso_cfdi">
                            <option *ngFor="let uso of usosCFDI" [value]="uso.id">{{uso.id}} - {{uso.name}}</option>
                          </select>
                          <div *ngIf="ufc['uso_cfdi'].touched && ufc['uso_cfdi'].invalid">
                            <div *ngIf="ufc['uso_cfdi'].errors?.['required']" class="text-danger text-sm">El RFC es requerido.</div>
                          </div>
                        </div>
                        <div class="form-group mb-0 mt-5 text-end">
                          <button type="button" class="btn btn-link me-2" routerLink="/invoices/creditnote">Cancelar</button>
                          <button type="submit" class="btn btn-success btn-lg rounded-5 px-4" [disabled]="loading">
                            <fa-icon [icon]="faCircleNotch" [spin]="true" class="me-2" *ngIf="loading"></fa-icon>
                            <fa-icon [icon]="faSave" class="me-2" *ngIf="!loading"></fa-icon>
                            Generar factura
                          </button>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="card rounded-4 shadow">
              <div class="card-body">
                <h3>Conceptos</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceCreditnoteCreateComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  loading: boolean = false;
  searching: boolean = false;

  conceptos: any[] = [];
  complemento: any = {};
  utilityData: any = {};
  profile: any = {};

  serie: string = 'NC';
  formapago: string = '01';

  invoiceForm: FormGroup = new FormGroup({});

  faCircleNotch = faCircleNotch;
  faSave = faSave;

  regimenes: any = [
    { id: 601, name: 'General de Ley Personas Morales' },
    { id: 603, name: 'Personas Morales con Fines no Lucrativos' },
    { id: 605, name: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { id: 606, name: 'Arrendamiento' },
    { id: 607, name: 'Régimen de Enajenación o Adquisición de Bienes' },
    { id: 608, name: 'Demás ingresos' },
    { id: 609, name: 'Consolidación' },
    { id: 610, name: 'Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { id: 611, name: 'Ingresos por Dividendos (socios y accionistas)' },
    { id: 612, name: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { id: 614, name: 'Ingresos por intereses' },
    { id: 615, name: 'Régimen de los ingresos por obtención de premios' },
    { id: 616, name: 'Sin obligaciones fiscales' },
    { id: 620, name: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { id: 621, name: 'Incorporación Fiscal' },
    { id: 622, name: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { id: 623, name: 'Opcional para Grupos de Sociedades' },
    { id: 624, name: 'Coordinados' },
    { id: 625, name: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
    { id: 626, name: 'Régimen Simplificado de Confianza' },
    { id: 628, name: 'Hidrocarburos' },
    { id: 629, name: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { id: 630, name: 'Enajenación de acciones en bolsa de valores' }
  ];

  usosCFDI: any = [
    { id: 'G01', name: 'Adquisición de mercancías.' },
    { id: 'G02', name: 'Devoluciones, descuentos o bonificaciones.' },
    { id: 'G03', name: 'Gastos en general.' },
    { id: 'I01', name: 'Construcciones.' },
    { id: 'I02', name: 'Mobiliario y equipo de oficina por inversiones.' },
    { id: 'I03', name: 'Equipo de transporte.' },
    { id: 'I04', name: 'Equipo de computo y accesorios.' },
    { id: 'I05', name: 'Dados, troqueles, moldes, matrices y herramental.' },
    { id: 'I06', name: 'Comunicaciones telefónicas.' },
    { id: 'I07', name: 'Comunicaciones satelitales.' },
    { id: 'I08', name: 'Otra maquinaria y equipo.' },
    { id: 'D01', name: 'Honorarios médicos, dentales y gastos hospitalarios.' },
    { id: 'D02', name: 'Gastos médicos por incapacidad o discapacidad.' },
    { id: 'D03', name: 'Gastos funerales.' },
    { id: 'D04', name: 'Donativos.' },
    { id: 'D05', name: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).' },
    { id: 'D06', name: 'Aportaciones voluntarias al SAR.' },
    { id: 'D07', name: 'Primas por seguros de gastos médicos.' },
    { id: 'D08', name: 'Gastos de transportación escolar obligatoria.' },
    { id: 'D09', name: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.' },
    { id: 'D10', name: 'Pagos por servicios educativos (colegiaturas).' },
    { id: 'S01', name: 'Sin efectos fiscales.' },
    { id: 'CP01', name: 'Pagos' },
    { id: 'CN01', name: 'Nómina' }
  ]

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!).profile;
  }

  ngOnInit(): void {
    this.initForm();
    this.breadcrumbService.set('@creditNote', 'Notas de crédito');
    this.breadcrumbService.set('@creditNoteCreate', 'crear');  
    
    this.store.getProfile(this.profile.utility_id).subscribe((data: any) => {
      this.utilityData = data;
        let imageUrl = `${this.apiUrl}/storage/${data.logo.blob.key.substring(0,2)}/${data.logo.blob.key.substring(2,4)}/${data.logo.blob.key}`;
          this.getBase64ImageFromUrl(imageUrl).then((result: any) => {
            this.utilityData.logo = "data:image/jpeg;base64," + result.split(',')[1];
          });
        console.log('utilityData  => ', this.utilityData);
    });
  }

  get ufc() {
    return this.invoiceForm.controls;
  }

  initForm() {
    this.invoiceForm = this.fb.group({
      rfc: ['', Validators.required],
      razon_social: ['', Validators.required],
      regimen: ['', Validators.required],
      uso_cfdi: ['', Validators.required],
      zip_code: ['', Validators.required],
    });
  }

  submit() {
    this.loading = true;

    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = (new Date(Date.now() - tzoffset)).toISOString();

    let encabezados: any = {
      CFDIsRelacionados: "",
      TipoRelacion: "04",
      Receptor:{
        RFC: this.invoiceForm.value.rfc,
        NombreRazonSocial: this.invoiceForm.value.razon_social,
        UsoCFDI: this.invoiceForm.value.uso_cfdi,
        RegimenFiscal: this.invoiceForm.value.regimen,
        Direccion: {
          Calle: "",
          NumeroExterior: "",
          NumeroInterior: "",
          Colonia: "",
          Localidad: "",
          Municipio: "",
          Estado: "",
          Pais: "",
          CodigoPostal: this.invoiceForm.value.zip_code
        },        
      },
      Fecha: localISOTime.slice(0,19),
      Serie: this.serie.toString(),
      Folio: '11111', //this.bill.id.toString(),
      MetodoPago:"PUE",
      FormaPago: this.formapago,
      Moneda: "MXN",
      LugarExpedicion: this.invoiceForm.value.zip_code,
      SubTotal: '100', // this.billForm.value.sub_total.toFixed(2),
      Total: '116', //this.billForm.value.total.toFixed(2),
    };

    this.complemento = {
      formaPago: this.formapago,
      serie: this.serie,
      folio: '11111',
      usoCFDI: this.invoiceForm.value.uso_cfdi,
      metodoPago: "PUE",
    }

    let payload: any = {      
      Encabezado: encabezados,
      Conceptos: this.conceptos,
      complemento: this.complemento
    };

    this.store.createInvoiceNotaCredito(payload).subscribe({
      next: (response: any) => {
        console.log(response);
        this.download(response);
        this.loading = false;
        this.toastr.success('La factura se generó correctamente.', 'Éxito');
      },
      error: (err: any) => {
        console.log(err);
        this.loading = false;
        this.toastr.error('Ocurrió un error al generar la factura.', 'Error');
      }
    });
  }

  download(invoice: any) {
    let invoicePayload: any = {
      invoiceData: invoice,
      conceptos: [],
      utilityData: this.utilityData
    }

    this.store.downloadNota(invoicePayload).subscribe({
      next: (data: any) => {
        const int8Array = new Uint8Array(data.result.data);
        const blob = new Blob([int8Array], { type: 'application/pdf' });

        let fileURL = window.URL.createObjectURL(blob);
        let link=document.createElement('a');

        link.href = fileURL;
        link.download = data.filename; // filePath.substr(filePath.lastIndexOf('/') + 1);
        link.click();

        this.store.updateInvoice(invoice.id, { pdf: data.filename }).subscribe((data: any) => {
          console.log('updateInvoice => ', data);
        });
      },
      error: (err: any) => {}
    });
  }

  async getBase64ImageFromUrl(imageUrl: any) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    console.log("BLOB => ", blob);
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
