import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentsService } from 'src/app/services/payments.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faCircleNotch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-item-payment-method',
  template: `
    <div class="row">
      <div class="col-10 col-md-8">
        <img [src]="cardType(paymentMethod.card_type)" alt="Card image cap" width="44" class="float-start me-3"> 
        <h5 class="pt-0 mb-0">{{paymentMethod.masked_card_number}}</h5>
        <p class="mb-0 muted"><small>{{paymentMethod.alias}}</small></p>
      </div>
      <div class="col-2 col-md-4 text-end">
        <!-- a routerLink="/tenant/payment-method/edit/{{paymentMethod.id}}" class="btn btn-outline-primary me-2">
          <fa-icon [icon]="faEdit"></fa-icon>
          Editar</! -->
        <button class="btn btn-outline-danger" (click)="remove(paymentMethod)">
          <fa-icon [icon]="faTrash"></fa-icon>
        </button>
      </div>
    </div>  
  `,
  styleUrls: []
})
export class ItemPaymentMethodComponent {

  @Input() paymentMethod: any;
  @Output() paymentMethodData = new EventEmitter<any>();

  faEdit = faEdit;
  faTrash = faTrashAlt;

  constructor(
    private paymentStore: PaymentsService,
    private toastr: ToastrService
  ) { }

  remove(paymentMethod: any) {
    this.paymentMethodData.emit(paymentMethod);
  }

  cardType(card: any) {
    if (card == 'VI') {
      return "/assets/images/icon-visa-sm.png";
    } else if (card == 'MC') {
      return "/assets/images/icon-mastercard-sm.png";
    } else if (card == 'AM') {
      return "/assets/images/icon-amex-sm.png";
    } else if (card == 'CASH') {
      return "/assets/images/icon-cash.png";
    } else {
      return "/assets/images/icon-visa-sm.png";
    }
  }

}
