<div class="container-fluid p-0">
  <div class="row mt-0 p-0">
    <div class="col-md-4 h-100 bg-dark"></div>
    <div class="col-md-8 offset-md-0 pt-0 mt-0 text-center bg-white">
      <div class="row mt-5 mb-5"></div>
      <div *ngIf="loading" class="row mt-5 pt-5">
        <div class="col-md-6 offset-md-3">
          <h3 class="mb-5">Verificando cuenta</h3>
          <fa-icon [icon]="faCircleNotch" [spin]="true" size="3x" *ngIf="loading" class="text-secondary me-2"></fa-icon>
          <p class="mt-5">Validando cuenta....</p>
        </div>
      </div>
      <div *ngIf="!loading" class="row mt-5 pt-5">
        <div *ngIf="validated" class="col-md-6 offset-md-3">
          <h3 class="mb-5">Verificando cuenta</h3>
          <p>Cuenta validada, redirigiendo...</p>
        </div>
        <div *ngIf="!validated" class="col-md-6 offset-md-3">
          <div class="formGroup">
            <h3 class="mb-2">Error al verificar</h3>
            <button type="button" class="btn btn-link">Renviar correo de verificación</button>
          </div>
        </div>
      </div>
      <div class="redirectToLogin mt-5">
        <span class="text-sm">Regresar?<span class="redirect btn btn-link text-sm" routerLink="/sign-in">Iniciar sesión</span></span>
      </div>
    </div>
  </div>
</div>
