import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-form-contract',
  template: `
    <form [formGroup]="contractForm" (ngSubmit)="saveForm()">
      <!-- div class="form-group mt-3">
        <label for="name">Nombre</label>
        <input type="text" class="form-control" id="name" formControlName="name">
      </!-->
      <div class="row">
        <div class="col-8">
          <div class="form-group mt-3">
            <div class="alert alert-info">
              <strong>Nota:</strong> Al guardar el contrato, se generará un PDF con los datos mostrados y se envíara un correo electrónico al usuario principal asociado a este departamento/local para iniciar el proceso de firma de contrato. Por favor valida la información.
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group mt-3">
            <label for="vigencia">Vigencia</label>
            <select class="form-select form-select-lg" id="vigencia" formControlName="vigencia">
              <option selected disabled>Selecciona una vigencia</option>
              <option value="1">1 año</option>
              <option value="2">2 años</option>
              <option value="3">3 años</option>
              <option value="4">4 años</option>
              <option value="5">5 años</option>
            </select>
          </div>
          <div class="form-group mt-3">
            <label for="contract_type">Tipo de contracto</label>
            <select class="form-select form-select-lg" id="contract_type" formControlName="contract_type_id">
              <option selected disabled>Selecciona un tipo de contrato</option>
              <option *ngFor="let contractType of contractTypes" [value]="contractType.id">{{ contractType.name }}</option>
            </select>
          </div>
          <div class="form-group mt-3 text-end">
            <button class="btn btn-primary btn-lg rounded-5">
              <fa-icon [icon]="icons.faSave" class="me-2"></fa-icon>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormContractComponent implements OnInit {
  @Input() contract: any;
  @Input() contractTypes: any;
  @Output() contractData = new EventEmitter<any>();

  loading: boolean = false;
  contractForm: FormGroup = new FormGroup({});

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.contractForm = this.fb.group({
      contract_type_id: [''],
      vigencia: [''],
    });
  }

  saveForm(): void {
    console.log('saveForm');
    if (this.contractForm.invalid) {
      this.toastr.error('Favor de llenar todos los campos');
    }
    //
    this.contractData.emit(this.contractForm.value);    
  }

}
