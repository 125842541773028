import { SettlementsService } from './../../services/settlements.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-service',
  template: `
    <form [formGroup]="formService" (ngSubmit)="saveService()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Nombre del servicio</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
            <!-- div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </!-->
          </div>
          <div class="form-group mb-4 mt-3">
            <label for="description" class="form-label ms-2">Descripción</label>
            <input type="text" class="form-control form-control-lg rounded-3" id="description" placeholder="" formControlName="description">
            <div *ngIf="ufc['description'].touched && ufc['description'].invalid">
              <div *ngIf="ufc['description'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
          </div>
          <div class="form-group required mb-4 mt-3">
            <label for="status" class="form-label ms-2">Estatus</label>

            <!-- input type="text" class="form-control form-control-lg rounded-3" id="status" placeholder="" formControlName="status" -->
            <select class="form-select form-select-lg" formControlName="status">
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>

            <div *ngIf="ufc['status'].touched && ufc['status'].invalid">
              <div *ngIf="ufc['status'].errors?.['required']" class="text-danger text-sm">El estatus es requerido.</div>
            </div>
          </div>
          <!-- div class="form-group required mb-4 mt-3">
            <label for="name" class="form-label ms-2">Condominio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" (change)="selectSettlement($event)">
              <option selected>Selecciona un condominio</option>
              <option *ngFor="let settlement of settlements" [value]="settlement.id">{{ settlement.name }}</option>
            </select>
          </!-->
          <!-- div class="form-group required mb-4 mt-3" *ngIf="buildings.length>0">
            <label for="name" class="form-label ms-2">Edificio</label>
            <select class="form-select form-select-lg rounded-3" aria-label=".form-select-lg example" formControlName="building_id">
              <option selected>Selecciona un edificio</option>
              <option *ngFor="let building of buildings" [value]="building.id">{{ building.name }}</option>
            </select>
          </!-->
          <div class="form-group required mb-0 mt-4 text-end">
            <button type="button" class="btn btn-link me-2" routerLink="/services">Cancelar</button>
            <button type="submit" class="btn btn-success btn-lg rounded-5">Guardar</button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormServiceComponent implements OnInit {
  @Input() service: any = {};
  @Output() serviceData = new EventEmitter<any>();

  formService: FormGroup = new FormGroup({});

  settlements: any[] = [];
  buildings: any[] = [];

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {
    // this.route.params.subscribe(params => {
      // console.log(params);
      // let payload: any = {
        // page: 1,
        // per_page: 30,
        // tipo: 0
      // }
        // this.store.getSettlements(payload).subscribe({
          // next: (data) => {
            // this.settlements = data;
          // },
          // error: (err) => {
            // console.log(err);
            // this.toastr.error('Ocurrió un error al obtener los condominios.');
          // }
        // });
    // });
  } 

  get ufc() {
    return this.formService.controls;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.formService = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      status: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['service'].currentValue) {
      this.formService.patchValue(changes['service'].currentValue);
    }
  }

  selectSettlement(e: any) {
    console.log('selectSettlement');
    console.log(e.target.value);
    this.store.getBuildingsBySettlement(e.target.value).subscribe({
      next: (data) => {
        console.log(data);
        this.buildings = data;
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  // selectBuilding(e: any) {
    // this.formService.patchValue({
      // building_id: e.target.value
    // });
  // }

  saveService() {
    console.log(this.formService.value);
    if (this.formService.invalid) {
      this.formService.markAllAsTouched();
      return;
    }

    this.serviceData.emit(this.formService.value);    
  }
}
