import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-user-password',
  template: `
    <ng-template #modalUserPassword let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Cambiar contraseña</h5>
        <button type="button" (click)="dismiss()" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
          <fa-icon [icon]="icons.faTimes"></fa-icon>
        </button>
      </div>
      <div class="modal-body p-4">
        <form [formGroup]="passwordForm">
          <div class="form-group mt-3">
            <label class="form-control-label" for="password">Contraseña</label>
            <div class="input-group">
              <input
                id="password"
                [type]="showPassword ? 'text' : 'password'"
                formControlName="password"
                class="form-control rounded-3 rounded-end-0 border-end-0"
                placeholder="Contraseña"
              >
              <span class="input-group-append bg-white border-start-0">
                <span class="input-group-text bg-transparent rounded-3 rounded-start-0 border-start-0" (click)="showPassword=!showPassword">
                  <span *ngIf="showPassword"><fa-icon [icon]="icons.faEye" class="text-dark me-2"></fa-icon></span>
                  <span *ngIf="!showPassword"><fa-icon [icon]="icons.faEyeSlash" class="text-dark me-2"></fa-icon></span>
                </span>
              </span>
            </div>
          </div>
          <div class="form-group mt-3">
            <label class="form-control-label" for="password_confirmation">Confirmar contraseña</label>
            <input type="password" class="form-control" formControlName="password_confirmation" name="password_confirmation" required>
          </div>

          <div class="form-group mt-4">
            <password-strength-meter [password]="passwordForm.value.password"></password-strength-meter>
          </div>
          <small id="passwordHelpBlock" class="form-text text-muted text-start text-sm">
            Su contraseña debe tener entre 8 y 20 caracteres, contener letras, números, caracteres especiales, y no debe contener espacios, ni emojis.
          </small>

          <div *ngIf="lf['password'].touched && lf['password'].invalid" class="text-start">
            <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
            <small *ngIf="lf['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
            <small *ngIf="lf['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
            <small *ngIf="!lf['password'].errors?.['minlength'] && lf['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($#!%*?&)</small>
          </div>
          <div *ngIf="lf['password_confirmation'].touched && lf['password_confirmation'].invalid" class="text-start">
            <div *ngIf="lf['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
            <small *ngIf="lf['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" (click)="savePassword()">Guardar</button>
      </div>
    </ng-template>
  `,
  styleUrls: []
})
export class ModalUserPasswordComponent implements AfterViewInit {
  @Input() user: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalUserPassword') modalUserPassword !: ElementRef;

  passwordForm: FormGroup = new FormGroup({});
  showPassword: boolean = false;

  get lf() {
    return this.passwordForm.controls;
  }

  private modalRef: NgbModalRef | undefined;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public icons: IconsService,
    private modalService: NgbModal
  ) { }

  ngAfterViewInit(): void {
    this.initForm();
  }

  savePassword() {
    console.log('Password saved');
    if (!this.passwordForm.valid) {
      this.toastr.error('Favor de llenar todos los campos');
      return;
    }

    if (this.passwordForm.value.password !== this.passwordForm.value.password_confirmation) {
      this.toastr.error('Las contraseñas no coinciden');
      return;
    }

    this.store.updateUser(this.user.id, this.passwordForm.value).subscribe({
      next: (resp: any) => {
        this.toastr.success('Contraseña actualizada correctamente');
        this.dismiss();
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al actualizar la contraseña');
        console.log(err);
      }
    });
  }

  initForm() {
    this.passwordForm = this.fb.group({
      password: [''],
      password_confirmation: ['']
    });
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
