import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-contract-types-index',
  template: `
    <div class="row">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-9 offset-xl-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Contratos</h1>
          </div>
          <div class="col-md-7 mt-4 text-end">
            
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 mt-4">
            <div class="list-group rounded-5 shadow mt-2 overflow-hidden" *ngIf="contract_types.length > 0">
              <div class="list-group-item" 
                app-item-contract-types 
                [contract_type]="contract_type"
                *ngFor="let contract_type of contract_types | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }"
              >              
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="contract_types.length==0">
                <div class="list-group-item text-center py-5" *ngIf="loading">
                  <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                  <p class="text-muted">Cargando...</p>
                </div>
                <div class="list-group-item text-center py-5" *ngIf="!loading">
                  <img src="/assets/images/empty-state.png" width="240px">
                  <h5 class="text-muted">No se encontraron registros</h5>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ContractTypesIndexComponent {

  contract_types: any[] = [];
  meta: any = {};
  loading: boolean = false;

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.loadContractTypes();
  }

  loadContractTypes() {
    this.loading = true;
    this.store.getContractTypes({}).subscribe((data: any) => {
      this.contract_types = data.data;
      this.meta = data.meta;
      this.loading = false;
    });
  }



}
