import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-gas-meter-maintenance',
  template: `
    <div #modalGasMeterMaintenance id="modalGasMeterMaintenance" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalGasMeterMaintenance" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Mantenimiento de medidor de gas</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="icons.faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body p-4">
            <form [formGroup]="meterForm" (ngSubmit)="onSubmit()">
              <div class="form-group mt-2">
                <label for="consumption">Lectura previa al mantenimiento</label>
                <input type="number" formControlName="consumption" class="form-control form-control-lg" id="consumption" placeholder="">
              </div>
              <div class="form-group mt-2">
                <label for="reason">Motivo del mantenimiento</label>
                <input type="text" formControlName="reason" class="form-control form-control-lg" id="reason" placeholder="">
              </div>
              <div class="form-group text-end mt-4">
                <button type="submit" class="btn btn-primary rounded-5 btn-lg">Crear mantenimiento</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalGasMeterMaintenanceComponent implements OnInit {
  @Input() meter: any;

  meterForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService,
  ) { }

  ngOnInit(): void {
    // console.log('METER ==========> ', this.meter);
    this.initForm();
  }

  initForm() {
    this.meterForm = this.fb.group({
      id: [],
      consumption: [''],
      reason: [''],
      gas_meter_id: [this.meter.id]
    });
  }

  onSubmit() {
    console.log('ngSubmit', this.meterForm.value);
    if (this.meterForm.invalid) {
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.store.createGasMeterMaintenance(this.meterForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Mantenimiento se creó correctamente.', 'Éxito');
        // this.loadUnit(this.unit.id);
        window.location.reload();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(`Mantenimiento no pudo ser creado. \n ${err.error.exception}`, 'Error');
      }
    });
  }

}
