import { Component, Input, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-item-provider',
  template: `
    <div style="overflow: hidden; width: 44px; height: 44px; border-radius: 40px;">
      <img [src]="avatarURL" width="100%">
    </div>
  `,
  styleUrls: []
})
export class ItemItemProviderComponent implements OnInit {
  @Input() provider: any;
  apiUrl: string = environment.AWS_REST_WSPREFIX;

  avatarURL: string = '';

  constructor(
    private store: SettlementsService,
  ) { }

  ngOnInit(): void {
    this.loadAvatar();
  }

  loadAvatar() {
    this.store.getProvider(this.provider.id).subscribe({
      next: (user: any) => {
        
        let el = user.avatar;
        console.log('el => ', user);
        if (el == null) {
          this.avatarURL = 'assets/images/avatar.png';
        } else {
          this.avatarURL = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;
        }
      },
      error: (err: any) => {
        this.avatarURL = 'assets/images/avatar.png';
      }
    });
  }
}
