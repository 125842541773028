import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-vehicle-asign',
  template: `
    <div #modalVehicleAsign id="modalVehicleAsign" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalVehicleAsign" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content rounded-5">
          <div class="modal-header">
            <h5 class="modal-title">Asignación de vehículos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="icons.faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body py-4">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="formVehicle">
                  <div class="alert alert-warning">Cuidado: Al ralizar la asignación si la unidad está asignada a otro usuario se le quitará y asignará al usuario seleccionado.</div>
                  <div class="mt-3 mb-3" *ngIf="technician">
                    <h5>Operador actualmente asignado</h5>
                    <p class="mb-0">{{technician.name}} {{technician.last_name}}</p>
                  </div>
                  <div class="form-group required mb-4 mt-3">
                    <label for="date" class="form-label ms-2">Operador</label>
                    <select class="form-select form-select-lg rounded-4" formControlName="assignee_id">
                      <option selected disabled>Selecciona un técnico/operador</option>
                      <option *ngFor="let technician of technicians" [value]="technician.id">{{technician.name}} {{technician.last_name}}</option>
                    </select>
                    <div *ngIf="ufc['assignee_id'].touched && ufc['assignee_id'].invalid">
                      <div *ngIf="ufc['assignee_id'].errors?.['required']" class="text-danger text-sm">El técnico/operador es requerido.</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-lg rounded-5" (click)="saveVehicle()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalVehicleAsignComponent implements OnInit {
  @Input() technicians: any;
  @Input() technician: any;
  @Input() vehicle: any;
  @Output() vehicleData = new EventEmitter<any>();
  formVehicle: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.formVehicle = this.fb.group({
      assignee_id: ['', Validators.required],
    });
  }

  get ufc() { return this.formVehicle.controls; }

  saveVehicle() {
    console.log('Save vehicle');
    this.vehicleData.emit(this.formVehicle.value);
  }
}
