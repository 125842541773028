import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-activity-type',
  template: `
    <form [formGroup]="formActivityType" (ngSubmit)="saveActivityType()">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group mb-4">
          <label for="title" class="form-label ms-2">Título</label>
          <input type="text" class="form-control form-control-lg rounded-3" id="title" placeholder="" formControlName="title">
          <div *ngIf="ufc['title'].touched && ufc['title'].invalid">
              <div *ngIf="ufc['title'].errors?.['required']" class="text-danger text-sm">El título es requerido.</div>
            </div>
        </div>
        <div class="form-group mb-4">
          <label for="description" class="form-label ms-2">Descripción</label>
          <input type="text" class="form-control form-control-lg rounded-3" id="description" placeholder="" formControlName="description">
          <div *ngIf="ufc['description'].touched && ufc['description'].invalid">
              <div *ngIf="ufc['description'].errors?.['required']" class="text-danger text-sm">La descripción es requerido.</div>
            </div>
        </div>
        <div class="form-group text-end">
          <button type="button" class="btn btn-link me-2" routerLink="/maintenances/activity-types">Cancelar</button>
          <button type="submit" class="btn btn-success btn-lg rounded-5 px-4">Guardar</button>
        </div>
      </div>
    </div>
  </form>
  `,
  styleUrls: []
})
export class FormActivityTypeComponent implements OnInit {
  @Input() activity_type: any;
  @Input() loading: boolean = false;
  @Input() profile: any;
  @Output() activityTypeData = new EventEmitter<any>();

  formActivityType: FormGroup = new FormGroup({});

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {    
  }

  ngOnInit(): void {
    this.initForm();
    this.route.params.subscribe((params: any) => {
      console.log("PROFILE => ", this.profile);
      this.formActivityType.patchValue({
        profile_id: this.profile.profile.id
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activity_type'] && changes['activity_type'].currentValue) {
      this.formActivityType.patchValue(changes['activity_type'].currentValue);
      this.preFill();
    }
  }

  get ufc() {
    return this.formActivityType.controls;
  }

  initForm() {
    this.formActivityType = this.fb.group({
      id: [''],
      title: ['', Validators.required],
      description: [''],
      profile_id: [''],
      status: [true],
    });
  }

  preFill() {
    console.log(this.activity_type);
    this.formActivityType.patchValue(this.activity_type);
    this.formActivityType.patchValue({
      profile_id: this.profile.profile.id
    });
  }

  saveActivityType() {
    console.log("FORM => ", this.formActivityType.value);
    if (this.formActivityType.invalid) {
      this.formActivityType.markAllAsTouched();
      this.toastr.error('Revisa los campos en rojo.', 'Error');
      return;
    }

    this.activityTypeData.emit(this.formActivityType.value);
  }
}
