import { TasksService } from 'src/app/services/tasks.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import { SettlementsService } from 'src/app/services/settlements.service';
import { environment } from 'src/environments/environment';
import esLocale from '@fullcalendar/core/locales/es';
import { DateService } from 'src/app/shared/services/date.service';
import { TextService } from 'src/app/shared/services/text.service';
import { ToastrService } from 'ngx-toastr';
import { SummaryService } from 'src/app/services/summary.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-dashboard',
  template: `
    <div class="row" *ngIf="permissions?.modules.admin.dashboard.active">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-9 offset-xl-3 mt-3">
        <div class="row mt-2">
          <div class="col-md-6">
            <div class="row mt-4" *ngIf="settlementId == 0">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <div class="avatar-container text-center" style="left: 0;">
                      <div class="image-container border border-4" style="width: 110px; height: 110px;">
                        <img [src]="profileLogo" alt="logo" class="img-fluid" style="width: 80%; padding-top: 10px;">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <h5 class="mb-0">PREFIL</h5>
                    <h1 style="line-height: 0.8;">{{profile.name}}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card rounded-5 shadow bg-light">
              <div class="card-body">
                <div class="row">
                  
                  <div class="col-md-4 text-center mt-2">
                    <h1 [countUp]="totalSettlements?.value" [duration]="500" style="font-size: 50px;" class="mb-0"></h1>
                    <p>Condominios</p>
                  </div>
                  <div class="col-md-4 text-center mt-2">
                    <h1 [countUp]="totalComm?.value" [duration]="500" style="font-size: 50px;" class="mb-0"></h1>
                    <p>Comerciales</p>
                  </div>
                  <div class="col-md-4 text-center mt-2" *ngIf="totalPay?.value">
                    <h1 [countUp]="technicians.length" [duration]="500" style="font-size: 50px;" class="mb-0"></h1>
                    <p>Técnicos</p>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-12 col-sm-6">
            <div class="rounded-5 shadow overflow-hidden" *ngIf="geojson.features.length>0">
              <app-map [geojson]="geojson"></app-map>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card rounded-5 shadow overflow-hidden">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-12" >
                    <h5>Cartera global</h5>
                  </div>
                  <div class="col-12 col-sm-4 text-center" #portafolioChart id="portafolioChart" *ngIf="portafolio.past_due">
                    <app-pie-chart-portfolio [data]="portafolio"></app-pie-chart-portfolio>
                  </div>
                  <div class="col-12 col-sm-8">
                    <div class="row mt-4">
                      <div class="col-6 text-center" *ngIf="portafolio.past_due">
                        <h4 [countUp]="portafolio.past_due" [currency]="'$'" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Vencido</p>
                      </div>
                      <div class="col-6 text-center" *ngIf="portafolio.current">
                        <h4 [countUp]="portafolio.current" [currency]="'$'" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Vigente</p>
                      </div>
                      <div class="col-6 text-center mt-4" *ngIf="portafolio.liters">
                        <h4 [countUp]="portafolio.liters" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Total lts. cartera</p>
                      </div>
                      <div class="col-6 text-center mt-4">
                        <h4 [countUp]="portafolio.paid" [currency]="'$'" class="mb-0"></h4>
                        <p class="mb-0 text-muted">Total pagos</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-2">
                    <h5>Clientes con más adeudos (Top 3)</h5>
                    <table class="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th></th>
                          <th class="text-end">Total</th>
                          <th class="text-end">Lts.</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let ov of portafolio.overview">
                          <td>{{ov.settlement_name}}</td>
                          <td class="text-end">{{ov.total | currency}}</td>
                          <td class="text-end">{{ov.lts | number: '1.2-2'}} Lts.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 mb-4">
            <div class="card rounded-5 shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-sm-4">
                    <app-filter-advanced
                      [toDate]="toDate"
                      [fromDate]="fromDate"
                      (dateRange)="updateDate($event)"
                    ></app-filter-advanced>
                  </div>
                  <div class="col-12 col-sm-2">
                    <button class="btn btn-outline-primary rounded-5 w-100" (click)="reportConsumo()">
                      <fa-icon [icon]="icons.faFileExcel" class="me-2"></fa-icon>
                      Consumo
                    </button>
                  </div>
                  <div class="col-12 col-sm-2">
                    <button class="btn btn-outline-primary rounded-5 w-100" (click)="reportTrabajados()">
                      <fa-icon [icon]="icons.faFileExcel" class="me-2"></fa-icon>
                      Trabajados
                    </button>
                  </div>
                  <div class="col-12 col-sm-2">
                    <button class="btn btn-outline-primary rounded-5 w-100" (click)="reportEficiencia()">
                      <fa-icon [icon]="icons.faFileExcel" class="me-2"></fa-icon>
                      Eficiencia
                    </button>
                  </div>
                  <div class="col-12 col-sm-2">
                    <button class="btn btn-outline-primary rounded-5 w-100" (click)="reportGeneral()">
                      <fa-icon [icon]="icons.faFileExcel" class="me-2"></fa-icon>
                      Eficiencia
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="card rounded-5 shadow" id="cardConsumptions" #cardConsumptions>
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-12">
                    <h5>Consumo del periodo</h5>
                  </div>
                  <div class="col-12" *ngIf="consumption_consumptions.length>0">
                    <app-line-chart-consumption
                      [data]="consumption_consumptions"
                      [start_date]="payload.start_date"
                      [end_date]="payload.end_date"
                    ></app-line-chart-consumption>
                  </div>
                  <div class="col-12" *ngIf="consumption_consumptions.length==0">
                    <div class="text-center py-5" >
                      <img src="/assets/images/empty-state.png" width="240px">
                      <p class="text-muted">No se encontraron registros</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="card rounded-5 shadow mb-5" id="cardConsumptions" #cardConsumptions >
              <div class="card-body py-4 px-5" >
                <div class="row">
                  <div class="col-12">
                    <h5>Lts. Trabajados VS Lts. Consumidos</h5>
                  </div>
                  <div class="col-12" *ngIf="consumption_tanques.length>0">
                    <app-stl-bar-chart-consumptions
                      [data]="consumption_data"
                    ></app-stl-bar-chart-consumptions>
                  </div>
                  <div class="col-12" *ngIf="consumption_tanques.length==0">
                    <div class="text-center py-5" >
                      <img src="/assets/images/empty-state.png" width="240px">
                      <p class="text-muted">No se encontraron registros</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-6">
            <div class="card rounded-5 shadow overflow-hidden">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-12" *ngIf="calendarOptions">
                    <full-calendar [options]="calendarOptions"></full-calendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="card rounded-5 border-0 shadow overflow-hidden">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <h5>Ordenes de suministro</h5>
                  </div>
                  <div class="col-4 text-end">
                    Ordenes: {{pos.length}}
                  </div>
                  <div class="col-12">
                    <div class="list-group">
                      <div class="list-group-item" *ngFor="let po of pos">
                        <div class="row">
                          <div class="col-1"></div>
                          <div class="col-8">
                            <h5 class="mb-0">{{po.folio}}</h5>
                            <p class="mb-0">Lts.{{po.liters}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card rounded-5 border-0 shadow overflow-hidden mt-3">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-12">
                    <h5>Resumen de tareas</h5>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th>Tareas programadas</th>
                          <td class="text-end">{{fullTasks.length}}</td>
                        </tr>
                        <tr>
                          <th>Tareas no asignados</th>
                          <td class="text-end">{{unasigned_tasks.length}}</td>
                        </tr>
                        <tr>
                          <th>Ordenes de suministro</th>
                          <td class="text-end">{{pos.length}}</td>
                        </tr>
                        <!-- tr>
                          <th>Tareas no asignada</th>
                          <td class="text-end">0</td>
                        </!-->
                        <!-- tr>
                          <th>Tareas no asignada</th>
                          <td class="text-end">0</td>
                        </!-->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="card rounded-5 shadow overflow-hidden mt-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-12" id="chart-dos" *ngIf="tech_summary.length>0">
                    <h5>Calificación de últimas tareas</h5>
                    <!-- ngx-charts-line-chart
                      [view]="[dos[0],dos[1]]"
                      [results]="consumptions"
                      [legendTitle]="'Product Sale Chart'"
                      [legend]="false"
                      [showXAxisLabel]="true"
                      [showYAxisLabel]="true"
                      [xAxis]="true"
                      [yAxis]="true"
                    >
                    </!-->
                    <div *ngIf="tech_summary">
                      <app-bar-chart [multi]="tech_summary"></app-bar-chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!permissions?.modules.admin.dashboard.active">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-3">
        <h1 class="text-center mt-5">PLATAFORMA ZAZZ</h1>
      </div>
    </div>
    <app-calendar-event
      [top]="top"
      [left]="left"
      [isVisible]="isVisible"
      [selectedTask]="selectedTask"
      [taskCategories]="taskCategories"
      [settlements]="settlements"
      [technicians]="technicians"
      [priority]="priority"
      [status]="status"
      (isVisibleChange)="toggleVisibility($event)"
    ></app-calendar-event>
  `,
  styleUrls: []
})
export class DashboardComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  profile: any = {};
  permissions: any = {};
  
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay' // user can switch between the two
    },
    locale: esLocale,
    selectable: true,
    nowIndicator: true,
    displayEventTime: true,
    eventDisplay: 'block',
    eventTimeFormat: { // like '14:30:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
    businessHours: {
      daysOfWeek: [ 1, 2, 3, 4, 5, 6 ], // Monday - Thursday
      startTime: '9:00', // a start time (10am in this example)
      endTime: '18:00', // an end time (6pm in this example)
    },
    eventClick: this.handleDateClick.bind(this),
    datesSet: this.handleDateChange.bind(this)
  };

  top: number = 0; // Default top position
  left: number = 0; // Default left position
  isVisible: boolean = false; // Default visibility

  cardColor: string = '#232837';

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  uno: any = [0,500];
  dos: any = [0,500];
  tres: any = [0,500];

  settlementId: any = 0;
  allSettlements: any = [];
  settlement: any = {};
  settlements: any = [];

  technicians: any = [];
  selectedTask: any = {};

  totalPay: any = {};
  totalReads: any = {};
  totalSettlements: any = {
    "name": "Condominios",
    "value": 0,
  };
  totalComm: any = {
    "name": "Comerciales",
    "value": 0,
  };
  totalUnits: any = {};

  profileLogo: string = '';
  

  payload: any = {
    per_page: 1000,
    page: 1,
    profile_id: 0,
    year: 2024,
    month: 5,
  }

  priority: any = [
    { id: 1, name: 'Baja', color: '#219C90' },
    { id: 2, name: 'Media', color: '#E9B824' },
    { id: 3, name: 'Alta', color: '#EE9322' },
    { id: 4, name: 'Urgente', color: '#D83F31' },
  ];

  status: any = [
    { id: 1, name: 'Pendiente', color: '#3C4F76', textColor: '#fff'},
    { id: 2, name: 'En proceso', color: '#E9B824', textColor: '#000'},
    { id: 3, name: 'Completada', color: '#D1BEB0', textColor: '#000'},
    { id: 4, name: 'Cancelada', color: '#EE9322', textColor: '#fff'},
  ];

  a = new Date();
  fromDate: any; // = "2023/12/01"; // Date = new Date();
  toDate: any; // = "2024/02/02"; // Date = new Date();

  // SUMMARY DATA

  fullTasks: any[] = [];
  unasigned_tasks: any[] = [];
  taskCategories: any[] = [];

  consumption_data: any[] = [];
  consumption_consumptions: any[] = [];
  consumption_tanques: any[] = [];

  tech_summary: any = [];
  portafolio: any = {};

  pos: any = [];
  
  geojson: { type: string; features: any[] } = {
    type: 'FeatureCollection',
    features: []
  };

  constructor(
    public authService: AuthService,
    private store: SettlementsService,
    private summ: SummaryService,
    private tasksService: TasksService,
    private dateService: DateService,
    private textService: TextService,
    private toastr: ToastrService,
    public icons: IconsService

  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
    this.payload.year = this.a.getFullYear();
    // this.payload.month = this.a.getMonth()+1;
    this.payload.month = [1,2,3,4,5,6,7,8,9,10]

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);

    if (this.profile.logo) {
      const el = this.profile.logo;
      this.profileLogo = `${this.apiUrl}/storage/${el.blob.key.substring(0,2)}/${el.blob.key.substring(2,4)}/${el.blob.key}`;   
    }
  }

  ngOnInit(): void {
    // this.loadSettlement(this.settlementId);
    this.loadStatSummary();
    this.loadBillsSettlement();
    this.getSettlements(this.profile.id);
    this.loadTaskCategories();
    this.loadTechnicians();
    this.loadPOs();
    this.loadTankSummary();
    this.loadPortafolio();
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;
    //
    // this.query.date_from = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
    // this.query.date_to = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;

    // this.loadBills(this.query);
  }

  handleDateClick(arg: any) {
    this.top = arg.jsEvent.pageY;
    this.left = arg.jsEvent.pageX;
    this.isVisible = true;
    this.selectedTask = this.fullTasks.find((task: any) => task.id === Number(arg.event.id));  
  }

  handleDateChange(arg: any) {
    this.payload.due_date_since = arg.startStr.split('T')[0];
    this.payload.due_date_to = arg.endStr.split('T')[0];
    this.loadTasks(this.payload);
  }

  async loadTechnicians() {
    this.store.getUsers({ page: 1, per_page: 100, role_id: 3, profile: this.profile.id }).subscribe((techs: any) => {
      this.technicians = techs.data;
    });
  }

  async loadTaskCategories() {
    this.tasksService.getBuilders({ page: 1, per_page: 100}).subscribe((cat: any) => {
      this.taskCategories = cat.data;
      this.loadTasks(this.payload);
    });    
  }

  loadTasks(payload: any) {
    this.tasksService.getTasks(payload).subscribe({
      next: async (resp: any) => {
        let tasks: any[] = [];
        this.fullTasks = resp.data;
        await resp.data.map((task: any) => {
          if (task.due_date == null) {
            this.unasigned_tasks.push(task);
            return;
          }
          let t: any = {
            id: task.id,
            title: this.textService.truncateTextAtWord(this.taskCategories.find((c: any) => c.id === task.builder_id).name, 15),
            start: `${task.due_date.split('.')[0]}`,
            end: `${this.dateService.addMinutesToDate(task.due_date, task.duration).split('.')[0]}`,
            display: 'block',
            startEditable: true,
            backgroundColor: this.status.find((p: any) => p.id === task.status).color,
            borderColor: this.status.find((p: any) => p.id === task.status).color,
            textColor: this.status.find((p: any) => p.id === task.status).textColor,
          };

          tasks.push(t);
        });
        //
        this.calendarOptions.events = tasks;
      },
      error: (err: any) => {
        this.toastr.error('No se ha podido cargar las tareas.', 'Error');
      }
    });
  }

  loadPOs() {
    let payload: any = {
      per_page: 1000,
      page: 1,
      profile_id: this.profile.id,
      status: false,
    };
    this.store.getPurchaseOrders(payload).subscribe((pos: any) => {
      console.log('pos ==> ', pos);
      this.pos = pos.data;
    });
  }

  getSettlements(id: any) {
    this.store.getSettlements({
      utility_id: this.profile.utility_id,
      per_page: 1000,
      page: 1,
    }).subscribe((stls: any) => {
      this.allSettlements = stls.data; 
      let condo = this.allSettlements.filter((stls: any) => {
        return stls.tipo == 0
      });
      let comm = this.allSettlements.filter((stls: any) => stls.tipo == 1);
      //
      this.totalSettlements = {
        "name": "Condominios",
        "value": condo.length,
      };
      this.totalComm = {
        "name": "Comerciales",
        "value": comm.length,
      }

      stls.data.map((stl: any) => {
        if (stl.latitude == null || stl.longitude == null) return;
        let feature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [stl.longitude, stl.latitude]
          },
          properties: {
            title: stl.name,
            description: stl.address
          }
        };        
        this.geojson.features.push(feature);
      });

      

      let units = 0;
      // this.allSettlements.map((stl: any) => {
        // this.store.getUnitsBySettlement(stl.id).subscribe((units: any) => {
          // console.log('units ==> ', units);
          // units += units.length;
        // });
      // });

      this.totalUnits = {
        "name": "Unidades",
        "value": 500,
      }

    });
  }

  loadSettlement(id: any) {
    if (id == 0) return;
    this.store.getSettlement(id).subscribe((stl: any) => {
      this.settlement = stl;
    });
  }

  loadBillsSettlement() {
    this.store.getBillsBySettlement(42, {
      state: 1,
      status: true,
      periodo_inicio: '2024-01-01',
      periodo_fin: '2024-02-01'
    }).subscribe((bills: any) => {
      let sum = 0;
      let sum_gas = 0;

      console.log('bills ==> ', bills)

      if (bills.data.length == 0) return;

      bills.map(async (bill: any) => {
        sum += bill.total;
        /*await this.store.getGasMeterReadings({ unit_id: bill.unit_id }).subscribe((readings: any) => {
          console.log('readings ==> ', readings.data);
          readings.data.map((reading: any) => {
            console.log('reading ==> ', reading.value);
            sum_gas += reading.value;
            console.log('sum_gas ==> ', sum_gas);

            this.totalReads = {
              "name": "Consumo de gas",
              "value": sum_gas,
            }
          });
        });*/
      });

      this.totalPay = {
        "name": "Pagos del mes",
        "value": sum,
      };

      this.totalReads = {
        "name": "Consumo de gas",
        "value": sum_gas,
      }
    });
  }

  loadSettlements() {
    this.store.getSettlements({
      tipo: 0,
      per_page: 1000,
      page: 1
    }).subscribe((stls: any) => {
      this.settlements = stls.data;      
    });
  }

  loadStatSummary() {
    this.tasksService.getTechStatSummaries({}).subscribe((res: any) => {
      // this.hardata = res;
      this.tech_summary = res.data;
    });
  
  }

  selectSettlement(e: any) {
    this.settlementId = e.target.value;
    // this.loadBillsPaidSummary();
    this.loadSettlement(this.settlementId);
  }

  toggleVisibility(event: any) {
    this.isVisible = event;
  }

  loadTankSummary() {
    console.log('loadTankSummary settlementId => ', this.payload)
    this.summ.getConsumeSummaryByProfileMonth(this.payload).subscribe((data: any) => {
      console.log('getTanqueSummary data => ', data);
      this.consumption_data = data;
      this.consumption_consumptions = data.consumptions;
      this.consumption_tanques = data.readings;
    });
  }

  loadPortafolio() {
    console.log('loadPortafolio settlementId => ', this.settlementId)
    this.store.getBillsPortafolioProfile({ profile_id: this.payload.profile_id })
    .subscribe((response: any) => {
      console.log('getBillsPortfolio response => ', response);
      this.portafolio = response;
    });
  }

  reportConsumo() {
    console.log('reportConsumo', this.payload);
    console.log('fromDate ==> ', this.fromDate);
    console.log('toDate ==> ', this.toDate);

    // this.query.date_from = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
    // this.query.date_to = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;

    let data: any = [];
    this.store.getConsumptionsByProfile({
      profile_id: this.profile.id,
      created_at_since: `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`, 
      created_at_to: `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    }).subscribe((response: any) => {
      console.log('response ==> ', response);
      data = response.data;
    
      let options = {
        title: `Reporte de consumo por perfil`,
        fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          noDownload: false,
          showTitle: true,
          useBom: true,
          headers: [
            '#ID',
            'Litros',
            'Fecha',
            'Tanque',
            '#Recibo',
            'Unidad',
            'Edificio',
            'Condominio',
            'Perfil',
          ],
      };

      new ngxCsv(data, 'Reporte consumo', options);
    });
  }

  reportTrabajados() {
    console.log('reportTrabajados');
    let data: any = [];
    this.store.getWorkedByProfile({
      profile_id: this.profile.id,
      created_at_since: `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`, 
      created_at_to: `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`
    }).subscribe((response: any) => {
      console.log('response ==> ', response);
      data = response.data;
    
      let options = {
        title: `Reporte de lts. trabajados por perfil`,
        fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          noDownload: false,
          showTitle: true,
          useBom: true,
          headers: [
            '#ID',
            'Porcentaje',
            'Lts. Trabajados',
            'Fecha',
            'Tanque',
            'Edificio',
            'Condominio',
            'Perfil',
          ],
      };

      new ngxCsv(data, 'Reporte trabajados', options);
    });
  }

  reportEficiencia() {
    console.log('reportEficiencia');
  }

  reportGeneral() {
    console.log('reportGeneral');
  }
}
