import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faCircleNotch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Payment } from 'src/app/models/payment.model';
import { PaymentsService } from 'src/app/services/payments.service';

@Component({
  selector: 'app-tenant-payment-methods',
  template: `
    <div class="row">
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-2 mt-sm-5">
        <div class="row mb-4">
          <div class="col-12 col-md-5">
            <h1>Métodos de pago</h1>
            <p>Configura tus métodos de pago.</p>
          </div>
          <div class="col-12 col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="add">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar tarjeta
            </button>
          </div>
        </div>
      </div>
      <div class="col-10 offset-1 col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 mt-5 mb-5">
        <div class="card border-0 rounded-5 shadow">
          <div class="card-body p-4">
            <div class="row">
              <div class="col-md-12">
                <h2 class="card-title mb-4">Tarjetas guardadas</h2>
                <div class="alert alert-info">
                  <p class="mb-0">Almacenar tus métodos de pago permite que puedas realizar compras recurrentes con mayor facilidad y seguridad.</p>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <ul class="list-group mb-5" *ngIf="paymentMethods.length>0" >
                  <li class="list-group-item list-group-item-action p-3" *ngFor="let paymentMethod of paymentMethods">
                    <app-item-payment-method (paymentMethodData)="removeTC($event)" [paymentMethod]="paymentMethod"></app-item-payment-method>      
                  </li>
                </ul>
                <ul class="list-group" *ngIf="paymentMethods.length==0" >
                  <li class="list-group-item text-center" *ngIf="!loading" >
                    <h4 class="mt-4 mb-3">No existen métodos de pago</h4>
                    <button class="btn btn-primary btn-lg rounded-5 mb-4" routerLink="add">Agregar</button>
                  </li>
                  <div *ngIf="loading" class="text-center m-3">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                </ul>
              </div>
              <div class="col-md-12">
                <p class="text-muted"><small>La tokenización de tarjetas de crédito es una técnica que permite reemplazar los datos sensibles de la tarjeta, como el número y la fecha de vencimiento, por un código único y generado aleatoriamente, conocido como token.</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TenantPaymentMethodsComponent implements OnInit {
  tenantId: number = 0;
  paymentMethods: any = [];
  loading: boolean = false;

  faEdit = faEdit;
  faPlus = faPlus;
  faTrash = faTrashAlt;
  faCircleNotch = faCircleNotch;

  constructor(
    private settlementsService: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private paymentStore: PaymentsService
  ) {
    this.activateRoute.params.subscribe(params => {
      this.tenantId = params['id'];
      this.loadPaymentMethods(params['id']);
    });
  }

  ngOnInit(): void {
      
  }

  loadPaymentMethods(id: any) {
    this.loading = true;
    this.settlementsService.getPaymentMethodsByTenant(id).subscribe((data: any) => {
      console.log('data => ', data);
      this.paymentMethods = data;
      this.loading = false;
    });
  }

  removeTC(paymentMethod: any) {
    console.log('removeTC');
    this.paymentStore.destroyPaymentMethod(paymentMethod.id).subscribe({
      next: (data: any) => {
        this.toastr.success('Método de pago eliminado correctamente', 'Éxito');
        this.loadPaymentMethods(this.tenantId);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Error al eliminar el método de pago', 'Error');
      }
    });
  }

  

}
