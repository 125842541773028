import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class SupportService extends CommonService {

  constructor(
    private HttpClient: HttpClient,
  ) {
    super(HttpClient);
  }

  paymentFailed(data: any): void {

    this.HttpClient.post(`${this.supportURL}/TN9PPQPAP/C06NN7PHJER/7xrMLZyd89IIvVImZHIjTL00`, {
      json: {
				"response_type": "in_channel",
				"attachments": [
					{
						"title": "Tarjeta agregada",
						"pretext": 'Tarjeta no agregada',
						"color": "good",
						"text": "Exito! \n",
						"footer": "IO DEVOPS",
						"ts": Date.now()
					}
				]
			}
    });

  }
}
