import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-gas-meter',
  template: `
    <form [formGroup]="meterForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group required mt-2">
            <label for="name">Nombre</label>
            <input type="text" formControlName="name" class="form-control form-control-lg" id="name" placeholder="">
            <div *ngIf="ufc['name'].touched && ufc['name'].invalid">
              <div *ngIf="ufc['name'].errors?.['required']" class="text-danger text-sm">El nombre es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="meter_code">Clave QR</label>
            <input type="text" formControlName="meter_code" class="form-control form-control-lg" id="meter_code" placeholder="">
          </div>
          <div class="form-group required mt-2">
            <label for="meter_type">Tipo de medidor</label>
            <input type="text" formControlName="meter_type" class="form-control form-control-lg" id="meter_type" placeholder="">
            <div *ngIf="ufc['meter_type'].touched && ufc['meter_type'].invalid">
              <div *ngIf="ufc['meter_type'].errors?.['required']" class="text-danger text-sm">El tipo de medidor es requerido.</div>
            </div>
          </div>
          <div class="form-group mt-2">
            <label for="meter_status">Estatus</label>
            <select formControlName="meter_status" class="form-select form-select-lg" id="meter_status">
              <option value="ACTIVO" selected>Activo</option>
              <option value="INACTIVO">Inactivo</option>
            </select>
          </div>
          <div class="form-group mt-2">
            <label for="meter_brand">Marca del medidor</label>
            <input type="text" formControlName="meter_brand" class="form-control form-control-lg" id="meter_brand" placeholder="">
          </div>
          <div class="form-group mt-2">
            <label for="meter_serial">Número de serie</label>
            <input type="text" formControlName="meter_serial" class="form-control form-control-lg" id="meter_serial" placeholder="">
          </div>
          <!-- div class="form-group mt-2">
            <label for="meter_location_latitude">Latitud</label>
            <input type="text" formControlName="meter_location_latitude" class="form-control form-control-lg" id="meter_location_latitude" placeholder="">
          </!-->
          <!-- div class="form-group mt-2">
            <label for="meter_location_longitude">Longitud</label>
            <input type="text" formControlName="meter_location_longitude" class="form-control form-control-lg" id="meter_location_longitude" placeholder="">
          </!-->
          <div class="form-group required mt-2">
            <label for="last_read">Última lectura</label>
            <input type="text" formControlName="last_read" class="form-control form-control-lg" id="last_read" placeholder="">
            <div *ngIf="ufc['last_read'].touched && ufc['last_read'].invalid">
              <div *ngIf="ufc['last_read'].errors?.['required']" class="text-danger text-sm">La última lectura es requerido.</div>
            </div>
          </div>
          <div class="form-gropu required mt-2">
            <input type="checkbox" formControlName="comodato" id="comodato" class="form-check-input" formControlName="comodato">
            <label for="comodato" class="form-check-label">Comodato</label>
          </div>
          <div class="form-group mt-5 text-end">
            <!-- button type="button" (click)="cancel()" class="btn btn-link me-2">Cancelar</!-->
            <button type="submit" class="btn btn-success btn-lg rounded-5">
            <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="loading" class="ms-2 text-dark"></fa-icon>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: []
})
export class FormGasMeterComponent implements OnInit {
  @Input() meter: any;
  @Input() unit_id: any;
  @Output() updateMeter = new EventEmitter<any>();
  @Output() createMeter = new EventEmitter<any>();
  @Output() cancelMeter = new EventEmitter<any>();

  loading: boolean = false;
  extraData: boolean = false;
  meterForm: FormGroup = new FormGroup({});

  faEdit = faEdit;
  faCircleNotch = faCircleNotch;

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    // console.log('FORM ==========> ', this.unit_id)
    // this.loadUser();
    if (this.unit_id) {
      this.meterForm.patchValue({
        unit_id: this.unit_id
      });
    } else {
      this.extraData = true;
    }
  }

  buildForm() {
    // console.log('BUILD FORM');
    this.meterForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      meter_code: [''],
      meter_type: [''],
      meter_status: ['ACTIVO'],
      meter_brand: [''],
      meter_serial: [''],
      meter_location_latitude: [''],
      meter_location_longitude: [''],
      last_read: ['', Validators.required],
      unit_id: [''],
      comodato: [false]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['meter'] && changes['meter'].currentValue) {
      // console.log('meter => ', changes['meter'].currentValue);
      this.meterForm.patchValue(changes['meter'].currentValue);
    }
  }

  get ufc() {
    return this.meterForm.controls;
  }

  cancel() {
    this.cancelMeter.emit(null);
  }

  onSubmit() {
    this.loading = true;

    this.meterForm.patchValue({
      unit_id: this.unit_id
    });

    console.log(this.meterForm.value)

    if (this.meterForm.invalid) {
      this.loading = false;
      this.toastr.error('Error', 'Formulario incompleto.');
      this.meterForm.markAllAsTouched();
      return;
    }

    if (this.meterForm.value.id) {
      this.updateMeter.emit(this.meterForm.value);
    } else {
      this.createMeter.emit(this.meterForm.value);
    }

    
    this.loading = false;
  }

}
