import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IconsService } from 'src/app/shared/services/icons.service';
@Component({
  selector: 'app-modal-tools',
  template: `
    <ng-template #modalTools let-modal>
      <div class="modal-header">
        <h5 class="modal-title">Herramientas</h5>
        <button type="button" (click)="dismiss()" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
          <fa-icon [icon]="icons.faTimes"></fa-icon>
        </button>
      </div>
      <div class="modal-body pt-3 pb-3">
        <div class="list-group shadow-sm" *ngIf="tools.length>0">
          <div class="list-group-item" *ngFor="let tool of tools">
            <div class="form-check form-switch ps-1">
              <input
                class="form-check-input float-end"
                type="checkbox"
                [checked]="tool.checked"
                [value]="tool.id"
                (change)="toggleTool($event)"
              >
              <label class="form-check-label" for="flexCheckDefault">
                {{tool.name}}
              </label>
            </div>
          </div>              
        </div>
        <div class="list-group shadow-sm" *ngIf="tools.length==0">
          <div class="list-group-item">
            <p class="mb-0 text-center text-muted">No hay herramientas disponibles</p>
          </div>
        </div>
      </div>
    </ng-template>
        
  `,
  styleUrls: []
})
export class ModalToolsComponent implements OnInit {
  @Input() technician: any;
  @Input() profile: any;
  @Output() updateDismiss: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('modalTools') modalTools !: ElementRef;

  private modalRef: NgbModalRef | undefined;

  tools: any = [];
  asigned_tools: any = [];

  constructor(
    private tasks: TasksService,
    private toastr: ToastrService,
    public icons: IconsService,
    private modalService: NgbModal
    
  ) { }

  ngOnInit(): void {
    this.loadTools();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['technician'] && changes['technician'].currentValue) {
      this.loadAssignedTools();
    }
  }

  loadTools() {
    console.log('loadTools');
    this.tasks.getTools(this.profile.id).subscribe({
      next: (resp: any) => {
        console.log('TOOLS LOADAD resp ===============> ', resp);
        this.tools = resp.data;
      },
      error: (err: any) => {
        console.log('err => ', err);
      }
    });
  }

  loadAssignedTools() {
    if (!this.technician.id) return;
    this.tasks.getUserTools(this.technician.id).subscribe({
      next: (resp: any) => {
        this.asigned_tools = resp;
        console.log('this.asigned_tools ==> ', this.asigned_tools);
        console.log('this.tools ==> ', this.tools);
        this.asigned_tools.map((tool_asigned: any) => {
          this.tools.forEach((tool: any) => {
            tool.checked = tool.id == tool_asigned.id ? true : false;
          });
        });
      },
      error: (err: any) => {
        console.log('err => ', err);
      }
    });
  }

  toggleTool(tool: any) {
    console.log('tool => ', tool.target.value);
    const toolId = tool.target.value;
    const isChecked = tool.target.checked;
    if (isChecked) {
      this.tasks.addUserTool(toolId, { assignee_id: this.technician.id }).subscribe({
        next: (resp: any) => {
          console.log('resp => ', resp);
          this.toastr.success('Herramienta agregada correctamente', 'Éxito');
          this.dismiss();
        },
        error: (err: any) => {
          console.log('err => ', err);
          this.toastr.error(err.error.message, 'Error');
        }
      });
    } else {
      this.tasks.removeUserTool(toolId).subscribe({
        next: (resp: any) => {
          console.log('resp => ', resp);
          this.toastr.success('Herramienta removida correctamente', 'Éxito');
          this.dismiss();
        },
        error: (err: any) => {
          console.log('err => ', err);
          this.toastr.error(err.error.message, 'Error');
        }
      });
    }
  }

  openModal(modalContent: TemplateRef<any>) {
    this.modalRef = this.modalService.open(modalContent);
  }

  dismiss() {
    if (this.modalRef) {
      this.updateDismiss.emit();
      this.modalRef.dismiss();
    }
  }
}
