import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as mapboxgl from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  mapbox = (mapboxgl as typeof mapboxgl);
  map: mapboxgl.Map | undefined;
  style = `mapbox://styles/mapbox/streets-v12`;
  // Coordenadas de la localización donde queremos centrar el mapa
  lat = 19.423354;
  lng = -99.133253;
  zoom = 11;
  constructor() {
    // Asignamos el token desde las variables de entorno
    this.mapbox.accessToken = environment.MAPBOX_TOKEN;
  }
  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: this.zoom,
      attributionControl: false,
      // pitch: 60, // pitch in degrees
      // bearing: -60,
      center: [this.lng, this.lat]
    });
    this.map.addControl(new mapboxgl.NavigationControl());
  }
  
}
