import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-stl-bar-chart-consumptions',
  template: `
    <div id="legend"></div>
    <figure id="bar-consumptions"></figure>    
  `,
  styles: [
  ]
})
export class StlBarChartConsumptionsComponent {
  @Input() data: any;
  
  private svg: any;
  private margin = 32;
  private width = 340 - (this.margin * 2);
  private height = 280 - (this.margin * 2);

  public yScale: any;
  public xScale: any;

  constructor() { }

  ngOnInit(): void {
    this.createSvg();
    this.drawChart(this.data);
  }

  private createSvg(): void {
    const myElement = document.getElementById('cardConsumptions');
    if (myElement) {
      this.width = myElement.getBoundingClientRect().width - 60;
    }

    this.svg = d3.select("figure#bar-consumptions")
    .append("svg")
    .attr("width", '100%')
    .attr("height", this.height + (this.margin * 2))
    .append("g")
    .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
  }

  simpleGroup(array: any[], key: string) {
    const groupedData = array.reduce((acc, obj) => {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(obj);
      return acc;
    }, {});
  
    return { keys: Object.keys(groupedData), data: groupedData };
  }

  groupBy(array1: any[], array2: any[], key: string) {
    return array1.reduce((acc, obj) => {
        const groupKey = obj[key];
        const correspondingObj = array2.find(item => item[key] === groupKey);
        if (!acc[groupKey]) {
            acc[groupKey] = [];
        }
        const combinedObj = { ...obj, ...correspondingObj };
        // delete combinedObj[key];
        acc[groupKey].push(combinedObj);
        return acc;
    }, {});
  }

  private drawChart(data: any): void {
    // console.log("data ====> ", data);
    let dates = data.consumptions.map((d: any) => {
      let dac: any = {
        "liters": d["liters"],
        "month": d["month"],
        "year": d["year"],
        "week": d["week"],
        "tanque": d["tanque_id"],
      };
      return dac; 
    });

    let readings = data.readings.map((d: any) => {
      let dac: any = {
        "litersRead": d["litersread"],
        "month": d["month"],
        "year": d["year"],
        "week": d["week"],
        "tanque": d["tanque_id"],
        "settlement_id": d["settlement_id"],
      };
      return dac; 
    });

    // // console.log('DATES ======> ', dates);
    // // console.log('READINGS ======> ', readings);

    const groupedData = this.groupBy(readings, dates, 'week');
    console.log('GROUPED DATA ======> ', groupedData);
    const groupdWeeks = this.simpleGroup(readings, 'week');
    let color = d3.scaleOrdinal(d3.schemeCategory10);
    var subgroups = ['litersRead', 'liters'];

    // this.xScale.domain(dates.map(function(d) { return d.week; }));
    // this.yScale.domain([0, d3.max(dates, function(d) { return d.liters; })]);
  
    console.log('WEEKS !!!! ===> ', dates.map((d: any) => d.week));

    const x = d3.scaleBand()
      .range([0, this.width])
      // .domain(d3.groupSort(data, ([d]) => -d.Stars, (d) => d.Framework))
      .domain(dates.map((d: any) => d.week))
      .padding(0.2);

    // Draw the X-axis on the DOM
    this.svg.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end");

    var xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding(0.05);

    let yMax =  Number(d3.max(dates.map((d: any) => Number(d.liters) )));

    console.log("YMAX => ", yMax);

    // Create the Y-axis band scale
    const y = d3.scaleLinear()
      .domain([0, yMax]) // Convert liters to a number
      .range([this.height, 0]);

    // console.log('dates => ', dates);

    const ctl = this;

    var tooltip = d3.select("figure#bar-consumptions")
      .append("div")
      .style("position", "absolute")
      .style("z-index", "10000")
      .style("visibility", "hidden")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px");

    groupdWeeks.keys.forEach((key: any) => {
      this.svg.append("g")
        .selectAll("g")
        .data(groupedData[key])
        .enter()
        .append("g")
          .attr("transform", function(d: any) { 
            // console.log("d ====> ", d);
            return "translate(" + x(d.week) + ",0)"; 
          })
        .selectAll("rect")
        .data(function(d: any) { return subgroups.map((key) => { return {key: key, value: d[key]}; }); })
        .enter().append("rect")
          .attr("x", function(d: any) { return xSubgroup(d.key); })
          .attr("y", function(d: any) { return y(d.value); })
          .attr("width", xSubgroup.bandwidth())
          .attr("height", function(d: any) { return ctl.height - y(d.value); })
          .attr("fill", function(d: any) { return color(d.key); })
          // .text('AAA')
          .on("mouseover", (d: any, i: any) => {
            // console.log('MOUSEOVER i ===> ', i);
            // console.log('MOUSEOVER d ===> ', d);
            let tipo: any = i.key === 'liters' ? 'Consumos' : 'Lecturas';
            tooltip
              .text(`${tipo} ${i.value.toFixed(2)} lts.`)
              .style("visibility", "visible")
              .style('top', (d.offsetY - 10) + 'px')
              .style('left', (d.offsetX + 10) + 'px');
          })
          .on("mouseout", (d: any) => {
            tooltip.style("visibility", "hidden");
          });
    });

    //Initialize legend
    var legendItemSize = 12;
    var legendSpacing = 4;
    var xOffset = 0;
    var yOffset = 0;
    var legend = d3
      .select('#legend')
      .append('svg')
      .selectAll('.legendItem')
      .data(subgroups);

    //Create legend items
    legend
      .enter()
      .append('rect')
      .attr('class', 'legendItem')
      .attr('width', legendItemSize)
      .attr('height', legendItemSize)
      .style('fill', (d: any) => {
        if (d === 'litersRead') {
          return '#1f77b4';
        } else {
          return '#ff7f0e';
        }
        // console.log("COLOR FILL )Z> ", d)
        // return color(d.color);
      })
      .attr('transform',
        (d, i) => {
            var x = xOffset;
            var y = yOffset + (legendItemSize + legendSpacing) * i;
            return `translate(${x}, ${y})`;
        });
    
    //Create legend labels
    legend
      .enter()
      .append('text')
      .attr('x', xOffset + legendItemSize + 5)
      .attr('y', (d, i) => yOffset + (legendItemSize + legendSpacing) * i + 12)
      // .text("AAA")
      .text((d: any) => { 
        if (d === 'litersRead') {
          return 'Lts. Trabajados';
        } else {
          return 'Lts. Consumidos';
        }
      });
    // Create and fill the bars
    // this.svg.selectAll()
      // .data(dates)
      // .enter()
      // .append("rect")
      // .attr("x", (d: any) => x(d.week))
      // .attr("y", (d: any) => y(d.liters))
      // .attr("width", x.bandwidth())
      // .attr("height", (d: any) => this.height - y(d.liters))
      // .attr("fill", 'orange');

    // this.svg.selectAll()
      // .data(readings)
      // .enter()
      // .append("rect")
      // .attr("x", (d: any) => x(d.week))
      // .attr("y", (d: any) => y(d.liters))
      // .attr("width", x.bandwidth())
      // .attr("height", (d: any) => this.height - y(d.liters))
      // .attr('fill', 'red')
  }
}
