import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-tenant-invoice',
  template: `
    <div class="row">
      <div class="col-md-7">
        <div class="form-group mb-4">
          <h5>FACTURA</h5>
          <h2 class="mb-0">{{invoice.unit_name}}</h2>
          <h3 class="mb-0">{{invoice.uuid}}</h3>
        </div>
        <div class="form-group">
          <table class="table table-bordered table-sm">
          <tr>
              <th>Factura</th>
              <td class="text-end"># <i>{{invoice?.folio}}</i></td>
            </tr>
            <tr>
              <th>Subtotal</th>
              <td class="text-end">{{invoice?.sub_total | currency: 'USD'}}</td>
            </tr>
            <tr>
              <th>IVA</th>
              <td class="text-end">{{invoice?.taxes | currency: 'USD'}}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td class="text-end">{{invoice?.total | currency: 'USD'}}</td>
            </tr>
            <tr>
              <th>Método de pago</th>
              <td class="text-end">{{invoice?.metodoPago}}</td>
            </tr>
            <tr>
              <th>Uso</th>
              <td class="text-end">{{usocfdi(invoice?.usoCFDI).id}} - {{usocfdi(invoice?.usoCFDI).name}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-12 col-sm-4 offset-0 offset-sm-1" *ngIf="permissions?.modules?.tenants?.payments?.active">
        <div class="form-group mb-4 mt-2">
          <a href="{{receiptUrl}}/public/{{invoice.pdf}}" target="_blank" class="btn btn-outline-info rounded-5 border border-2 border-info shadow-sm w-100">
            <fa-icon [icon]="icons.faDownload" class="me-2"></fa-icon>
            <b>Descargar factura</b>
          </a>
        </div>
        <div class="form-group mb-5">
          <button class="btn btn-outline-info rounded-5 border border-2 border-info shadow-sm w-100" (click)="downloadXML(invoice)"><b>Descargar XML</b></button>
        </div>

        <div class="form-group mt-3 mb-3" *ngIf="!completed">
          <button type="button" 
            class="btn btn-outline-warning w-100 rounded-4" 
            (click)="confirmed(invoice)"                     
            [disabled]="invoice?.is_confirmed"
          >Confirmo recepción de la factura</button>  
        </div>                                    
        <div class="form-group mt-3 mb-3" *ngIf="!completed">
          <button type="button" 
            class="btn btn-outline-success w-100 rounded-4" 
            (click)="accepted(invoice)" 
            [disabled]="!invoice?.is_confirmed || invoice?.is_accepted"
          >Confirmo aceptación de factura</button>
        </div>
      </div>
      <div class="col-12 col-sm-4 offset-0 offset-sm-1"  *ngIf="permissions?.modules?.admin?.invoices?.active">
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input border-dark" type="checkbox" id="flexSwitchCheckDefault" (click)="opened(invoice)" [checked]="invoice.is_opened" disabled="true">
            <label class="form-check-label" for="flexSwitchCheckDefault"><strong>Recibida</strong> <span class="ms-2 mb-0 text-sm text-muted">{{invoice.opened_time | date: 'dd/MM/yyyy HH:mm'}}</span></label>
          </div>          
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input border-dark" type="checkbox"  id="flexSwitchCheckDefault" (click)="read(invoice)" [checked]="invoice.is_read" disabled="true">
            <label class="form-check-label" for="flexSwitchCheckDefault"><strong>Leída</strong> <span class="ms-2 mb-0 text-sm text-muted">{{invoice.read_time | date: 'dd/MM/yyyy HH:mm'}}</span></label>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input border-dark" type="checkbox" id="flexSwitchCheckDefault" (click)="accepted(invoice)" [checked]="invoice.is_accepted" disabled="true">
            <label class="form-check-label" for="flexSwitchCheckDefault"><strong>Aceptada</strong> <span class="ms-2 mb-0 text-sm text-muted">{{invoice.accepted_time | date: 'dd/MM/yyyy HH:mm'}}</span></label>
          </div>
          
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input border-dark" type="checkbox" id="flexSwitchCheckDefault" (click)="confirmed(invoice)" [checked]="invoice.is_confirmed" disabled="true">
            <label class="form-check-label" for="flexSwitchCheckDefault"><strong>Confirmada</strong> <span class="ms-2 mb-0 text-sm text-muted">{{invoice.confirmed_time | date: 'dd/MM/yyyy HH:mm'}}</span></label>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemTenantInvoiceComponent {
  @Input() invoice: any;
  @Input() permissions: any;
  
  tenantId: any = localStorage.getItem('tenant_id');
  loading: boolean = false;
  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  completed: boolean = false;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService,
    public catalog: CatalogsService
  ) { }

  opened(invoice: any) {
    this.store.setInvoiceOpened({tenant_id: this.tenantId, invoice_id: invoice.id}).subscribe({
       next: (data: any) => {
         console.log(data);
         invoice.is_opened =  data.invoice.is_opened;
          invoice.opened_time =  data.invoice.opened_time;
         this.toastr.success('Actualización correcta de la factura.', 'Éxito');
       },
       error: (err: any) => {
         console.log(err);
         if (err.status >= 500) {
           this.toastr.error('Ocurrió un error al actualizar la factura.', 'Error');
         }
         this.loading = false;
       }
     });
   }
 
   read(invoice: any) {
     this.store.setInvoiceRead({tenant_id: this.tenantId, invoice_id: invoice.id}).subscribe({
        next: (data: any) => {
          console.log(data);
          invoice.is_read =  data.invoice.is_read;
          invoice.read_time =  data.invoice.read_time;
          this.toastr.success('Actualización correcta de la factura.', 'Éxito');
        },
        error: (err: any) => {
          console.log(err);
          if (err.status >= 500) {
            this.toastr.error('Ocurrió un error al actualizar la factura.', 'Error');
          }
          this.loading = false;
        }
      });
    }
 
    accepted(invoice: any) {
     this.store.setInvoiceAccepted({tenant_id: this.tenantId, invoice_id: invoice.id}).subscribe({
        next: (data: any) => {
          console.log(data);
          invoice.is_accepted =  data.invoice.is_accepted;
          invoice.accepted_time =  data.invoice.accepted_time;
          this.toastr.success('Actualización correcta de la factura.', 'Éxito');
        },
        error: (err: any) => {
          console.log(err);
          if (err.status >= 500) {
            this.toastr.error('Ocurrió un error al actualizar la factura.', 'Error');
          }
          this.loading = false;
        }
      });
    }
 
    confirmed(invoice: any) {
     this.store.setInvoiceConfirmed({tenant_id: this.tenantId, invoice_id: invoice.id}).subscribe({
        next: (data: any) => {
          console.log(data);
          invoice.is_confirmed =  data.invoice.is_confirmed;
          invoice.confirmed_time =  data.invoice.confirmed_time;
          this.toastr.success('Actualización correcta de la factura.', 'Éxito');
        },
        error: (err: any) => {
          console.log(err);
          if (err.status >= 500) {
            this.toastr.error('Ocurrió un error al actualizar la factura.', 'Error');
          }
          this.loading = false;
        }
      });
    }
 
   
 
   downloadXML(invoice: any) {
     const blob2 = new Blob([invoice.cfdixml], { type: 'application/xml' });
 
     var fileURL2 = window.URL.createObjectURL(blob2);
     var link2=document.createElement('a');
 
     link2.href = fileURL2;
     link2.download = `${invoice.serie}_${invoice.folio}.xml`;
     link2.click();
   }
   usocfdi(uso_cfdi: any) {
     let finder = this.catalog.usosCFDI.find((uso: any) => uso.id == uso_cfdi);
     return finder;
   }
}
